import axios from 'axios';

const SERVICE_URL = __API_URL_V1;

const GetStatement = (uri) => {

  console.log(__API_URL + uri);
  return axios({
    method: 'GET',
    url:__API_URL + uri,
    responseType: 'blob',
  });
};

export {
  GetStatement,
};
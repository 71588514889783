<template>
  <FeedbackTemplate>
    <div class="flex flex-col min-h-[26.625rem]">
      <template v-if="currViewState === viewStates.error">
        <div class="block my-auto">
          <p id="error-message" class="feedback-error">
            Uh oh! There seems to be a problem.
          </p>
          <p v-if="error" class="pt-12 text-center">
            <strong>{{ error }}</strong><br />
            If you'd still like to give us feedback to help us improve how we help you
            please email any feedback you have to:
            <a class="mt-4 text-center block text-2xl" href="mailto:support@talkatoo.com"> support@talkatoo.com</a>
          </p>
          <p v-else class="pt-12 text-center">
            If you're seeing this message we have broken something! If you'd still like to give us feedback to help us improve how we help you
            please email any feedback you have to:
            <a class="mt-4 text-center block text-2xl" href="mailto:support@talkatoo.com"> support@talkatoo.com</a>
          </p>
        </div>
      </template>
      <template v-else-if="currViewState === viewStates.pendingScore || currViewState === viewStates.pendingFeedback">
        <div class="flex justify-center mb-8">
          <span class="font-bold text-3xl text-center">
            How easy was it to start using Talkatoo?
          </span>
        </div>
        <!-- score display -->
        <div class="flex flex-col pt-2 px-6">
          <TransitionGroup name="list">
            <div v-for="(n, index) in options" :key="n"
                 :class="['flex justify-center items-center',
                          score === index? ' flex-grow ': '']">
              <button v-if="score === undefined || score === index" :id="`score-btn-${index}`"
                      class="btn-feedback my-1" :selected="score === index" @click="selectScore(index)">
                {{ n }}
              </button>
            </div>
          </TransitionGroup>
        </div>

        <!-- feedback box -->
        <Transition name="fade-pull">
          <div v-if="currViewState === viewStates.pendingFeedback" class="mx-8 flex flex-col items-center feedback-transition-timing">
            <div v-if="score !== undefined" class="flex w-full justify-center py-4">
              <button class="text-sm text-main-alt underline" @click="changeScore">
                change score
              </button>
            </div>
            <div class="flex my-2 justify-center">
              <span class="text-base text-center">
                <strong>Thank you.</strong> We would love to hear why. (Optional)
              </span>
            </div>
            <textarea id="feedback-area" v-model="feedback" :placeholder="placeholder" rows="4" class="w-full border border-gray-300 flex p-2  text-black">
            </textarea>
            <button id="feedback-submit" class="py-12 mt-7 btn-lg btn-primary w-full sm:w-80"
                    :disabled="disableSubmit "
                    @click="submitFeedback">
              Submit
            </button>
          </div>
        </Transition>
      </template>
      <template v-else-if="currViewState === viewStates.feedbackFinished">
        <div class="block my-auto">

          <img src="@/assets/images/talkatoo_emblem.svg" class="w-40 m-auto mb-4">
          <p id="success-message" class="feedback-success">
            Your feedback is appreciated!
          </p>
          <p class="pt-12 text-center ">
            Your feedback will help us build a better experience for you and your colleagues, we may be in touch to help you or to get more details on the feedback you have provided!
          </p>
        </div>

      </template>
    </div>

  </FeedbackTemplate>
</template>
<script src="./CesSurvey.js"></script>
<style src="./CesSurvey.css" scoped></style>
<template>
  <div class="bg-white rounded-t-md lg:rounded-t-0">
    <div class="heading mb-5">
      <div class="flex w-full  lg:max-w-[88vw] 2xl:max-w-[98rem] flex-wrap sm:flex-nowrap justify-between">
        <div class="my-auto whitespace-nowrap text-3xl font-bold">
          All Notes
        </div>
        <div class="flex items-center pr-2 order-2 sm:order-3">
          <div class="flex ">
            <div class="block my-auto text-sm mr-2 font-normal">
              View:
            </div>
            <button
              class="note-view-button grid-cols-4 grid-rows-3 gap-[1px] mr-2"
              @click="toggleListType">
              <div class="w-full h-full"></div>
              <div class="w-full h-full col-span-3"></div>
              <div class="w-full h-full"></div>
              <div class="w-full h-full col-span-3"></div>
              <div class="w-full h-full"></div>
              <div class="w-full h-full col-span-3"></div>
            </button>

            <button class="note-view-button active grid-cols-3">
              <div class="w-full h-full"></div>
              <div class="w-full h-full"></div>
              <div class="w-full h-full"></div>
            </button>
          </div>
        </div>
        <div class="flex items-center mr-1 ml-6 order-3 sm:order-2 basis-full justify-center sm:basis-auto">
          <transition name="fade">
            <div v-if="noteUsage.start" class="flex fade-duration">
              <span class="text-sm mr-4 font-normal text-right">
                <div class="tooltip-basic relative">
                  <strong> {{ noteUsage.notesUsedInPeriod }} </strong> Notes this period <span class="text-sm text-gray-500 material-icons align-middle">info</span>
                  <div class="tooltip-text inline-block whitespace-nowrap">
                    {{ noteUsage.start }} - {{ noteUsage.end }}
                  </div>
                </div>
              </span>
            </div>
          </transition>
        </div>
      </div>

    </div>
    <!-- filters -->
    <div class="body-container kanban-filters pb-4 flex flex-row-reverse xl:flex-row items-center lg:max-w-[90vw] 2xl:max-w-[100rem] sticky top-0 lg:left-12 z-10 bg-white">
      <!-- hidden xl: -->
      <div class="xl:w-full xl:p-1 bg-[#DBE1EB] bg-opacity-40 rounded-2xl">
        <div class="flex xl:flex-grow">
          <BaseIconDropdown id="filter-list"
                            class="h-[34px] w-28 xl:mr-1 text-xl sm:text-base font-normal font-roboto text-gray-500"
                            icon="filter_list"
                            :notifCount="customFilterCount"
                            square
                            menuText="Filters"
                            :menuX="windowWidth < 1200 ? 'right-0' : 'left-0'"
                            @click-out="resetFiltersDropdown">
            <div class="px-4 w-80">
              <div class="flex justify-between">
                <span class="font-bold text-xl text-black my-2">Filters</span>
                <a class="text-main underline" @click="clearHeaderFilters">
                  Clear Filters
                </a>
              </div>
              <template v-for="(filter, index) in customFilters.input" :key="index">
                <BaseDatepicker v-if="filter.inputType == 'datepicker'" class="pb-2"
                                :label="filter.label"
                                :placeholder="filter.placeholder"
                                :date="filter.value"
                                @update:date="(range) => { filter.value = range; updateCustomFilters(filter); }" />

                <BaseSelectList v-else-if="filter.inputType == 'select'" class="pb-2"
                                :list="filter.value"
                                :label="filter.label"
                                :placeholderText="filter.placeholder"
                                :errorMessage="filter.errorMessage"
                                @select="updateCustomFilters(filter)"
                />
                <BaseMultiSelect v-else-if="filter.inputType == 'multi-select'" class="pb-2"
                                 :value="filter.value"
                                 :placeholderText="filter.placeholder"
                                 fullMenu
                                 showCount
                                 ellipsis
                                 showSearch
                                 @update:value="updateCustomFilters(filter)"
                />
              </template>
            </div>
            <div class="px-4 pb-2 flex justify-between text-base">
              <div class="flex w-full justify-between">
                <button v-if="!nameFilters" class="w-[7.5rem] p-2 text-xs border-2 cursor-pointer
                                    border-gray-400 hover:border-gray-600 hover:bg-gray-100 transition-colors rounded-md"
                        @click="resetFiltersDropdown">
                  Done
                </button>
                <button v-if="!nameFilters"
                        class="w-[7.5rem] p-2 text-xs font-bold text-white bg-main-alt hover:bg-main transition-colors
                                rounded-md" :disabled="customFilterCount == 0"
                        @click="toggleSaveFilters">
                  Name & Save
                </button>
                <div v-else class="flex w-full relative">
                  <input id="name-filter-input" v-model="filterNameValue"
                         placeholder="Name Your Filter"
                         class="h-10 w-full py-1 pl-4 pr-16 border-gray-400 border-2 rounded-md
                                  text-xs text-black "
                         @keypress.enter="saveCustomFilters" />
                  <button class="absolute p-2 bg-rare2 rounded-lg text-white font-bold text-xs font-montserrat
                                  right-1 top-1/2 -translate-y-1/2 leading-normal" :disabled="customFilterCount == 0" @click="saveCustomFilters">
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </BaseIconDropdown>
          <div class="hidden xl:flex flex-grow">

            <div class="preset-filter-container flex flex-wrap flex-grow overflow-hidden space-x-1 max-h-[3rem] ">
              <button v-for="filter in savedFilters" :key="filter.id"
                      :class="['preset-filter-button relative flex-grow basis-24 w-24 sm:w-48 xl:w-auto min-w-[6rem] max-w-[max-content]',
                               'px-2 rounded-md ellipsis-overflow',
                               filter.id === selectedSavedFilter ? 'bg-main-alt text-white' : 'bg-white text-gray-500 ']"
                      @click="e => { selectSavedFilter(filter); }">
                <span class="block my-auto text-xs leading-normal ellipsis-overflow max-w-[30rem]">
                  {{ filter.tag }}
                </span>
              </button>
            </div>
            <button class="relative flex items-center justify-end h-full w-16 min-w-[44px] xl:max-w-[4rem] rounded-r-md"
                    @click.stop="toggleSavedFiltersDropdown">
              <span class="material-icons text-black align-middle mr-2"> keyboard_arrow_down </span>
            </button>
          </div>
        </div>
        <!-- dropdown menu -->
        <div class="relative flex w-full">
          <div v-if="showSavedFilterDropdown"
               v-click-outside="(e) => { toggleSavedFiltersDropdown(e, false); }"
               class="preset-filter-menu z-10 absolute min-h-16  w-full left-0 bottom-0 transform translate-y-full
                        bg-white border border-gray-200 rounded-md">
            <div class="flex flex-col font-roboto">
              <div v-if="!savedFilters || savedFilters.length == 0" class="px-2 py-4 text-center text-sm font-roboto">
                <span class="text-lg font-bold"> You Have no Filters Saved! </span> <br /> Create a filter by opening the filter dropdown,<br /> selecting a filter and saving it.
              </div>
              <div v-for="filter in savedFilters" v-else :key="filter.id"
                   :class="['flex justify-between h-11 border-b border-gray-200',
                            filter.id === selectedSavedFilter ? 'bg-rare2-lighter' : '']">
                <button :class="['text-left px-4 py-3 text-gray-500 hover:text-black transition-colors',
                                 filter.id === selectedSavedFilter ? '' : 'hover:bg-gray-200']"
                        style="width:calc(100% - 3rem);"
                        @click.prevent="() => { selectSavedFilters(filter) }">
                  <div class="my-auto ellipsis-overflow max-w-full text-sm font-normal">
                    {{ filter.name }}
                  </div>
                </button>
                <button class="w-12 h-full material-icons text-lg hover:bg-gray-200"
                        @click.prevent="() => { removeSavedFilters(filter) }">
                  close
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-grow xl:flex-grow-0 relative mr-2 xl:mr-0  min-w-[16rem]">
        <div class="flex relative xl:ml-auto">

          <input v-model="searchText" placeholder="Search all notes"
                 class="filter-input pl-12" />
          <i class="material-icons magnifier 2xl:text-2xl"> search
          </i>
        </div>
      </div>
    </div>
  </div>

  <div class="kanban-wrapper flex flex-grow overflow-x-auto bg-white">
    <div id="notes" class="flex ">
      <div class="flex flex-col xl:mr-4">
        <!-- kanban board -->
        <div class="flex flex-grow">
          <!-- kanban containers -->
          <template v-for="(group, index) in kanbanGroups" :key="group.state">
            <div v-if="group.key !== 'verified' || ShowVerifiedLane"
                 :class="['kanban-lane-wrapper  relative flex flex-grow flex-col px-2 pt-4 drop-target',
                          'border-r last:border-r-0 border-gray-300 ',
                          group.shown ? 'max-w-[12rem] min-w-[21rem]' : 'max-w-[4rem] min-w-[4rem]']">
              <template v-if="group.shown">
                <div :class="['kanban-header flex rounded-md border-2 mb-3', group.key]">
                  <div class="rounded-l-md flex border-r">
                    <button class="material-icons p-2 select-none text-neutral-alt" @click="toggleLane(group)">
                      keyboard_arrow_down
                    </button>
                  </div>
                  <div class="flex my-auto pl-4 mr-4 font-roboto font-bold">
                    {{ group.state }}
                  </div>
                  <div v-if="group.totalCount !== undefined" class="flex my-auto bg-white min-w-[1.5rem] h-6 px-1 rounded-md justify-center">
                    <div class="my-auto text-gray-alt font-roboto overflow-hidden">
                      {{ group.totalCount }}
                    </div>
                  </div>
                </div>
                <!-- kanban container. Holds cards and manages drag and drop api -->
                <div :id="`${group.key}-container`" :data-group="index"
                     :class="['kanban-group-container flex flex-col h-full max-h-[120rem] overflow-y-auto',
                              heldNote === undefined ? '' : group.dropTarget ? '' : 'opacity-25']"
                     @drop.prevent="drop" @dragover.prevent="dragover"
                     @dragenter.prevent="dragenter" @dragleave.prevent="dragleave"
                     @onscroll="handleScroll">

                  <div class="last:flex hidden flex-grow justify-center text-xl text-center">
                    <span class="py-4 font-roboto">
                      <!-- Nothing to show -->
                    </span>
                  </div>
                  <div v-if="heldNote !== undefined && group.notes.length <= 1 && group.dropTarget"
                       class="flex flex-col items-center border-black border-2 rounded-lg border-dashed mb-1">
                    <div class="text-center text-xl font-bold font-roboto my-10">
                      Drop Here
                    </div>
                  </div>
                  <template v-if="!loading">
                    <TransitionGroup name="list">
                      <KanbanCard v-for="note in group.notes" :key="note.id" :note="note" @dragStart="dragstart" @dragEnd="dragend" />
                    </TransitionGroup>
                  </template>
                  <div v-if="group.loading" class="mt-12">
                    <BaseLoading />
                  </div>
                </div>
              </template>
              <!-- collapsed header of container -->
              <template v-else>
                <div :class="['absolute kanban-header flex rounded-md border-2 left-[1.875rem] top-[11rem] transform -translate-y-1/2 -translate-x-1/2 rotate-90 w-[20rem]', group.key]">
                  <div class="collapse-header rounded-l-md flex border-r">
                    <button class="material-icons p-2 select-none text-neutral-alt" @click="toggleLane(group)">
                      keyboard_arrow_up
                    </button>
                  </div>
                  <div class="flex my-auto pl-4 mr-4 font-roboto font-bold">
                    {{ group.state }}
                  </div>
                  <div v-if="group.totalCount !== undefined" class="flex my-auto bg-white min-w-[1.5rem] h-6 rounded-md justify-center">
                    <div class="my-auto text-gray-alt font-roboto overflow-hidden">
                      {{ group.totalCount }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>

        </div>

      </div>
    </div>
  </div>
</template>

<script src="./Kanban.js"></script>
<style src="./Kanban.css" scoped></style>
<template>
  <div class="flex flex-grow full-view">
    <!-- sidebar -->
    <template v-if="lgBreakpoint">
      <TheSidebar v-if="isLoggedIn && !hideSidebar" />
      <slot name="blade">
        <TheBlade v-if="isLoggedIn && !hideSidebar" />
      </slot>
    </template>
    <div :class="['flex flex-col flex-grow ',
                  isLoggedIn ? 'blue-details' : '',]">
      <TheHeading v-if="!hideHeading" />
      <div :class="['flex flex-col flex-grow',
                    lgBreakpoint ? '' : 'vertical-gradient']">
        <!-- min height to force the window to fit the blade -->
        <div class="px-1 lg:p-0 flex flex-col flex-grow lg:min-h-[586px]">
          <slot>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TheHeading from "Components/layout/TheHeading";
import TheSidebar from "Components/layout/TheSidebar";
import TheBlade from "Components/layout/TheBlade/TheBlade";

export default {
  name: "TheNav",
  components: { TheHeading, TheSidebar, TheBlade },
  props: {
    authenticating: Boolean,
    // isLoggedIn: Boolean,
    windowWidth: Number,
  },
  data () {
    return {
      adminRoutes: ["AdminDashboard", "UserManagement", "BillingHistory", "Subscription"]
    };
  },
  computed: {
    isAdminPage () {
      return this.adminRoutes.find(x => x == this.$router.currentRoute.value.name);
    },
    hideSidebar () {
      return this.$route.meta.hideSidebar;
    },
    hideHeading () {
      return this.$route.meta.hideHeading;
    },
    isLoggedIn () {
      return this.$store.getters.isLoggedIn;
    },
    lgBreakpoint () {
      return this.windowWidth >= 1024;
    }
  },
  methods: {

  },
};
</script>
<style scoped>
.blue-details {
  border-right: 0px solid;
  @apply border-main-alt;
  transition-property: border;
  transition-duration: 0.15s;
}

@media (min-width: 1024px) {
  .blue-details {
    border-right: 12px solid;
    @apply border-main-alt;
  }
}
</style>
<template>
  <div class="base-table-container">
    <!-- Table Header -->
    <div class="flex flex-row justify-between lg:pr-9 px-2 lg:pl-2.5">
      <!-- select all check -->
      <div class="relative inline-flex">
        <div class="my-auto pr-4">
          <ActionMenu
            class="h-[34px]"
            :checkVal="allSelected"
            :disabled="nonInviteSelectedUsers === 0 && selectedCount === 0 && inviteCount === 0"
            @check-click="selectAll">
            <div class="flex flex-col flex-grow">
              <!-- action reset password -->
              <button :class="['whitespace-nowrap px-4 py-3 hover:no-underline hover:bg-gray-200 text-main-alt text-xl sm:text-base']"
                      :disabled="nonInviteSelectedUsers === 0 ?? undefined"
                      @click="$emit('action-item-click', 'reset-passwords')">
                Reset Password
              </button>
              <!-- action change permissions -->
              <button class="whitespace-nowrap cursor-pointer px-4 py-3 hover:no-underline hover:bg-gray-200 text-main-alt text-xl sm:text-base"
                      :disabled="selectedCount === 0 ?? undefined"
                      @click="$emit('action-item-click', 'update-permissions')">
                Change Permissions
              </button>
              <button class="whitespace-nowrap cursor-pointer mb-8 px-4 py-3 hover:no-underline hover:bg-gray-200 text-main-alt text-xl sm:text-base"
                      :disabled="inviteCount === 0 ?? undefined"
                      @click="$emit('action-item-click', 'resend-invites')">
                Resend {{ inviteSelectedCount === 0 ? "All" : `(${inviteSelectedCount})` }} {{ inviteSelectedCount > 1 || inviteSelectedCount === 0 ? "invites" : "invite" }}
              </button>
              <!-- action remove user -->
              <button class="whitespace-nowrap cursor-pointer px-4 py-3 hover:no-underline hover:bg-gray-200 text-error hover:text-error-dark text-xl sm:text-base"
                      :disabled="selectedCount === 0 ?? undefined"
                      @click="$emit('action-item-click', 'remove-users')">
                Remove Users
              </button>
            </div>
          </ActionMenu>

        </div>
        <transition name="fade">
          <div v-if="selectedCount > 0" class="fade-duration relative hidden xl:block my-auto ml-2 xl:ml-4 text-sm whitespace-nowrap">
            ({{ selectedCount }} selected)
          </div>
        </transition>
      </div>
      <!-- actions drop and  search filter -->
      <div class="flex sm:flex-grow-0 sm:justify-between sm:flex-row">

        <!-- filter -->
        <div class="flex flex-grow my-2 xl:m-auto xl:mx-0 flex-shrink xl:flex-grow-0">
          <div class="inline relative justify-end w-full xl:w-auto">
            <input v-model="filterText"
                   type="text"
                   class="filter-input w-full pl-12"
                   placeholder="Search all users"
                   @input="filter(false)" />
            <i class="material-icons magnifier text-xl"> search
            </i>
          </div>
        </div>
      </div>
    </div>
    <template v-if="!showLoading && data.length > 0">
      <!-- display for the table. shown in regular desktops  -->
      <table v-if="!mobileView" class="base-table table">
        <thead class="table-header-gradient">
          <tr>
            <th v-for="col in colDef.col"
                :key="col.field"
                :class="col.colClass">
              {{ col.displayName }}
            </th>
          </tr>
        </thead>
        <tbody id="table-body">
          <tr v-for="(row, rowIndex) in pageData" :key="row[colDef.keyField]"
              :class="[row.selected ? 'bg-rare2-lighter' : 'hover:bg-pastel-blue-light hover:bg-opacity-50']"
              @click="(e) => { rowClick(row); }">
            <td v-for="col in colDef.col" :key="col.field"
                :class="col.cellClass">
              <!-- Select display -->
              <template v-if="col.selectCol">
                <input
                  v-model="row[col.field]"
                  type="checkbox"
                  class="tk-check my-auto"
                  @click.stop="rowSelect(row)" />
              </template>
              <component :is="col.customComponent(row).component"
                         v-else-if="col.customComponent"
                         :props="col.customComponent(row).props" />
              <!-- dropDown display -->
              <template v-else-if="col.dropdownCol">
                <div v-if="!row.clicked" role="button"
                     class="-ml-3 border border-transparent cursor-pointer hover:border-gray-300 rounded-md p-3"
                     @click.stop="toggleDropdown(row, col.field, col.items)">
                  {{ row[col.field] }}
                </div>
                <BaseMultiSelect
                  v-if="row.clicked"
                  :value="row[col.items]"
                  :show="true"
                  class="-ml-3"
                  :fullMenu="windowWidth < 1500"
                  :disabledList="col.disableFunc(row, data, col.params, $store.getters.getUserPerms)"
                  @click.stop
                  @unchanged="(c) => row.unchanged = c"
                  @click-out="(obj) => submitDropdown(row, col.field, col.items)"
                />
              </template>

              <!-- Name/email display -->
              <template v-else-if="col.field == 'title'">
                <div v-if="row.title.name"
                     :class="[col.colClass, 'max-w-[12rem] overflow-ellipsis whitespace-nowrap overflow-hidden']">
                  {{ row.title.name }}
                </div>
                <div v-else>
                  User's Name Not on File
                </div>
                <div class="flex">
                  <a class="text-main font-semibold text-sm py-1"
                     :href="`mailto:${row.title.email}`" @click.stop>
                    {{ row.title.email }}
                  </a>
                </div>
              </template>
              <!-- Action option -->
              <template v-else-if="col.isActionLinkText && row.actionLinkText">
                <div class="flex flex-col justify-center">
                  <Transition name="fade-pull">
                    <template v-if="!row.linkClicked">
                      <a
                        :id="`action-link-text-${row.actionLinkText.text.toLowerCase()}-${rowIndex}`"
                        :class="[col.cellClass, 'invite-animation absolute underline']"
                        :disabled="row.actionLinkText.disabled"
                        @click="actionLinkClick(row, col)">
                        {{ row.actionLinkText.text }}
                      </a>
                    </template>
                    <template v-else>
                      <span class="invite-animation absolute my-auto text-rare2 whitespace-nowrap">
                        {{ row.actionLinkText.success }}
                      </span>
                    </template>
                  </Transition>
                </div>
              </template>
              <button
                v-else-if="col.isActionLinkIcon && row.actionLinkIcon"
                :id="`action-link-icon-${rowIndex}`"
                :disabled="row.actionLinkIcon.disabled"
                @click="actionLinkClick(row, col)"
              >
                <i :class="[row.actionLinkIcon.disabled ? 'text-gray-200' : 'text-gray-400 cursor-pointer',
                            'material-symbols-outlined center align-vertical select-none w-16']">
                  {{ row.actionLinkIcon.icon }}
                </i>
              </button>
              <!-- regular text -->
              <div v-else>
                {{ row[col.field] }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- mobile "table" -->
      <div v-if="mobileView" class="mobile-table flex flex-col">
        <div class="flex blue-gradient">
          <!-- <input v-model="allSelected"
                 type="checkbox"
                 class="table-check table-select-all my-auto mx-3"
                 @click="selectAll" /> -->
          <span class="text-white font-bold py-5 my-auto ml-12 mr-2 text-base xl:leading-5">
            {{ title }}
          </span>
          <div class="flex">
            <div v-if="selectedCount > 0"
                 class="my-auto mr-2 text-white whitespace-nowrap"
            >
              ({{ selectedCount }} selected)
            </div>
          </div>
        </div>

        <div v-for="(row, rowIndex) in pageData" :id="'table-row-' + rowIndex"
             :key="row[colDef.keyField]"
             class="mobile-row relative border-b border-gray-400">
          <div :class="[row.selected ? 'bg-rare2-lighter' : '',
                        row.clicked ? 'bg-gray-100' : '', ' pl-6 pr-16 py-4']"
               @click="(e) => { if (rowAction) rowClick(row); }">
            <div v-for="col in colDef.col"
                 :key="col.field" class="pl-6">
              <!-- Select display -->
              <template v-if="col.selectCol">
                <input v-model="row[col.field]"
                       type="checkbox"
                       class="tk-check absolute left-3 transform translate-y-1/2"
                       @click.stop="rowSelect(row)" />
              </template>
              <component :is="col.customComponent(row).component"
                         v-else-if="col.customComponent"
                         :props="col.customComponent(row).props" />
              <!-- Email/name display -->
              <template v-else-if="col.field == 'title'">
                <div :class="'pt-1 font-roboto text-base'">
                  <span v-if="row.title.name"
                        class="flex flex-nowrap whitespace-nowrap overflow-hidden font-bold">
                    {{ row.title.name }}
                  </span>
                  <span v-else>
                    User's Name Not on File
                  </span>
                  <div class="whitespace-nowrap overflow-hidden">
                    <div class="flex">
                      <a class="text-main-alt pb-1"
                         :href="`mailto:${row.title.email}`">
                        {{ row.title.email }}
                      </a>
                    </div>
                  </div>
                </div>
              </template>

              <!-- dropDown display -->
              <template v-else-if="col.dropdownCol">
                <div class="py-2">
                  <div v-if="!row.clicked"
                       class="border-transparent border text-sm capitalize font-normal text-neutral-alt font-roboto"
                       @click="toggleDropdown(row, col.field, col.items)">
                    <span>
                      {{ col.showDisplayName ? col.displayName + ' : ' : '' }}
                    </span>

                    {{ row[col.field] }}
                  </div>
                  <BaseMultiSelect
                    v-if="row.clicked"
                    :value="row[col.items]"
                    :show="true"
                    class="-ml-3"
                    :disabledList="col.disableFunc(row, data, col.params, $store.getters.getUserPerms)"
                    @unchanged="(c) => row.unchanged = c"
                    @click-out="(obj) => submitDropdown(row, col.field, col.items)"
                  />
                </div>
              </template>
              <!-- Action option -->
              <template v-else-if="col.isActionLinkText && row.actionLinkText">
                <Transition name="fade-pull">
                  <template v-if="!row.linkClicked">
                    <a :id="`action-link-text-${row.actionLinkText.text.toLowerCase()}-${rowIndex}`"
                       :class="[col.cellClass, 'invite-animation absolute left-64 bottom-10']"
                       :disabled="row.actionLinkText.disabled"
                       @click="actionLinkClick(row, col);">
                      {{ row.actionLinkText.text }}
                    </a>
                  </template>
                  <template v-else>
                    <span :class="['invite-animation absolute font-semibold left-64 bottom-10 text-rare2']">
                      {{ row.actionLinkText.success }}</span>
                  </template>
                </Transition>
              </template>
              <button
                v-else-if="col.isActionLinkIcon && row.actionLinkIcon"
                :id="`action-link-icon-${rowIndex}`"
                class="absolute right-4 top-5"
                :disabled="row.actionLinkIcon.disabled"
                @click.stop="actionIconClick(row, col)"
              >
                <i :class="[row.actionLinkIcon.disabled ? 'text-gray-200' : 'text-gray-400 cursor-pointer',
                            'material-symbols-outlined center align-vertical select-none w-16']
                ">
                  {{ row.actionLinkIcon.icon }}
                </i>
              </button>
              <!-- regular display -->
              <template v-else>
                <div :class="col.cellClass + ' pt-1 pr-10 text-xl'">
                  <span class="text-gray-400">
                    {{ col.showDisplayName ? col.displayName + ' : ' : '' }}
                  </span>
                  {{ row[col.field] }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div v-else-if="!showLoading && data.length == 0" class="flex justify-center">
      <a v-if="callToAction && callToAction.action"
         class="mobile-link py-8 lg:py-4 text-2xl lg:text-lg font-semibold" @click=" callToAction.action">
        {{ callToAction === undefined ? "There is no data to display yet!" : callToAction.text }}
      </a>
      <span v-else class="mobile-link py-8 lg:py-4 text-2xl lg:text-lg font-semibold">
        {{ callToAction === undefined ? "There is no data to display yet!" : callToAction.text }}
      </span>
    </div>
    <div v-if="showLoading" class="py-2">
      <BaseLoading v-if="showLoading" />
    </div>
    <div v-if="!showLoading && data.length > 15 && pagination" class="w-full">
      <div class="flex justify-between px-4 bg-[#FAFBFC]  rounded-b-lg border-t border-neutral-lighter whitespace-nowrap">
        <!-- pagination buttons -->
        <div class="py-2 pr-2 flex flex-col lg:flex-row justify-center lg:justify-start">
          <div class="flex">

            <button class="material-icons text-[#9BACB2] font-bold leading-none my-auto h-6 px-1" @click="paginationPrev">
              keyboard_arrow_left
            </button>
            <input v-model="pagination.pageTmp" type="number"
                   class="w-16 h-8 pl-4 text-center border border-[#9BACB2] rounded-lg"
                   @change="selectPage(pagination.pageTmp)"
                   @keypress.enter="selectPage(pagination.pageTmp)"
            />
            <button class="material-icons text-[#9BACB2] font-bold leading-none my-auto h-6 px-1" @click="paginationNext">
              keyboard_arrow_right
            </button>
          </div>

          <div class="inline-block text-center mx-2 my-auto text-sm text-[#9BACB2]">
            Page {{ pagination.page }} of {{ pagination.pageCount }}
          </div>
          <div v-if="filteredData.length == 0" class="hidden xl:inline-block mx-2 py-1 my-auto lg:w-40 text-sm text-[#9BACB2]">
            showing 0 - 0 of 0
          </div>
          <div v-else-if="pagination.truePagination" class="hidden xl:inline-block mx-2 py-1 my-auto lg:w-40 text-sm text-[#9BACB2]">
            showing {{ pagination.start + 1 }} - {{ Math.min(pagination.totalCount, pagination.start + pagination.pageSize) }} of {{ pagination.totalCount }}
          </div>
          <div v-else class="hidden xl:inline-block mx-2 py-1 my-auto lg:w-40 text-sm text-[#9BACB2]">
            Showing {{ pagination.start + 1 }} - {{ filteredData.length < pagination.numberOnPage ? filteredData.length : pagination.end }} of {{ filteredData.length }}
          </div>
        </div>

        <div class="flex flex-col-reverse lg:flex-row justify-center lg:justify-start py-2 text-lg lg:text-xs whitespace-nowrap mr-2">
          <div class="inline-block text-center  mx-2 py-1 my-auto text-sm text-[#9BACB2]">
            Items
          </div>

          <select v-model="pagination.numberOnPage"
                  class="border text-sm h-8 w-18 bg-white pl-4
                       text-neutral border-[#9BACB2] rounded-lg"
                  @change="pageSizeSelect">
            <optgroup class="lg:text-xs">
              <option v-for="item in [15, 25, 50, 100]" :key="item"
                      :value="item">
                {{ item }}
              </option>
            </optgroup>
          </select>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import BaseDropdown from "Components/ui/BaseDropdown";
import BaseLoading from "Components/ui/BaseLoading";
import BaseMultiSelect from "Components/ui/BaseMultiSelect";
import ActionMenu from "Components/ui/ActionMenu";
import BaseSelectList from "Components/ui/BaseSelectList";

export default {
  name: "BaseTable",
  emits: ['row-action-click', 'toggle-select-all', 'toggle-select', 'submit-select-list', 'submit-dropdown', 'action-item-click', 'row-click'],
  components: { BaseDropdown, BaseLoading, BaseMultiSelect, ActionMenu, BaseSelectList },
  props: [
    "title",
    "data",
    "colDef",
    "searchPlaceholder",
    "showButton",
    "buttonText",
    "showAlert",
    "imgLink",
    "paginationSeed",
    "callToAction",
    "showLoading",
  ],
  data () {
    return {
      allSelected: false,
      filterText: "",
      filterColumns: [],
      filteredData: [],
      pageData: [],
      pagination: {},
      windowWidth: window.innerWidth,
    };
  },
  created () {
    if (this.paginationSeed) {
      this.pagination.page = this.paginationSeed.page;
      this.pagination.numberOnPage = this.paginationSeed.numberOnPage;
    }
    if (!this.pagination.page) {
      this.pagination.page = 1;
    }
    if (!this.pagination.numberOnPage) {
      this.pagination.numberOnPage = 15;
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    showLoading (newVal) {
      //the data first gets filtered into filteredData then goes to show pages with selectPageGroup generating pageData which is the final set
      if (!newVal) {
        this.allSelected = false;
        this.filterColumns = this.colDef.col.filter((col) => col.filter);
        this.filter(false);
      }
    },
    data: {
      handler () {
        //Filter order:
        //the data first gets filtered into filteredData then
        //selectPageGroup() generates pageData which is the final set to show in the table
        this.allSelected = false;
        this.filter(true);
      },
      deep: true,
    }
  },
  computed: {
    mobileView () {
      return this.windowWidth < 1200;
    },
    selectedCount () {
      let count = 0;
      this.data.forEach(x => count += x.selected);
      return count;
    },
    inviteSelectedCount () {
      return this.data.filter(x => x.selected && x.status === "invited").length;
    },
    inviteCount () {
      return this.data.filter(x => x.status === "invited").length;
    },
    // set of row that are both selected and not an invite record
    nonInviteSelectedUsers () {
      return this.data.filter(x => x.selected && x.status === "enabled").length;
    },

  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth;
    },
    // function handler for selecting a row that has a checkbox on it. row comes in unchanged from selection
    rowSelect (row) {
      this.data.find(userRow => userRow.userId == row.userId).selected = row.selected;
      if (this.selectedCount > 1 || row.selected) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
      this.$emit('toggle-select', row);
    },
    rowClick (row) {
      this.$emit('row-click', row);
    },
    selectAll (val) {
      this.allSelected = !val;
      // this.allSelected is toggled in v-model after the click-handler
      this.data.forEach(userRow => {
        if (this.pageData.find(filterRow => filterRow.userId === userRow.userId)) {
          userRow.selected = this.allSelected;
          userRow.clicked = false;
        }
      });
      this.$emit('toggle-select-all', this.allSelected, this.data);
      this.filter(true);
    },
    clearSelection () {
      this.allSelected = false;
      this.data.forEach(userRow => {
        userRow.clicked = false;
        userRow.selected = false;
      });
    },
    // toggles the dropdown, triggers a submit for any opened dropdowns
    //  row: the table row related to the event, field: the field text field of the dropdown obj, items field the dropdown referenced. `row[col.items]`
    toggleDropdown (row, field, items) {
      // row.clicked comes in unchanged. dropdown gets toggled through clickedRow
      const clickVal = !row.clicked;
      let clickedRow;
      this.pageData.forEach(pageRow => {
        // submit the dropdown that is open
        if (pageRow.clicked) {
          this.submitDropdown(pageRow, field, items);
        }
        // on opening a new dropdown, close others
        pageRow.clicked = false;
        if (pageRow[this.colDef.keyField] == row[this.colDef.keyField]) {
          clickedRow = pageRow;
        }
      });
      // assign selected row
      this.data.forEach(x => x.clicked = false);
      this.data.find(userRow => userRow[this.colDef.keyField] === clickedRow[this.colDef.keyField]).clicked = clickVal;
      clickedRow.clicked = clickVal;
      if (!clickedRow.clicked) {
        this.$emit('submit-select-list', clickedRow);
      }
    },
    //  row: the table row related to the event, field: the field text field of the dropdown obj, items field the dropdown referenced. `row[col.items]`
    submitDropdown (row, field, items) {
      // hide the dropdown in the table
      row.clicked = false;
      this.data.find(userRow => userRow[this.colDef.keyField] === row[this.colDef.keyField]).clicked = false;
      // check to see if the data of the dropdown changed the row
      if (row.unchanged) {
        return;
      }
      row.unchanged = true;
      let keys = [];
      Object.keys(row[items]).forEach(key => { if (row[items][key]) keys.push(key.replace(/_/g, " ")); });
      if (keys.length == 0) keys.push("None");
      // update the text in the table
      row[field] = keys.reduce((a, b) => a.concat(b).concat(", "), "").slice(0, -2);
      // data update for if a user does an action which calls filter()
      this.data.find(userRow => userRow[this.colDef.keyField] === row[this.colDef.keyField])[field] = row[field];
      this.$emit('submit-dropdown', row);
    },

    // filter is the beginning step of the data being shown in the table
    filter (samePage) {
      //on filter we will want to reset selected page view
      if (!samePage) {
        this.pagination.page = 1;
      }
      let dataCopy = this.data.map(x => { return { ...x }; });
      if (this.filterText == "") {
        // copy data into filter data
        this.filteredData = dataCopy;
        this.selectPageGroup();
        return;
      }
      let escapedText = this.filterText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&").toLowerCase();
      let regExp = new RegExp(escapedText);

      this.filteredData = dataCopy.filter((row) => {
        for (var i = 0; i < this.filterColumns.length; i++) {
          var found = regExp.test(JSON.stringify(row[this.filterColumns[i].field]).toLowerCase());
          if (found) {
            return true;
          }
        }
        return false;
      }, this);
      this.selectPageGroup();
    },
    // calc and show pages based on settings
    selectPageGroup () {
      if (this.pagination === undefined) {
        this.pageData = this.filteredData;
        return;
      }
      //setting the number of pages in current pagination schema
      this.pagination.pageCount = Math.ceil(this.filteredData.length / this.pagination.numberOnPage);

      this.pagination.start = ((this.pagination.page - 1) * this.pagination.numberOnPage);
      this.pagination.end = (this.pagination.page * this.pagination.numberOnPage);

      if (this.pagination.end > this.filteredData.length)
        this.pagination.end = this.filteredData.length;


      // set page data based on start index of page and end index of page
      this.pageData =
        this.filteredData.slice(this.pagination.start,
          this.pagination.end);

      this.pagination.pageTmp = this.pagination.page;

    },

    // pagination selection action
    pageSizeSelect () {
      this.clearSelection();
      this.pagination.page = 1;
      this.filter(true);
    },
    paginationPrev () {
      this.clearSelection();
      if (this.pagination.page > 1)
        this.pagination.page--;
      this.selectPageGroup();
    },
    paginationNext () {
      this.clearSelection();
      if (this.pagination.page < this.pagination.pageCount)
        this.pagination.page++;
      this.selectPageGroup();
    },
    selectPage (index) {
      if (index < 1) index = 1;
      if (index > this.pagination.pageCount) index = this.pagination.pageCount;
      this.clearSelection();
      this.pagination.page = index;
      this.selectPageGroup();
    },
    actionLinkClick (row, col) {
      row.linkClicked = true;
      window.setTimeout(() => { row.linkClicked = false; }, 3000);
      this.$emit('row-action-click', { row, col });
    },
    actionIconClick (row, col) {
      this.$emit('row-action-click', { row, col });
    },
  },
};
</script>
<style scoped>
.base-table-container {
  @apply flex;
  @apply flex-col;
}

@media (min-width: 640px) {
  .base-table-container {
    max-width: 100%;
  }
}

.base-table-container .filter-input {
  @apply m-auto;
  @apply my-4;
  @apply pr-9;
  @apply font-roboto;
  @apply text-sm;
  border: 1px solid #9BACB2;
  border-radius: 0.75rem;
  box-sizing: border-box;
  display: inline-flex;
  height: 34px;
  width: 235px;
  transition: all 0.2s ease-out;
}

.base-table-container .filter-input:focus {
  transition: all 0.2s ease-out;
  @apply border-main-dark;
}

.base-table-container .filter-input::placeholder {
  color: #9BACB2;
}

.base-table-container .error-message {
  @apply absolute;
  @apply left-4;
  @apply bottom-0;
  @apply bg-white;
}

.header-form .base-input {
  padding-right: 6rem !important;
}

/* adjustments for error message to float over form */
.header-form>div {
  @apply pb-2;
}

.header-form .error-message {
  @apply px-1;
}

.table-header-gradient {
  background: linear-gradient(90deg, #0386B7 0%, #52BAE2 84.9%, #97D6ED 99.99%);
  background-attachment: fixed;
  width: 100%;
}

table.base-table {
  max-width: 100%;
}

.base-table tr {
  width: 100%;
  border-bottom: 1px solid;
  @apply border-neutral-lighter;
}

.base-table tr:last-child {
  @apply border-none;
}

.mobile-table .mobile-row:last-child {
  @apply border-none;
}

.base-table th {
  @apply text-base;
  @apply text-white;
  @apply text-left;
  @apply py-5;
  @apply pl-2;
}

.base-table td {
  @apply py-2;
  @apply pl-6;
}

.base-table td.select {
  @apply pl-5;
}

@media (min-width: 1200px) {
  .base-table th {
    @apply pl-8;
  }

  .base-table td {
    @apply pl-8;
  }
}

.btn-pagination {
  @apply rounded-md;
}

.page-button-container {
  max-width: 100%;
}

@media (min-width: 1024px) {

  .page-button-container {
    max-width: 13rem;
  }
}

.table-action-menu button {
  font-weight: 700;
  text-align: left;
}

.fade-duration {
  transition-duration: 0.1s;
}

.invite-animation {
  transition: all 0.2s ease-out;
}
</style>
<!--
props data is your list of content you will show
to properly use the base-table component format data as such:
data: [{...}]

where data is full of objects, where the property names are used to match with colDef[i].field (the field property in col array)

pagination is a property that is used to define and display the pagination elements at the foot of the table
Strictly speaking it must just be a defined object {} but you can initalized a value like page that defines
the starting page (1 indexed, not zero) and the number of elements to start on a page, numberOnPage should be in the set [5,10,15,25,50]
pagination: { page: 1, numberOnPage: 5}

after processing based on _filtered_ data pagination ends up formatted like
pagination: {
  page: current page,
  numberOnPage: number to show in a page
  pageCount: # of pages based on filteredData / numberOnPage
  start: current page's first row index (0 indexed)
  end: current page's last row index
}

this table specifically needs a selected column with a property field=`selected`, where actioned are performed on rows where selected = true

colDef:{
  keyField: this is required though vue V-For to reference data to element.
              Provide a single field name in data that is unique across every element in data. Does not need to be in the table, just in the data set
  col: [{
      selectCol: true,
      field: "selected",
      displayName: "",
      colClass: "w-12",
    },{
    field : reference to the data you want to display in the column
    displayName : your data row field display name

  /* For menus the data field will be expected to be an array of objects formatted
        [{ menuText : text for menu element, menuClass : string of css class(es) to apply to the menu element }]
  */
    isMenu : true/false to display a kebab menu, the content in the data field will render as the menu elements
    menuOptions: {  this is obviously conditionally required as ony menu columns will need this
      clickEvent :The name of the event that is emited from clicking on an element in the menu
          The Event emits an object with the data of row from the table and data.{{field}}.MenuId as the menu event key
    }

  /* For filters on specific columns
  */
    filter: true/false to allow filtering on the column in the table filter input
    displayFilter : (NOT IMPLEMENTED)  function/undefined a function for a vue filter to display the field though
  }, ..]
}
-->

import axios from 'axios';
import { store } from '../store';

function GetUser (userId) {
  if (userId === undefined) userId = store.getters.getUserId;
  return axios({
    method: 'GET',
    url: __API_URL_V2 + `user/${userId}`
  })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      console.log('getting user error: ', err);
      return Promise.reject(err);
    });
}

function GetUserProfile (userId) {
  if (!userId)
    userId = store.getters.getUserId;
  return axios({
    method: 'GET',
    url: __API_URL_V2 + `user/${userId}/user_profile`
  })
    .catch(err => {
      console.log('getting user error: ', err);
      return Promise.reject(err);
    });
}

function UpdateUserProfile (profileData, userId) {
  if (userId == undefined) {
    var userId = store.getters.getUserId;
  }
  return axios({
    method: 'PUT',
    url: __API_URL_V2 + `user/${userId}/user_profile`,
    data: profileData
  });
}

function ResetUserPassword (userId, token, password) {
  const payload = {
    "reset_token": token,
    "new_password": password
  };
  return axios({
    method: 'POST',
    url: __API_URL_V2 + `user/${userId}/reset_password`,
    data: payload
  });
}

function GetCustomNullValues () {
  let userId = store.getters.getUserId;

  return axios({
    method: 'GET',
    url: __API_URL_V2 + `user/${userId}/default_value`
  })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      console.log('getting user error: ', err);
      return Promise.reject(err);
    });
}

function UpdateCustomNullValues (newValues) {
  let userId = store.getters.getUserId;
  const updateObj = {
    default_values: newValues,
    user_id: userId
  };

  return axios({
    method: 'POST',
    url: __API_URL_V2 + `user/${userId}/default_value`,
    data: updateObj,
  })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      console.log('updating default values error: ', err);
      return Promise.reject(err);
    });
}

function UpdatePassword (oldPass, newPass) {
  const payload = {
    current_password: oldPass,
    new_password: newPass,
  };
  let userId = store.getters.getUserId;
  return axios({
    method: 'POST',
    url: `${__API_URL_V2}user/${userId}/reset_password`,
    data: payload
  });
}

// rpc CreateUserTemplateSetting(CreateUserTemplateSettingRequest) returns (TemplateSetting) {
//   option (google.api.http) = {
//     post: "/v2/user/{id}/template_setting"
//     body: "*"
//   };
// }
function CreateUserTemplateSetting({template_settings})  {
  const payload = {
    template_setting: template_settings,
  };
  let userId = store.getters.getUserId;
  return axios({
    method: 'POST',
    url: `${__API_URL_V2}user/${userId}/template_setting`,
    data: payload
  });
}
// rpc GetUserTemplateSetting(GetUserTemplateSettingRequest) returns (TemplateSetting) {
//   option (google.api.http) = {
//     get: "/v2/user/{id}/template_setting/{template_id}"
//   };
// }
function GetUserTemplateSetting({id})  {
  let userId = store.getters.getUserId;
  return axios({
    method: 'GET',
    url: `${__API_URL_V2}user/${userId}/template_setting/${id}`,
  });
}

// rpc ListUserTemplateSettings(ListUserTemplateSettingsRequest) returns (TemplateSettings) {
//   option (google.api.http) = {
//       get: "/v2/user/{id}/template_settings"
//   };
// }
function ListUserTemplateSettings()  {
  let userId = store.getters.getUserId;
  return axios({
    method: 'GET',
    url: `${__API_URL_V2}user/${userId}/template_settings`,
  });
}

// rpc UpdateUserTemplateSetting(UpdateUserTemplateSettingRequest) returns (TemplateSetting) {
//   option (google.api.http) = {
//     put: "/v2/user/{id}/template_setting/{template_id}"
//     body: "*"
//   };
// }
function UpdateUserTemplateSettings(template_settings)  {
  let userId = store.getters.getUserId;
  const payload = {
    id:userId,
    template_id: template_settings.template_id,
    template_setting: template_settings,
  };
  return axios({
    method: 'PUT',
    url: `${__API_URL_V2}user/${userId}/template_setting/${template_settings.template_id}`,
    data: payload
  });
}

// rpc DeleteUserTemplateSetting(DeleteUserTemplateSettingRequest) returns (api.Result) {
//   option (google.api.http) = {
//       delete: "/v2/user/{id}/template_setting/{template_id}"
//   };
// }
function DeleteUserTemplateSettings(id)  {
  let userId = store.getters.getUserId;
  return axios({
    method: 'DELETE',
    url: `${__API_URL_V2}user/${userId}/template_setting/${id}`,
  });
}


export {
  GetUser,
  GetUserProfile,
  UpdateUserProfile,
  ResetUserPassword,
  GetCustomNullValues,
  UpdateCustomNullValues,
  UpdatePassword,
  CreateUserTemplateSetting,
  GetUserTemplateSetting,
  ListUserTemplateSettings,
  UpdateUserTemplateSettings,
  DeleteUserTemplateSettings,
};
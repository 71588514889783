import { computed } from 'vue';
const NotesService = require("Services/NotesService");
const UserService = require("Services/UserService");
const TemplateService = require("Services/TemplateService");
import MixpanelService from "Services/MixpanelService";
import BaseLoading from "Components/ui/BaseLoading.vue";
import BaseIcon from "Components/ui/BaseIcon.vue";
import BaseBox from "Components/ui/BaseBox.vue";
import BaseInput from "Components/ui/BaseInput.vue";
import BaseTextArea from "Components/ui/BaseTextArea.vue";
import BaseIconDropdown from "Components/ui/BaseIconDropdown";
import BaseDropdown from "Components/ui/BaseDropdown";
import BaseSelectList from 'Components/ui/BaseSelectList.vue';
import BaseModal from "Components/ui/BaseModal.vue";
import RichTextField from "../RichTextField";
import NoteTimeline from "../NoteTimeline";
import NoteHeaderSection from "../NoteHeaderSection";
import CopyButton from "../CopyButton.vue";
import StandardTemplate from '@/components/layout/StandardTemplate.vue';

import NotesStateMap from "Modules/NoteStatesMap";
import { ProseMirrorView } from './../ProseMirror.js';

export default {
  name: "Templated Note View",
  components: { BaseBox, BaseLoading, BaseInput, BaseIcon, BaseTextArea, BaseIconDropdown, BaseDropdown, BaseSelectList, BaseModal, RichTextField, NoteTimeline, NoteHeaderSection, CopyButton, StandardTemplate },
  emits: ["noteViewChange"],
  // TODO actually make use of the inNote
  props: { inNote: Object },
  data () {
    // * removed settings by always resetting the content of setting to default. avoids messing up any current settings applied or weird behavior
    const defaultHighlightColor = "#fdd999";
    const defaultSettings = { showOriginal: true, showHighlighting: true, selectHighlightColor: false, highlightColor: defaultHighlightColor, altCopy: false };
    let noteSettings = defaultSettings;
    const params = new window.URLSearchParams(window.location.search);
    const hasIssue = params.get("has_issue") ?? false;
    return {
      index: 0,
      loading: true,

      note: undefined,
      templateIndex: 0,

      volumeDragging: false,
      audioConfig: undefined,
      noAudio: false,
      // used to control playback bar dragging
      playbackBarDragging: false,
      // used to prevent keydown event
      focusedField: false,
      // toggle on copying text success message
      copiedAllText: false,
      copiedAllHtml: false,
      saveTimeout: 0,
      //flag to prevent double updating note on input
      ignoreInputEvents: false,
      // note view settings
      noteSettings: noteSettings,
      defaultHighlightColor,
      // page state bool
      showTranscript: false,
      // note requires attention modal props
      showAttentionModal: false,
      submittedNeedsAttn: false,
      selectedProblem: 1,
      problemDescription: '',
      otherProblem: '',
      problemsList: [
        { text: 'There is difficulty understanding a section of audio', value: 1 },
        { text: 'I had trouble understanding a word', value: 2 },
        { text: 'I believe the speaker misspoke', value: 3 },
        // {text: 'foo bar baz buzz', value: 4 },
        { text: 'Other', value: 999 },
      ],
      hasIssue: hasIssue == 'true',
      windowWidth: window.innerWidth,

      toggleShowAllIssuesMessaging: false,

      // properties for managing scribe data-collection state
      record: undefined,
      templateList: [],
      templateLoading: false,
      copiedAllLlmText: false,
      selectInitialValue: undefined,

      // websocket props
      wsIds: [],
      simuEditingWarning: false,

      editingTitle: false,
      titleTarget: { height: 0, width: 0, max: 0 },
      pendingTitleChange: false,
      titleSaveTimeout: -1,
      settings: [],

      preferences: undefined,
      languageSelection: [
        { text: "English (United States)", value: 'en-us' },
        { text: "English (Canada)", value: 'en-ca' },
        { text: "English (United Kingdom)", value: 'en-uk' },
        { text: "English (Australia)", value: 'en-au' }],

    };
  },
  provide () {
    return {
      settings: computed(() => this.noteSettings),
      defaultHighlightColor: this.defaultHighlightColor,
    };
  },
  created () {
    this.getPageData();


    UserService.GetCustomNullValues()
      .then((results) => {
        const emptyValues = [
          // vitals
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Temperature", display_name: "Temperature" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Heart Rate", display_name: "Heart Rate" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Breathing", display_name: "Breathing" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Mucus Membranes", display_name: "Mucus Membranes" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Capillary Refill Time", display_name: "Capillary Refill Time" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Weight", display_name: "Weight" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Body Condition Score", display_name: "Body Condition Score" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Pain Score", display_name: "Pain Score" },
          // physical
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Attitude/Behavior" },
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Hydration" },
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Nose/Throat" },
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Eyes" },
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Ears" },
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Mouth/Teeth" },
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Heart/Blood Vessels" },
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Lungs/Airways" },
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Abdomen" },
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Gastrointestinal System" },
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Anal Glands" },
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Coat/Skin/Nails" },
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Lymph Nodes" },
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Musculoskeletal" },
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Central Nervous System" },
          { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Urinary/Genitals" },

          // ??
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Cardiovascular" },
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Respiratory" },
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Neurological" },
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Lymphatic" },
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Gastrointestinal" },
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Reproductive/Urinary" },
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Fur/Skin" },
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Respiratory Rate" },
        ];
        let tmp = results.data.default_values;

        tmp = tmp
          .filter(x => x.subsection !== "")
          .filter(x => x.section !== "Objective");// temporary TODO phase 3 work with
        // get unique records
        tmp = tmp.filter((x, index) => {
          return index === tmp.findIndex(y => y.subsection == x.subsection);
        });

        for (let i = 0; i < tmp.length; i++) {
          let v = emptyValues.findIndex((val) => val.subsection == tmp[i].subsection);
          if (v !== -1) {
            // doing this value swap gives us the preferred ordering of the emptyValues set
            emptyValues[v] = tmp[i];
            tmp.splice(i, 1);
            i--;
          }
        }
        this.settings = [...emptyValues, ...tmp];
        this.settings = this.settings.map((x) => x.subsection.toLowerCase());
      });
  },

  mounted () {
    // removing event listeners to avoid n > 1  number of listeners on the same event with HMR while developing
    document.removeEventListener('keydown', this.keyHandler);
    document.addEventListener('keydown', this.keyHandler);
    document.removeEventListener('mouseup', this.mouseUp);
    document.addEventListener('mouseup', this.mouseUp);
    // removing this one incase if it got left around from dragging while naving somehow
    document.removeEventListener('mousemove', this.volumeChange);
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onNoteStateEvent(this.noteStateEventHandler));
    this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onNoteIssueEvent(this.noteIssueEventHandler));
  },
  watch: {
    inNote (newVal, oldVal) {
      this.note = this.parseNoteInts(newVal);
      // if (this.note.note_templates.length === 0) {
      //   this.$router.push(`/notes/${this.note.id}${this.note.needs_attention ? '?has_issue=true' : ''}`);
      // }
      if (this.isUserScribe) {
        this.GetUserPreferences(this.note.user_id);
      }
      // if there is no issues, or we are pre-requesting issues, return, do not get issues
      if (!this.note.needs_attention || this.hasIssue) return;
      NotesService.GetNoteIssues(this.note.id)
        .then((resp) => {
          this.note.issues = this.parseIssuesInts(resp.data.issues.filter(x => !x.resolved));
          this.sortNNA();
        });

      this.getAudio();
    }
  },
  computed: {
    isUserScribe () {
      if (!this.$store.getters.getUserGroups) return false;
      return this.$store.getters.getUserGroups.findIndex(x => x === "Scribe") !== -1;
    },
    isUserVerified () {
      return this.$store.getters.getUserPerms.verified;
    },
    showVerifiedFeatures () {
      return this.isUserScribe || this.isUserVerified || this.note?.verification_flag;
    },
    hasLmmTestingFlag () {
      return this.$store.getters.getFeatureFlags.llmtesting;
    },
    noteState () {
      if (!this.note) return '';
      if (this.note.needs_attention) return 'Needs Attention';
      if (this.note?.archived) return 'Archived';
      let key = this.note.state;
      return NotesStateMap[key];
    },
    noteCreatedOn () {
      if (!this.note) return '';
      const createdAt = new Date(this.note.created_at * 1000);
      return {
        date: Intl.DateTimeFormat(undefined, { year: "numeric", month: "short", day: "numeric" }).format(createdAt),
        time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", second: "numeric" }).format(createdAt),
      };
    },
    notePublishedOn () {
      if (!this.note) return '';
      let ts = this.note.published_at == 0 ? this.note.last_edited : this.note.published_at;
      const publishedAt = new Date(ts * 1000);
      return {
        date: Intl.DateTimeFormat(undefined, { year: "numeric", month: "short", day: "numeric" }).format(publishedAt),
        time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", second: "numeric" }).format(publishedAt),
      };
    },
    noteEditedOn () {
      if (!this.note || this.note.last_edited == 0) return '';
      const editedAt = new Date(this.note.last_edited * 1000);
      return {
        date: Intl.DateTimeFormat(undefined, { year: "numeric", month: "short", day: "numeric" }).format(editedAt),
        time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", second: "numeric" }).format(editedAt),
      };
    },
    templateKey () {
      let keys = [];
      if (!this.selectedTemplate) return keys;
        Object.keys(this.selectedTemplate).forEach(
          key => {
            if (key != "ratings" )
            keys.push(key);
          }
        );
        return keys;
    },
    templateField () {
      if (!this.note) return '';
      return this.note.note_templates[this.templateIndex].type;
    },
    selectedTemplate () {
      if (!this.note || this.note.note_templates.length === 0) return undefined;
      return this.note.note_templates[this.templateIndex][this.templateField];
    },

    canPlay () {
      return this.audioConfig && this.audioConfig.canPlay;
    },
    isPlaying () {
      return this.audioConfig?.isPlaying ?? false;
    },

    // used to pass through to RTF to highlight the currently playing word
    currentTime () {
      return this.audioConfig?.currTime ?? 0;
    },
    currTimeMinutes () {
      if (!this.audioConfig) {
        return "0:00";
      }
      return this.secondsToMinutesString(this.audioConfig.currTime);
    },
    durationMinutes () {
      if (!this.audioConfig) {
        return "0:00";
      }
      return this.secondsToMinutesString(this.audioConfig.duration);
    },
    volumeIcon () {
      if (!this.audioConfig) return 'volume_up';
      if (this.audioConfig.volume > .75) return 'volume_up';
      if (this.audioConfig.volume > 0) return 'volume_down';
      return 'volume_off';  //'volume_mute'
    },
    mdSaveState () {
      switch (this.saveTimeout) {
        case -1: return "done";
        case 0: return "none";
      }
      return "pending";
    },
    enableEditing () {
      if (!this.note) return false;
      return !this.note.archived && this.noteState !== "Draft";
    },
    userOwnsNote () {
      if (!this.note) return false;
      return this.$store.getters.getUserId == this.note.user_id;
    },
    authorRespondedToIssue () {
      if (!this.note) return false;
      if (!this.note.needs_attention) return false;
      if (this.note.issues.length === 0) return false;
      if (this.note.issues[0].responses.length === 0) return false;
      return this.note.issues[0].responses.findIndex(x => x.responded_by == this.note.user_id) !== -1;
    },

    // doing this offset for the sticky header. solution for how sticky works and avoiding hard-coded values of offeset
    noteSectionTopOffset () {
      if (this.loading || this.pendingTitleChange) return '';
      let header = document.getElementById("note-header-section");
      let media = document.getElementById("media-bar");
      let transcript = document.getElementById("original-transcript");
      if (!header || !media || !transcript) return '';
      return `top:-${header.clientHeight - (media.clientHeight + transcript.clientHeight)}px;`;
    },
    copyableNote () {
      let note = '';
      if (!this.selectedTemplate) return note;

      Object.keys(this.selectedTemplate).forEach((key, index) => {
        if (key == "ratings") return;
        note += `${key.slice(0, 1).toUpperCase()}${key.slice(1)}: \n`;
        note += this.selectedTemplate[key];
        if (index != note.length - 1) {
          note += '\n';
        }
      });
      return note;
    },
    copyableRichTextNote () {
      let note = '';
      if (navigator.userAgent.match("Firefox")) {
        Object.keys(this.selectedTemplate).forEach((key, index) => {
          if (key == "ratings") return;
          note += `<strong>${key.slice(0, 1).toUpperCase()}${key.slice(1)}</strong> <br/>`;
          if (key == "objective") {
            note += "<br/>";
            let section = "";
            this.selectedTemplate[key].split('\n').forEach(seg => {
              const regex = /(^|\t)?([\w\s/]+?:)/gm;
              let m = regex.exec(seg);
              if (m !== null) {
                m.lastIndex = m.index + m[0].length;
                let tmp = seg.substr(0, m.index);
                tmp += `<strong>${m[2]}</strong>`;
                tmp += seg.substr(m.lastIndex);
                section += `<span>${tmp} </span><br/>`;
              } else {
                section += `<span>${seg} </span><br/>`;

              }
            });
            note += section;
          } else {
            note += this.selectedTemplate[key].replaceAll('\n', "<br/>");
          }
          if (index != note.length - 1) {
            note += '<br/>';
          }
        });
      } else {
        if (!this.selectedTemplate) return;
        Object.keys(this.selectedTemplate).forEach((key, index) => {
          if (key == "ratings") return;
          note += `<span class="font-bold">${key.slice(0, 1).toUpperCase()}${key.slice(1)}</span> <br/>`;
          if (key == "objective") {
            let section = "";
            this.selectedTemplate[key].split('\n').forEach(seg => {
              const regex = /(^|\t)?([\w\s/]+?:)/gm;
              let m = regex.exec(seg);
              if (m !== null) {
                m.lastIndex = m.index + m[0].length;
                let tmp = seg.substr(0, m.index);
                tmp += `<span class="font-bold">${m[2]}</span>`;
                tmp += seg.substr(m.lastIndex);
                section += `<span class="no-style">${tmp} </span><br/>`;
              } else {
                section += `<span class="no-style">${seg} </span><br/>`;

              }
            });
            note += section;
          } else {
            this.selectedTemplate[key].split('\n').forEach(seg => {
              note += `<span class="no-style">${seg} </span><br/>`;
            });
          }

          if (index != note.length - 1) {
            note += '<br/>';
          }
        });
      }

      return note;
    },
    originalTranscript () {
      return this.note.transcript_string;
    },
    // performs update before copying takes place
    copyableFullNoteDraftMarkdown () {
      if (!this.note) return;
      if (!this.selectedTemplate) return;
      // join all the bits of the note together for a full note
      let fullNote = Object.keys(this.selectedTemplate).reduce((prop, field, index) => {
        if (field == "ratings") return prop;
        if (index !== 0) prop += '\n\n\n\n';
        prop += `**${field.slice(0, 1).toUpperCase()}${field.slice(1)}** \n\n\n\n`;
        if (this.selectedTemplate[field]) {
          prop += this.selectedTemplate[field];
        }
        return prop;
      }, '');
      // take out regex special characters
      fullNote = fullNote.replaceAll(/\n{2}/g, '\n');
      // .replaceAll(/(?<!\\)\*+/g, '')
      // find all unescaped * and remove them
      for (let i = 1; i < fullNote.length - 1; i++) {
        if (fullNote.charCodeAt(i) == 42 /* '*' */ && fullNote.charCodeAt(i - 1) != 92/* \ */) {
          fullNote = fullNote.substring(0, i) + fullNote.substring(i + 1);
          i--;
        }
      }
      if (fullNote.charCodeAt(0) == 42) fullNote = fullNote.substring(1);
      fullNote = fullNote
        .replace(/\\([*_`{}\[\]()#+\-.!])/g, '$1').trim();
      return fullNote;
    },
  },
  methods: {
    // performs update before copying takes place
    updateFullNoteCopyField () {
      // no need to update anything with the no template option
      if (!this.note) return;
      if (!this.selectedTemplate) return;
      // join all the bits of the note together for a full note
      let fullNote = Object.keys(this.selectedTemplate).reduce((prop, field, index) => {
        if (field == "ratings") return prop;
        if (index !== 0) prop += '\n\n\n\n';
        prop += `**${field.slice(0, 1).toUpperCase()}${field.slice(1)}** \n\n\n\n`;
        if (this.selectedTemplate[field]) {
          prop += this.selectedTemplate[field];
          // prop += template[field].replaceAll(/\n{2}/g, "  \n");
        }
        return prop;
      }, '');
      // remove and rebuild copyable rich text field
      fullNote = fullNote.trimEnd();
      this.copyableFullNotePmView = undefined;
      let editor = document.getElementById(`text-area-rich-note`);
      while (editor.children.length > 0) {
        editor.children[0].remove();
      }
      this.copyableFullNotePmView = new ProseMirrorView(editor, fullNote);
    },
    copyableDraftMarkdown (field) {
      // this can happen when a user has settings to remove soap sections
      if (!this.selectedTemplate[field]) return "";
      let text = this.selectedTemplate[field].replaceAll(/\n{2}/g, '\n');
      // .replaceAll(/(?<!\\)\*+/g, '')
      // find all unescaped * and remove them
      for (let i = 1; i < text.length - 1; i++) {
        if (text.charCodeAt(i) == 42 /* '*' */ && text.charCodeAt(i - 1) != 92/* \ */) {
          text = text.substring(0, i) + text.substring(i + 1);
          i--;
        }
      }
      if (text.charCodeAt(0) == 42) text = text.substring(1);
      text = text.replace(/\\([*_`{}\[\]()#+\-.!])/g, '$1').trim();
      return text;
    },
    navBack () {
      if (this.$router.options.history.state.back) this.$router.back();
      else this.$router.push('/notes');
    },
    noteStateEventHandler (event) {
      // if current user triggered this event. ignore the event
      if (event.noteId != this.note.id) return;
      if (event.eventUserId == this.$store.getters.getUserId) return;
      this.simuEditingWarning = true;
      this.$toast.error({
        message: `There has been a change to this note!`,
        action: { fn: this.getPageData, message: "Refresh" }
      });
      this.note.state = event.newState;
    },
    // * Audio request handling
    // get all audio for segments in the note.
    getAudio () {
      return NotesService.GetNoteAudio(this.note.id)
        .then((resp) => this.handleBlob(resp, this.note.id))
        .catch((err) => {
          this.noAudio = true;
          console.log("no audio found", err);
        });
    },
    changePlaybackRate (rate) {
      if (!this.audioConfig) return;
      this.audioConfig.audioTag.playbackRate = rate;
      localStorage.setItem("playbackRate", rate);
    },
    async handleBlob (resp, noteId) {
      let blobURL;
      // using relative assets for testing because testing framework destroys response of passthrough request
      if (__ENV === 'test') {
        blobURL = require(`@/tests/audio/lorem_ipsum.wav`);
      } else {
        blobURL = window.URL.createObjectURL(resp.data);
      }
      let audio = new Audio(blobURL);
      audio.src = blobURL;
      audio.id = `audio-${noteId}`;
      audio.load();

      let rate = localStorage.getItem("playbackRate");
      if (rate !== null) {
        audio.playbackRate = parseFloat(rate);
      }
      let tl = document.getElementById('timeline');
      if (tl == undefined) return; // user change the view while promise is running
      tl.appendChild(audio);
      // push latest audio clip data into
      let audioConfig = {
        // data: blob,
        dataURL: blobURL,
        id: noteId,
        audioTag: audio,
        currTime: 0,
        duration: audio.duration,
        volume: audio.volume,
        playbackRate: audio.playbackRate,
        isPlaying: false,
        canPlay: false,
        order: undefined, //gets set once all audio is gathered and sorted, also acts like a 1 indexed unique id
        yPos: 0,          // gets set once all audio is gathered and sorted, and dom is rendered with RTF to match the position to align with the fields
        // these function handlers the the events from the audio tag and pull them out for the v-dom to react on
        _loadedHandler: (e) => {
          this.audioConfig.duration = this.audioConfig.audioTag.duration;
          this.audioConfig.canPlay = true;
          this.audioConfig.playbackRate = this.audioConfig.audioTag.playbackRate;
        },
        _playingHandler: () => {
          /* the audio is now playable; play it if permissions allow */
          this.audioConfig.isPlaying = true;
        },
        _pauseHandler: () => {
          this.audioConfig.isPlaying = false;
        },
        _currentTimeHandler: () => {
          this.audioConfig.currTime = this.audioConfig.audioTag.currentTime;
        },
        _volumeChangeHandler: () => {
          this.audioConfig.vol = this.audioConfig.audioTag.volume;
        },
        _rateChangeHandler: () => {
          this.audioConfig.playbackRate = this.audioConfig.audioTag.playbackRate;
        },
        _durationChangeHandler: () => {
          this.audioConfig.duration = this.audioConfig.audioTag.duration;
        },
      };
      this.audioConfig = audioConfig;
      audio.addEventListener("canplaythrough", audioConfig._loadedHandler);
      audio.addEventListener('playing', audioConfig._playingHandler);
      audio.addEventListener('pause', audioConfig._pauseHandler);
      audio.addEventListener('timeupdate', audioConfig._currentTimeHandler);
      audio.addEventListener('volumechange', audioConfig._volumeChangeHandler);
      audio.addEventListener('ratechange', audioConfig._rateChangeHandler);
      audio.addEventListener('durationchange', audioConfig._durationChangeHandler);
    },

    // * playback controls
    // listener for the keyboard events to control playback
    keyHandler (event) {
      let code = event.code;
      let ctrlKey = event.ctrlKey;
      if (!code || !ctrlKey) return;

      // if (this.focusedField) return;
      switch (code) {
        case ("KeyK"):
          if (this.audioConfig) this.togglePlayback();
          event.preventDefault();
          event.stopPropagation();
          break;
        case ("KeyJ"):
          this.changeCurrTime(-5);
          event.preventDefault();
          event.stopPropagation();
          break;
        case ("KeyL"):
          this.changeCurrTime(5);
          event.preventDefault();
          event.stopPropagation();
          break;
      }
    },
    // returns a format of 0:00 from a number of seconds
    secondsToMinutesString (time) {
      if (!time) return '0:00';
      if (time === Infinity || isNaN(time)) return '0:00';
      const minutes = parseInt(time / 60);
      const seconds = parseInt(time % 60);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    togglePlayback () {
      if (this.audioConfig.isPlaying) {
        this.audioConfig.audioTag.pause();
      } else {
        this.audioConfig.audioTag.play();
      }
    },
    pausePlayback () {
      if (this.audioConfig.isPlaying) {
        this.audioConfig.audioTag.pause();
      }
    },
    // sets default playback values for changing from one clip to the next or selecting clips
    resetClip () {
      this.pausePlayback();
      // resetting on tag, all eventHandlers should set any reactive props as well
      this.audioConfig.audioTag.currentTime = 0;
    },    // sets default playback values for changing from one clip to the next or selecting clips
    endClip () {
      this.pausePlayback();
      // resetting on tag, all eventHandlers should set any reactive props as well
      this.audioConfig.audioTag.currentTime = this.audioConfig.audioTag.duration;
    },
    seekClip (event) {
      if (!this.canPlay) return;
      if (!this.playbackBarDragging && event.type === "mousemove") return;
      if (event.type === "mousedown") {
        this.playbackBarDragging = true;
        document.addEventListener('mousemove', this.seekClip);
      }
      else if (event.type === "mouseup") {
        this.playbackBarDragging = false;
        document.removeEventListener('mousemove', this.seekClip);
      }
      const rect = document.getElementById("playback-bar").getBoundingClientRect();
      const absX = event.x;
      // get relative click position in the timeline
      const relX = absX - rect.x;
      // translate relative click position to percentage to seek to the relative audio time
      const setTime = (this.audioConfig.audioTag.duration * (relX / rect.width));
      this.audioConfig.audioTag.currentTime = setTime;
      if (!this.audioConfig.isPlaying)
        this.audioConfig.audioTag.play();
    },
    mouseUp () {
      this.volumeDragging = false;
      this.playbackBarDragging = false;
    },
    volumeChange (event) {
      if (!this.volumeDragging && event.type === "mousemove") return;

      // adding the mouse move event handler fsor when the user drags outside of the volume div
      if (event.type === "mousedown") {
        this.volumeDragging = true;
        document.addEventListener('mousemove', this.volumeChange);
      }
      else if (event.type === "mouseup") {
        this.volumeDragging = false;
        document.removeEventListener('mousemove', this.volumeChange);
      }
      const rect = document.getElementById("volume-slider").getBoundingClientRect();
      const absX = event.x;
      // get relative click position in the timeline
      const relX = absX - rect.x;
      // translate relative click position to percentage to seek to the relative audio time
      let setVolume = (relX / rect.width);
      if (setVolume < 0.1) setVolume = 0;
      else if (setVolume > 0.95) setVolume = 1;
      this.audioConfig.audioTag.volume = setVolume < 0.1 ? 0 : setVolume;
      this.audioConfig.volume = setVolume < 0.1 ? 0 : setVolume;
    },
    // this adds some positive or negative value to the current time to seek through playback. bound to arrow keys for +/- 5 seconds
    changeCurrTime (val) {
      let newTime = 0;
      if (val < 0) {
        newTime = Math.max(0, this.audioConfig.audioTag.currentTime + val);
      } else {
        newTime = Math.min(this.audioConfig.audioTag.currentTime + val, this.audioConfig.duration);
      }
      this.audioConfig.audioTag.currentTime = newTime;
    },

    playClip (id) {
      this.pausePlayback();
      this.index = id;
      this.resetClip(this.index);
      this.togglePlayback();
    },

    // * Page Actions
    toggleShowTranscript () {
      this.showTranscript = !this.showTranscript;
    },
    navOldNoteView () {
      this.$emit("noteViewChange");
    },

    // * title editing functions
    toggleTitleEditing () {
      this.editingTitle = !this.editingTitle;
      if (this.editingTitle) {
        this.titleCopy = this.note.title;
        this.titleTarget.width = document.getElementById('title').getBoundingClientRect().width;
        this.titleTarget.height = document.getElementById('title').getBoundingClientRect().height;
        this.$nextTick(() => {
          this.titleTarget.max = document.getElementById('title-edit-wrapper').parentElement.getBoundingClientRect().width - 64;
        });
        this.$nextTick(() => document.getElementById("edit-title-input").select());

      }
    },
    //so much text input hacker-y bs to get this to work right
    trimInput (e) {
      if (this.titleSaveTimeout !== -1) window.clearTimeout(this.titleSaveTimeout);
      this.titleSaveTimeout = window.setTimeout(() => {
        this.pendingTitleChange = false;
        NotesService.UpdateNote({ note_id: this.note.id, title: this.note.title })
          .then((resp) => {
            // this.$toast.success({ message: "Title successfully updated" });
          })
          .catch((err) => {
            console.log("failed editing title", err);
            this.$toast.error({ message: "Something when wrong when saving!" });
          });
        this.trackTitleEdit();
      }, 3000);
      // clean input, trim new line
      if (e.inputType === 'insertLineBreak')
        this.note.title = this.note.title.trim();
      if (e.data === null)
        this.note.title = this.note.title.trim();
      this.pendingTitleChange = true;
      let target = document.getElementById('title-target');
      if (target) {
        let target = document.getElementById('title-target').children[0];
        let rect = target.getBoundingClientRect();
        this.titleTarget.height = rect.height;
        this.titleTarget.width = rect.width;
      } else {
        console.warn("could not get the title mock to target for sizing");
      }

    },

    // preference functions
    GetUserPreferences (userId) {
      NotesService.GetUserPreferences(userId)
        .then((resp) => {
          this.preferences = resp.data;
        })
        .catch(err => {
          console.log(err);
        })
        .finally();
    },
    selectLanguagePreference (selection) {
      this.preferences.language_code = selection;
      this.updatePreferences();
    },
    updatePreferences () {
      if (this.saveTimeout > 0) {
        clearTimeout(this.saveTimeout);
        this.saveTimeout = -1;
      }
      this.saveTimeout = setTimeout(() => {
        NotesService.UpdateUserPreferences(this.preferences)
          .catch(err => {
            console.error(err);
          });
        this.saveTimeout = -1;
      }, 1500);
    },

    // * note update functions
    handleTranscriptChange (field, text) {
      // this just lets a user delete the whole transcript.
      if (text == '') text = ' ';
      this.selectedTemplate[field] = text;
      NotesService.UpdateNoteTemplate(this.note.id, this.note.note_templates[this.templateIndex])
        .then(resp => {
        })
        .catch(err => {
          console.error(err);
        });
      this.trackTranscriptChange(field);
    },

    // used handle RTF text input events. moves state of the note to in review if review is pending or verified
    checkState () {
      const state = this.note.state;
      if (!this.ignoreInputEvents && NotesStateMap[state] !== "In Review") {
        this.setInReview(state);
        this.ignoreInputEvents = true;
      }
    },

    timelineStateHandler (state) {
      // special case for drafts, we need to close th note
      switch (NotesStateMap[state]) {
        case ("Awaiting Review"):
          this.note.state == 0 ?
            this.closeNote() :
            this.setAwaitingReview(state);
          break;
        case ("In Review"):
          this.setInReview(state);
          break;
        case ("Verified"):
          this.verifyNote(state);
          break;
        case ("In PMS"):
          this.copiedToPms(state);
          break;
        case ("Archived"):
          this.archiveNote(true);
          break;
      }
    },
    closeNote () {
      NotesService.CloseNote(this.note.id)
        .then(resp => {
          this.note = this.parseNoteInts(resp.data);
          if (this.note.note_templates.length === 0) {
            this.$router.push(`/notes/${this.note.id}`);
          } else {
            this.getAudio();
          }
        })
        .catch((e) => { console.log(e); });
    },
    setAwaitingReview () {
      const state = NotesStateMap.indexOf("Awaiting Review");
      NotesService.UpdateNote({ note_id: this.note.id, title: this.note.title, state: state })
        .then(resp => {
          this.note.last_edited = parseInt(resp.data.last_edited);
          this.note.state = state;
          this.ignoreInputEvents = false;
        })
        .catch((e) => { console.log(e); });
    },
    setInReview () {
      const state = NotesStateMap.indexOf("In Review");
      return NotesService.UpdateNote({ note_id: this.note.id, title: this.note.title, state: state })
        .then(resp => {
          this.note.last_edited = parseInt(resp.data.last_edited);
          this.note.state = state;
        })
        .catch((e) => { console.log(e); });
    },
    verifyNote () {
      const state = NotesStateMap.indexOf("Verified");
      NotesService.UpdateNote({ note_id: this.note.id, title: this.note.title, state: state })
        .then(resp => {
          this.note.last_edited = parseInt(resp.data.last_edited);
          this.note.state = state;
        })
        .catch((e) => { console.log(e); });
    },
    copiedToPms () {
      const state = NotesStateMap.indexOf("In PMS");
      NotesService.UpdateNote({ note_id: this.note.id, title: this.note.title, state: state })
        .then(resp => {
          this.note.last_edited = parseInt(resp.data.last_edited);
          this.note.state = state;
          // check if the user is an editor
          // TODO When we have the Verified and in PMS state move this check to that update
          if (this.$store.getters.getUserId != this.note.user_id) {
            this.$toast.success({
              message: "Note marked as Verified.",
              action: {
                fn: () => {
                  this.setInReview()
                    .then(() => this.$router.push("/notes/" + this.note.id));
                },
                message: "UNDO",
              }
            });
            this.$router.push("/notes");
          }
        })
        .catch((e) => { console.log(e); });
    },
    archiveNote (archive) {
      if (!this.userOwnsNote) return;
      this.loading = true;
      return NotesService.ArchiveNote(this.note.id, archive)
        .then(resp => {
          this.note.last_edited = parseInt(resp.data.last_edited);
          this.note.archived_on = parseInt(resp.data.archived_on);
          this.note.archived = archive;
          if (archive) {
            this.$toast.success({
              message: "The note has been archived.",
              action: {
                fn: () => {
                  this.archiveNote(false)
                    .then(() => this.$router.push("/notes/" + this.note.id));
                },
                message: "UNDO",
              }
            });
            this.navBack();
          } else {
            this.$toast.success({ message: "The note has been restored." });
          }

        })
        .catch((e) => { console.log(e); })
        .finally(() => {
          this.loading = false;
        });
    },
    saveSettings (settings) {
      this.noteSettings = settings;
    },
    upThumb (e, field) {
      let rating = 1;
      if (this.selectedTemplate.ratings[field] === 1) rating = 0;
      else {
        e.target.classList.add('animate');
        window.setTimeout(() => { e.target.classList.remove('animate'); }, 1000);
      }
      this.selectedTemplate.ratings[field] = rating;
      NotesService.UpdateNoteTemplateRating(this.note.id, this.note.note_templates[this.templateIndex], field, rating)
        .then((resp) => {
          this.$toast.success({ message: "Feedback received!" });
        }).catch((err) => {
          console.log(err);
          this.$toast.success({ message: "Error receiving feedback!" });
        });
    },
    downThumb (e, field) {
      let rating = -1;
      if (this.selectedTemplate.ratings[field] === -1) rating = 0;
      else {
        e.target.classList.add('animate');
        window.setTimeout(() => e.target.classList.remove('animate'), 1000);
      }
      this.selectedTemplate.ratings[field] = rating;
      NotesService.UpdateNoteTemplateRating(this.note.id, this.note.note_templates[this.templateIndex], field, rating).then((resp) => {
        this.$toast.success({ message: "Feedback received!" });
      }).catch((err) => {
        console.log(err);
        this.$toast.success({ message: "Error receiving feedback!" });
      });
    },
    //* issue modal actions
    toggleAttentionModal () {
      this.selectedProblem = 1;
      this.problemDescription = '';
      this.otherProblem = '';
      this.showAttentionModal = !this.showAttentionModal;
    },
    toggleIssueResponseModal () {
      this.showIssueResponseModal = !this.showIssueResponseModal;
    },
    getAllIssues () {
      if (this.toggleShowAllIssuesMessaging) {
        this.toggleShowAllIssuesMessaging = !this.toggleShowAllIssuesMessaging;
        this.note.issues = this.note.issues.filter(x => !x.resolved);
        return;
      }
      NotesService.GetNoteIssues(this.note.id)
        .then(resp => {
          if (resp.data.issues.length == 0) {
            this.$toast.success({ message: "There were no issues on this note!" });
            return;
          }
          this.toggleShowAllIssuesMessaging = !this.toggleShowAllIssuesMessaging;
          this.note.issues = this.parseIssuesInts(resp.data.issues);
          this.sortNNA();
        }).catch(err => {
          console.log(err);
        });
    },
    sortNNA () {
      this.note.issues.forEach(x => x.ts = x.created_at_unix);
      // order date desc
      this.note.issues = this.note.issues.sort((a, b) => a.ts == b.ts ? 0 : a.ts > b.ts ? -1 : 1);
      // put unresolved issues first
      this.note.issues = this.note.issues.sort((a, b) => a.resolved == b.resolved ? 0 : a.resolved ? 1 : -1);
    },
    SubmitIssue () {
      this.submittedNeedsAttn = true;
      let problem = this.problemsList.find(x => x.value == this.selectedProblem).text;
      problem += '.';
      if (this.selectedProblem == 999) {
        problem = this.otherProblem;
        // add punctuation if the user doesn't add any.
        const lastChar = problem.substring(problem.length - 1);
        if ([".", ",", ":", ";", "!", "?"].some(x => x !== lastChar)) {
          problem += '.';
        }
      }
      NotesService.CreateNoteIssue(this.note.id, problem, this.problemDescription)
        .then((resp) => {
          this.note.issues.push(...this.parseIssuesInts([resp.data]));
          this.sortNNA();
          this.note.needs_attention = true;
          this.$toast.success({ message: "This note has been flagged as requiring attention and the author has been notified." });
          this.toggleAttentionModal();
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error({ message: "There was an issue with sending your issue." });
        }).finally(() => {
          this.submittedNeedsAttn = false;
        });
    },
    respondIssue (problemResponse, issueId) {
      NotesService.respondToNoteIssue(this.note.id, issueId, problemResponse)
        .then((resp) => {
          this.note.issues.find(x => x.id === issueId)
            .responses.push(...this.parseResponsesInts([resp.data]));
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error({ message: "There was a problem responding to the issue." });
        });
    },

    resolveIssue (issueId) {
      NotesService.ResolveNoteIssue(this.note.id, issueId)
        .then(() => {
          if (this.toggleShowAllIssuesMessaging) {
            this.note.issues.find(x => x.id === issueId).resolved = true;
          } else {
            this.note.issues = this.note.issues.filter(x => x.id !== issueId);
          }
          if (this.note.issues.every(x => x.resolved)) this.note.needs_attention = false;
          this.$toast.success({ message: "You have marked this issue as resolve." });
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error({ message: "There was an issue with resolving the note." });
        });
    },

    deleteIssue (issueId) {
      NotesService.DeleteNoteIssue(this.note.id, issueId)
        .then(() => {
          this.note.issues = this.note.issues.filter(x => x.id !== issueId);
          if (this.note.issues.every(x => x.resolved)) this.note.needs_attention = false;
          this.$toast.success({ message: "You have deleted the issue. The note is back in the scribes queue." });
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error({ message: "There was a problem with deleting the note." });
        });
    },
    // retrieves the note, note audio and potentially the issue if one exists.
    getPageData () {
      let notePromise, issuesPromise;

      notePromise = NotesService.GetNote(this.$route.params.noteId)
        .then(resp => {
          this.note = this.parseNoteInts(resp.data);

          if (this.note.note_templates.length === 0) {
            this.$router.push(`/notes/${this.note.id}${this.note.needs_attention ? '?has_issue=true' : ''}`);
          }
          if (this.isUserScribe) {
            this.GetUserPreferences(this.note.user_id);
          }
          // if there is no issues, or we are pre-requesting issues, return, do not get issues
          if (!this.note.needs_attention || this.hasIssue) return;
          NotesService.GetNoteIssues(this.note.id)
            .then((resp) => {
              this.note.issues = this.parseIssuesInts(resp.data.issues.filter(x => !x.resolved));
              this.sortNNA();
            });
        })
        .then(this.getAudio)
        .catch(err => {
          this.$toast.error({ message: "Note was not found!" });
          // this.$router.push("/notes");
          console.log(err);
        })
        .finally(() => this.loading = false);
      let noteIssues;
      if (this.hasIssue) {
        issuesPromise = NotesService.GetNoteIssues(this.$route.params.noteId)
          .then((resp) => {
            noteIssues = resp.data.issues.filter(x => !x.resolved);
            noteIssues = this.parseIssuesInts(noteIssues);
          });
        Promise.allSettled([notePromise, issuesPromise])
          .then((resps) => {
            if (resps.some(x => x.status !== "fulfilled")) return Promise.reject({ message: "one or promised was not fulfilled", data: resps });
            this.note.issues = noteIssues;
          }).catch((err) => {
            console.log(err);
          });
      }
    },
    // todo: move into NoteService Calls from the module.
    // clean up note json int64 formatting for JS
    parseNoteInts (note) {
      note.issues = note.issues.filter(x => !x.resolved);
      // note.transcript.forEach(x => { x.start = parseInt(x.start); x.end = parseInt(x.end); });
      // note.edited_transcript.forEach(x => { x.start = parseInt(x.start); x.end = parseInt(x.end); });
      // parsing numbers from strings to numbers
      note = this.parseNoteDates(note);
      note.state = parseInt(note.state);
      return note;
    },
    parseNoteDates (note) {
      note.created_at = parseInt(note.created_at);// * 1000
      note.archived_on = parseInt(note.archived_on);// * 1000
      note.last_edited = parseInt(note.last_edited);// * 1000
      note.published_at = parseInt(note.published_at);// * 1000
      return note;
    },
    parseIssuesInts (issues) {
      issues.forEach(issue => {
        issue.created_at_unix = parseInt(issue.created_at_unix);
        issue.responses = this.parseResponsesInts(issue.responses);
      });
      return issues;
    },
    parseResponsesInts (responses) {
      responses.forEach(response => {
        response.responded_by = parseInt(response.responded_by);
        response.responded_at_unix = parseInt(response.responded_at_unix);
      });
      return responses;
    },
    onResize () {
      this.windowWidth = window.innerWidth;
    },

    // this height transition will only work in specific cases. It doesn't handle nesting elements
    // before initial render
    setEnterLeaveHeight (el) {
      el.style.maxHeight = "0px";
    },
    // called one frame after the element is inserted.
    setTransitionHeight (el, done) {
      let height = 0;
      for (var i = 0; i < el.childElementCount; i++) {
        height = Math.max(el.children[i].clientHeight, height);
      }
      el.style.maxHeight = `${height}px`;
      setTimeout(done, 250);
    },

    transitionDone () {
      // this.$emit('toggleOriginal');
    },
    // * Tracking functions
    trackTranscriptChange (field) {
      let subId = this.$store.getters.getSubscriptionId;
      MixpanelService.Track("AdminPortal:TranscriptChange", {
        subscription_id: subId,
        note_id: this.note.id,
        field: field,
        view: "auto-soap",
      });
    },
    trackCopy (field) {
      let subId = this.$store.getters.getSubscriptionId;
      MixpanelService.Track("AdminPortal:TranscriptCopy", {
        subscription_id: subId,
        note_id: this.note.id,
        field: field,
        view: "auto-soap",
      });
    },
    trackTitleEdit () {
      let subId = this.$store.getters.getSubscriptionId;
      MixpanelService.Track("AdminPortal:TitleChange", {
        subscription_id: subId,
        note_id: this.note.id,
        view: "auto-soap",
      });
    },
  },

  beforeUnmount () {
    // revoke blob urls
    // remove listeners, especially space listener
    document.removeEventListener('keydown', this.keyHandler);
    document.removeEventListener('mouseup', this.mouseUp);
    document.removeEventListener('mousemove', this.volumeChange);
    window.removeEventListener('resize', this.onResize);

    if (this?.audioConfig?.audioTag) {
      window.URL.revokeObjectURL(this.audioConfig.audioTag.src);
      document.getElementById('timeline').removeChild(this.audioConfig.audioTag);
    }
    this.$store.getters.getWebsocketEventHandler.removeEvents(this.wsIds);
  },
};
<template>
  <div class="flex flex-col-reverse min-w-96">
    <TransitionGroup name="notify">
      <div v-for="(message) in messages" :key="message.id"
           :class="`notification ${message.type}`">
        <div class="m-auto text-center">
          <span class="message">
            {{ message.text }}
          </span>
        </div>
        <a v-if="message.action" class="ml-4" :disabled="message.clicked"
           @click="actionClick(message)">
          {{ message.action.message }}
        </a>
        <button id="notify-close" @click="close(message.id)">
          <i class="material-icons text-2xl ml-4">close</i>
        </button>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
export default {
  name: "BaseNotify",
  props: [],
  data () {
    return {
      messages: [],
    };
  },
  created () {
  },
  methods: {
    notify (type, message, action) {
      const timeoutId = window.setTimeout(() => this.expire(timeoutId), 3000);
      this.messages.push({ text: message, type, action, id: timeoutId });
    },
    actionClick (message) {
      message.clicked = true;
      this.close(message.id);
      message.action.fn();
    },
    expire (id) {
      this.messages = this.messages.filter(x => x.id !== id);
    },
    close (id) {
      window.clearTimeout(id);
      this.messages = this.messages.filter(x => x.id !== id);
    },
  }
};
</script>

<style scoped>
.notification {
  display: flex;
  @apply justify-between;
  @apply p-4;
  @apply my-1;
  @apply border-3;
  @apply rounded-sm;
  @apply font-normal;
  @apply text-gray-500;
  @apply bg-white;
  @apply border-main;
}

.notification .message {
  @apply text-lg;
}

.notify-enter-active,
.notify-leave-active {
  transition: all 0.2s ease;
}

.notify-enter-from {
  transform: translateY(-20px);
}
.notify-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>

<template>
  <!-- this component is intended to represent the number of users in a plan, from 1 to 15 users in the
     form of birds.
-->

  <div class="overflow-x-clip">
    <div class="relative flex scale-[130%] -ml-12">
      <img id="city-image" src="@/assets/images/upgrade/birds_on_a_wire1.svg" class="w-full" @load="onResize"
      />
      <TransitionGroup name="fade">
        <img v-for="bird in birdSelection" :key="bird"
             :src="require(`@/assets/images/upgrade/bird${bird}.svg`)" class="absolute default-fade-duration bird" />
      </TransitionGroup>

      <!-- sun -->
      <Transition name="fade">
        <img v-if="animateSun" src="@/assets/images/upgrade/sun.svg"
             class="absolute happy-sun default-fade-duration" :style="`width:${cityScale * 53}px; top:1.4%; left:84.2%;`" />
      </Transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    birdCount: Number,
    animateSun: Boolean,
  },
  data () {
    return {
      birds: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], // [...Array(16).keys()].slice(2),
      // used to size the sun svg for mobile
      cityScale: 1,
    };
  },
  mounted () {
    this.$nextTick(() => {
      this.onResize();
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    animateSun (newVal) {
      if (newVal) {
        this.$nextTick(this.onResize);
      }
    }
  },
  computed: {
    // cuts the bird index array to the size of users selected to display # of birds === number of users selected -1 (default is 2 bird shown, indexed at 2)
    // [2..15].slice(0,5 users) === [2,3,4,5]
    birdSelection () {
      if (this.birdCount < 0) return [];
      return this.birds.slice(0, this.birdCount);
    },
  },
  methods: {
    // used to create a ratio to scale the sun svg so it sits correctly at any screen size. bound to resize event listener for testing
    onResize () {
      let city = document.getElementById("city-image");
      if (!city) {
        this.cityScale = 1;
      } else {
        this.cityScale = city.width / 799;
      }
    },
  },
};
</script>

<style>
.bird {
  transition: opacity 0.2s ease-in-out;
  width: 100%;
}

.happy-sun {
  /* for Transition */
  transition: opacity 0.5s;
  animation: rotate-and-scale 6s cubic-bezier(0.3, 0.25, 0.25, 0.3) both;
  transform: translate3d(0, 0, 0);
  filter: drop-shadow(0px 0px 7px #fac666) saturate(150%);
  animation-iteration-count: infinite;
}

@keyframes rotate-and-scale {
  25% {
    transform: rotate(90deg) scale(1.5);
    filter: drop-shadow(0px 0px 15px #fac666);
  }

  50% {
    transform: rotate(180deg) scale(1);
    filter: drop-shadow(0px 0px 10px #fac666);
  }

  75% {
    transform: rotate(270deg) scale(1.5);
    filter: drop-shadow(0px 0px 15px #fac666);
  }

  100% {
    transform: rotate(360deg) scale(1);
    filter: drop-shadow(0px 0px 10px #fac666);
  }
}</style>
<template>
  <div class="text-lg flex flex-col sm:min-w-128">
    <div class="pb-8 pt-6 text-2xl sm:text-lg text-center sm:text-left font-bold mx-4">
      Please confirm that your billing information is correct
    </div>
    <div class="relative min-h-[18.125rem] flex mx-2 mb-4 p-8 flex-col border-3 border-main  rounded-xl blue-shadow-lg">
      <div class="bg-pastel-yellow-light min-w-[18.25rem] mr-auto p-6 rounded-lg  mb-2 text-2xl sm:text-sm ">
        <div class="mb-2">
          <img v-if="cardMap[subscription.payment_profile.card_type] != undefined"
               :src="require('@/assets/images/' + subscription.payment_profile.card_type + '.svg')"
               height="40" width="70" class="bg-white rounded-lg" />
        </div>
        <span class="whitespace-nowrap font-semibold">
          {{ cardMap[subscription.payment_profile.card_type] }} ending in
          {{ concatCardNumber }}
          <br />
          Expiry:
          {{ subscription.payment_profile.expiration_month + "/" + subscription.payment_profile.expiration_year }}
        </span>
      </div>

      <div class="ml-4">
        <div class="flex flex-col text-lg sm:text-sm font-bold">
          <span class="text-3xl sm:text-2xl py-2 whitespace-nowrap">
            {{ subscription.payment_profile.billing_address.name }} {{ subscription.payment_profile.billing_address.first_name }} {{ subscription.payment_profile.billing_address.last_name }}
          </span>
          <span class="pb-2">{{ subscription.payment_profile.billing_address.address }}, {{ subscription.payment_profile.billing_address.address_2 }}</span>
          <span class="pb-2">{{ subscription.payment_profile.billing_address.city }}, {{ stateMap }} {{ subscription.payment_profile.billing_address.zip }}</span>
          <span>{{ countryMap }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BillingInfoPreview",
  filters: {

  },
  props: ["subscription", "countryList"],

  data () {
    return {
      cardMap: {
        visa: "Visa",
        master: "Mastercard",
        discover: "Discover",
        american_express: "American Express",
      },
    };
  },
  computed: {
    concatCardNumber () {
      return this.subscription.payment_profile.masked_card_number.substr(-4);
    },
    countryMap () {
      const map = this.countryList.find((x) => {
        return x.value === this.subscription.payment_profile.billing_address.country;
      });
      return map?.text ?? this.subscription.payment_profile.billing_address.country;
    },
    stateMap () {
      const map = this.countryList.find((x) => {
        return x.value === this.subscription.payment_profile.billing_address.country;
      });
      if (map?.states) {
        let match = map.states.find((x) => x.code === this.subscription.payment_profile.billing_address.state);
        if (match) {
          return match.state;
        }
      }
      return this.subscription.payment_profile.billing_address.state;
    },
  },
};
</script>

<script setup>
import { ref, watch, reactive } from "vue";
import { useStore } from "vuex";

let store = ref(useStore());

const props = defineProps({
  //required to use nested props objects in this case when passing props
  props: {
    type: Object,
    default () {
      return {
        noteState: '',
        updatedStatus: '',
        responseCount: 1
      };
    },
  }
});

function isUserScribe() {
  if (store.value.getters.getUserGroups === undefined) return false;
  return store.value.getters.getUserGroups.indexOf("Scribe") !== -1;
}

let isUserVerified = ref(store.value.getters.getUserPerms.verified);
let noteState = ref(props.props.noteState);
let responseCount = ref(props.props.responseCount ?? 1);
let updatedStatus = ref(props.props.updatedStatus);

watch(props, (newVal, oldVal) => {
  if (oldVal) {
    responseCount = props.props.responseCount ?? 1;
    if (props.props.updatedStatus) {
      updatedStatus = props.props.updatedStatus;
    }
  }
});
</script>
<template>
  <div class="flex justify-start px-4 xl:px-0  mt-3 mb-4 lg:my-0 font-roboto
              sm:absolute xl:static sm:right-0 sm:pb-8 xl:pb-0 sm:-translate-y-full xl:translate-y-0">
    <!-- <TransitionGroup name="fade-pull"> -->
    <template v-if="updatedStatus">
      <div v-if="updatedStatus === 'Draft'" class="base-label px-4 bg-pastel-purple-lighter text-rare-darker">
        <span class="text-neutral-alt"> new! </span> Draft
      </div>
      <div v-else-if="updatedStatus === 'Processing'" class="base-label px-4 bg-pastel-purple-lighter text-rare-darker">
        <span class="text-neutral-alt"> new! </span> Processing
      </div>
      <div v-else-if="updatedStatus === 'Awaiting Review'" class="base-label px-4 bg-pastel-yellow-light text-secondary-darker">
        <span class="text-neutral-alt"> new! </span> Awaiting Review
      </div>
      <div v-else-if="updatedStatus === 'In Review'" class="base-label px-4 bg-pastel-blue-lighter text-main">
        <span class="text-neutral-alt"> new! </span> In Review
      </div>
      <div v-else-if="updatedStatus === 'Verified'" class="base-label px-4 bg-pastel-green-lighter text-rare2 ">
        <span class="text-neutral-alt"> new! </span> Verified
      </div>
      <div v-else-if="updatedStatus === 'In PMS'" class="base-label px-4 bg-pastel-green-lighter text-rare2">
        <span class="text-neutral-alt"> new! </span>  In PMS
      </div>
    </template>
    <div v-else-if="noteState === 'Draft'" class="base-label px-4 bg-pastel-purple-lighter text-rare-darker">
      Draft
    </div>
    <div v-else-if="noteState === 'Processing'" class="base-label px-4 bg-pastel-purple-lighter text-rare-darker">
      Processing
    </div>
    <div v-else-if="noteState === 'Awaiting Review'" class="base-label px-4 bg-pastel-yellow-light text-secondary-darker">
      Awaiting Review
    </div>
    <div v-else-if="noteState === 'In Review'" class="base-label px-4 bg-pastel-blue-lighter text-main">
      In Review
    </div>
    <div v-else-if="noteState === 'Verified'" class="base-label px-4 bg-pastel-green-lighter text-rare2 ">
      {{ isUserVerified || isUserScribe() ? "Verified" : 'Completed' }}
    </div>
    <div v-else-if="noteState === 'In PMS'" class="base-label px-4 bg-pastel-green-lighter text-rare2 whitespace-nowrap">
      {{ isUserVerified || isUserScribe() ? "Verified" : 'Completed' }}  and In PMS
    </div>
    <div v-else-if="noteState === 'Needs Attention'" class="base-label px-4 bg-error-lighter text-error">
      Needs Attention
    </div>
    <div v-else-if="noteState === 'Resp:Needs Attention'"
         class="base-label pl-1 pr-3 flex justify-center whitespace-nowrap bg-error-lighter">
      <div class="resp-notif w-4 h-4 lg:w-5 lg:h-5 flex mr-1 rounded-full bg-error align-middle border border-white">
        <div class="flex flex-col flex-grow justify-center">
          <div class="my-auto text-white font-roboto-condensed font-bold text-xs md:text-xs leading-[initial]">
            {{ responseCount ?? 1 }}
          </div>
        </div>
      </div>
      <span class="my-auto text-error font-semibold">Needs Attention</span>
    </div>
    <div v-else-if="noteState === 'Archived'" class="base-label bg-gray-200 text-gray-700 px-4">
      Archived
    </div>

  </div>
</template>

<style scoped>
.base-label {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  @apply font-semibold;
  @apply text-center;
  @apply text-sm;
  @apply w-44;
  @apply font-semibold;
  @apply rounded;
  transition-duration: 0.3s, 0.3s;
}

.resp-notif {
  @apply my-auto;
}

@media (min-width: 1024px) {
  .resp-notif {
    min-width: 16px;
    min-height: 16px;
  }
}

@media (min-width: 2560px) {
  .resp-notif {
    min-width: 24px;
    min-height: 24px;
  }
}
</style>
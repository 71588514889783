<template>
  <div class="flex flex-col-reverse">
    <TransitionGroup name="toast">
      <div v-for="(message) in messages" :key="message.id"
           :class="`toast relative ${message.type}`">
        <div class="flex my-auto">
          <span class="message">
            {{ message.text }}
          </span>
        </div>
        <a v-if="message.action" class="underline ml-4" :disabled="message.clicked"
           @click="actionClick(message)">
          {{ message.action.message }}
        </a>
        <button id="toast-close" @click="close(message.id)">
          <i class="material-icons text-xl ml-4">close</i>
        </button>
        <div :class="`absolute bottom-0 ${message.type}-progress`" :style="`animation-duration:${message.timeout}ms;`">

        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
export default {
  name: "BaseToast",
  props: [],
  data () {
    return {
      messages: [],
      notif: [],
    };
  },
  created () {
  },
  methods: {
    addToast (type, message, action, timeout) {
      let t = timeout ?? 3000;
      const timeoutId = window.setTimeout(() => this.expire(timeoutId), t);
      this.messages.push({ text: message, type, action, id: timeoutId, timeout: t });
    },
    actionClick (message) {
      message.clicked = true;
      this.close(message.id);
      message.action.fn();
    },
    expire (id) {
      this.messages = this.messages.filter(x => x.id !== id);
    },
    close (id) {
      window.clearTimeout(id);
      this.messages = this.messages.filter(x => x.id !== id);
    },
  }
};
</script>

<style scoped>
.toast {
  display: flex;
  @apply mx-auto;
  @apply rounded-xl;
  @apply overflow-hidden;
  @apply justify-between;
  @apply p-3;
  @apply my-1;
  @apply border;
  @apply font-normal;
  @apply text-gray-500;
  @apply bg-white;
  min-height:60px;
}

.toast .message {
  @apply text-base;
}

.toast.success {
  @apply border-main-alt;
  @apply bg-pastel-blue-lighter;
}

.toast.error {
  @apply border-error;
  @apply bg-pastel-red-lighter;
}

.progress {
  width:0px;
  animation-name: progress;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  position: absolute;
  left:0;bottom:0;
}
.success-progress {
  @apply progress;
  @apply border-t-2;
  @apply border-main-alt;
}

.error-progress {
  transition: width 3s linear;

  @apply progress;
  @apply border-t-2;
  @apply border-error;
}

@keyframes progress {
  0% {
    width: 100%;
  }
  100% {
    width: 0px;
  }
}
.toast-enter-active,
.toast-leave-active {
  transition: all 0.2s ease;
}

.toast-enter-from {
  transform: translateY(-20px);
}

.toast-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>

let genFiltersName = function (headerFilters, asTag) {
    let name = [];
    headerFilters.input.forEach(input => {
        switch (input.inputType) {
            case ("datepicker"):
                if (input.value.length === 2) {
                    const start = input.value[0].toLocaleDateString('en-us', { day: "2-digit", month: "short", year: "numeric" });
                    const end = input.value[1].toLocaleDateString('en-us', { day: "2-digit", month: "short", year: "numeric" });
                    // locale date as 'Jul 21, 2023 - Jul 28, 2023'
                    name.push(`${start} - ${end}`);
                }
                break;
            case ("select"):
                name.push(input.value);
                break;
            case ("multi-select"):
                let activeKeys = [];
                Object.keys(input.value).forEach(key => { if (input.value[key]) activeKeys.push(key.replace(/_/g, " ")); });
                if (asTag) {
                    if (activeKeys.length === 1) name.push(activeKeys[0]);
                    else if (activeKeys.length === 2) name.push(...activeKeys);
                    else if (activeKeys.length > 2) {
                        let tag = `${activeKeys[0]} and ${activeKeys.length - 1} others`;
                        name.push(tag);
                    }
                } else {
                    name.push(...activeKeys);
                }
                break;
        }
    });
    return name.join(", ");
};

export {
    genFiltersName
};
<template>
  <div class="container-gray flex-col flex container-box">
    <div v-if="title" class="flex box-gradient banner">
      <span class="text-white p-4 ml-2 lg:ml-0 font-bold text-base lg:leading-5">
        {{ title }}
      </span>
    </div>
    <div class="p-6 lg:p-4 flex flex-col flex-grow bg-white rounded-sm">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseBox",
  props: ["title"],
};
</script>
<style scoped>
.banner {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.box-gradient {
  background: linear-gradient(90deg, #0386B7 0%, #52BAE2 52.08%, #97D6ED 99.99%);
}
</style>
<template>
  <div class="rounded-md lg:rounded-none flex-grow h-full" style="background-color: #d3d7e0">
    <BaseErrorPage
      :errorImgLink="require('@/assets/images/page-404.png')"
      class="flex justify-center"
    />
    <div class="flex justify-center">
      <p class="font-light text-3xl">
        Something is missing...
      </p>
    </div>
    <div class="flex justify-center">
      <p class="text-center text-sm leading-10">
        We're sorry! Looks like something went wrong on our end.
        <br />
        It seems that the page you were trying to reach does not exist anymore
        <br />
        Head back to the
        <RouterLink class="inline underline text-sm font-normal" to="/">
          homepage
        </RouterLink>
      </p>
    </div>
  </div>
</template>

<script>
import BaseErrorPage from "Components/ui/BaseErrorPage";

export default {
  name: "NotFound",
  components: {
    BaseErrorPage,
  },
};
</script>

<style></style>

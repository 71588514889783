<template>
  <div>
    <img
        v-if="errorImgLink"
        :src="errorImgLink"
        aria-label="error code"
        style="width: 60vw; padding-top: 6em; max-width: 50em"
    />
  </div>
</template>

<script>

export default {
  name: "BaseError",
  props: ["errorImgLink"],
};
</script>

<style>
</style>

export default [
  "Draft",
  "Awaiting Review",
  "In Review",
  "Verified",
  "In PMS",
  "Processing",
  "Needs Attention",
  "Archived",
];
<template>
  <LoginTemplate title=" " footer=" ">
    <div class="flex-col m-2 text-center">

      <p class="text-center text-3xl font-bold"> Email Confirmation </p>
      <br />
      <p :class="[successMessage == '' ? 'hidden' : '', 'text-rare2-dark text-2xl py-4 mx-4']">
        {{ successMessage }}
      </p>

      <p :class="[errorMessage == '' ? 'hidden' : 'shake', 'text-error text-2xl py-4']">
        {{ errorMessage }}
      </p>
    </div>
    <span v-if="!loading">
      <!-- resend button will show if email is included in the header and confirmation failure -->
      <div v-show="showResendButton" class="flex flex-col justify-center  text-center pt-4 pb-8">
        <button class="btn-primary btn-lg" @click="sendConfirmation">
          Resend Confirmation Email
        </button>
      </div>

      <div v-show="!confirmSuccess">
        <div class="flex flex-col justify-center pt-8">
          <p class="text-sm text-center text-gray-500 pb-4">Already confirmed your email?
            <RouterLink class="inline-anchor" to="/login"> Sign in</RouterLink>
          </p>
          <p class="text-sm text-center text-gray-500">contact support at
            <a class="inline-anchor" href="tel:18558862156">1-855-886-2156</a>
          </p>
        </div>
      </div>

      <div v-show="confirmSuccess">
        <br />
        <div class="flex mt-6 justify-center">
          <button class="btn-primary btn-thick" @click="downloadTalkatoo">
            Download Talkatoo
          </button>
        </div>
      </div>
    </span>
  </LoginTemplate>
</template>

<script src="./ConfirmEmail.js"></script>

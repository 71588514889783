import axios from 'axios';

const SERVICE_URL = __API_URL_V1 + 'forgot_password';

const SendPasswordReset = (email) => {
  const payload = {
    "email": email
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL,
    data: payload
  });
};


const VerifyResetToken = (email, token) => {
  const payload = {
    "email": email,
    "token": token
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL + "/verify",
    data: payload
  });
};

export {
  SendPasswordReset,
  VerifyResetToken,
};
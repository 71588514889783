<template>
  <div class="flex flex-col bg-white rounded-t rounded-md lg:rounded-t-0 mb-32">
    <div class="heading w-full mb-5">
      <div class="flex flex-row flex-grow justify-between lg:max-w-5xl xl:max-w-[1152px]">

        <div class="my-auto text-3xl font-bold">
          Subscription
        </div>
        <div class="flex flex-row justify-center lg:h-full">
          <!-- Hiding upgrade button for annual plans for the moment High priority  to add prorate functionality  -->
          <button v-show="!loading"
                  id="upgrade-button"
                  class="btn-heading w-full my-auto text-sm font-bold"
                  @click="toggleSubscriptionModal">
            Upgrade

          </button>
        </div>
      </div>
    </div>
    <div class="body-container">
      <!-- error messages -->
      <p v-if="errorMessage !== ''" class="flex justify-center text-error text-sm bg-error-lighter bg-opacity-25 my-2 rounded lg:max-w-4xl xl:max-w-6xl">
        {{ errorMessage }}
      </p>
      <div class="lg:max-w-5xl xl:max-w-6xl">
        <HeaderBanner
          :loading="loading"
          :subscription="subscription"
          :filterList="['pastDue', 'cancelled', 'subscriptionFull', 'trialing', 'paused']"
          @toggleSubscriptionModalEvent="toggleSubscriptionModal"
          @toggleBillingModalEvent="toggleBillingModal"
          @reactivateSubscription="reactivateSubscription" />
      </div>
    </div>

    <!-- main body -->
    <div class="body-container">
      <div v-if="loading && subscription.subscription_state === undefined"
           class="flex flex-wrap lg:flex-nowrap pb-4  lg:max-w-5xl xl:max-w-6xl">
        <BaseBox title="Payment" class="flex-col flex w-full">
          <div class="h-48 lg:h-52">
            <BaseLoading />
          </div>
        </BaseBox>
      </div>
      <!-- trialing /trial ended CTA -->
      <div v-else-if="($store.getters.isTrialing || $store.getters.isTrialEnded) && subscription.payment_profile == undefined"
           class="flex flex-wrap lg:flex-nowrap pb-4 lg:max-w-5xl xl:max-w-6xl">
        <BaseBox title="Payment" class="flex-col flex w-full">
          <!-- for an account without billing info, we display a custom message-->
          <div class="flex flex-col lg:flex-row">
            <div class=" block flex-col flex-none px-6 md:px-4 pt-4 pb-3 rounded-lg mb-2 text-xl sm:text-base bg-pastel-yellow-light">
              <div class="flex justify-center lg:justify-start whitespace-nowrap mb-4 lg:mb-0">
                <img v-if="cardMap['visa'] != undefined" src="@/assets/images/visa.svg" class="rounded-lg max-h-16 sm:max-h-20 md:max-h-16 lg:max-h-10 max-w-1/3" />
                <img v-if="cardMap['master'] != undefined" src="@/assets/images/master.svg" class="rounded-lg max-h-16 sm:max-h-20 md:max-h-16 lg:max-h-10 max-w-1/3" />
                <img v-if="cardMap['american_express'] != undefined" src="@/assets/images/american_express.svg" class="rounded-lg max-h-16 sm:max-h-20 md:max-h-16 lg:max-h-10 max-w-1/3" />
                <img v-if="cardMap['discover'] != undefined" src="@/assets/images/discover.svg" class="rounded-lg max-h-16 sm:max-h-20 md:max-h-16 lg:max-h-10 max-w-1/3" />
              </div>
              <div class="flex justify-center lg:justify-start">
                <p class="text-gray-500 py-4 whitespace-nowrap text-2xl lg:text-base">
                  0000 0000 0000 0000
                </p>
              </div>
              <div class="flex justify-center lg:justify-start">
                <button class="text-center sm:text-left text-2xl md:text-xl lg:text-base sm:whitespace-nowrap
                            text-main-alt hover:text-main-dark underline decoration-main-alt hover:decoration-main-dark"
                        @click="toggleBillingModal">
                  Add a credit card to continue.
                </button>
              </div>
            </div>
            <div class="text-center px-6 mt-auto lg:mb-4 text-2xl sm:text-lg lg:text-xl lg:max-w-4xl xl:max-w-6xl">
              <template v-if="store.getters.isTrialing">
                Upgrade to a paid plan before your trial is over to immediately begin paid service, avoid interruptions and grow your team.
              </template>
              <template v-else>
                <span class="font-bold">Your trial has ended!</span> Rejoin the flock and upgrade to a paid plan to immediately begin paid service and grow your team.
              </template>
              <div class="mt-4">
                <button class="btn-primary mx-auto py-4 border-2 border-main-dark hover:border-main w-3/5 sm:w-4/5"
                        @click="toggleSubscriptionModal">
                  Upgrade Now!
                </button>
              </div>
            </div>
          </div>
        </BaseBox>
      </div>
      <div v-else class="flex flex-wrap xl:flex-nowrap pb-4 lg:gap-4 lg:max-w-5xl xl:max-w-6xl">
        <!-- base box for displaying payment information-->
        <BaseBox title="Payment" class="flex-col flex w-full">
          <div v-if="loading" class="h-48 lg:h-40">
            <BaseLoading />
          </div>

          <!-- for an account with billing info -->
          <div v-else-if="subscription.payment_profile.card_type != ''">
            <div class="flex flex-col p-2">
              <!-- Credit card box -->
              <div class="flex flex-col w-full px-6 pt-4 pb-3 mb-2 rounded-lg bg-pastel-yellow-lighter">
                <div class="flex justify-between mb-2">
                  <img v-if="cardMap[subscription.payment_profile.card_type] != undefined"
                       :src="require('@/assets/images/' + subscription.payment_profile.card_type + '.svg')"
                       height="40" width="70" class="bg-white rounded-lg" />
                </div>
                <span class="whitespace-nowrap font-roboto text-sm font-medium">
                  {{ cardMap[subscription.payment_profile.card_type] ?? subscription.payment_profile.card_type }} ending in
                  {{ concatCardNumber }}
                </span>
                <span class="whitespace-nowrap font-roboto text-sm font-medium">
                  Expires
                  {{ subscription.payment_profile.expiration_month + "/" + subscription.payment_profile.expiration_year }}
                </span>
              </div>

              <!-- Making distinction between having an active subscription or not -->
              <div v-if="!productInactive && subscription.subscription_state != 'canceled' && subscription.subscription_state != 'trial_ended'"
                   class="text-sm font-roboto font-medium leading-5">

                <div v-if="subscription.subscription_state == 'trialing'" class="flex flex-col py-3 ">
                  Trail ends: {{ formattedDate }}
                  <span v-if="estimatedCharge">
                    Estimate Charge: <b class="whitespace-nowrap">{{ estimatedCharge }}</b>
                  </span>
                </div>
                <div v-else class="flex flex-col py-3 ">
                  <span>
                    Next Payment Amount: <b class="whitespace-nowrap">{{ nextBillingAmount }}</b>
                  </span>
                  <span>
                    Next Payment:
                    <b class="whitespace-nowrap">{{ formattedDate }}</b>
                  </span>
                </div>

                <div class="flex justify-center">
                  <button id="payment-update-button" class="btn-alt py-2 px-4"
                          @click="toggleBillingModal">
                    Update Payment Method
                  </button>
                </div>
              </div>

              <div v-else-if="productInactive" class="flex flex-col">
                <span class="payment-heading font-extrabold text-base">Something went wrong</span>
                <p class="w-full mb-6 2xl:pr-12 text-sm">
                  It seems your account is out of date; your product doesn't exist anymore. Please give support a call, tell them about this message and we can help you immediately.
                </p>
                <a data-v-b814a4d4="" href="tel:+18558862156" class="font-semibold whitespace-nowrap text-lg">1-855-886-2156</a>
              </div>
              <div v-else class="flex flex-col py-4 px-6">

                <span class="text-lg font-extrabold">
                  SUBSCRIPTION ENDED
                </span>
                <p class="w-full mb-6 text-sm font-medium">
                  Set up your subscription to start using Talkatoo again!
                </p>

                <div class="flex justify-center">
                  <button class="btn-alt py-2 px-4"
                          @click="toggleSubscriptionModal">
                    Upgrade Now!
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- for an account without billing info, we display a custom message not just trialing. catch case -->
          <div v-else-if="subscription.payment_profile.card_type == ''">
            <div class="flex flex-col">
              <div class="bg-pastel-yellow-lighter px-6 md:px-4 pt-4 pb-3 rounded-lg w-full flex flex-col mb-2 text-xl sm:text-base">
                <div class="flex flex-wrap justify-center lg:justify-start mb-4 lg:mb-0">
                  <img v-if="cardMap['visa'] != undefined" src="@/assets/images/visa.svg" class="bg-white rounded-lg max-h-20 md:max-h-16 lg:max-h-10 max-w-1/3" />
                  <img v-if="cardMap['master'] != undefined" src="@/assets/images/master.svg" class="bg-white rounded-lg max-h-20 md:max-h-16 lg:max-h-10 max-w-1/3" />
                  <img v-if="cardMap['american_express'] != undefined" src="@/assets/images/american_express.svg" class="bg-white rounded-lg max-h-20 md:max-h-16 lg:max-h-10 max-w-1/3" />
                  <img v-if="cardMap['discover'] != undefined" src="@/assets/images/discover.svg" class="bg-white rounded-lg max-h-20 md:max-h-16 lg:max-h-10 max-w-1/3" />
                </div>
                <div class="flex justify-center lg:justify-start">
                  <p class="text-neutral-darker py-4 whitespace-nowrap font-roboto text-xl">
                    0000 0000 0000 0000
                  </p>
                </div>
                <div class="flex justify-center lg:justify-start">
                  <a class="text-center sm:text-left text-2xl md:text-xl lg:text-base" @click="toggleBillingModal">
                    Add a credit card to continue.
                  </a>
                </div>
              </div>
              <div class="text-center px-6 my-2 text-2xl sm:text-lg lg:text-sm">
                Upgrade to a paid plan to immediately begin paid service and grow your team!
              </div>
            </div>

            <div class="flex justify-center">
              <button class="btn-alt py-2 px-4"
                      @click="toggleSubscriptionModal">
                Upgrade Now!
              </button>
            </div>
          </div>
        </BaseBox>
        <!-- billing info box -->
        <BaseBox title="Billing" class="flex-col flex w-full my-4 lg:my-0 lx:mx-4">
          <div v-if="loading" class="h-48 lg:h-40">
            <BaseLoading />
          </div>
          <div v-else-if="subscription.payment_profile.masked_card_number !== ''"
               class="flex-col flex flex-grow text-neutral text-sm font-medium mb-2">
            <div class=" font-extrabold text-base my-4 text-neutral-darker leading-5">
              {{ billingInfo.name }} {{ billingInfo.first_name }} {{ billingInfo.last_name }}
            </div>
            <div>{{ billingInfo.address }}, <br /> {{ billingInfo.address2 }}</div>
            <div>{{ billingInfo.city }}, {{ billingInfo.state }} {{ billingInfo.zip }}</div>
            <div>{{ billingInfo.country }}</div>
          </div>
          <div v-else class="flex-col flex flex-grow text-2xl sm:text-lg lg:text-base mb-4 lg:mb-0">
            <div class="m-auto text-center">
              <template v-if="$store.getters.isTrialing">
                <span class="font-bold">Add billing information to continue using Talkatoo after your trial is over.</span> <br />
                Your card will not be charged until the trial period ends and you'll enjoy uninterrupted usage!
              </template>
              <template v-else>
                Your trial has ended! <br />
                <span class="font-bold">Add billing information to easily start using Talkatoo on a paid plan.</span> <br />
                You will be charged immediately.

              </template>

              <!-- It looks like you have no Billing information <br/> add It to begin a paid account! -->
            </div>
          </div>
          <div v-if="!loading" class="flex justify-center">
            <button id="billing-update-button" class="btn-alt py-2 px-4"
                    @click="toggleBillingModal">
              Update Billing Info
            </button>
          </div>
        </BaseBox>
        <!-- Shipping info box -->
        <BaseBox title="Shipping" class="flex-col flex w-full">
          <div v-if="loading" class="h-48 lg:h-40">
            <BaseLoading />
          </div>
          <template v-else>
            <div v-if="shippingInfo.address !== ''"
                 class="flex-col flex flex-grow text-neutral text-sm font-medium mb-2">
              <div class=" font-extrabold text-base my-4 text-neutral-darker leading-5">
                {{ shippingInfo.name }} {{ shippingInfo.first_name }} {{ shippingInfo.last_name }}
              </div>
              <div>{{ shippingInfo.address }}, <br /> {{ shippingInfo.address2 }}</div>
              <div>{{ shippingInfo.city }}, {{ shippingInfo.state }} {{ shippingInfo.zip }}</div>
              <div>{{ shippingInfo.country }}</div>
            </div>
            <div v-else class="flex-col flex flex-grow text-2xl sm:text-lg lg:text-base font-medium mb-4 lg:mb-0">
              <div class="m-auto text-center">
                Add a shipping destination so we can send anything that may be required to that address!
              </div>
            </div>

            <div class="flex justify-center">
              <button class="btn-alt py-2 px-4"
                      @click="toggleShippingModal">
                Update Shipping Info
              </button>
            </div>
          </template>

        </BaseBox>
      </div>
    </div>

    <!-- mobile table display block -->
    <div class="body-container xl:hidden">

      <BaseBox title="Subscription">
        <span v-if="loading" class="m-auto">
          <BaseLoading />
        </span>
        <div v-if="tableData[0]">
          <div class="flex flex-col text-2xl">
            <b class="mb-1">{{ tableData[0].productName }} ({{ tableData[0].userDescription }})</b>
            <span v-if="!$store.getters.isTrialing" class="text-gray-400 text-xl mb-1">
              {{ tableData[0].term }} plan purchased on:
              <b class="mb-1 text-black whitespace-nowrap"> {{ tableData[0].purchased }} </b>
            </span>

          </div>
        </div>
        <div v-if="!loading && validThroughDate">
          <div v-if="$store.getters.isTrialing" class="bg-rare2-lighter text-rare2 rounded-lg my-4 p-4 text-center text-xl">
            Your trial is active through to {{ validThroughDate }}
          </div>
          <div v-else-if="$store.getters.isActive" class="bg-rare2-lighter text-rare2 rounded-lg my-4 p-4 text-center text-xl">
            Your subscription is active and valid through {{ validThroughDate }}
          </div>
          <div v-else class="bg-error-lighter text-error rounded-lg my-4 p-4 text-center text-xl">
            Your subscription has ended! Update your billing info to continue using Talkatoo!
          </div>

          <div class="flex justify-center">
            <a class="mt-4 text-2xl" @click="toggleSubscriptionModal"> Change Plan</a>
          </div>
        </div>
      </BaseBox>
    </div>

    <!-- bottom box credit additional info desktop display -->
    <div class="body-container pb-4">
      <div class="container-gray lg:max-w-5xl xl:max-w-6xl hidden xl:flex flex-col">
        <BaseTable title="SUBSCRIPTION" :colDef="colDef" :data="tableData" :showLoading="loading" />
        <div v-if="!loading && validThroughDate">
          <div v-if="$store.getters.isTrialing" id="trial-valid-through" class="bg-rare2-lighter text-rare2 rounded-lg m-4 p-2 text-center">
            Your trial is active through to {{ validThroughDate }}
          </div>
          <div v-else-if="$store.getters.isActive" id="active-valid-through" class="bg-rare2-lighter text-rare2 rounded-lg m-4 p-2 text-center">
            Your subscription is active and valid through {{ validThroughDate }}
          </div>
          <div v-else id="ended-message" class="bg-error-lighter text-error rounded-lg m-4 p-2 text-center">
            Your subscription has ended! Update your billing info to continue using Talkatoo!
          </div>
        </div>
      </div>
    </div>

    <UpdateModals
      :loading="loading"
      :productList="productList"
      :subscription="subscription"
      :upgrade="upgrade"
      :countryList="countryList"

      :subscriptionModalDisplay="subscriptionModalDisplay"
      :billingModalDisplay="billingModalDisplay"
      :shippingModalDisplay="shippingModalDisplay"
      :threeDSecureUpgradeModalDisplay="threeDSecureUpgradeModalDisplay"

      @subscriptionUpdate="subscriptionUpdate"
      @toggleSubscriptionModal="toggleSubscriptionModal"
      @toggleBillingModal="toggleBillingModal"
      @toggleShippingModal="toggleShippingModal"
      @toggleThreeDSecureModal="toggleThreeDSecureModal"
      @pendingRequest="pendingRequest"
    />
  </div>

</template>

<script src="./Subscription.js"></script>
<style src="./Subscription.css" scoped></style>

<template>
  <TheNav v-if="!authenticating"
          :authenticating="authenticating"
          :windowWidth="windowWidth">
    <!-- router view -->

    <router-view>
    </router-view>

  </TheNav>

</template>
<script>
import TheNav from "Components/layout/TheNav";

export default {
  name: "AppView",
  components: {
    TheNav,
},
  data () {
    return {
      authenticating: true,
      windowWidth: window.innerWidth,
    };
  },
  async beforeMount () {
    const params = new window.URLSearchParams(window.location.search);

    // Set up landing redirect on app load
    let iter = params.keys();
    let query = {};

    for (let key = iter.next(); !key.done; key = iter.next()) {
      query[key.value] = params.get(key.value);
    }
    // SUT/SSO/refresh Landing

    this.$store.dispatch("landing_redirect", {
      path: window.location.pathname.replace(__BASE_URL, ''), query: query
    })
      .catch((e) => {
        console.log("failure", e);
        if (this.$store.getter.GetState.isSsoUser) {
          this.$router.push("/login/sso");
        } else {
          this.$router.push("/login");
        }
      })
      .finally(() => {
        this.authenticating = false;
      });
  },
  created () {
    // load hubspot happens after all routing and initializing done in main.js happens
    // this.loadHubspot();
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    isAdminPage () {
      return this.adminRoutes.find(x => x == this.$router.currentRoute.value.name);
    },
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth;
    },
    // HubSpot Embed Code https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code
    // loadHubspot () {
      // let env = __ENV;
      // if (env == "development" || env == "test" ) return;
      // const exclude = ["/confirm", "/reset", "/password-reset"];
      // const landingPath = window.location.pathname;
      // if (!exclude.includes(landingPath)) {
        // document.addEventListener("DOMContentLoaded", () => {
          // setTimeout(() => {
          //   let HubSpotScript = document.createElement("script");
          //   HubSpotScript.type = "text/javascript";
          //   HubSpotScript.src = "//js.hs-scripts.com/6240191.js";
          //   HubSpotScript.id = "hs-script-loader";
          //   document.body.appendChild(HubSpotScript);
          // }, 5000);
        // });
      // }
    // },
  },

};
</script>
<style src="./assets/styles.css"></style>
<style scoped>
.login-fade-duration {
  transition-duration: 0.3s;
}
</style>
<template>
  <div :class="['container-blue relative mb-5 overflow-hidden timeline-transition',
                altMenu ? 'alt-menu' : '',
                attnMenu ? 'attn-menu' : '',
                showMenu ? 'full-menu' : 'small-menu',
                noteStateVal === inPms ? 'small-menu' : '',
                isUserVerified || isUserScribe || noteStateVal === verified ? 'verified-user': '']">
    <div class="absolute right-4 top-4">
      <button v-if="!(altMenu || attnMenu)"
              :class="['material-icons mr-auto hover:bg-gray-100 rounded-full',
                       showMenu ? 'arrow-up' : 'arrow-down']"
              @click="toggleMenu">
        keyboard_arrow_down
      </button>
    </div>
    <template v-if="noteStateVal !== needsAttention">
      <div class="flex flex-col w-full p-4 pr-1 2xl:py-5 2xl:pr-8">
        <!-- Draft state -->
        <div id="timeline-draft" key="0" class="timeline-item flex flex-col" :data-show="noteStateVal === draft || isNoteProcessing">
          <div class="flex w-full">
            <!-- timeline state indicator-->
            <div :class="`w-6 h-6 rounded-full flex
                          ${noteStateVal < draft ? 'future-state' :
            noteStateVal === draft || isNoteProcessing ? 'bg-rare-alt' : 'bg-gray-300'}`">
              <i v-if="noteStateVal !== draft && isNoteProcessing" class="material-icons check">
                check
              </i>
              <div v-else-if="noteStateVal === draft || isNoteProcessing" class=" rounded-full p-[0.325rem] m-auto border-white border-[0.3rem] block box-border" />
              <div v-else class="p-1 rounded-full border-white m-auto" />
            </div>
            <!-- timeline heading-->
            <span :class="`timeline-heading
                          ${noteStateVal === draft || isNoteProcessing ? ' font-bold text-rare-alt' : 'text-gray-400 '}`">
              {{ isNoteProcessing ? "Processing" : "Draft" }}
            </span>
          </div>
          <!-- timeline body-->
          <div class="timeline-body flex font-roboto text-gray-400 w-full">
            <!-- timeline line-->
            <div class="timeline-line" />
            <div v-if="noteStateVal === draft || isNoteProcessing" class="timeline-item-transition flex flex-col w-full">
              <div class="timeline-message font-normal pt-2">
                <template v-if="isNoteProcessing">
                  The note has been submitted for <br> review and the audio is being processed.
                </template>
                <template v-else>
                  Submit your note to begin the review <br> process and verify the transcript.
                </template>
              </div>
              <div class="btn-container mb-11">
                <button class="btn-primary btn-timeline active:" :disabled="loading || noteStateVal !== draft"
                        @click="stateChange(pending)">
                  Submit for Review
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Awaiting review -->
        <div id="timeline-pending" key="1" class="timeline-item flex flex-col" :data-show="noteStateVal === pending">
          <div class="flex w-full">
            <!-- timeline state indicator-->
            <div :class="`w-6 h-6 rounded-full flex
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ${noteStateVal < pending ? 'future-state' :
            noteStateVal === pending ? 'bg-secondary-darker' : 'bg-gray-300'}`">
              <i v-if="noteStateVal > pending && !isNoteProcessing" class="material-icons check">
                check
              </i>
              <div v-else-if="noteStateVal === pending" class=" rounded-full p-[0.325rem] m-auto border-white border-[0.3rem] block box-border" />
              <div v-else class="p-1 rounded-full border-white m-auto" />
            </div>
            <!-- timeline heading-->
            <span :class="`timeline-heading
                          ${noteStateVal === pending ? 'text-secondary-darker font-bold' : 'text-gray-400 '}`">
              Awaiting Review
            </span>
          </div>
          <!-- timeline body-->
          <div class="timeline-body flex font-roboto text-gray-400 w-full">
            <div class="timeline-line" />

            <div v-if="noteStateVal === pending" class="timeline-item-transition flex flex-col w-full">
              <div class="timeline-message font-normal pt-2">
                The note is ready for you to review <br>and edit the transcript of the audio.
              </div>
              <div class="btn-container mb-11">
                <!-- debug for styles -->
                <!-- <button  class="btn-secondary btn-timeline" :disabled="(noteStateVal !== pending || loading)"
                  @click="stateChange(draft)">
                  Back to draft
                </button> -->
                <button class="btn-primary btn-timeline" :disabled="loading || noteStateVal !== pending"
                        @click="stateChange(review)">
                  Begin Review
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- in review -->
        <div id="timeline-review" key="2" class="timeline-item flex flex-col" :data-show="noteStateVal === review">
          <div class="flex w-full">
            <!-- timeline state indicator-->
            <div :class="`w-6 h-6 rounded-full flex
                                                                                                                                                                                                                                                                                                                                                                                                                                   ${noteStateVal < review ? 'future-state' :
            noteStateVal === review ? 'bg-main-alt' : 'bg-gray-300'}`">
              <i v-if="noteStateVal > review && !isNoteProcessing" class="material-icons check">
                check
              </i>
              <div v-else-if="noteStateVal === review" class=" rounded-full p-[0.325rem] m-auto border-white border-[0.3rem] block box-border" />
              <div v-else class="p-1 rounded-full border-white m-auto" />
            </div>
            <!-- timeline heading-->
            <span :class="`timeline-heading
            ${noteStateVal === review ? 'text-main-alt font-bold' : 'text-gray-400 '}`">
              In Review
            </span>
          </div>
          <!-- timeline body-->
          <div class="timeline-body flex font-roboto text-gray-400 w-full">
            <!-- timeline line-->
            <div class="timeline-line" />
            <div v-if="noteStateVal === review" class="timeline-item-transition flex flex-col w-full">
              <div class="timeline-message font-normal pt-2">
                <!-- TODO update to show review/verify for Notes/Verified users -->
                Review the note and fix mistakes.<br> Mark the note as Verified when complete.
              </div>
              <div class="btn-container flex-col">
                <button class="btn-primary btn-verify btn-timeline"
                        :disabled="loading || noteStateVal !== review"
                        @click="stateChange(isUserVerified || isUserScribe ? verified : inPms)">
                  {{ isUserVerified || isUserScribe ? ' Verify Note' : 'Complete Note' }}
                </button>
                <a class="previous-state-link" :disabled="loading || noteStateVal !== review"
                   @click="stateChange(pending)">
                  Stop Review
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- Verified -->
        <div v-if="noteStateVal === verified || isUserVerified || isUserScribe"
             id="timeline-verified" key="3"
             class="timeline-item flex flex-col"
             :data-show="noteStateVal === verified">
          <div class="flex w-full">
            <!-- timeline state indicator-->
            <div :class="`w-6 h-6 rounded-full flex
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  ${noteStateVal < verified ? 'future-state' :
            noteStateVal === verified ? 'bg-rare2-dark' : 'bg-gray-300'}`">
              <i v-if="noteStateVal > verified && !isNoteProcessing" class="material-icons check">
                check
              </i>
              <div v-else-if="noteStateVal === verified" class=" rounded-full p-[0.325rem] m-auto border-white border-[0.3rem] block box-border" />
              <div v-else class="p-1 rounded-full border-white m-auto" />
            </div>
            <!-- timeline heading-->
            <span :class="`timeline-heading
            ${noteStateVal === verified ? 'text-rare2-dark font-bold' : 'text-gray-400 '}`">
              {{ isUserVerified || isUserScribe ? 'Verified' : 'Reviewed' }}

            </span>
          </div>
          <!-- timeline body-->
          <div class="timeline-body flex font-roboto text-gray-400 w-full">
            <!-- timeline line-->
            <div class="timeline-line" />
            <div v-if="noteStateVal === verified" class="timeline-item-transition flex flex-col w-full">
              <div class="timeline-message font-normal pt-2">
                The note is ready to be added to the <br> practice management software.
              </div>
              <div class="btn-container flex-col">
                <button class="btn-primary btn-verify btn-timeline" :disabled="loading || noteStateVal !== verified"
                        @click="stateChange(inPms)">
                  Added to PMS
                </button>
                <a class="previous-state-link" :disabled="loading || noteStateVal !== verified"
                   @click="stateChange(review)">
                  Modify Review
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- inPms -->
        <div id="timeline-pms" key="4" class="timeline-item flex flex-col" :data-show="noteStateVal === inPms">
          <div class="flex w-full">
            <!-- timeline state indicator-->
            <div :class="`w-6 h-6 rounded-full flex
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ${noteStateVal < inPms ? 'future-state' :
            noteStateVal === inPms ? ' bg-rare2-dark' : 'bg-gray-300'}`">
              <i v-if="noteStateVal === inPms" class="material-icons check">
                check
              </i>
              <div v-else class="p-1 rounded-full border-white m-auto" />
            </div>
            <!-- timeline heading-->
            <span :class="`timeline-heading
            ${noteStateVal === inPms ? 'text-rare2-dark font-bold' : 'text-gray-400 '}`">
              <!-- TODO talkatoo verified customer vs regular customer  -->
              {{ isUserVerified || isUserScribe ? 'Verified and in PMS' : 'Completed and in PMS' }}
            </span>
          </div>
          <!-- timeline body-->
          <div class="timeline-body flex font-roboto text-gray-400 w-full">
            <!-- timeline line-->
            <div class="ml-3 mr-6" />
            <div v-if="noteStateVal === inPms" class="timeline-item-transition flex flex-col w-full">
              <div class="timeline-message font-normal pt-2">
                You’re done with this one! <br> Great work.
                If you see an error, <br><a class="inline-anchor underline" @click="stateChange(review)">you can edit this note</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </template>
    <!-- Needs attention -->

    <div v-else id="timeline-issue" key="5" class="timeline-item flex flex-col flex-grow h-full" :data-show="noteStateVal === needsAttention">
      <div class="flex w-full bg-error">
        <!-- timeline heading-->
        <div class="flex flex-grow px-5 py-3 text-base">
          <span class="text-white font-bold my-auto whitespace-nowrap ">Note Needs Attention</span>
          <div class="text-white font-roboto-slab 2xl:ml-2 text-sm flex items-center whitespace-nowrap overflow-hidden">
            <button :disabled="issueIndex == 0" @click="issueIndex--">
              <span class="material-icons">keyboard_arrow_left</span>
            </button>
            <div> {{ issueIndex + 1 }} / {{ note.issues.length }}</div>
            <button :disabled="issueIndex + 1 == note.issues.length" @click="issueIndex++">
              <span class="material-icons">keyboard_arrow_right</span>
            </button>
          </div>
          <BaseKebab kebabError class="my-auto ml-auto">
            <div class="flex justify-center text-error">
              <button class="flex py-2 px-4 text-base hover:bg-error-lighter transition-colors active:bg-red-200"
                      @click="deleteIssueEvent">
                Delete
              </button>
            </div>
          </BaseKebab>
        </div>
      </div>
      <!-- timeline body-->
      <div class="flex font-roboto text-gray-400 h-full w-full">
        <!-- timeline line-->
        <div class="timeline-item-transition flex flex-col w-full ">
          <!-- messaging container -->
          <div id="scrollable-chat"
               class="flex flex-col max-h-[21rem] px-6 pb-6 overflow-auto scroll-y-auto ">
            <div class="flex w-full flex-grow py-2 justify between">
              <div class="py-2 px-1 text-sm font-roboto text-neutral-darker mr-auto font-bold">
                opened at {{ issueCreatedAtString.time }} on <span class="inline-block"> {{ issueCreatedAtString.date }}</span>
              </div>
              <div v-if="selectedIssue.resolved"
                   class="py-2 w-20 my-auto text-center rounded-md bg-pastel-green-lighter  text-rare2-darker">
                Resolved
              </div>
              <div v-else
                   class="py-2 w-20 my-auto text-center rounded-md bg-error bg-opacity-10 text-error">
                Active
              </div>
            </div>
            <div class="p-4 mb-3 bg-error-lighter rounded-md ml-6">
              <p class="font-roboto text-sm whitespace-pre-line break-words">
                <span class="font-bold mb-4 text-base text-gray-700">
                  {{ selectedIssue?.type ?? '' }}
                  <br>
                </span>
                <span class="text-neutral-alt font-normal">
                  {{ selectedIssue?.description ?? '' }}
                </span>
              </p>
            </div>
            <template v-for="(chat, index) in noteIssueResponses" :key="index">
              <!-- scribe question -->
              <div v-if="chat.responded_by != note.user_id"
                   class="p-4 mb-3 bg-error-lighter rounded-md ml-6 sm:ml-12 md:ml-20 xl:ml-6">
                <p class="font-roboto text-sm whitespace-pre-line break-words">
                  <span class="text-neutral-alt font-normal">
                    {{ chat.message }}
                  </span>
                </p>
              </div>
              <!-- author response -->
              <template v-else>
                <div class="py-4 px-4 mr-6 sm:mr-12 md:mr-20 xl:mr-6 mb-3 rounded-lg text-neutral-alt font-roboto font-normal bg-rare-lighter break-words">
                  {{ chat.message }}
                </div>
                <div v-if="latestAuthorResponseOn.id == chat.id" class="flex flex-grow pb-2">
                  <BaseIcon :text="authorInitials" :themeColorMain="{ name: 'rare', suffix: 'alt' }" :themeColorBg="{ name: 'rare', suffix: 'lighter' }" diameter="3" />
                  <div class="flex-col flex-grow ml-4 text-sm my-auto">
                    <span class="font-bold text-gray-700"> Response from  {{ note.metadata.recorded_by_name }}</span><br>
                    <span class="text-gray-400 font-normal"> on {{ latestAuthorResponseOn.date }} at {{ latestAuthorResponseOn.time }} </span>
                  </div>
                </div>
              </template>
            </template>
            <div v-if="selectedIssue.resolved"
                 class="text-center  font-bold text-base">
              Issue was resolved!
            </div>
          </div>
          <!-- message Box -->
          <div class="flex flex-col flex-grow ">
            <div class="flex mt-auto">
              <div class="flex flex-col w-full p-4 bg-gray-100 ">
                <!-- input -->
                <input id="issue-field"
                       v-model="issueMessage" :disabled="selectedIssue.resolved"
                       class="w-full p-2 mb-2 border-gray-700 border rounded"
                       placeholder="Need more clarification? Reply"
                       @keydown.enter="respondIssueEvent">
                <button id="issue-respond" :disabled="selectedIssue.resolved"
                        class="flex flex-grow justify-center py-3 mb-2 text-sm font-montserrat font-bold bg-main-alt hover:bg-main-dark active:bg-main-darker transition-colors  text-white rounded-md "
                        @click="respondIssueEvent">
                  Send Message
                </button>
                <button id="issue-resolve" :disabled="selectedIssue.resolved"
                        class="flex flex-grow justify-center py-3 text-sm font-montserrat font-bold bg-rare2-dark hover:bg-rare2-darker active:bg-rare2-dark transition-colors  text-white rounded-md "
                        @click="resolveIssueEvent">
                  Resolve Issue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseKebab from '@/components/ui/BaseKebab.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
export default {
  name: "NoteTimeline",
  emits: ["NoteTimeline:respondIssue", "NoteTimeline:changeState", "NoteTimeline:resolveIssue", "NoteTimeline:deleteIssue"],
  components: { BaseKebab, BaseIcon },
  props: {
    note: Object,
  },
  data () {
    const menuSave = localStorage.getItem('NoteViewMenu');
    return {
     // values match the noteStateValMap, We just don't need the text values
      draft: 0,
      pending: 1,
      review: 2,
      verified: 3,
      inPms: 4,
      processing: 5,
      needsAttention: 6,
      showMenu: menuSave !== null ? menuSave === 'true' : true,
      issueMessage: "",
      loading: false,
      // used to index the issue, form the issue list. this will let a user page through the issues if there are many
      issueIndex: 0,
    };
  },
  watch: {
    // watch note, On state change we animate the timeline
    note: {
      handler (newVal, oldVal) {
        this.loading = false;
        if (oldVal && newVal.issues.length > oldVal.issues.length) this.issueIndex = 0;
        else if (newVal.issues.length <= this.issueIndex) this.issueIndex = 0;
        if (newVal.needs_attention) {
          this.$nextTick(() => {
            document.getElementById('scrollable-chat').scrollBy({ top: 99999, left: 0, behavior: "smooth" });
          });
        }
      },
      deep: true
    }
  },
  computed: {
    isUserScribe () {
      if (!this.$store.getters.getUserGroups) return false;
      return this.$store.getters.getUserGroups.findIndex(x => x === "Scribe") !== -1;
    },
    isUserVerified () {
      return this.$store.getters.getUserPerms.verified;
    },
    altMenu () {
      return this.noteStateVal === this.inPms;
    },
    attnMenu () {
      return this.noteStateVal === this.needsAttention;
    },
    // guarded computed value for note state
    noteStateVal () {
      if (!this.note) return -1;
      if (this.note.needs_attention || this.note.issues.length > 0) return this.needsAttention;
      return parseInt(this.note.state);
    },
    // special state we need to check when a note is in a transition from draft to awaiting review
    isNoteProcessing () {
      return this.noteStateVal === this.processing;
    },
    selectedIssue () {
      if (this.note.issues.length === 0) return undefined;
        if (this.note.issues.length <= this.issueIndex) return this.note.issues[this.note.issues.length];
      return this.note.issues[this.issueIndex];
    },
    noteIssueResponses () {
      if (!this.selectedIssue) return [];
      let list = [...this.selectedIssue.responses];
      list.forEach(x => x.responded_at_unix = parseInt(x.responded_at_unix));
      return list.sort((a, b) => (a.responded_at_unix - b.responded_at_unix));
    },
    latestAuthorResponseOn () {
      if (!this.note) return '';
      // find author response
      let latestAuthorResponse = undefined;
      // sorted by response time, so grabbing the very last chat from the author
      for (var i = this.noteIssueResponses.length - 1; i >= 0; i--) {
        if (this.noteIssueResponses[i].responded_by == this.note.user_id) {
          latestAuthorResponse = this.noteIssueResponses[i];
          break;
        }
      }
      const respAt = new Date(parseInt(latestAuthorResponse.responded_at_unix) * 1000);
      return {
        id: latestAuthorResponse.id,
        date: Intl.DateTimeFormat(undefined, { year: "numeric", month: "short", day: "numeric" }).format(respAt),
        time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", second: "numeric" }).format(respAt),
      };
    },
    issueCreatedAtString () {
    if (!this.note) return '';
      if (!this.selectedIssue) return '';
      const createdAt = new Date(this.selectedIssue.created_at_unix * 1000);
      return {
        date: Intl.DateTimeFormat(undefined, { year: "numeric", month: "short", day: "numeric" }).format(createdAt),
        time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", second: "numeric" }).format(createdAt),
      };
    },
    scribeStates () {
      if (!this.note) return '';
      return this.note.problem;
    },
    authorInitials () {
      //testing first and lastName for undefined
      let firstName = "";
      let initials = "";
      let noteFirstName = this.note.metadata.recorded_by_name.split(' ')[0];
      let noteLastName = this.note.metadata.recorded_by_name.split(' ')[1];
      let noteEmail = this.note.metadata.email;

      if (noteFirstName) {
        firstName = noteFirstName.trim();
        //setting initials with name sections
        if (firstName.length > 0) {
          initials += firstName[0].toUpperCase();
        }
      }
      let lastName = "";
      if (noteLastName) {
        lastName = noteLastName.trim();
        //setting initials with name sections
        if (lastName.length > 0) {
          initials += lastName[0].toUpperCase();
        }
      }
      let email = "";
      if (initials === "") {
        if (noteEmail) {
          email = noteEmail.trim();
          if (email.length > 0) {
            initials += email[0].toUpperCase();
          }
        }
      }
      return initials;
    }
  },
  methods: {
    toggleMenu () {
      this.showMenu = !this.showMenu;
      localStorage.setItem('NoteViewMenu', this.showMenu);
    },
    // passed the integer value representing a state.
    stateChange (changeTo) {
      this.loading = true;
      this.$emit("NoteTimeline:changeState", changeTo);
    },
    respondIssueEvent () {
      if (this.issueMessage.trim() == "") {
        return;
      }
      this.$emit("NoteTimeline:respondIssue", this.issueMessage.trim(), this.selectedIssue.id);
      this.issueMessage = "";
    },
    resolveIssueEvent () {
      this.$emit("NoteTimeline:resolveIssue", this.selectedIssue.id);
      this.issueIndex -= 1;
      if (this.issueIndex < 0) {
        this.issueIndex = 0;
      }
    },
    deleteIssueEvent () {
      this.$emit("NoteTimeline:deleteIssue", this.selectedIssue.id);
      this.issueIndex -= 1;
      if (this.issueIndex < 0) {
        this.issueIndex = 0;
      }
    }
  },
};
</script>

<style>
/* state timeline styling */
.future-state {
  @apply bg-white;
  border: solid 0.2rem #d8d8d8;
}

.alt-menu.small-menu {
  height: 8rem;
}

.alt-menu.full-menu {
  height: 8rem;
}

.full-menu.verified-user {
  height: 22.8125rem;
}
.alt-menu.full-menu.verified-user {
  height: 8rem;
}
.attn-menu.small-menu {
  height: 36rem;
}

.attn-menu.full-menu {
  height: 36rem;
}

.full-menu {
  display: flex;
  flex-direction: column;
  height: 20rem;
}

.small-menu {
  display: flex;
  flex-direction: column;
  height: 12.5rem;
}

@media (min-width: 1400px) {
  .small-menu {
    height: 13rem;
  }
}

.timeline-transition {
  @apply transition-all;
  @apply duration-500;
  @apply ease-in-out;
}

.timeline-item {
  @apply bg-white;
  @apply overflow-hidden;
  flex: 0;

  transition-property: flex;
  @apply duration-500;
  transition-timing-function: cubic-bezier(0.4, 0.2, 0.7, 1);
}

.timeline-heading {
  @apply my-auto;
  @apply pl-2;
  font-size: 0.875rem
    /* 14px */
  ;
  line-height: 1.5rem
    /* 24px */
  ;
  @apply transition-all;
}

@media (min-width: 1400px) {
  .timeline-heading {
    @apply pl-3;
  }
}

.timeline-body {
  min-height: 10.125rem;
}

.timeline-item[data-show="false"] .timeline-body {
  min-height: 0rem;
}

.timeline-message {
  font-size: 13px;
}


.small-menu,
.full-menu,
.alt-menu {
  @apply hidden;
}

.attn-menu {
  @apply flex;
}

@media (min-width: 1200px) {

  .small-menu,
  .full-menu,
  .alt-menu {
    @apply flex;
  }
}

/* node and line min height */
.small-menu .timeline-item {
  min-height: 0rem;
}

.full-menu .timeline-item {
  min-height: 2.35rem;
}

.alt-menu.full-menu .timeline-item {
  min-height: 0rem;
}

.timeline-item[data-show="true"] {
  flex: 1;
}

.full-menu .timeline-item-transition {
  @apply timeline-transition;
  transition-timing-function: cubic-bezier(0.4, 0.2, 0.7, 1);
}

.btn-container {
  @apply flex;
  @apply mt-4;
  @apply mr-8;
}

@media (min-width: 1400px) {
  .btn-timeline {
    @apply mr-0;
  }

  .btn-container {
    @apply mr-4;
  }
}

.btn-container .previous-state-link {
  @apply text-main-alt;
  @apply whitespace-nowrap;
  @apply font-bold;
  @apply text-sm;
  @apply mx-auto;
  @apply my-2;
  @apply underline;
  @apply transition-colors;
  @apply duration-200;
}

.btn-container .previous-state-link:hover {
  @apply text-main-darker;
}

.btn-timeline {
  font-weight: 700;
  font-size: 0.8125rem;
  line-height: 1rem;
  padding: 0.8125rem 1.5rem;
  width: 100%;
}


.btn-primary.btn-timeline:not(:hover) {
  @apply bg-main-alt;
}

.btn-secondary.btn-timeline:not(:hover) {
  @apply border-main-alt;
  @apply text-main-alt;
}

.btn-secondary.btn-prev-state:hover {
  @apply border-gray-400;
  @apply text-gray-400;
}

.btn-secondary.btn-prev-state:not(:hover) {
  @apply border-gray-500;
  @apply text-gray-500;
}

.btn-primary.btn-verify:hover {
  @apply bg-rare2;
}

.btn-primary.btn-verify:not(:hover) {
  @apply bg-rare2-dark;
}

.btn-primary.btn-verify:active {
  @apply bg-rare2-dark;
}

.timeline-line {
  @apply ml-3;
  @apply mr-5;
  border-left-width: 0.125rem;
  @apply border-gray-300;
  @apply py-1;
  @apply my-1;
  @apply w-0;
  @apply box-content;
}

@media (min-width: 1400px) {
  .timeline-line {
    @apply mr-6;
  }
}

.arrow-up {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: 0.2s ease-out;
}

.arrow-down {
  transition: 0.2s ease-out;
}

.check {
  @apply text-white;
  @apply flex;
  @apply m-auto;
  @apply text-base;
}</style>
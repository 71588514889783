<template>
  <!-- <div class="vertical-gradient"> -->
  <!-- standard Template -->
  <div class="standard-template-wrapper rounded-md lg:rounded-none px-2 sm:px-4 xl:pl-12 bg-white mb-16">

    <!-- standard Template slot -->
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "StandardTemplate",
};
</script>
<style scoped></style>
import BaseIcon from "Components/ui/BaseIcon";
import BaseInput from "Components/ui/BaseInput";
import BaseSelectList from "Components/ui/BaseSelectList";
import BaseMultiSelect from "Components/ui/BaseMultiSelect";
import BaseDropdown from "Components/ui/BaseDropdown";
import BaseIconDropdown from "Components/ui/BaseIconDropdown";
import BaseKebab from "Components/ui/BaseKebab";
import BaseLoading from "Components/ui/BaseLoading";
import BaseDatepicker from "Components/ui/BaseDatepicker";
import ActionMenu from "Components/ui/ActionMenu.vue";
import { genFiltersName } from "Modules/CustomFiltersHelpers";

export default {
  name: "BaseTable",
  components: { BaseIcon, BaseSelectList, BaseMultiSelect, BaseDatepicker, BaseDropdown, BaseIconDropdown, BaseKebab, BaseLoading, BaseInput, ActionMenu },
  emits: ['toggle-row-select', 'toggle-all-select', 'row-click', 'row-action-click',
    'header-button-click', 'header-icon-click', 'header-form-submit',
    'text-search',
    'clear-header-filters', 'update-custom-filters', 'save-custom-filters', 'remove-preset-filters', 'select-preset-filters',
    'page-size-select', 'page-change',
    'update-column-sort'],
  data () {
    return {
      tableData: [],
      allSelected: false,
      rowSelection: [],
      filterText: "",
      filterColumns: [],
      filteredData: [],
      sortingColumns: [],
      sortedData: [],
      pageData: [],
      windowWidth: window.innerWidth,
      customFilterCount: 0,
      // shortstop our watcher on data when we're already in the filter loop
      filtering: false,
      isMounted: false,
      // state variables for naming a filter
      nameFilters: false,
      filterNameValue: "",
      // preset filter menu state variables
      selectedPresetFilter: undefined,
      showPresetFilterDropdown: false,
      eatClickout: false,
    };
  },
  watch: {
    data: {
      handler (newVal, oldVal) {
          this.tableData = this.data.map(x => ({ ...x }));
        this.allSelected = false;
        //Filter order:
        // searchFilter() uses the text input to filter all filterable data into the filteredData array
        // customFilter() will filter based on the headerFilters options selected
        // sortData() sorts the filtered data by the column with the sorted flag
        // selectPageGroup() generates pageData which is the final set to show in the table
        this.filterColumns = this.colDef?.col?.filter((col) => col.filter) ?? [];
        this.sortingColumns = this.colDef?.col?.filter((col) => col.sortable) ?? [];
        if (!this.filtering && newVal) {
          this.searchFilter();
        }
      },
      deep: true
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.isMounted = true;
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    mobileView () {
      return this.windowWidth < 1200;
    },
    selectedCount () {
      if (!this.selectTable) {
        return 0;
      }
      return this.pageData.reduce((prev, x) => { return prev + (x._selected ?? false); }, 0);
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth;
    },
    // *function handler for selecting a row's checkbox.
    rowSelect (event, row, selectVal) {

      row._selected = selectVal;
      if (selectVal) {
        this.allSelected = true;
      }else {
        this.allSelected = this.pageData.some(x => x._selected);
      }
      this.$emit('toggle-row-select', row, selectVal);
      event.stopPropagation();
    },
    selectAll (allSelected) {

      this.allSelected = !allSelected;
      this.pageData.forEach(x => x._selected = this.allSelected);
      this.$emit('toggle-all-select', this.allSelected, this.pageData.filter(x => x._selected === this.allSelected).map(x => x.id));
    },
    rowClick (row) {
      this.$emit('row-click', row);
    },
    headerButtonClick () {
      this.$emit('header-button-click');
    },
    actionLinkClick (row, col) {
      this.$emit('row-action-click', row, col);
    },
    headerIconClick () {
      this.$emit("header-icon-click");
    },
    formSubmit () {
      this.$emit("header-form-submit", this.headerForm.input);
    },
    formChange () {
      this.headerForm.input.error = "";
    },
    // * Custom filter state management
    resetFiltersDropdown () {
      document.body.click();
      this.nameFilters = false;
      this.filterNameValue = "";
    },
    toggleSaveFilters (event) {
      this.filterNameValue = genFiltersName(this.headerFilters);
      this.nameFilters = true;
      this.$nextTick(() => { document.getElementById("name-filter-input").select(); });
      event.stopPropagation();
    },
    updateCustomFilters (filter) {
      this.selectedPresetFilter = undefined;
      this.$emit("update-custom-filters", filter);
    },
    saveCustomFilters () {
      let tagName = '';
      if (this.filterNameValue !== genFiltersName(this.headerFilters)) tagName = this.filterNameValue;
      else tagName = genFiltersName(this.headerFilters, true);
      this.nameFilters = false;
      this.$emit("save-custom-filters", { fullName: this.filterNameValue, tagName: tagName });
      this.$nextTick(() => {
        this.selectedPresetFilter = this.presetFilters[this.presetFilters.length - 1].id;
      });
    },
    clearHeaderFilters () {
      this.nameFilters = false;
      this.selectedPresetFilter = undefined;
      this.$emit("clear-header-filters");
    },
    selectPresetFilters (event, filter) {
      this.selectedPresetFilter = filter.id;
      this.$emit("select-preset-filters", filter.id);
      event.stopPropagation();
    },
    removePresetFilters (event, filter) {
      this.$emit("remove-preset-filters", filter.id);
      event.stopPropagation();
    },
    togglePresetFiltersDropdown (e, val) {
      if (this.eatClickout) return this.eatClickout = false;
      this.showPresetFilterDropdown = !this.showPresetFilterDropdown;
      // this is contrived but click events trigger before click-out events, so eating the click-out on menu open is required. otherwise the menu will open and then close immediately
      if (this.showPresetFilterDropdown && val === undefined) this.eatClickout = true;
    },
    // * Data filtering pipeline functions
    handleSearchFilter () {
      this.$emit('text-search', this.filterText);
      this.searchFilter();
    },
    searchFilter () {
      this.filtering = true;
      //on filter we will want to reset selected page view
      // copying values from data.keeps from mutating parent prop
      this.filteredData = this.tableData.map(x => { return { ...x }; });

      if (this.pagination?.truePagination || this.filterText == "") {
        this.customFilter();
        this.filtering = false;
        return;
      }
      let escapedText = this.filterText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&").toLowerCase();
      let regExp = new RegExp(escapedText);

      this.filteredData = this.filteredData.filter((row) => {
        for (var i = 0; i < this.filterColumns.length; i++) {
          var found = regExp.test(JSON.stringify(row[this.filterColumns[i].field]).toLowerCase());
          if (found) {
            return true;
          }
        }
        return false;
      }, this);
      this.customFilter();
      this.filtering = false;
    },
    // filter based on the custom filters, operates on keywords a 'user' may select on the table's contents
    // items in each input will be or'd and filter sets from each input will be and'd
    // ie. (active || trial) && (startDateFilter =< date =< endDateFilter)
    customFilter () {
      this.filtering = true;
      if (!this.showHeaderFilters) {
        this.sortData();
        this.filtering = false;
        return;
      }
      this.customFilterCount = 0;
      let selectedFilters = [];
      // take the keys from the inputs in the filter to build a list of items to filter on
      this.headerFilters.input.forEach(input => {
        // we will filter down on filter groups, groups can have specific methods to filter on,
        // eg input text filters on key strings matching, date pickers can filter on ranges
        let filterGroup = [];
        switch (input.inputType) {
          case ("datepicker"):
            // we expect the datepicker value to be [startDate, endDate]
            if (input.value && input.value.length > 0) {
              this.customFilterCount++;
              const start = new Date(input.value[0]).setHours(0, 0, 0, 0);
              const end = new Date(input.value[1].getTime()).setHours(23, 59, 59, 999);
              filterGroup.push({ type: "range", field: input.filterField, start: start, end: end });
            }
            break;
          case ("multi-select"):
            if (input._value) {
              Object.keys(input._value).forEach(key => {
                if (input.value[key]) {
                  this.customFilterCount++;
                  filterGroup.push({ type: "key", field: input.filterField, text: input._value[key], });
                }
              });
            } else {
              Object.keys(input.value).forEach(key => {
                if (input.value[key]) {
                  this.customFilterCount++;
                  filterGroup.push({ type: "key", field: input.filterField, text: key, });
                }
              });
            }
            break;
          // TODO (Adam) select, input
          // case ("select"):
          // case ("input"):
        }
        if (filterGroup.length > 0) {
          selectedFilters.push(filterGroup);
        }
      });
      // shortstop here if theres no filter set
      if (selectedFilters.length == 0) {
        return this.sortData();
      }

      // filter data on selected header filters
      selectedFilters.forEach(input => {
        this.filteredData = this.filteredData.filter((row) => {
          return input.some(filter => {
            switch (filter.type) {
              case ("key"):
                return filter.text == row[filter.field];
              case ("range"):
                return filter.start <= row[filter.field] && row[filter.field] <= filter.end;
            }
          });
        }, this);
      });
      this.sortData();
      this.filtering = false;
    },
    sortData () {
      this.sortedData = [...this.filteredData];
      if (this.sortingColumns.length == 0) {
        return this.selectPageGroup();
      }

      let sortingCol = this.sortingColumns.find(x => x.sortBy > 0);
      if (!sortingCol) {
        return this.selectPageGroup();
      }
      sortingCol.sortFunctions.forEach((fn) => {
        this.sortedData.sort((a, b) => fn(a, b, sortingCol.sortBy == 1));
      });
      this.selectPageGroup();
    },
    // has incoming column, sets sorted field when user interacts
    sortBy (col) {

      let currSort = this.sortingColumns.find(x => x.sortBy > 0);
      if (currSort && currSort != col) {
        currSort.sortBy = 0;
        // currSort.sorted = false;
      }
      // wrapping add on sort by. 0 == nosort, 1 == asc, 2 == desc
      col.sortBy = col.sortBy ?? 0;
      col.sortBy = (col.sortBy + 1) % 3;
      this.$emit('update-column-sort', col);
      if (col.sortBy !== 0) {
        this.sortData();
      } else {
        if (!this.pagination?.truePagination) {
          this.searchFilter();
        }
      }
    },


    selectPageGroup () {
      if (this.pagination === undefined) {
        this.pageData = this.sortedData;
        return;
      }
      if (this.pagination.page > this.pagination.totalPages) {
        this.pagination.page = 1;
      }
      //setting the number of pages in current pagination schema
      if (!this.pagination.truePagination) {
        this.pagination.totalPages = Math.ceil(this.sortedData.length / this.pagination.pageSize);
      }

      this.pagination.start = ((this.pagination.page - 1) * this.pagination.pageSize);
      this.pagination.end = (this.pagination.page * this.pagination.pageSize);

      if (this.pagination.truePagination) {
        if (this.pagination.end > this.sortedData.length) {
          this.pagination.end = this.sortedData.length;
        }
      }


      // set page data based on start index of page and end index of page
      if (this.pagination.truePagination) {
        this.pageData = this.sortedData.map(x => ({ ...x }));
      } else {
        this.pageData =
          this.sortedData.slice(this.pagination.start,
            this.pagination.end);
      }

      this.pagination.pageTmp = this.pagination.page;
    },
    // pagination selection actions controls the page display
    pageSizeSelect () {
      this.pagination.page = 1;
      this.$emit("page-size-select", { page: this.pagination.page, pageSize: this.pagination.pageSize });
      if (!this.pagination.truePagination) {
        this.searchFilter();
      }
    },
    paginationPrev () {
      if (this.pagination.page > 1) {
        this.pagination.page--;
        this.$emit("page-change", this.pagination.page);
        this.selectPageGroup();
      }
    },
    paginationNext () {
      if (this.pagination.page < this.pagination.totalPages) {
        this.pagination.page++;
        this.$emit("page-change", this.pagination.page);
        this.selectPageGroup();
      }
    },
    selectPage (index) {
      if (index < 1) index = 1;
      if (index > this.pagination.pageCount) index = this.pagination.pageCount;
      this.pagination.page = index;
      this.$emit("page-change", this.pagination.page);
      this.selectPageGroup();
    },
    resetPagination () {
      if (this.pagination !== undefined) {
        if (this.pagination.page == 1) return;
        this.pagination.page = 1;
        this.$emit("page-change", this.pagination.page);
      }
    }
  },
  props: {
    // title of the table
    title: String,
    showLoading: Boolean,
    // header icon properties
    // toggle for the selectable table elements.
    // used to perform group action on sets of data
    selectTable: Boolean,
    // creates a clicable BaseIcon element in the table header
    showHeaderIcon: Boolean,
    headerIconText: String,
    // search field properties
    showSearch: Boolean,
    searchPlaceholder: String,
    // definition for simple button in the table header. emits an event when clicked for the parent to act on `header-button-click`
    headerButton: Object,
    // TODO definition for action list. Actions apply to any selected elements
    actionList: Object,
    showHeaderFilters: Boolean,
    // determines whether to render ui for sending save events for storing the current filter
    showSaveFilters: Boolean,
    presetFilters: Array,
    // definition for the predefined filter in the table
    headerFilters: {
      type: Object,
      // input is the required props to fill out the specified component in the inputType field
      input: [{
        //the type of component to display
        inputType: String,
        Placeholder: String,
        label: String,
        errorMessage: String,
        //string key to match field on tableData, like colDef.col[i].field
        filterField: String,
        // some inital value for the filter input
        Value: [Object, String, Number]
      }],
      // validates the input types are ones that can be used
      validator (value) {
        return value.input?.reduce((x, y) => { return x && ["datepicker", "input", "select", "multi-select"].includes(y.inputType); }, true);
      },
    },
    // this is an array of objects that define rows in the table.
    data: {
      type: Array,
      required: true,
    },
    // defines what each column is and how it should be displayed
    colDef: {
      type: Object,
      required: true,

      //this is required though vue V-For to reference data to element. each row should have some unique property that can be referenced as the keyField
      keyField: String,
      col: [{
        //string reference to the property of the row to be displayed in the column
        field: String,
        // display name for the column
        displayName: String,
        // if the column will display an action link
        isActionLink: Boolean,
        // string for what the action link text will display to the users
        // row-action-click is the emitted action from the table.you will have to bind a function to the event when using action links: @action-click="funcAction"
        actionLinkText: String,
        /* For menus the data field will be expected to be an array of objects formatted
              [{ menuText : text for menu element, menuClass : string of css class(es) to apply to the menu element }]
        */
        //true / false to display a kebab menu, the content in the data field will render as the menu elements
        isMenu: Boolean,
        //this is conditionally required as ony menu columns will need this
        menuOptions: {
          // The name of the event that is emitted from clicking on an element in the menu
          // The Event emits an object with the data of row from the table and data.{ { field } }.MenuId as the menu event key
          clickEvent: String
        },
        // true / false to display a filter to allow filtering on the column though the search field in the table or filter dropdown
        filter: Boolean,
        // marks whether the column is sortable
        sortable: Boolean,
        // defines the field name of the data to sort by
        sortField: String,
        // this is a method to apply general styling to a column header
        colClass: String,
        // This is a method to apply specific styling to a column header,
        // expected in the format of a vue-bound styling object
        // https://vuejs.org/guide/essentials/class-and-style.html#binding-inline-styles
        colStyle: Object,

        // function a function for creating a custom render for a table cell, passed the value of the column
        customDisplay: Function

      },]
    },
    animateChanges: Boolean,
    // pagination is a property tha defines and display the pagination elements at the foot of the table
    // Strictly speaking it must just be a defined object {} but can initialized with values:
    // the starting page (1 indexed, not zero) and the number of elements to start on a page, pageSize should be in the set [5,10,15,25,50]
    // pagination: { page: 1, pageSize: 5}
    pagination: {
      type: Object,

      page: Number,
      pageSize: Number,
      //set this if the parent component queries and provides data as the user pages.
      truePagination: Boolean,
      validator (value) {
        return [15, 25, 50, 100].includes(value.pageSize);
      },
    },
    // CTA placeholder image
    imgLink: String,
    // CTA messaging and links
    callToAction: {
      type: Object,
      text: String,
      action: {
        type: Function,
      }
    },

    // flag for clickable rows. will emit row-click on clicks when flagged
    rowAction: Boolean,
    // makes a row a link with a navigation. gives regular nav functionality
    // expected object is {to: 'router/link/1'}
    rowLink: Object,
    // headerForm should be an object describing elements of a form that may submit new data though a single text field
    headerForm: {
      label: String,
      buttonText: String,
      pattern: String,
      placeholderText: String,
      input: {
        value: [String, Number],
        error: String,
      },
      event: String
    },
  },
};
<template>

  <button class="whitespace-nowrap text-sm p-3 text-neutral-alt transition-colors hover:bg-gray-200 hover:bg-opacity-50" :disabled="archiveDisabled" @click="archive">
    {{ props.activeView ? "Archive" : "Un-Archive" }} Selection
  </button>
</template>

<script>
export default {
  name: "NoteEntryPoint",
  emits: ["archive-click"],
  components: {  },
  props: {
    props: Object
  },
  data () {
    return {};
  },
  computed: {
    archiveDisabled () {
      return this.props.selectedData.length == 0;
    },
    allDisabled () {
      return this.archiveDisabled; /* && otherDisabled */
    }
  },
  methods: {
    archive () {
      this.$emit("archive-click");
    },

  },
};
</script>
<style scoped></style>
<template>
  <!-- 🐶 woof 🐶 -->
  <div class="base-dog ">
    <div :class="[hideBackground || imageNotFound ? '' : 'background', 'm-auto']">
      <img id="base-dog" v-if="pathToDog"
        class="m-auto h-full w-full"
        :src="pathToDog"
      />
    </div>
<!--
chihuahua
border_collie
golden_retriever
st_bernard
great_dane
 -->
  </div>
</template>

<script>
export default {
  name: "BaseDog",
  props: {
    dog: String,
    hideBackground: Boolean,
  },
  data () {
    let path = '';
    let loadingPath = true;
    let imageNotFound = false;
    try {
      path = require(`Assets/images/dogs/${this.dog}.svg`);
    } catch {
      path = require(`Assets/images/talkatoo_logo_icon.svg`);
      imageNotFound = true;
    }
    return { pathToDog: path, loadingPath: loadingPath, imageNotFound: imageNotFound };
  },
  mounted () {
    this.loadingPath = false;
  },
  watch: {
    dog (newVal) {
      this.loadingPath = true;
      if (newVal) {
        try {
          this.pathToDog = require(`Assets/images/dogs/${newVal}.svg`);
        } catch {
          this.pathToDog = require(`Assets/images/talkatoo_logo_icon.svg`);
        }
      }
      this.loadingPath = false;

    }
  }
};
</script>

<style scoped>
.base-dog {
  @apply h-full;
  @apply flex;
  @apply flex-col;
}

.base-dog > .background {
  background-image: url("../../assets/images/dogs/dog-background.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>


<template>

  <LoginTemplate>
    <div v-if="!loading">
      <!-- validation message that can't apply to a specific field -->
      <p v-show="errorMessage === '' && !resetSuccess" class="text-center text-2xl font-bold pb-4">
        Set your new Password
      </p>

      <p v-show="errorMessage !== ''" class="text-center text-error text-3xl font-bold py-4">
        Reset link has expired
      </p>

      <p class="text-center my-4 mx-12 text-lg">
        {{ errorMessage }}
      </p>

      <p v-show="passwordError !== ''" class="error-message text-center">
        {{ passwordError }}
      </p>
      <!-- password form -->
      <div v-if="errorMessage == '' && !resetSuccess" class="flex-col mx-2 my-4">
        <div class="tooltip-basic relative">
          <div class="tooltip-text whitespace-nowrap">
            <span class="pb-1">requirements: </span>
            <ul class="pr-2">
              <li>6 characters long. </li>
              <!-- <li>1 capital letter.</li> -->
              <!-- <li>1 number.</li> -->
            </ul>
          </div>
          <BaseInput v-model="pwd1.value" :tabindex="1"
                     class="py-3"
                     label="Password"
                     name="pwd1"
                     :type="showPasswordToggle1 ? 'text' : 'password'"
                     :errorMessage="pwd1.error"
                     :maxLength="128" required
                     @input="validatePassword" @blur="focusChange" />
          <button aria-hidden="true" class="material-icons h-8 w-8 absolute right-3 top-1/4 rounded-full
            transition-colors transition-duration-200 hover:bg-slate-500 hover:bg-opacity-10"
                  @click="showPasswordToggle1 = !showPasswordToggle1">
            {{ showPasswordToggle1 ? "visibility_off" : "visibility" }}
          </button>
        </div>
        <div class="tooltip-basic relative items-center">
          <div class="tooltip-text whitespace-nowrap">
            <span class="pb-1">requirements: </span>
            <ul class="pr-2">
              <li>6 characters long. </li>
              <!-- <li>1 capital letter.</li> -->
              <!-- <li>1 number.</li> -->
            </ul>
          </div>
          <BaseInput v-model="pwd2.value" :tabindex="1"
                     class="pt-3 mb-4"
                     label="Confirm Password"
                     name="pwd2"
                     :type="showPasswordToggle2 ? 'text' : 'password'"
                     :errorMessage="pwd2.error"
                     :maxLength="128"
                     required
                     @blur="focusChange" @input="checkPasswords" />
          <button aria-hidden="true" class="material-icons h-8 w-8 absolute right-3 top-1/4 rounded-full
            transition-colors transition-duration-200 hover:bg-slate-500 hover:bg-opacity-10"
                  @click="showPasswordToggle2 = !showPasswordToggle2">
            {{ showPasswordToggle2 ? "visibility_off" : "visibility" }}
          </button>
        </div>
        <button :class="[(validPwd && matchingPwd) ? '' : 'disabled', 'btn-long btn-primary h-12 text-sm']"
                tabindex="1" @click="resetPassword">
          Reset Password
        </button>
      </div>

      <p v-show="resetSuccess"
         :class="[resetSuccess ? '' : 'hidden', 'text-rare2-dark text-center text-3xl font-bold']">
        Your password has been reset successfully!
      </p>
      <div v-show="errorMessage !== '' || resetSuccess" class="flex justify-center my-16 ">
        <a class="link-lg" @click="loginRedirect"> Back to Sign In </a>
      </div>
      <!-- error and success messages -->
      <div class="flex justify-center">
        <p class=" font-normal text-sm text-center mt-2 mx-28 text-gray-500">
          If you have any additional issues, please contact support at
          <a href="tel:18558862156" class="inline-anchor"> 1-855-886-2156</a>
        </p>
      </div>
    </div>
  </LoginTemplate>
</template>

<script src="./PasswordUpdate.js"></script>

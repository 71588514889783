import axios from 'axios';
import { store } from '../store';

const TALKATEXT_SERVICE = __API_URL_V1 + 'talkatext';


function Get (id) {
  return axios({
    method: 'GET',
    url: `${TALKATEXT_SERVICE}/${id}`,
  });
}

function GetAll () {
  let userId = store.getters.getUserId;
  return axios({
    method: 'GET',
    url: `${TALKATEXT_SERVICE}/user/${userId}`,
  });
}

function Create ({ keyword, talkatext, is_pinned }) {
  let userId = store.getters.getUserId;
  let payload = {
    user_id: parseInt(userId),
    keyword: keyword,
    talkatext: talkatext,
    is_pinned: is_pinned,
  };
  return axios({
    method: 'POST',
    url: `${TALKATEXT_SERVICE}/create`,
    data: payload
  });
}

function Update ({ talkatext_id, keyword, talkatext, is_pinned }) {
  let payload = {
    talkatext_id: talkatext_id,
    keyword: keyword,
    talkatext: talkatext,
    is_pinned: is_pinned,
  };

  return axios({
    method: 'POST',
    url: `${TALKATEXT_SERVICE}/${talkatext_id}/update`,
    data: payload
  });
}

function Delete ({ talkatext_id }) {
  return axios({
    method: 'DELETE',
    url: `${TALKATEXT_SERVICE}/${talkatext_id}`,
    data: {}
  });
}

export {
  Get,
  GetAll,
  Create,
  Update,
  Delete,
};

const FeedbackService = require("@/services/FeedbackService");
import BaseBox from 'Components/ui/BaseBox';
import BaseLoading from 'Components/ui/BaseLoading';
import FeedbackTemplate from 'Components/layout/FeedbackTemplate.vue';

// http://localhost:8080/feedback?survey_token=asdf&user_id=1234&score=9
export default {
  name: 'feedback',
  components: {
    BaseBox,
    BaseLoading,
    FeedbackTemplate
  },
  data () {
    let score = this.$route.query.score;
    if (score) {
      score = parseInt(score);
      if (![0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(score))
        score = undefined;
    }
    return {
      token: this.$route.query.token,
      userId: this.$route.query.user,
      score: score,
      feedback: "",
      surveyId: undefined,
      feedbackSubmit: false,
      feedbackFinished: false,
      error: "",
    };
  },
  created () {

    this.error = !this.token || !this.userId;
    FeedbackService.VerifySurveyToken(this.token, this.userId)
      .then((resp) => {
        this.surveyId = resp.data.survey_id;
      })
      .catch((err) => {
        console.log(err);
        this.error = true;
      });
  },
  computed: {
    placeholder () {
      if (this.score === undefined || isNaN(this.score)) {
        return "";
      }
      if (this.score > 8) {
        return "Can you tell us why you scored us so highly?";
      }
      if (this.score > 6) {
        return "Can you tell us more about your experience, so we can do even better next time?";
      }
      return "Can you tell us more about your experience, so we can get it right the next time?";
    },
    disableSubmit () {
      return this.score === undefined;
    }
  },
  methods: {
    submitFeedback () {
      this.feedbackSubmit = true;
      FeedbackService.NpsSurveyResponse(this.surveyId, this.token, this.userId, this.score, this.feedback)
        .then((resp) => {
          this.feedbackFinished = true;
        })
        .catch((err) => {
          console.log(err);
        }).finally(() => {
          this.feedbackSubmit = false;
        });

    }
  }
};
const NotesService = require("Services/NotesService");

import StandardTemplate from "Components/layout/StandardTemplate.vue";
import BaseIconDropdown from "Components/ui/BaseIconDropdown.vue";
import BaseSelectList from "Components/ui/BaseSelectList";
import BaseMultiSelect from "Components/ui/BaseMultiSelect";
import BaseDatepicker from "Components/ui/BaseDatepicker";
import BaseInput from "Components/ui/BaseInput.vue";
import BaseLoading from "Components/ui/BaseLoading.vue";
import BaseSwitch from "Components/ui/BaseSwitch.vue";
import KanbanCard from "./KanbanCard.vue";
import { IssueMessageTypes } from "@/modules/Websocket.js";
import { genFiltersName } from "Modules/CustomFiltersHelpers";
import { QueryManager, NoteViews, Sorting } from "Modules/NotesQueryManager.js";
import NoteStatesMap from "Modules/NoteStatesMap";

// tests for rect containing an x,y coordinate
const rectContains = function (rect, x, y) {
  if (!rect) return false;
  if (rect.x > x) return false;
  if (rect.y > y) return false;
  if (rect.x + rect.width < x) return false;
  if (rect.y + rect.height < y) return false;
  return true;
};

// simple hash function. Used to create an id for saved filters on the json string of the filter config
function hash (str) {
  let hash = 32;
  for (let i = 0, len = str.length; i < len; i++) {
    let c = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + c;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}


export default {
  name: "NotesKanban",
  components: {
    StandardTemplate, BaseIconDropdown, BaseInput, BaseSwitch, BaseLoading, KanbanCard,
    BaseSelectList, BaseMultiSelect, BaseDatepicker,
  },
  emits: ["noteViewChange"],
  props: {
    kanbanView: Boolean,
    noteUsage: Object
  },
  data () {
    const currUserId = this.$store.getters.getUserId;
    //shared user config with notes table
    let userConfig = localStorage.getItem(`Kanban:${currUserId}UserConfig`);
    if (!userConfig) userConfig = {};
    else userConfig = JSON.parse(userConfig);
    const pageSize = 30;
    // let sort = userConfig.sortBy ??  { field: "date", sortBy: 1 };
    let savedFilters = userConfig.savedFilters ?? [];
    let isUserScribe = false;
    if (this.$store.getters.getUserGroups)
      isUserScribe = this.$store.getters.getUserGroups.findIndex(x => x === "Scribe") !== -1;
    let isUserVerified = this.$store.getters.getUserPerms.verified;

    let kanbanGroups = [
      {
        key: "draft",
        state: "Draft",
        filter: () => { return true; },
        stateFilter: { field: "state", value: NoteStatesMap.findIndex(x => x === "Draft") },
        noteView: NoteViews.ACTIVE,
        shown: userConfig?.groups?.draft?.shown ?? false,
      }, {
        key: "pending",
        state: "Awaiting Review",
        filter: () => { return true; },
        stateFilter: { field: "state", value: NoteStatesMap.findIndex(x => x === "Awaiting Review") },
        noteView: NoteViews.ACTIVE,
        shown: userConfig?.groups?.pending?.shown ?? true,
      }, {
        key: "review",
        state: "In Review",
        filter: () => { return true; },
        stateFilter: { field: "state", value: NoteStatesMap.findIndex(x => x === "In Review") },
        noteView: NoteViews.ACTIVE,
        shown: userConfig?.groups?.review?.shown ?? true,
      }, {
        key: "verified",
        state: (isUserScribe || isUserVerified) ? "Verified" : "Completed",
        filter: () => { return true; },
        stateFilter: { field: "state", value: NoteStatesMap.findIndex(x => x === "Verified") },
        noteView: NoteViews.ACTIVE,
        shown: userConfig?.groups?.verified?.shown ?? true,
      }, {
        key: "pms",
        state: (isUserScribe || isUserVerified) ? "Verified and in PMS" : "Completed and in PMS",
        filter: () => { return true; },
        stateFilter: { field: "state", value: NoteStatesMap.findIndex(x => x === "In PMS") },
        noteView: NoteViews.IN_PMS,
        shown: userConfig?.groups?.pms?.shown ?? false,
      }, {
        key: "archived",
        state: "Archived",
        filter: () => { return true; },
        stateFilter: { field: "archived", value: true },
        noteView: NoteViews.ARCHIVED,
        shown: userConfig?.groups?.archived?.shown ?? false,
      }
    ];
    // iterating over the groups for adding all the initial config properties
    kanbanGroups.forEach(group => {
      group.notes = [];
      group.totalPages = undefined;
      group.totalCount = undefined;
      group.loading = true;
      group.page = 1;
    });

    return {
      currUserId,
      loading: false,
      // * rendering/data properties
      kanbanGroups,
      containers: {},
      authors: undefined,
      heldNote: undefined,
      dupeNote: undefined,
      pickupPosition: { group: -1, index: -1 },
      dupePosition: { group: -1, index: -1 },
      lastHoveredNoteId: undefined,
      timeoutGuard: -1,
      windowWidth: window.innerWidth,

      // * filter configuration
      customFilters: { input: [] },
      nameFilters: false,
      filterNameValue: '',
      savedFilters,
      selectedSavedFilter: undefined,
      showSavedFilterDropdown: false,
      // * querying state
      queryManager: new QueryManager({ paged: true, page: 0, pageLength: pageSize }),
      pageSize: pageSize,
      userConfig,
      searchText: '',
      textSearchTimeoutId: -1,
      queryId: 0,

      //  * websocket management
      wsIds: [],
      eventBatching: { timeoutId: -1, events: [] },
    };
  },
  created () {
  },
  mounted () {
    this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onNoteStateEvent(this.noteStateEventHandler));
    this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onNoteIssueEvent(this.noteIssueEventHandler));

    this.$nextTick(() => {
      // set up containers object for mapping divs correctly
      this.containers.keys = [];
      // setting up references to the dom elements and the scroll
      this.kanbanGroups.forEach(group => {
        this.containers.keys.push(group.key);
        this.registerContainer(group);
      });
    });

    NotesService.GetAuthorList()
      .then((resp) => {
        let i = 0;
        const names = resp.data.authors.map(x => `${x.first_name} ${x.last_name}`);
        this.authors = resp.data.authors.map((x, index, self) => {
          // add integer to names when there are duplicates. Not great but its something
          let dup = names.indexOf(`${x.first_name} ${x.last_name}`) !== index;
          return {
            firstName: x.first_name, lastName: x.last_name,
            name: `${x.first_name} ${x.last_name}${dup ? ` ${(++i)}` : ''}`,
            userId: parseInt(x.user_id)
          };
        });
        this.setCustomFilter();
        this.getPageData();
      }).catch(err => {
        this.$toast.error({ message: "There was an error getting the list of verified users!" });
        console.log(err);
      });
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  watch: {
    searchText (newVal, oldVal) {
      this.kanbanGroups.forEach(x => x.loading = true);
      if (this.textSearchTimeoutId !== -1) window.clearTimeout(this.textSearchTimeoutId);
      this.textSearchTimeoutId = window.setTimeout(() => {
        this.getNewFilteredData();
      }, 250);
    },
    // watching ShowVerifiedLane computed prop to make sure that the verified lane is registered
    ShowVerifiedLane (newVal, oldVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.registerContainer(this.kanbanGroups.find(x => x.key == 'verified'));
        });
      }
    }
  },
  computed: {
    isUserScribe () {
      if (!this.$store.getters.getUserGroups) return false;
      return this.$store.getters.getUserGroups.findIndex(x => x === "Scribe") !== -1;
    },
    isUserVerified () {
      return this.$store.getters.getUserPerms.verified;
    },
    ShowVerifiedLane () {
      return (this.isUserScribe || this.isUserVerified)
        || this.kanbanGroups.find(x => x.key == 'verified').notes.length > 0;
    },
    customFilterCount () {
      let count = 0;
      this.customFilters.input.forEach(input => {
        switch (input.inputType) {
          case ("datepicker"):
            // we expect the datepicker value to be [startDate, endDate]
            if (input.value && input.value.length > 0) {
              count++;
            }
            break;
          case ("multi-select"):
            if (input._value) {
              Object.keys(input._value).forEach(key => {
                if (input.value[key]) {
                  count++;
                }
              });
            } else {
              Object.keys(input.value).forEach(key => {
                if (input.value[key]) {
                  count++;
                }
              });
            }
            break;
          // TODO (Adam) select, input
          // case ("select"):
          // case ("input"):
        }
      });
      return count;
    },
  },
  methods: {
    toggleListType (event) {
      this.$store.commit("set_notes_view", "table");
      this.$emit("noteViewChange", "table");
    },
    onResize () {
      this.windowWidth = window.innerWidth;
    },
    // * page lifecycle entry point
    getNewFilteredData () {
      this.loading = true;
      this.kanbanGroups.forEach(group => {
        group.notes = [];
        group.totalPages = undefined;
        group.totalCount = undefined;
        // group.loading = true;
        group.page = 1;
      });
      this.getPageData();
    },
    getPageData () {
      this.queryId += 1;
      let queryId = this.queryId;
      this.kanbanGroups.forEach((group, index) => {
        if (group.shown || group.key == 'verified') {
          group.loading = true;
          this.queryManager.getNotesWith({
            filters: [group.stateFilter, ...this.customFilters.input.map(x => ({ ...x }))],
            sorting: { field: 'date', sortBy: Sorting.DESCENDING },//this.userConfig.sortBy,
            paged: true,
            page: group.page,
            pageSize: this.pageSize,
            noteView: group.noteView,
            textSearch: this.searchText,
          }).then((resp) => {
            if (queryId != this.queryId) return;
            group.page += 1;
            group.notes = resp.data.notes;
            // for easy reference to which group to read when referencing the note
            let order = 0;
            group.notes.forEach(note => {
              note.groupIndex = index;
              note.order = order++;
            });
            group.totalPages = resp.data.pages;
            group.totalCount = resp.data.total_count;
          })
            .catch(err => {
              this.$toast.error({ message: "There was an error getting the Notes!" });
              console.log(err);
            }).finally(() => {
              group.loading = false;
              this.loading = false;
            });
        }
      });
    },
    getGroupData (group) {
      group.loading = true;
      this.queryId += 1;
      let queryId = this.queryId;
      const groupIndex = this.kanbanGroups.findIndex(x => x.key === group.key);
      this.queryManager.getNotesWith({
        filters: [group.stateFilter, ...this.customFilters.input.map(x => ({ ...x }))],
        sorting: { field: 'date', sortBy: Sorting.DESCENDING },
        paged: true,
        page: group.page,
        pageSize: this.pageSize,
        noteView: group.noteView,
        textSearch: this.searchText,
      }).then((resp) => {
        if (queryId != this.queryId) return;
        if (group.page > 1) {
          group.notes = group.notes.concat(resp.data.notes);
        } else
          group.notes = resp.data.notes;

        group.page += 1;
        // for easy reference to which group to read when referencing the note
        let order = 0;
        group.notes.forEach(note => {
          note.groupIndex = groupIndex;
          note.order = order++;
        });
        group.totalPages = resp.data.pages;
        group.totalCount = resp.data.total_count;
      })
        .catch(err => {
          this.$toast.error({ message: "There was an error getting the Notes!" });
          console.log(err);
        }).finally(() => {
          group.loading = false;
          this.loading = false;
        });
    },
    registerContainer (group) {
      this.containers[group.key] = document.getElementById(`${group.key}-container`);
      if (this.containers[group.key]) {
        this.containers[group.key].addEventListener("scroll", (event) => { this.scrollHandler(event, group); });
      }
    },
    scrollHandler (event, group) {
      const cont = event.target;
      const contHeight = event.target.getBoundingClientRect().height;
      const scrollTriggerPadding = 64;
      if (cont.scrollHeight - (cont.scrollTop + contHeight + scrollTriggerPadding) <= 0) {
        if (!group.loading && group.notes.length !== group.totalCount) {
          this.getGroupData(group);
        }
      }
    },
    // * websocket event handlers
    noteStateEventHandler (event) {
      this.queryManager?.invalidateCache();
      if (event.eventUserId == this.$store.getters.getUserId) return;
      // search for the note in the current view, add a notif icon for it and create a toast message
      // events are batch to prevent spamming a view with tons of toast messages
      let groupIndex = 0;
      let noteIndex = -1;
      for (groupIndex = 0; groupIndex < this.kanbanGroups.length; groupIndex++) {
        noteIndex = this.kanbanGroups[groupIndex].notes.findIndex(n => n.id == event.noteId) ?? -1;
        if (noteIndex !== -1) break;
      }
      if (noteIndex == -1) return;

      this.eventBatching.events.push(event);
      // setting a timeout to batch note change events.
      if (this.eventBatching.timeoutId !== -1) window.clearTimeout(this.eventBatching.timeoutId);
      this.eventBatching.timeoutId = window.setTimeout(() => {
        let ids = this.eventBatching.events.map(x => x.noteId)
          .filter((item, i, self) => self.indexOf(item) === i);
        if (ids.length === 1) {
          let note = this.kanbanGroups[groupIndex].notes.find(n => n.id == event.noteId);
          let startEvent = this.eventBatching.events[0];
          let endEvent = this.eventBatching.events[this.eventBatching.events.length - 1];

          // guard on state toast message. don't post toast on no real state change
          if (startEvent.oldState === endEvent.newState) return;

          let noteRef = note.title ? `"${note.title}"` : `Note ${startEvent.noteId}`;
          this.$toast.success({
            message: `${noteRef} changed from ${NoteStatesMap[startEvent.oldState]} to ${NoteStatesMap[endEvent.newState]}.`,
            action: { fn: this.getNewFilteredData, message: "Refresh" },
            timeout: 5000,
          });
        } else {
          //counting unique ids
          this.$toast.success({
            message: `${ids.length} Notes were updated!`,
            action: { fn: this.getNewFilteredData, message: "Refresh" },
            timeout: 5000,
          });
        }
        this.eventBatching = { timeoutId: -1, events: [] };
      }, 500);
      // disabled updating state. doing this would animate the table to add/remove notes based on filters
      // this.kanbanGroups[groupIndex].notes[noteIndex].state = event.newState;
      this.kanbanGroups[groupIndex].notes[noteIndex].updated = true;
      this.kanbanGroups[groupIndex].notes[noteIndex].updatedState = event.newState;
    },
    noteIssueEventHandler (event) {
      // there is not a (simple) way to handle note updates coming in than just
      // invalidating all cache in the query manager
      this.queryManager?.invalidateCache();
      // search for the note in the current view, add a notif icon for it and create a toast message
      // events are batch to prevent spamming a view with tons of toast messages
      let groupIndex = 0;
      let noteIndex = -1;
      for (groupIndex = 0; groupIndex < this.kanbanGroups.length; groupIndex++) {
        noteIndex = this.kanbanGroups[groupIndex].notes.findIndex(n => n.id == event.noteId) ?? -1;
        if (noteIndex !== -1) break;
      }
      if (noteIndex === -1) return;

      let noteMessage = "";
      switch (event.eventType) {
        case (IssueMessageTypes.NEW_ISSUE):
          noteMessage = `Note ${event.noteId} had an issue raised on it!`;
          this.kanbanGroups[groupIndex].notes[noteIndex].needs_attention = true;
          break;
        case (IssueMessageTypes.ISSUE_RESPONSE):
          noteMessage = `Note ${event.noteId} had a response to the issue!`;
          this.kanbanGroups[groupIndex].notes[noteIndex].number_of_responses = 1 + (this.kanbanGroups[groupIndex].notes[noteIndex].number_of_responses ?? 0);
          break;
        case (IssueMessageTypes.ISSUE_RESOLVED):
          noteMessage = `Note ${event.noteId} had its issue resolved!`;
          this.kanbanGroups[groupIndex].notes[noteIndex].needs_attention = false;
          this.kanbanGroups[groupIndex].notes[noteIndex].state = 1;
          break;
      }
      this.$toast.success({
        message: noteMessage,
        action: { fn: () => { this.$router.push(`/notes/${event.noteId}`); }, message: "Take me to it" },
      });
      this.kanbanGroups[groupIndex].notes[noteIndex].updated = true;
    },

    // * State change handler for when a card is dropped in a container
    // stateKey is the key field of the group
    async stateHandler (note, stateKey) {

      if (stateKey === NoteStatesMap[note.state]) return;
      if (note.archived && stateKey === "archived") return; // dropping an archived note in the archived swimlane
      if (note.archived && stateKey !== "archived") {
        await this.archiveNote(note, false);
        note.archived = false;
      }
      switch (stateKey) {
        case ("pending"):
          // special case for drafts, we need to close the note
          note.state == 0 ?
            this.closeNote(note) :
            this.setAwaitingReview(note);
          break;
        case ("review"):
          this.setInReview(note);
          break;
        case ("verified"):
          this.verifyNote(note);
          break;
        case ("pms"):
          this.copiedToPms(note);
          break;
        case ("archived"):
          this.archiveNote(note, true);
      }
    },
    closeNote (note) {
      return NotesService.CloseNote(note.id)
        .then(resp => {
          note.published_at = parseInt(resp.data.published_at);
        })
        .catch((e) => { console.log(e); });
    },
    setAwaitingReview (note) {
      const state = NoteStatesMap.indexOf("Awaiting Review");
      NotesService.UpdateNote({ note_id: note.id, title: note.title, state: state })
        .then(resp => {
          note.last_edited = parseInt(resp.data.last_edited);
          note.state = state;
        })
        .catch((e) => { console.log(e); });
    },
    setInReview (note) {
      const state = NoteStatesMap.indexOf("In Review");
      return NotesService.UpdateNote({ note_id: note.id, title: note.title, state: state })
        .then(resp => {
          note.last_edited = parseInt(resp.data.last_edited);
          note.state = state;
        })
        .catch((e) => { console.log(e); });
    },
    verifyNote (note) {
      const state = NoteStatesMap.indexOf("Verified");
      NotesService.UpdateNote({ note_id: note.id, title: note.title, state: state })
        .then(resp => {
          note.last_edited = parseInt(resp.data.last_edited);
          note.state = state;
        })
        .catch((e) => { console.log(e); });
    },
    copiedToPms (note) {
      const state = NoteStatesMap.indexOf("In PMS");
      NotesService.UpdateNote({ note_id: note.id, title: note.title, state: state })
        .then(resp => {
          note.last_edited = parseInt(resp.data.last_edited);
          note.state = state;
          // check if the user is an editor
          // TODO When we have the Verified and in PMS state move this check to that update
          if (this.$store.getters.getUserId != note.user_id) {
            this.$toast.success({
              message: "Note marked as Verified.",
              action: {
                fn: () => {
                  this.setInReview()
                    .then(() => this.$router.push("/notes/" + note.id));
                },
                message: "UNDO",
              }
            });
            this.$router.push("/notes");
          }
        })
        .catch((e) => { console.log(e); });
    },
    archiveNote (note, archive) {
      return NotesService.ArchiveNote(note.id, archive)
        .then(resp => {
          note.last_edited = parseInt(resp.data.last_edited);
          note.archived = archive;
        })
        .catch((e) => { console.log(e); });
    },
    // * event handlers for the Drag and Drop api
    dragstart ({ groupIndex, noteId }) {
      // "protect" held note from being dropped from the DOM and create a duplicate that animates as a placeholder
      let noteIndex = this.kanbanGroups[groupIndex].notes.findIndex(x => x.id == noteId);
      this.heldNote = this.kanbanGroups[groupIndex].notes[noteIndex];
      this.dupeNote = { ... this.heldNote };
      this.dupeNote.id = this.dupeNote.id + 'dupe';
      this.heldNote.hide = true;
      this.heldNote.held = true;
      // adding a duplicate note
      this.dupeNote.held = true;
      this.dupeNote.dupe = true;
      this.kanbanGroups.forEach((group) => { group.dropTarget = this.isValidDropTarget(group, this.heldNote); });
      this.kanbanGroups[groupIndex].notes.splice(noteIndex, 0, this.dupeNote);
      this.pickupPosition = { group: groupIndex, index: noteIndex };
    },
    dragend () {
      this.kanbanGroups.forEach((group) => { group.dropTarget = false; });
      if (!this.heldNote) return;
      this.heldNote.held = false;
      this.heldNote.hide = false;
      this.heldNote = undefined;
      if (this.dupeNote) {
        const dupeGroupIndex = this.dupeNote.groupIndex;
        const dupeNoteIndex = this.kanbanGroups[dupeGroupIndex].notes.findIndex(x => x.id === this.dupeNote.id);
        this.kanbanGroups[dupeGroupIndex].notes.splice(dupeNoteIndex, 1);
        this.dupeNote = undefined;
      }
      this.pickupPosition = { group: -1, index: -1 };
    },
    drop (event) {
      event.preventDefault();
      this.heldNote.held = false;
      this.heldNote.hide = false;
      const dupeGroupIndex = this.dupeNote.groupIndex;
      let dupeNoteIndex = this.kanbanGroups[dupeGroupIndex].notes.findIndex(x => x.id === this.dupeNote.id);
      let heldNoteIndex = this.kanbanGroups[this.heldNote.groupIndex].notes.findIndex(note => note.id === this.heldNote.id);
      let cont = this.containers.keys.find(key => {
        return rectContains(this.containers[key]?.getBoundingClientRect(), event.clientX, event.clientY);
      });
      let hoveredGroupIndex = this.kanbanGroups.findIndex(x => x.key === cont);
      let validCont = this.isValidDropTarget(this.kanbanGroups[hoveredGroupIndex], this.heldNote);
      if (cont && validCont) {
        // note is drop to different container
        if (hoveredGroupIndex !== this.heldNote.groupIndex) {
          // drop held note from group
          this.kanbanGroups[this.heldNote.groupIndex].notes.splice(heldNoteIndex, 1);
          // add held note to dropped group
          let index = this.kanbanGroups[this.dupeNote.groupIndex].notes.findIndex(x => x.id === this.dupeNote.id);
          if (index !== -1) {
            this.kanbanGroups[hoveredGroupIndex].notes.splice(index, 0, this.heldNote);
          } else {
            this.kanbanGroups[hoveredGroupIndex].notes.splice(0, 0, this.heldNote);
          }
          this.heldNote.groupIndex = hoveredGroupIndex;
          // note is moved in current container container
        } else {
          this.kanbanGroups[this.heldNote.groupIndex].notes.splice(heldNoteIndex, 1);
          if (heldNoteIndex < dupeNoteIndex) dupeNoteIndex -= 1;
          this.kanbanGroups[dupeGroupIndex].notes.splice(dupeNoteIndex, 0, this.heldNote);
        }
      }
      // remove dupeNote
      dupeNoteIndex = this.kanbanGroups[dupeGroupIndex].notes.findIndex(x => x.id === this.dupeNote.id);
      this.kanbanGroups[dupeGroupIndex].notes.splice(dupeNoteIndex, 1);

      this.kanbanGroups[hoveredGroupIndex].totalCount += 1;
      this.kanbanGroups[this.pickupPosition.group].totalCount -= 1;
      this.pickupPosition = { group: -1, index: -1 };
      if (validCont) this.stateHandler(this.heldNote, this.kanbanGroups[hoveredGroupIndex].key);
      this.heldNote = undefined;
      this.dupeNote = undefined;
      // const data = event.dataTransfer.getData('text/plain');
    },
    // in this event handler the position of the held note is spliced in and out of the different note lists to preview where the note will end up
    // the event is triggered over the cards as well as the lanes
    dragover (event) {
      // lil timeout guard to make the animation more smooth
      if (this.timeoutGuard !== -1) return;
      this.timeoutGuard = window.setTimeout(() => { this.timeoutGuard = -1; }, 100);

      if (!this.heldNote) return;
      // if dragging over another note
      if (event.target.dataset["noteid"] === undefined) return;
      let hoveredId = parseInt(event.target.dataset["noteid"]);
      // don't re-eval movement
      if (this.lastHoveredNoteId === hoveredId) return;
      this.lastHoveredNoteId = hoveredId;
      // hoveredNote != heldNote
      if (this.heldNote.id == hoveredId) return;

      let hoveredGroupIndex = parseInt(event.target.dataset["notegroup"]);
      if (!this.isValidDropTarget(this.kanbanGroups[hoveredGroupIndex], this.heldNote)) return;
      let hoveredNoteIndex = this.kanbanGroups[hoveredGroupIndex].notes.findIndex(x => hoveredId == x.id);
      // let hoveredNote = this.kanbanGroups[hoveredGroupIndex].notes[hoveredNoteIndex];
      // hoveredNote, HeldNote
      // now with hoveredNote and held note, we splice
      const dupeGroupIndex = this.dupeNote.groupIndex;
      const dupeNoteIndex = this.kanbanGroups[dupeGroupIndex].notes.findIndex(x => this.dupeNote.id == x.id);
      // same group, just move card to after hovered card
      if (dupeGroupIndex === hoveredGroupIndex) {
        this.kanbanGroups[dupeGroupIndex].notes.splice(dupeNoteIndex, 1);
        if (dupeNoteIndex < hoveredNoteIndex) hoveredNoteIndex -= 1;
        this.kanbanGroups[dupeGroupIndex].notes.splice(hoveredNoteIndex, 0, this.dupeNote);
        // moving held note within container for better animation
      }
      // different group, /* remove card from old group */ move card to after hovered card in new group
      else {
        this.kanbanGroups[dupeGroupIndex].notes.splice(dupeNoteIndex, 1);
        this.dupeNote.groupIndex = hoveredGroupIndex;
        this.kanbanGroups[hoveredGroupIndex].notes.splice(hoveredNoteIndex, 0, this.dupeNote);
        this.dupePosition = { group: hoveredGroupIndex, index: hoveredNoteIndex };
      }
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    },
    dragenter (event) {
      event.preventDefault();
    },
    isValidDropTarget (group, note) {
      if (note === undefined) return false;
      if (group === undefined) return false;
      // handle draft
      if (note.state > 0 && group.key === "draft") return false;
      if (note.state == NoteStatesMap.indexOf("Draft")) {
        return group.key === "draft" || group.key === "pending";
      }
      // only users who authored the note may move a note to archived status
      if (group.key === "archived") {
        if (note.user_id != this.$store.getters.getUserId) return false;
      }
      // preventing unarchiving notes where the user did not create the note.
      if (note.archived) {
        if (note.user_id != this.$store.getters.getUserId) return false;
      }
      return true;
    },

    // * filter event
    resetFiltersDropdown () {
      document.body.click();
      this.nameFilters = false;
      this.filterNameValue = "";
    },
    // * View state handlers
    toggleLane (group) {
      group.shown = !group.shown;
      if (this.userConfig.groups === undefined) this.userConfig.groups = {};
      if (this.userConfig.groups[group.key] === undefined) this.userConfig.groups[group.key] = {};
      this.userConfig.groups[group.key].shown = group.shown;
      if (group.shown) {
        this.$nextTick(() => {
          this.registerContainer(group);/* this.containers[group.key] = document.getElementById(`${group.key}-container`); */
        });
      }
      this.saveUserConfig();
      if (group.notes.length === 0) this.getGroupData(group);
    },

    // * filter handling
    clearHeaderFilters () {
      this.selectedSavedFilter = undefined;
      this.nameFilters = false;
      this.userConfig.cachedFilters = {};
      this.saveUserConfig();
      this.setCustomFilter();
      this.getNewFilteredData();
    },
    setCustomFilter () {
      let cachedFilters = this.parseCachedFilters();
      this.customFilters = {
        input: [
          {
            id: 'dp-timestamp',
            inputType: "datepicker",
            placeholder: "Date Range",
            filterField: "timestamp",
            value: cachedFilters['dp-timestamp'] ?? [],
          },
          {
            id: 'ms-record-by',
            inputType: "multi-select",
            filterField: "user_id",
            placeholder: "Recorded By",
            ErrorMessage: "",
            // reduce to object for multi select {recorded_by_name:false/true}
            value: this.authors
              .reduce((prop, curr) => {
                if (cachedFilters['ms-record-by'] && cachedFilters['ms-record-by'][curr.name]) {
                  prop[curr.name] = true;
                } else {
                  prop[curr.name] = false;
                }
                return prop;
              }, {}),
            _value: this.authors.reduce((prop, x) => {
              prop[x.name] = x.userId;
              return prop;
            }, {})
          },
        ]
      };
    },
    updateCustomFilters (filter) {
      let cachedFilters = this.userConfig.cachedFilters;
      this.selectedSavedFilter = undefined;
      if (!cachedFilters) {
        cachedFilters = {};
      }
      //specifically target multiselect to cull out useless values. remove all false fields from the filter
      let cache = filter.value;
      if (filter.inputType === "multi-select") {
        cache = Object.keys(cache)
          .reduce((prop, key) => {
            if (cache[key]) {
              prop[key] = true;
            }
            return prop;
          }, {});
      }
      cachedFilters[filter.id] = cache;
      this.userConfig.cachedFilters = cachedFilters;
      this.saveUserConfig();
      this.getNewFilteredData();
    },
    clearCustomFilters () {
      this.userConfig.cachedFilters = {};
      this.saveUserConfig();
      // localStorage.removeItem("Notes:CustomTableFilters");
    },
    mapNameToUserId () {
      let nameMap = {};
      this.notes.forEach(x => nameMap[x.metadata.recorded_by_name] = parseInt(x.user_id));
      return nameMap;
    },
    // Function to check local storage and parse out any filters that were cached from the previous session.
    parseCachedFilters () {
      let cachedFilters = this.userConfig.cachedFilters;
      // keyed on id of custom filter input properties
      let parsedValues = {};
      // return parsedValues;
      if (!cachedFilters) {
        return parsedValues;
      }
      const id = hash(JSON.stringify(cachedFilters));
      let i = this.savedFilters.findIndex(x => x.id == id);
      if (i !== -1) {
        this.selectedSavedFilter = id;
      }
      // hardcoding these, if anything changes about this format we'd be in a lot of pain if there wasn't a specific key to reference
      if (cachedFilters['dp-timestamp'] && cachedFilters['dp-timestamp'].length > 0) {
        // needs to be an array of length 2
        let d0, d1;
        if (Array.isArray(cachedFilters['dp-timestamp']) && cachedFilters['dp-timestamp'].length === 2) {
          // attempt to parse the dates
          d0 = new Date(cachedFilters['dp-timestamp'][0]);
          d1 = new Date(cachedFilters['dp-timestamp'][1]);
        }
        if (!d0 || d0 == 'Invalid Date' || !d1 || d1 === 'Invalid Date') {
          parsedValues['dp-timestamp'] = [];
        } else {
          parsedValues['dp-timestamp'] = [d0, d1];
        }
      }
      if (cachedFilters['ms-record-by']) {
        parsedValues['ms-record-by'] = cachedFilters['ms-record-by'];
      }
      return parsedValues;
    },
    // handler for filter change events coming from custom filter dropdown
    // takes in a filter item from the customFilters list that is passed to the filter dropdown generator
    updateCachedFilters (filter) {
      let cachedFilters = this.userConfig.cachedFilters;
      if (!cachedFilters) {
        cachedFilters = {};
      }
      //specifically target multiselect to cull out useless values. remove all false fields from the filter
      let cache = filter.value;
      if (filter.inputType === "multi-select") {
        cache = Object.keys(cache)
          .reduce((prop, key) => {
            if (cache[key]) {
              prop[key] = true;
            }
            return prop;
          }, {});
      }
      cachedFilters[filter.id] = cache;
      this.userConfig.cachedFilters = cachedFilters;
      this.saveUserConfig();
    },
    handleTextSearch (text) {
      this.pagination.page = 1;
      this.queryManager.setTextSearch(text);
      this.getPageData();
    },

    // * functions for managing saved custom filters
    toggleSaveFilters (event) {
      this.nameFilters = !this.nameFilters;
      if (this.nameFilters) {
        this.filterNameValue = genFiltersName(this.customFilters);
      }
      this.$nextTick(() => { document.getElementById("name-filter-input").select(); });
      event.stopPropagation();
    },
    toggleSavedFiltersDropdown (event, override) {
      if (override !== undefined) {
        this.showSavedFilterDropdown = override;
      } else {
        this.showSavedFilterDropdown = !this.showSavedFilterDropdown;
      }
    },
    // savedFilters should be given a unique id
    // Saving the current set of custom filters into the cashed preset filters for users
    // this.$emit("save-custom-filters", { fullName: this.filterNameValue, tagName: tagName });
    saveCustomFilters () {
      let tag = genFiltersName(this.customFilters);
      // the cool thing about using a hash is I can splice out any duplicate filters and rename ezpz
      const id = hash(JSON.stringify(this.userConfig.cachedFilters));
      let i = this.savedFilters.findIndex(x => x.id == id);
      if (i !== -1) {
        this.savedFilters.splice(i, 1);
      }
      let newFilter = {
        id: id,
        name: this.filterNameValue,
        tag: tag,
        filters: structuredClone(this.userConfig.cachedFilters), // cachedFilters is kept up-to-date as current filter as filters change so it can be used to save
      };
      this.savedFilters.push(newFilter);
      this.userConfig.savedFilters = this.savedFilters;
      this.saveUserConfig();
      this.nameFilters = false;
      this.selectedSavedFilter = id;
    },

    selectSavedFilter (filter) {
      let selected = this.savedFilters.find(x => x.id == filter.id);
      this.userConfig.cachedFilters = structuredClone(selected.filters);
      this.saveUserConfig();
      this.setCustomFilter();
      this.getNewFilteredData();
    },
    removeSavedFilters (filter) {
      let i = this.savedFilters.findIndex(x => x.id == filter.id);
      if (i === -1) return;
      this.savedFilters.splice(i, 1);
      this.userConfig.savedFilters = structuredClone(this.savedFilters);
      this.saveUserConfig();
    },
    saveUserConfig () {
      localStorage.setItem(`Kanban:${this.currUserId}UserConfig`, JSON.stringify(this.userConfig));
    },
  },
  unmounted () {
    window.removeEventListener('resize', this.onResize);
    this.$store.getters.getWebsocketEventHandler.removeEvents(this.wsIds);
    this.containers.keys.forEach((key) => {
      if (this.containers[key]) {
        this.containers[key].removeEventListener("scroll", this.scrollHandler);
      }
    });
  },
};
<template>
  <div class="mt-2 pb-5 relative">
    <label v-if="label" class="base-label " :for="name">{{ label }} </label>
    <input
      :id="name"
      v-model="inputValue"
      :name="name"
      :class="['base-input border-[#9BACB2] text-neutral-alt',
               errorMessage && errorMessage != '' ? 'input-error' : '']"
      :placeholder="placeholderText"
      :tabindex="tabindex"
      :type="type"
      :pattern="pattern"
      :disabled="disabled ?? undefined"
      :required="!!required"
      :maxlength="maxLength"
      :autocomplete="autocomplete? autocomplete: 'on'"
      :max="max"
      :min="min"
      @input="action"
      @focus="focusIn"
      @blur="focusOut"
      @keydown.enter="enter"
    />

    <p v-show="errorMessage" class="error-message footer-message absolute left-4 ">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  emits: ['input', 'update:modelValue', 'focus', 'blur', 'enter'],
  props: {
    modelValue: [String, Number],
    label: [String, undefined],
    name: [String, undefined],
    type: String,
    pattern: String,
    placeholderText: String,
    disabled: Boolean,
    errorMessage: String,
    required: Boolean,
    maxLength: Number,
    autocomplete: String,
    tabindex: Number,
    max: Number,
    min: Number,
  },
  data () {
    return { inputValue: this.modelValue };
  },
  watch: {
    modelValue(newVal) {
      this.inputValue = newVal;
    }
  },
  methods: {
    action (e) {
      // this.modelValue = this.inputValue;
      // automatically handled though the v-model directive
      this.$emit("update:modelValue", e.target.value);
      this.$emit("input", e.target.value);
    },
    focusIn (e) {
      this.$emit("focus", e.target.value);
    },
    focusOut (e) {
      this.$emit("blur", e.target.value);
    },
    enter (e) {
      this.$emit("enter", e.target.value);
    },
  },
};
</script>
<style scoped>
.base-input {
  padding: 10px 16px;
  @apply w-full;
  @apply h-12;
  font-size: 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
  box-sizing: border-box;
  border-color: #9BACB2;
  display: inline-flex;
  transition-property: color, background-color, border, border-radius;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
@media (min-width: 640px) {
  .base-input {
    @apply h-11;
  }
}
.base-input:focus {
  @apply border-main-alt;
}

.base-label {
  @apply font-roboto;
  font-style: normal;
  font-weight: 400;
  color: #9BACB2;
  @apply px-1;
  @apply ml-2;
  @apply absolute;
  @apply bg-white;
  font-size: 0.75rem;
  line-height: 0.875rem;
  /* identical to box height */
  transform: translate(0%, -50%);
}
.footer-message {
  font-size:12px;
}

</style>
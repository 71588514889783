<template>
  <div class="block">

    <!-- <input type="radio" name="view-toggle" id="view-toggle" class="" :value="viewSelectVal"/> -->
    <div :id="`bubble-toggle-${$.uid}`" class="inline-flex p-1 bg-main-darker bg-opacity-40 text-white rounded-full relative">
      <!-- @mouseover="grow(index)" @mouseleave="targetButton(false)" -->
      <button v-for="(tag, index) in selections" :id="tagName(index, tag)" :key="tag"
              :class="['py-1 text-xs leading-5 z-10 active:bg-main-darker rounded-full ',
                       'bg-opacity-50 active:bg-opacity-10 hover:bg-opacity-10 transition-colors',
                       icons ? 'material-icons text-[26px] leading-5 px-2' : 'px-4 font-bold']" :style="setWidth ? `width: ${setWidth};` : ''"
              @mousemove="grow(index)"
              @mouseleave="targetButton(false)"
              @click="selectionMade(tag, index)">
        {{ tag }}
      </button>
      <div :class="['absolute bg-bubble flex flex-col flex-grow magic-bubble bg-main-darker rounded-full',
                    selected === undefined ? 'opacity-0' : 'opacity-100',
                    preventPreload ? 'prevent-preload-transition': '']"
           :style="setPosition">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BubbleToggle",
  emits: ['selection-made'],
  props: {
    //selection in the toggle. should be an index of the array
    selected: [Number, undefined],
    // array of strings that represent the tags that can be selected
    selections: Array,
    icons: Boolean,
    // if provided it will give all selectable elements the same width value should be some pixel or rem value '12rem' / '160px'
    setWidth: [String, undefined],
  },

  data () {
    return {
      setPosition: '',
      oldIndex: 0,
      preventPreload: true,
      growTimeout:-1,
    };
  },
  mounted () {
    if (this.selected !== undefined) {
      this.targetButton(false);
    }
  },
  created() {
    setTimeout(()=>{this.preventPreload = false;},1);
  },
  watch: {
    selected (newVal, oldVal) {
      if (this.newVal >= this.selections.length) return;
      this.oldIndex = oldVal;

      this.targetButton(true);
    }
  },
  methods: {
    selectionMade (tag, index) {
      this.$emit('selection-made', index);
    },
    tagName (index, tag) {
      return `${tag.split(" ").join("").toLowerCase()}-${index}-${this.$.uid}`;
    },

    grow (index) {
      if (this.growTimeout !== -1) return;
      this.growTimeout = setTimeout(()=>{this.growTimeout = -1;},100);
      if (this.selected < index) {
        let target = document.getElementById(this.tagName(this.selected, this.selections[this.selected]));
        if (!target) return;
        let rect = target.getBoundingClientRect();
        let cont = document.getElementById(`bubble-toggle-${this.$.uid}`);
        let offset = rect.x - cont.getBoundingClientRect().x - 4;
        this.setPosition = `height: ${rect.height}px; width: ${rect.width + 6}px; margin-left: ${offset}px;`;
      }
      else if (this.selected > index) {
        let target = document.getElementById(this.tagName(this.selected, this.selections[this.selected]));
        if (!target) return;
        let rect = target.getBoundingClientRect();
        let cont = document.getElementById(`bubble-toggle-${this.$.uid}`);
        let offset = rect.x - cont.getBoundingClientRect().x - 4;
        this.setPosition = `height: ${rect.height}px; width: ${rect.width + 6}px; margin-left: ${offset - 6}px;`;
      }

    },

    targetButton (withTimeout) {
      let delay = 100;
      if (!withTimeout) delay = 0;
      // these delays add a bit of squish to the animation
      else if (this.selected > this.oldIndex) {
        let target = document.getElementById(this.tagName(this.oldIndex, this.selections[this.oldIndex]));
        if (!target) return;
        let rect = target.getBoundingClientRect();
        let cont = document.getElementById(`bubble-toggle-${this.$.uid}`);
        let offset = rect.x - cont.getBoundingClientRect().x - 4;
        this.setPosition = `height: ${rect.height}px; width: ${rect.width + 16}px; margin-left: ${offset}px;`;
      }
      else if (this.selected < this.oldIndex) {
        let target = document.getElementById(this.tagName(this.oldIndex, this.selections[this.oldIndex]));
        if (!target) return;
        let rect = target.getBoundingClientRect();
        let cont = document.getElementById(`bubble-toggle-${this.$.uid}`);
        let offset = rect.x - cont.getBoundingClientRect().x - 4;
        this.setPosition = `height: ${rect.height}px; width: ${rect.width + 16}px; margin-left: ${offset - 16}px;`;
      }

      window.setTimeout(() => {
        if (this.selected >= this.selections.length) return;
        let target = document.getElementById(this.tagName(this.selected, this.selections[this.selected]));
        if (!target) return;
        let rect = target.getBoundingClientRect();
        let cont = document.getElementById(`bubble-toggle-${this.$.uid}`);
        let offset = rect.x - cont.getBoundingClientRect().x - 4;
        this.setPosition = `height: ${rect.height}px; width: ${rect.width}px; margin-left: ${offset}px;`;
      }, delay);
    }
  }
};

</script>
<style>
.bg-bubble {
  transition-property: width, height, margin, opacity, padding;
  transition: 0.15s ease-in-out;
  z-index: 0;
}
</style>
<template>
  <div class="login-wrapper flex flex-col items-center">
    <div class="pt-16 mb-8">
      <span v-if="!title" class=" hidden xs:flex z-50">
        <h2 class="text-center text-white">Managing your flock can be pandemonium.</h2>
      </span>
      <span v-else class="hidden xs:flex z-50">
        <h2 class="text-center text-white">{{ title }}</h2>
      </span>
    </div>
    <div class="login-container z-50 py-6 sm:py-10 px-6 sm:px-12 ">
      <slot></slot>
    </div>
    <div v-if="!hideFooter"
         class="flex bg-pastel-blue-lighter
                border-main border rounded-xl
                mt-6 sm:mt-8 p-4 w-4/5 sm:w-[524px]">
      <div class="flex flex-col sm:flex-row mx-auto">
        <p class="flex z-40 xs:mr-4 text-center text-base md:text-sm text-black font-bold">
          Don't have an account yet?
        </p>
        <div class="flex justify-center">
          <a class="text-sm underline text-center text-main-alt hover:text-main transition-colors"
             href="https://talkatoo.com/pricing/">
            Sign Up Now
          </a>
        </div>
      </div>
    </div>
    <div class="flex flex-col pt-2 sm:pt-6 sm:mb-0">
      <span class="w-full mx-auto text-center text-sm text-white
                   opacity-0 hover:opacity-100 transition-opacity">
        version {{ versionNumber }}
      </span>

      <!-- <span class="w-full mx-auto text-center text-sm
                   opacity-0 hover:opacity-100 transition-opacity">
        <RouterLink to="/login/sso" class="inline-anchor text-white underline hover:text-white">
          login to sso
        </RouterLink>
      </span> -->
    </div>
    <!-- <img class="absolute w-full bottom-0 lg:-bottom-60 select-none pointer-events-none" style="user-drag:none; z-index:0;" src="@/assets/images/clouds-new-no-background.png" /> -->
  </div>
</template>

<script>
export default {
  name: "LoginTemplate",
  props: {
    title: String,
    hideFooter: Boolean
  },
  data () {
    return {
      versionNumber: __PACKAGE_VERSION,
    };
  },
  mounted () {
    document.body.classList.add('login');
  },
  beforeUnmount () {
    document.body.classList.remove('login');
  }
};
</script>

<style scoped>
.login-container {
  min-height: 27rem;
  width: 40rem;
  @apply bg-white;
  @apply border;
  @apply border-main-dark;
  @apply box-border;
  @apply block;
  @apply rounded-xl;
}

@media (max-width: 640px) {
  .login-container {
    width: calc(100vw - 2rem);
  }
}
</style>
<style>
body.login {
  background: url("@/assets/images/clouds-no-background-no-shadow.png"),
    linear-gradient(0.25turn, #0ab8da, #0087b3, #00475d, #003a4a);
  background-position: bottom ;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
<template>
  <div class="base-icon" :style="baseStyles" @click="action">
    <span v-if="icon">
      <i class="material-icons icon-pos" :style="iconStyles">
        {{ icon }}
      </i>
    </span>
    <span v-else-if="img">
      <img class="material-icons icon-pos" :style="iconStyles" :src="require(`Assets/images/${img}`)" />
    </span>
    <span v-else class="base-icon-text" :style="textStyles">
      {{ text }}
    </span>
    <div class="base-icon-background" :style="backgroundStyles" />
  </div>
</template>

<script>
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../tailwind.config.js";
const { theme } = resolveConfig(tailwindConfig);

export default {
  name: "BaseIcon",
  props: ["text", "icon", "img", "themeName", "themeSuffix", "themeColorMain", "themeColorBg", "diameter", "borderWidth", "fontSize"],

  computed: {
    // selects the suffix from the tailwindConfig theme to use, ie dark is default but it can be set to light, darker, etc
    suffix () {
      return this.themeSuffix ?? 'dark';
    },
    baseStyles () {
      let styles = `border-width: ${(this.borderWidth ?? this.diameter / 1.25) / 16}rem;
              height: ${this.diameter}rem;
              width: ${this.diameter}rem;`;
      if (this.themeColorMain) {
        styles += ` border-color: ${theme.colors[this.themeColorMain.name][this.themeColorMain.suffix]};`;
      }
      else {
        styles += ` border-color: ${theme.colors[this.themeName][this.suffix]};`;
      }
      return styles;
    },
    backgroundStyles () {
      let styles = `height: ${this.diameter}rem;
                    width: ${this.diameter}rem;`;
      if (this.themeColorBg) {
        styles += ` background-color: ${theme.colors[this.themeColorBg.name][this.themeColorBg.suffix]};`;
      } else {
        styles += ` background-color: ${theme.colors[this.themeName][this.suffix]};`;
      }
      return styles;
    },
    textStyles () {
      let styles = '';
      if (this.themeColorMain) {
        styles += `color: ${theme.colors[this.themeColorMain.name][this.themeColorMain.suffix]};`;
      }
      else {
        styles += `color: ${theme.colors[this.themeName][this.suffix]};`;
      }
      //manual font size
      if (this.fontSize !== undefined) {
        styles += ` font-size: ${this.fontSize}rem;`;
      }
      //else use relative text length vs diameter font size
      else {
        styles += `font-size: ${this.diameter / (1.25 * this.text.length)}rem;`;
      }
      return styles;
    },

    iconStyles () {
      let styles = '';
      if (this.themeColorMain) {
        styles += `color: ${theme.colors[this.themeColorMain.name][this.themeColorMain.suffix]};`;
      }
      else {
        styles += `color: ${theme.colors[this.themeName][this.suffix]};`;
      }
      //manual font size
      if (this.img !== undefined) {
        styles += ` width:55%;`;
      }
      if (this.fontSize !== undefined) {
        styles += ` font-size: ${this.fontSize}rem;`;
      }
      //else use relative size of diameter
      else {
        styles += ` font-size: ${this.diameter / 1.5}rem;`;
      }
      return styles;
    },
  },
  methods: {
    action () {
      this.$emit("action");
    },
  },
};
</script>

<style scoped>
.base-icon {
  @apply text-rare2-dark;
  @apply border-rare2-dark;
  @apply rounded-full;
  @apply mx-2;
  border: 0.125rem solid;
  position: relative;
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  text-align: center;
}

.base-icon-text {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  position: absolute;
  user-select: none;
}

.base-icon-background {
  @apply text-rare2-dark;
  @apply bg-rare2-dark;
  @apply opacity-25;
  @apply rounded-full;
  width: 3.125rem;
  height: 3.125rem;
  left: 50%;
  top: 50%;
  transform: translate(-40%, -50%);
  position: absolute;
}

.icon-pos {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
}
</style>
import axios from 'axios';
import { store } from '../store';

const SERVICE_URL = __API_URL_V7 + 'subscription/';

function GetSubscription () {
  //getting the current user's subscription
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'GET',
    url: SERVICE_URL + subscriptionId,
  }).then((resp) => {
    store.commit("set_subscription", resp.data);
    return resp;
  });
}

function PostSubscription(subscription){
  //getting the current user's subscription
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'POST',
    url: SERVICE_URL + subscriptionId,
    data: subscription
  }).then((resp) => {
    store.commit("set_subscription", resp.data);
    return resp;
  });
}

function UpdatePermissions (batch) {
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'POST',
    url: SERVICE_URL + subscriptionId + '/permissions',
    data: batch
  });
}

function GetSubscriptionUsers () {
  //getting the current user's subscription
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'GET',
    url: SERVICE_URL + subscriptionId + "/users",
    data: { subscription_id: subscriptionId }
  });
}

function GetPermissions (userId) {
  //getting the current user's subscription
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'GET',
    url: SERVICE_URL + subscriptionId + "/permissions/" + userId,
    data: { subscription_id: subscriptionId }
  });
}

function DeleteSubscriptionUser (userId) {
  //getting the current user's subscription
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'DELETE',
    url: SERVICE_URL + subscriptionId + "/users/" + userId,
  });
}

function ReactivateSubscription () {
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'POST',
    url: `${SERVICE_URL}${subscriptionId}/reactivate`,
  }).then((resp) => {
    store.commit("set_subscription", resp.data);
    return resp;
  });
}

function UpdateStripeSubscription (token) {
  const subscriptionId = store.getters.getSubscriptionId;
  const payload = {
    "stripe_token": token.id,
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL + subscriptionId,
    data: payload
  }).then((resp) => {
    store.commit("set_subscription", resp.data);
    return resp;
  });
}

function UpgradeSubscriptionProduct (products, stripeToken) {
  const subscriptionId = store.getters.getSubscriptionId;
  if (stripeToken == undefined)
    stripeToken = "";
  const payload = {
    "stripe_token": stripeToken.id,
    "products": products,
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL + subscriptionId + '/products',
    data: payload
  }).then((resp) => {
    store.commit("set_subscription", resp.data);
    return resp;
  });
}

function GetInvites (){
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'GET',
    url: SERVICE_URL + subscriptionId + '/invites',
    data: { 'subscription_id': subscriptionId }
  });
}

function CreateInvites (invitees) {
  const subscriptionId = store.getters.getSubscriptionId;
  const payload = {
    subscription_id: subscriptionId,
    invitees: invitees
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL + subscriptionId + '/invites',
    data: payload
  });
}


function ResendInvites (invites) {
  const subscriptionId = store.getters.getSubscriptionId;
  const payload = {
    invites: invites
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL + subscriptionId + '/invites/resend',
    data: payload
  });
}

function DeleteInvite (inviteId) {
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'DELETE',
    url: SERVICE_URL + subscriptionId + '/invites/' + inviteId,
  });
}

function GetStatementPage (page, pageSize) {
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'GET',
    url: SERVICE_URL + subscriptionId + `/statements?page_number=${page}&page_size=${pageSize}`,
  });
}
function GetStripeStatementPage (statementId, pageSize) {
  const subscriptionId = store.getters.getSubscriptionId;
  let startingAfter = "";
  if (statementId !== undefined){
    startingAfter = `&starting_after=${statementId}`;
  }
  return axios({
    method: 'GET',
    url: SERVICE_URL + subscriptionId + `/statements?page_size=${pageSize}${startingAfter}`,
  });
}

function ReactivateChargifyOnStripe (products,token) {
  const subscriptionId = store.getters.getSubscriptionId;
  let payload = {
    stripe_token:token,
    products: products,
    return_url: window.location.href
  };
  return axios({
    method: 'POST',
    url: `${SERVICE_URL}${subscriptionId}/reactivate/chargify`,
    data: payload,
  }).then((resp) => {
    store.commit("set_subscription", resp.data);
    return resp;
  });
}



export default {
  GetSubscription,
  PostSubscription,
  UpdatePermissions,
  GetSubscriptionUsers,
  GetPermissions,
  DeleteSubscriptionUser,
  ReactivateSubscription,
  UpdateStripeSubscription,
  UpgradeSubscriptionProduct,
  GetInvites,
  CreateInvites,
  ResendInvites,
  DeleteInvite,
  GetStatementPage,
  GetStripeStatementPage,
  // for chargify js auth
  ReactivateChargifyOnStripe,
};
<template>

  <div :class="['min-w-[17.75rem] kanban-card-wrapper relative transition-opacity duration-200',
                note.hide ? 'hidden-card' : 'pad-card',
                note.dupe ? 'dupe-card' : '',
                note.held ? 'held-card' : '',
                !visible ? 'invisible' : '',
  ]">
    <RouterLink :id="`card-${note.id}`" :to="linkPath"
                :class="['kanban-card invisible-anchor min-h-[6rem] transition-colors duration-200 font-roboto text-sm',
                         note.needs_attention ? 'card-needs-attention' : '',]"
                :data-noteid="note.id" :data-notegroup="note.groupIndex"
                draggable="true" @dragstart="dragstart" @dragend="dragend" @click="trackNoteNav"
    >
      <div v-if="note.needs_attention" class="absolute text-xl text-error material-icons right-1 top-0">
        error
      </div>
      <!-- {{ note.id }} -->
      <div class="flex">
        <span class="font-bold">
          <span v-if="note.updated" class="inline-block notif-sizing rounded-full bg-error"></span>
          {{ note.title ? note.title : "Untitled Note" }}
        </span>
      </div>
      <br />
      <!-- <strong v-if="note.preview">{{ note.preview }}<br /></strong> -->
      <span class="text-neutral-500">{{ recordedTime }}</span> <br />
      <span class="text-gray-alt">by </span>
      <a class="text-main-alt inline-anchor font-medium text-sm" :href="`mailto:${note.metadata.recorded_by_email}`" @click.stop>
        {{ note.metadata.recorded_by_name }}
      </a>

    </RouterLink>
  </div>
</template>

<script>
import NotesStateMap from "Modules/NoteStatesMap.js";
import MixpanelService from "Services/MixpanelService";

export default {
  name: "KanbanCard",
  emits: ['dragstart', 'dragEnd', 'dragStart'],
  components: {},
  props: {
    note: Object,
  },
  data () {
    let linkPath = '/notes';
    if (this.note) {
      let params = new URLSearchParams();
      if (this.note.needs_attention) {
        params.append('has_issue', 'true');
      }
      if (this.note.metadata.user_preferred_view) {
        params.append('view', this.note.metadata.user_preferred_view);
      }
      linkPath = params.size == 0 ? `/notes/${this.note.id}` : `/notes/${this.note.id}?${params.toString()}`;
    }

    return {
      visible: true,
      linkPath: linkPath,
    };
  },
  watch: {
    note: {
      handler: function (newVal, oldVal) {
        // this timeout gives the browser time to grab the dom element to drag with the cursor
        if (newVal.hide) window.setTimeout(() => { this.visible = false; }, 0);
        else window.setTimeout(() => { this.visible = true; }, 0);//this.visible = true;
        this.updateLinkPath();
      }, deep: true,
    }
  },
  computed: {
    recordedTime () {
      let ts = 0;
      if (NotesStateMap[this.note.state] === "Draft")
        ts = this.note.created_at * 1000;
      else
        ts = this.note.published_at * 1000;

      const date = Intl.DateTimeFormat(undefined, { month: "short", day: "numeric", year: "numeric", }).format(ts);
      const time = Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric" }).format(ts);
      return `${date} (${time})`;
    },
  },
  methods: {
    updateLinkPath () {
      if (!this.note) return '';
      let params = new URLSearchParams();
      if (this.note.needs_attention) {
        params.append('has_issue', 'true');
      }
      if (this.note.metadata.user_preferred_view) {
        params.append('view', this.note.metadata.user_preferred_view);
      }
      this.linkPath = params.size == 0 ? `/notes/${this.note.id}` : `/notes/${this.note.id}?${params.toString()}`;
    },
    dragstart (event) {
      // this catch case is here for the anchor tag in the card. the browser natively has dragging on anchor tags which interferes here
      if (event.target.id !== `card-${this.note.id}`) return;
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.setData('text/plain', event.target.id);

      this.$emit("dragStart", { groupIndex: this.note.groupIndex, noteId: this.note.id });
      event.stopPropagation();
    },
    dragend (event) {
      this.$emit("dragEnd");
    },
    trackNoteNav () {
      let subId = this.$store.getters.getSubscriptionId;
      MixpanelService.Track("AdminPortal:NoteNavigation", {
        subscription_id: subId,
        note_id: this.note.id,
        source: "kanban",
      });
    }
  }
};

</script>
<style scoped>
.kanban-card {
  display: block;
  @apply border;
  @apply rounded-md;
  @apply text-sm;
  @apply p-4;
  @apply border-neutral-light;
  @apply bg-white;
  @apply transition-transform;
}

.hidden-card {
  position: absolute;
  opacity: 0;
  max-height: 0px;
  max-width: 0px;
}

.held-card {
  opacity: 0.5;
}

.list-enter-active.held-card,
/* .list-move.hidden-card, */
/* .list-move.invisible, */
.list-enter-active.dupe-card,
.list-leave-active.dupe-card {
  transition-duration: 0.00ms;
}

.list-leave-from.dupe-card {
  transition-duration: 0.00ms;
  opacity: 0;
}


.kanban-card-wrapper.pad-card:not(:last-child) {
  @apply mb-1
}

.kanban-card {
  transition-property: background-color;
  transition-duration: 150ms;
  transition-timing-function: ease;
}

.kanban-card:hover {
  @apply z-50;
  @apply cursor-pointer;
  @apply bg-gray-100;
  /* @apply text-xs; */
}

.kanban-card.card-needs-attention {
  transition-property: background-opacity;
  transition-duration: 150ms;
  transition-timing-function: ease;
  @apply border-error;
  @apply bg-error;
  @apply bg-opacity-5;
}

.kanban-card.card-needs-attention:hover {
  @apply bg-opacity-10;
}


.notif-sizing {
  min-width: 0.75rem;
  min-height: 0.75rem;
  width: 0.75rem;
  height: 0.75rem;
}
</style>
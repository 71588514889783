<template>
  <div>
    <div class="blade-header flex bg-[#E7EBF2] lg:bg-[#DBE1EB] rounded-t-md lg:rounded-none">
      <slot>
      </slot>
    </div>
  </div>

</template>
<script>
export default {

};
</script>
<style>
.blade-header {
  min-height:var(--header-h);
}
</style>

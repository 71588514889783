const NotesService = require("Services/NotesService");
import NoteStatesMap from "@/modules/NoteStatesMap";

const SupportedTypes = {
  INT: 0,
  STRING: 1,
  BOOL: 2
};
const Operators = {
  EQ: 0, // ==
  NE: 1, //(!=),
  LT: 2, //(<)
  LTE: 3, //(<=)
  GT: 4, //(>),
  GTE: 5, //(>=),
  BT: 6,//(field BETWEEN $1 AND $2)
  IN: 7,// (field ANY($1) )
  CT: 8,//(`field LIKE '%' || $1 || '%'`) ).
};

const Sorting = {
  UNSORTED: 0,
  ASCENDING: 1,
  DESCENDING: 2,
};
const sorting = {
  unsorted: 0,
  ascending: 1,
  descending: 2,
};

const NoteViews = {
  ACTIVE: 0,
  ARCHIVED: 1,
  IN_PMS: 2,
  ALL: 3,
};


const filterMap = {
  'dp-timestamp': { type: SupportedTypes.INT },
  'ms-record-by': { type: SupportedTypes.INT },
  'ms-status': { type: SupportedTypes.INT },
};
/*
 * filter <T: bool|int|string>:  {
 *  field: string
 *  values: [T]
 * }
 */
class QueryManager {
  // initial details for filtering, sorting, page size
  constructor({ filters, sorting, paged, page, pageSize, asTable, noteView }) {
    //*custom filters and column sorting from the base table
    this.filters = filters ?? [];
    this._filters = [];
    this.sorting = sorting ?? [];
    this._sorting = [];
    this.textSearch = "";
    this.noteView =  noteView ?? NoteViews.ACTIVE;
    //* paging parameters from basetable pagination
    this.paged = paged || true;
    this.page = page || 0;
    this.pageSize = pageSize || 100;
    //* hashTable info and state management
    this._hashTable = {};
    this._prevHash = -1;
    //* special qualifier, handles filters with the needs attention "state" as is required in the table
    //* adds the correct set of filters to get the needs attention filter working as expected. see status parsing for asTable handling.
    this.asTable = asTable ?? false;
  }

  setFilters (filters) {
    if (filters) {
      this.filters = filters;
      this._parseFilters();
    }
  }
  setNotesView (noteView) {
    if (noteView === undefined) return;
    if (typeof noteView === 'string')
      this.noteView = NoteViews[noteView];
    else
      this.noteView = noteView;
  }
  setTextSearch (text) {
    if (text === undefined)
      text = "";
    this.textSearch = text;
  }
  setSorting (sorting) {
    if (sorting) {
      this.sorting = sorting;
      this._parseSorting();
    }
  }
  // Page is 1 indexed.
  setPage ({ paged, page, pageSize }) {
    this.paged = paged || this.paged;
    this.page = page || this.page;
    this.pageSize = pageSize || this.pageSize;
  }

  getNotesWith ({ filters, sorting, textSearch, paged, page, pageSize, noteView }) {
    this.textSearch = textSearch ?? this.textSearch;
    this.paged = paged ?? this.paged;
    this.page = page ?? this.page;
    this.pageSize = pageSize ?? this.pageSize;
    this.setNotesView(noteView);
    this.setFilters(filters);
    this.setSorting(sorting);
    return this._query();
  }
  getNotes () {
    return this._query();
  }
  _query () {
    const queryHash = this._hash();
    let row = this._checkTable(queryHash);
    if (row) {
      return Promise.resolve(row.val);
    }
      return NotesService.GetNoteFilteredList(this.paged, this.page - 1, this.pageSize, this._sorting, this._filters, this.textSearch)
      .then(x => {
        this._tablePut(queryHash, x);
        return x;
      });
  }
  // takes the table-formatted filters and converts them to the required format for a request
  _parseFilters () {
    this._filters = [];
    //first handle the case for which view of the notes to filter for. Active, Archived or In PMS
    switch (this.noteView) {
      case (NoteViews.ACTIVE):
        this._filters.push(createFilter("archived", [false], Operators.EQ, SupportedTypes.BOOL));
        this._filters.push(createFilter("state", [NoteStatesMap.indexOf("In PMS")], Operators.NE, SupportedTypes.INT));
        break;
      case (NoteViews.ARCHIVED):
      this._filters.push(createFilter("archived", [true], Operators.EQ, SupportedTypes.BOOL));
        this._filters.push(createFilter("state", [NoteStatesMap.indexOf("Needs Attention")], Operators.NE, SupportedTypes.INT));

        break;
      case (NoteViews.IN_PMS):
      this._filters.push(createFilter("archived", [false], Operators.EQ, SupportedTypes.BOOL));
        this._filters.push(createFilter("state", [NoteStatesMap.indexOf("In PMS")], Operators.EQ, SupportedTypes.INT));
        this._filters.push(createFilter("state", [NoteStatesMap.indexOf("Needs Attention")], Operators.NE, SupportedTypes.INT));
        break;
    }
    // special handlers for the basetable filters
    this.filters.forEach(filter => {
      switch (filter.id) {
        case ('dp-timestamp'):
          if (filter.value && filter.value.length === 2) {
            const start = new Date(filter.value[0]).setHours(0, 0, 0, 0) / 1000;
            const end = new Date(filter.value[1].getTime()).setHours(23, 59, 59, 999) / 1000;
            this._filters.push(createFilter("date_filter", [start, end], Operators.BT, SupportedTypes.INT));
          }
          break;
        case ('ms-record-by'):
          var ids = [];
          Object.keys(filter.value).forEach(key => { if (filter.value[key]) ids.push(filter._value[key]); });
          if (ids.length > 0) {
            this._filters.push(createFilter('user_id', ids, Operators.IN, SupportedTypes.INT));
          }
          break;
        case ('ms-status'):
          let states = [];
          let na = false;
          NoteStatesMap.forEach((state, index) => {
            if (filter.value[state] && state == "Needs Attention") { na = true; }
            else if (filter.value[state]) states.push(index);
          });
          // special case for handling the table rendering correctly
          if (this.asTable) {
            if (!na && states.length > 0) {
              // exclude Needs attention when NA filter is not specified
              this._filters.push(createFilter('issue_count', [0], Operators.EQ, SupportedTypes.INT));
            } else if (na && states.length == 0) {
              // notes where there are more than 0 unresolved issue
              this._filters.push(createFilter('issue_count', [0], Operators.NE, SupportedTypes.INT));
            }
          }
          if (states.length > 0) {
            this._filters.push(createFilter('state', states, Operators.IN, SupportedTypes.INT));
          }
          break;
        default:
          // switch case on this is not very pretty
          // todo support more operators
          if (filter.op) this._filters.push(createFilter(filter.field, filter.value, filter.op, filter.type));
          else if (Array.isArray(filter.value) && filter.value.length > 0) {
            if (typeof filter.value[0] == 'boolean') this._filters.push(createFilter(filter.field, filter.value, Operators.IN, SupportedTypes.BOOL));
            else if (typeof filter.value[0] == 'number') this._filters.push(createFilter(filter.field, filter.value, Operators.IN, SupportedTypes.INT));
            else if (typeof filter.value[0] == 'string') this._filters.push(createFilter(filter.field, filter.value, Operators.IN, SupportedTypes.STRING));
          }
          else if (typeof filter.value == 'boolean') this._filters.push(createFilter(filter.field, [filter.value], Operators.EQ, SupportedTypes.BOOL));
          else if (typeof filter.value == 'number') this._filters.push(createFilter(filter.field, [filter.value], Operators.EQ, SupportedTypes.INT));
          else if (typeof filter.value == 'string') this._filters.push(createFilter(filter.field, [filter.value], Operators.EQ, SupportedTypes.STRING));
          else console.warn("unexpected input for filters:", filter);
      }
    });
  }
  // takes the table-formatted sorting and converts it to the required format for a request
  _parseSorting () {
    this._sorting = [];
    switch (this.sorting.field) {
      case ('date'):
        this._sorting.push({ sort_by: 'date_filter', sort_desc: this.sorting.sortBy === Sorting.DESCENDING });
        break;
      case ('status'):
        this._sorting.push({ sort_by: 'state', sort_desc: this.sorting.sortBy === Sorting.DESCENDING }, { sort_by: 'date_filter', sort_desc: false });
        break;
      default:
        this._sorting.push({ sort_by: this.sorting.field, sort_desc: this.sorting.sortBy === Sorting.DESCENDING });
    }
  }
  // * Hash table functions below ⬇️
  invalidateCache () {
    Object.keys(this._hashTable).forEach(hash => { if (this._hashTable[hash]) this._hashTable[hash].ts = 0; });
  }

  _tablePut (hash, value) {
    this._hashTable[hash] = { val: value, ts: new Date().getTime() };

  }
  // attempted hit on the hash_table
  _checkTable (hash) {
    let row = this._hashTable[hash];
    if (row && timestampExpired(row.ts)) {
      this._hashTable[hash] = undefined;
      row = undefined;
    }
    return row;
  }
  _hash () {
    //lazy stringify for hash
    const str =
      JSON.stringify({
        f: this._filters,
        s: this._sorting,
        pd: this.paged,
        p: this.page,
        pl: this.pageSize,
        ts: this.textSearch,
        nv: this.noteView,
      });
    let hash = 32;
    for (let i = 0, len = str.length; i < len; i++) {
      let c = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + c;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

}
// helper for initializing the operand arrays
// returns object of the form { field:"", operand_int/bool/string:[], operator:""}
function createFilter (field, vals, op, type) {
  let filter = {};
  filter.field = field;
  switch (type) {
    case (SupportedTypes.INT):
      filter.operand_int = vals.map(x => parseInt(x));
      break;
    case (SupportedTypes.BOOL):
      filter.operand_bool = vals.map(x => !!x);
      break;
    case (SupportedTypes.STRING):
      filter.operand_string = vals.map(x => x.toString());
      break;
  }
  filter.operator = op;
  return filter;
}
// helper function returns true if ts is 5 minutes old
function timestampExpired (ts) {
  return (new Date().getTime() - (5 * 60 * 1000)) > ts;
}

export {
  QueryManager,
  Operators,
  SupportedTypes,
  Sorting,
  NoteViews
};



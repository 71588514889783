const FeedbackService = require("@/services/FeedbackService");
import BaseBox from 'Components/ui/BaseBox';
import BaseLoading from 'Components/ui/BaseLoading';
import FeedbackTemplate from 'Components/layout/FeedbackTemplate.vue';

// http://localhost:8080/feedback?survey_token=asdf&user_id=1234&score=9
const viewStates = {
  pendingScore: 0,
  pendingFeedback: 1,
  feedbackFinished: 2,
  error: 3,
};
export default {
  name: 'feedback',
  components: {
    BaseBox,
    BaseLoading,
    FeedbackTemplate
  },
  data () {
    let score = this.$route.query.score;
    let viewState = viewStates.pendingScore;
    if (score) {
      score = parseInt(score);
      if (![0, 1, 2, 3, 4, 5].includes(score)) {
        viewState = viewStates.pendingScore;
        score = undefined;
      } else {
        this.$nextTick(() => {

          this.currViewState = viewStates.pendingFeedback;
        });
          // this.score = score;
      }
    }
    return {
      token: this.$route.query.token,
      userId: this.$route.query.user,
      score: score,
      feedback: "",
      surveyId: undefined,
      feedbackSubmit: false,
      feedbackFinished: false,
      error: "",
      // page config data
      options: ["Extremely easy", "Very easy", "Somewhat easy", "Not very easy", "Not easy at all", "I'd rather not say"],
      viewStates: viewStates,
      currViewState: viewState
    };
  },
  created () {
    this.error = !this.token || !this.userId;
    if (this.error) return;
    FeedbackService.VerifySurveyToken(this.token, this.userId)
      .then((resp) => {
        this.surveyId = resp.data.survey_id;
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 400)
          this.error = "This survey was already answered!";
        if (err.status === 404) {
          // this.error = survey not found, something went wrong.
        }
        this.currViewState = viewStates.error;
      });
  },
  computed: {
    placeholder () {
      if (this.score === undefined) {
        return "";
      }
      if (this.score < 2) {
        return "What highlights of the process made it so easy?";
      }
      if (this.score < 4) {
        return "Can you tell us more about your experience, so we can do even better next time?";
      }
      return "Can you tell us more about your experience, so we can get it right the next time?";
    },
    disableSubmit () {
      return this.score === undefined
        || this.currViewState === viewStates.pendingScore
        || this.currViewState === viewStates.error;
    }
  },
  methods: {
    // takes in the score for a button and updates score
    selectScore (n) {
      this.currViewState = viewStates.pendingFeedback;
      this.score = n;
    },
    changeScore () {
      this.currViewState = viewStates.pendingScore;
      // timeout change score to reduce layout shift.transition components delay render changes by one render cycle it seems
      // Couldn't get next tick to work for me correctly :thinking_emoji:
      window.setTimeout(() => {
        this.score = undefined;
      }, 25);
    },

    submitFeedback () {
      this.feedbackSubmit = true;

      FeedbackService.CesSurveyResponse(this.surveyId, this.token, this.userId, this.score, this.options[this.score], this.feedback)
        .then((resp) => {
          this.currViewState = viewStates.feedbackFinished;
        })
        .catch((err) => {
          console.log(err);
        }).finally(() => {
          this.feedbackSubmit = false;
        });
    }
  }
};
import { Vue, createApp } from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import { store } from './store';
import ToastMessage from './plugins/ToastMessage/ToastMessage.js';
import MixpanelService from "Services/MixpanelService";
/***
* there are 4 separate routing flows:
*
* SUT check: intended to catch a user landing on the site with a single use token
*
* requiresAuth: user requests auth page, we validate they are logged in
*
* RequiresPermissions: subpath to requires auth, validate that a user has the required permissions to the page
*
* PreAuthPage: if a users hits this page and is logged in, they will bounce to their home screen
*
* note: we short-stop routing for provisioning users to direct them to download
***/

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresPerms = to.matched.some(record => record.meta.requiredPerms);
  const requiresFlag = to.matched.some(record => record.meta.requiresFlag);
  const PreAuthPage = to.matched.some(record => record.meta.PreAuthPage);
  const allowProvisioning = to.matched.some(record => record.meta.allowProvisioning);
  const userPerms = store.getters.getUserPerms;
  const featureFlags = store.getters.getFeatureFlags;

  if (requiresAuth) {
    if (store.getters.isLoggedIn) {
      // provisioning user short-stop
      if (store.getters.getBillingProvider === "chargify") {
        if (to.name == "Reactivate") next();
        else next({ name: "Reactivate" });
        return;
      }
      if (store.getters.getSubscriptionState === "provisioning") {
        !allowProvisioning ? next({ name: "GettingStarted" }) : next();
        return;
      }
      // * RequiresPermissions path
      if (!requiresPerms) {
        if (!requiresFlag) {
          next();
        } else if (to.meta.requiresFlag(featureFlags)) {
          next();
        } else {
          next({ name: "UserProfile" });
        }
      } else if (to.matched.reduce((prop, record) => prop && record.meta.requiredPerms(userPerms), true)) {
        if (!requiresFlag || to.meta.requiresFlag(featureFlags)) {
          next();
        } else {
          next({ name: "UserProfile" });
        }
      } else {
        // redirect, user doesn't have required perms
        next({ name: "UserProfile" });
      }
      // user not logged in for auth page. redirect after auth
    } else {
      store.getters.isSsoUser ? next({ name: "SSO" }) : next({ name: "Login" });
    }
    // pre-auth check
  } else if (PreAuthPage) {
    if (store.getters.isLoggedIn) {
      next({ name: "NotesEntry" });
    } else {
      next();
    }
  } else { // :pathMatch(.*)*
    next();
  }
});

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

function createAxiosResponseInterceptor () {
  const interceptor = axios.interceptors.request.use(config => {
    const token = store.getters.getToken;
    // adding token header to all requests to the talkatoo api for folks with cookies disabled
    if (token && token != '' && (

      config.url.match(__API_URL_V1) !== null ||
      config.url.match(__API_URL_V2) !== null ||
      config.url.match(__API_URL_V3) !== null ||
      config.url.match(__API_URL_V4) !== null ||
      config.url.match(__API_URL_V5) !== null ||
      config.url.match(__API_URL_V6) !== null ||
      config.url.match(__API_URL_V7) !== null
    )) {
      // config.headers['x-jwt'] = token;
      config.headers['grpc-metadata-x-jwt'] = token;
    }
    return config;
  }, error => {
    return Promise.reject(error);
  });

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    if (!error.response) {
      console.error(error);
      return Promise.reject(error);
    }
    switch (error.response.status) {
      case 400:
        break;
      case 401:
        // not redirecting on password update. the validate returns 401.
        // TODO re-eval this 401 handler
        if (["/profile", "/reset"].includes(window.location.pathname))
          break;
        // handle error
        if (store.getters.getCanAttemptRefresh) {
          //eject interceptor until request handled correctly
          axios.interceptors.response.eject(interceptor);
          store.dispatch("refresh")
            .then(() => {
              return axios.request(error.config);
            })
            .catch(() => {
              if (store.getters.isSsoUser) {
                router.push("/login/sso").catch(err => { console.log(err); });
              } else {
                router.push("/login").catch(err => { console.log(err); });
              }
            })
            .finally(() =>
              createAxiosResponseInterceptor()
            );
        } else {
          store.dispatch('logout');
        }
        break;
      case 403:
        router.push('/profile').catch(err => { console.log(err); });
        break;
      default:

        let mixpanelProperties;
        if (error?.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response);
          if (__ENV !== 'development' && __ENV !== 'test') {
            mixpanelProperties = {
              "data": error.response?.data,
              "status": error.response?.status,
              "headers": error.response?.headers,
              "failure_stage": "response",
              "env": __ENV,
            };
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            mixpanelProperties = {
              "data": error.request,
              "failure_stage": "request",
              "env": __ENV,
            };
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('undefined Error', error.message);
            mixpanelProperties = {
              "data": error.message,
              "failure_stage": "setup",
              "env": __ENV,
            };
          }
          // identifying the current user
          if ((__ENV !== 'development' && __ENV !== 'test')) {
            mixpanelProperties.subscription = store.getters.getSubscription;
            MixpanelService.Log("AdminPortal:VueError", mixpanelProperties);
          }
        }
    }
    return Promise.reject(error?.response);
  });
}
createAxiosResponseInterceptor();


// initialize our vue app
const app = createApp(App);
/*
  errorHandler logs errors to Mixpanel
  these will usually happen when a user has a weird state and the page cannot render properly
  don't log to mixpanel in test or development
*/
if (__ENV !== 'development' && __ENV !== 'test') {
  app.config.errorHandler = function (err, instance, info) {
    console.log("Err!!", err);
    MixpanelService.EngageLog({}); // default engage
    MixpanelService.Log("AdminPortal:VueError",
      {
        "info": info,
        "error_message": err.message,
        "error_name": err.name,
        "error_stack": err.stack,
        "env": __ENV,
        subscription: JSON.stringify(store.getters.getSubscription),
      });
    Promise.reject(err);
  };
}


// directive based on https://gist.github.com/AnalyzePlatypus/22ca31c8f953db92eedadfe930bce31f
// TODO (Adam) Breakout into directive file
app.directive('click-outside', {
  beforeMount: function (el, binding) {
    //mobile support functions
    el.eventSetDrag = function () {
      el.setAttribute('data-dragging', 'yes');
    };
    el.eventClearDrag = function () {
      el.removeAttribute('data-dragging');
    };
    //onclick event triggers on clicking anything without the click-outside directive
    el.eventOnClick = function (event) {
      const dragging = el.getAttribute('data-dragging');
      // Check that the click was outside the el and its children, and wasn't a drag
      if (!(el == event.target || el.contains(event.target)) && !dragging) {
        // call method provided in attribute value
        binding.value(event);
      }
    };
    document.addEventListener('click', el.eventOnClick);
  },
  unmounted: function (el) {
    document.removeEventListener('click', el.eventOnClick);
    el.removeAttribute('data-dragging');
  },
});

//interceptor to point to the mirage db
if (__ENV === 'test') {
  // removes all animations for e2e tests, keeps them for the pages deployment
  import('./tests/mock/server')
    .then(module => module.makeServer())
    .catch(err => console.error(err));
}

// initialize mixpanel
app.config.globalProperties.$http = axios;
app.config.unwrapInjectedRef = true;

app.use(store);
app.use(router);

app.use(ToastMessage);

app.mount('#app');

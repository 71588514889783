<template>
  <div class="base-table-container">
    <!-- Table Header -->
    <div class="flex flex-col-reverse xl:flex-row pl-3 pr-4 min-h-[68px]">
      <div v-if="selectTable || title || selectedCount" class="relative hidden xl:inline-flex">
        <div v-if="selectTable" class="my-auto flex mr-2">

          <ActionMenu
            class="h-[34px]"
            :checkVal="allSelected"
            @check-click="selectAll">
            <component :is="actionList.component" :props="actionList.props" v-bind="$attrs" />
          </ActionMenu>
        </div>
        <h3 v-if="title" class="p-4 hidden lg:flex">
          {{ title }}
        </h3>
        <div v-if="selectedCount > 0" class="relative my-auto text-sm whitespace-nowrap">
          <!-- ({{ selectedCount }} selected) -->
        </div>
      </div>

      <div class="flex">
        <div v-if="showHeaderFilters && headerFilters"
             class="flex flex-col w-full my-auto h-10">
          <div class="flex w-full">
            <div class="flex h-full p-1 flex-grow xl:flex-grow-0 bg-[#DBE1EB] bg-opacity-40 rounded-2xl">
              <div class="flex my-auto">
                <BaseIconDropdown id="filter-list"
                                  class="h-[34px] mr-1 w-28 min-w-[6rem] text-sm sm:text-base font-normal font-roboto "
                                  icon="filter_list"
                                  :notifCount="customFilterCount"
                                  square
                                  menuText="Filters"
                                  menuX="left-0"
                                  @click-out="resetFiltersDropdown">
                  <div class="px-4 w-80">
                    <div class="flex justify-between">
                      <span class="font-bold text-xl text-black my-2">Filters</span>
                      <a class="text-main underline" @click="clearHeaderFilters">
                        Clear Filters
                      </a>
                    </div>
                    <template v-for="(filter, index) in headerFilters.input" :key="index">
                      <BaseDatepicker v-if="filter.inputType == 'datepicker'" class="pb-2"
                                      :label="filter.label"
                                      :placeholder="filter.placeholder"
                                      :date="filter.value"
                                      @update:date="(range) => { filter.value = range; updateCustomFilters(filter); }" />

                      <BaseSelectList v-else-if="filter.inputType == 'select'" class="pb-2"
                                      :list="filter.value"
                                      :label="filter.label"
                                      :placeholderText="filter.placeholder"
                                      :errorMessage="filter.errorMessage"
                                      @select="updateCustomFilters(filter)"
                      />

                      <BaseMultiSelect v-else-if="filter.inputType == 'multi-select'" class="pb-2"
                                       :value="filter.value"
                                       :placeholderText="filter.placeholder"
                                       fullMenu
                                       showCount
                                       ellipsis
                                       showSearch
                                       @update:value="updateCustomFilters(filter)"
                      />
                    </template>
                    <div class="pb-2 flex justify-between text-base">
                      <div v-if="showSaveFilters" class="flex w-full justify-between">
                        <button v-if="!nameFilters"
                                class="w-[7.5rem] p-2 text-xs border-2 cursor-pointer
                                     border-gray-400 hover:border-gray-600 hover:bg-gray-100 transition-colors rounded-md"
                                @click="resetFiltersDropdown">
                          Done
                        </button>
                        <button v-if="!nameFilters"
                                class="w-[7.5rem] p-2 text-xs font-bold text-white bg-main-alt hover:bg-main transition-colors
                                        rounded-md" :disabled="customFilterCount == 0"
                                @click="toggleSaveFilters">
                          Name & Save
                        </button>
                        <div v-else class="flex w-full relative">
                          <input id="name-filter-input" v-model="filterNameValue"
                                 placeholder="Name Your Filter"
                                 class="h-10 w-full py-1 pl-4 pr-16 border-gray-400 border-2 rounded-md
                                          text-xs text-black "
                                 @keypress.enter="saveCustomFilters" />
                          <button class="absolute p-2 bg-rare2 rounded-lg text-white font-bold text-xs font-montserrat
                                          right-1 top-1/2 -translate-y-1/2 leading-normal" :disabled="customFilterCount == 0" @click="saveCustomFilters">
                            SAVE
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                </BaseIconDropdown>
              </div>
              <div class="flex flex-wrap flex-grow overflow-hidden space-x-1 max-h-[2.5rem] ">
                <button v-for="filter in presetFilters" :key="filter.id"
                        :class="['relative flex-grow basis-24 w-24 sm:w-48 xl:w-auto min-w-[6rem] max-w-[max-content] h-9 my-[2px] px-2 rounded-md ellipsis-overflow',
                                 filter.id === selectedPresetFilter ? 'bg-main-alt text-white' : 'bg-white text-gray-500 ']"
                        @click="e => { selectPresetFilters(e, filter); }">
                  <span class="block my-auto text-xs leading-normal ellipsis-overflow max-w-[30rem]">
                    {{ filter.tag }}
                  </span>
                </button>
              </div>
              <button class="relative flex items-center justify-end h-full w-16 min-w-[44px] xl:max-w-[4rem] rounded-r-md"
                      @click="togglePresetFiltersDropdown">
                <span class="material-icons text-black align-middle mr-2"> keyboard_arrow_down </span>
              </button>
            </div>
          </div>
          <!-- dropdown menu -->
          <div class="relative flex w-full">
            <div v-if="showPresetFilterDropdown"
                 v-click-outside="(e) => { togglePresetFiltersDropdown(false); }"
                 class="preset-filter-menu z-10 absolute min-h-16  w-full left-0 bottom-0 transform translate-y-full
                          bg-white border border-gray-200 rounded-md">
              <div class="flex flex-col font-roboto">
                <div v-if="!presetFilters || presetFilters.length == 0" class="px-2 py-4 text-center text-sm font-roboto">
                  <span class="text-lg font-bold"> You Have no Filters Saved! </span> <br /> Create a filter by opening the filter dropdown,<br /> selecting a filter and saving it.
                </div>
                <div v-for="filter in presetFilters" v-else :key="filter.id"
                     :class="['flex justify-between h-11 border-b border-gray-200',
                              filter.id === selectedPresetFilter ? 'bg-rare2-lighter' : '']">
                  <button :class="['text-left px-4 py-3 text-gray-500 hover:text-black transition-colors',
                                   filter.id === selectedPresetFilter ? '' : 'hover:bg-gray-200']"
                          style="width:calc(100% - 3rem);"
                          @click="(event) => { selectPresetFilters(event, filter) }">
                    <div class="my-auto ellipsis-overflow max-w-full text-sm font-normal">
                      {{ filter.name }}
                    </div>
                  </button>
                  <button class="w-12 h-full material-icons text-lg hover:bg-gray-200"
                          @click="(event) => { removePresetFilters(event, filter) }">
                    close
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Header form -->
      <div v-if="headerForm" class="flex">
        <form v-if="headerForm.input !== undefined" class="header-form relative inline-flex m-auto" @submit.prevent="$emit('formSubmit', headerForm.input.value)">
          <BaseInput
            v-model="headerForm.input.value"
            :label="headerForm.label"
            :name="headerForm.label"
            :placeholderText="headerForm.placeholderText"
            :errorMessage="headerForm.input.error"
            :pattern="headerForm.pattern"
            class="w-96"
            @input="formChange"
          />
          <button :class="[headerForm.input.error !== '' ? 'disabled' : '', 'btn-primary text-xs py-2 px-3  absolute transform -translate-x-1/4 translate-y-4 right-0']">
            {{ headerForm.buttonText }}
          </button>
        </form>
      </div>

      <div class="flex xl:ml-auto">
        <div v-if="selectTable" class="my-auto  xl:hidden flex mr-2">
          <ActionMenu
            class="h-[34px]"
            :checkVal="allSelected"
            @check-click="selectAll">
            <component :is="actionList.component" :props="actionList.props" v-bind="$attrs" />
          </ActionMenu>
        </div>

        <!-- filter -->
        <div v-if="showSearch" class="flex flex-grow my-2 py-1 xl:m-auto xl:mx-0 xl:flex-grow-0">
          <div class="inline w-full xl:ml-2 xl:w-[20rem] 3xl:w-[25rem]">
            <div class="relative flex flex-grow ml-auto my-auto">
              <input v-model="filterText" type="text"
                     class="filter-input w-full flex-grow pl-12"
                     :placeholder="searchPlaceholder"
                     @input="handleSearchFilter">
              <i class="material-icons magnifier text-xl"> search
              </i>
            </div>
          </div>
        </div>
      </div>
      <!-- header action icon -->
      <BaseIcon
        v-if="showHeaderIcon"
        class="m-auto"
        themeName="rare2"
        diameter="2.6"
        :text="headerIconText"
        @action="headerIconClick"
      />
    </div>
    <template v-if="!showLoading && data.length > 0">
      <!-- display for the table. shown in regular desktops  -->
      <table v-if="!mobileView" class="base-table table">
        <thead class="table-header-gradient">
          <tr>
            <th v-if="selectTable" class="w-12 whitespace-nowrap select-none" />
            <th v-for="col in colDef.col" :key="col.field"
                :class="col.colClass ?? '' + ' whitespace-nowrap select-none'"
                :style="col.colStyle" :role="col.sortable ? 'button' : undefined"
                @click="col.sortable && sortBy(col)">
              {{ col.displayName }}
              <i v-if="col.sortable && (col.sortBy === undefined || col.sortBy === 0)" class="material-icons w-3 text-sm my-auto">
                import_export
              </i>
              <i v-else-if="col.sortable" :class="`material-icons text-sm my-auto transition-transforms w-3 duration-200`"
                 :style="`${col.sortBy === 1 ? ' transform: matrix(1, 0, 0, -1.2, 0, 1);' : ''}`">
                sort
              </i>
            </th>
          </tr>
        </thead>
        <tbody id="table-body">
          <TransitionGroup name="list">
            <tr v-for="(row, index) in pageData" :id="'table-row-' + index"
                :key="row[colDef.keyField]" tabindex="0"
                :class="['border-b border-neutral-lighter last:border-none',
                         rowAction ? 'hover-row' : '', row._selected ? 'bg-rare2-lighter bg-opacity-50' : '',
                         animateChanges ? 'transition-all duration-300' : '']"
                :role="rowAction ? 'button' : undefined"
                @click="(e) => { if (rowAction) rowClick(row); }">
              <td v-if="selectTable" class="">
                <div class="block">
                  <input
                    :checked="row._selected"
                    type="checkbox"
                    class="tk-check h-5 my-auto"
                    @click="(e) => rowSelect(e, row, !row._selected)">
                </div>
              </td>
              <template v-for="(col, colIndex) in colDef.col" :key="col.field">
                <td :id="'table-row-item-' + colIndex" :class="col.colClass" :style="col.colStyle">
                  <component
                    :is="row.rowLink ? 'RouterLink' : 'div'"
                    :to="row.rowLink ? row.rowLink.to : ''"
                    :class="[row.rowLink !== undefined ? 'invisible-anchor flex flex-grow h-full w-full' : '']">
                    <div v-if="col.customDisplay" v-html="col.customDisplay(row[col.field])" />
                    <component :is="col.customComponent(row).component"
                               v-else-if="col.customComponent"
                               :props="col.customComponent(row).props" />
                    <div v-else-if="!col.isMenu && !col.isActionLink">
                      {{ row[col.field] }}
                    </div>

                    <!-- Kebab menu -->
                    <BaseKebab v-if="col.isMenu">
                      <div>
                        <a v-for="item in row[col.field]"
                           :key="item.menuId"
                           :class="item.menuClass + ' px-4 hover:no-underline hover:bg-gray-200 '"
                           @click="$emit(col.menuOptions.clickEvent, { row, event: item.menuId, })">
                          {{ item.menuText }}
                        </a>
                      </div>
                    </BaseKebab>
                    <!-- Action option -->
                    <a v-if="col.isActionLink && col.actionLinkText"
                       :id="'action-link-text-' + col.actionLinkText.toLowerCase()"
                       :class="[col.cellClass]"
                       :disabled="row.disabled"
                       @click="actionLinkClick(row, col)">
                      {{ col.actionLinkText }}
                    </a>
                    <button
                      v-if="col.isActionLink && col.actionLinkIcon"
                      id="action-link-icon"
                      :disabled="row.disabled"
                      @click="actionLinkClick(row, col)"
                    >
                      <i :class="[row.disabled ? 'text-gray-200' : 'text-gray-400 cursor-pointer',
                                  'material-icons center align-vertical select-none w-16']">
                        {{ col.actionLinkIcon }}
                      </i>
                    </button>
                  </component>
                </td>
              </template>
            </tr>
          </TransitionGroup>
        </tbody>
      </table>
      <!-- mobile table -->
      <div v-if="mobileView" id="table-body" class="flex flex-col mt-2">
        <div class="flex blue-gradient">
          <span v-if="title" class="text-white px-4 py-5 ml-2 text-base font-bold leading-5">
            {{ title }}
          </span>
        </div>
        <TransitionGroup name="list">
          <div v-for="(row, index) in pageData" :id="'table-row-' + index"
               :key="row[colDef.keyField]" :role="rowAction ? 'button' : undefined"
               :class="['relative flex  border-neutral-lighter border-b last:border-none px-2 text-base',
                        animateChanges ? 'transition-all duration-300' : '',
                        row._selected ? 'bg-rare2-lighter bg-opacity-50' : '',
                        selectTable ? 'pl-10 ' : '']"
               @click="rowAction ? rowClick(row) : null">
            <div v-if="selectTable" class="absolute left-5 top-6">
              <input
                :checked="row._selected"
                type="checkbox"
                class="tk-check h-5 my-auto"
                @click="(e) => rowSelect(e, row, !row._selected)">
            </div>
            <component
              :is="row.rowLink ? 'RouterLink' : 'div'"
              :to="row.rowLink ? row.rowLink.to : ''" :class="[row.rowLink !== undefined ? 'invisible-anchor flex flex-grow h-full w-full' : '']"
              @click="(e) => { if (row.clickEvent) { row.rowClickEvent(e, row); } }">
              <div class="overflow-x-auto ">
                <div v-for="(col, colIndex) in colDef.col" :id="'table-row-item-' + colIndex" :key="col.field">
                  <!-- regular display -->
                  <div v-if="col.customDisplay" v-html="col.customDisplay(row[col.field])" />
                  <component :is="col.customComponent(row).component"
                             v-else-if="col.customComponent"
                             :props="col.customComponent(row).props" />
                  <div v-else-if="!col.isMenu && !col.isActionLink" :class="col.cellClass" class="text-neutral-alt">
                    <span class="text-gray-400">{{ col.showDisplayName ? col.displayName + ' : ' : '' }}</span>
                    {{ row[col.field] }}
                  </div>
                  <!-- menu -->
                  <BaseKebab v-if="col.isMenu" class="absolute float-right top-1/2 right-0 transform -translate-y-1/2 mr-2" :style="'z-index:' + (data.length - index)">
                    <div>
                      <a v-for="item in row[col.field]"
                         :key="item.menuId"
                         :class="item.menuClass + ' px-4 hover:no-underline hover:bg-gray-200'"
                         @click="$emit(col.menuOptions.clickEvent, { row, event: item.menuId, })">
                        {{ item.menuText }}
                      </a>
                    </div>
                  </BaseKebab>
                </div>
                <div class="absolute top-3 right-3 transform">
                  <div v-for="col in colDef.col.filter(x => x.isActionLink)" :key="col.field">
                    <!-- anchor click -->
                    <a v-if="col.isActionLink && col.actionLinkText"
                       :id="'action-link-text-' + col.actionLinkText.toLowerCase()"
                       :disabled="row.disabled ?? undefined"
                       :class="[row.disabled ? 'opacity-50 text-main-light' : 'cursor-pointer', ' text-xl']"
                       @click="actionLinkClick(row, col)">
                      {{ col.actionLinkText }}
                    </a>

                    <button v-if="col.isActionLink && col.actionLinkIcon"
                            id="action-link-icon"
                            :disabled="row.disabled ?? undefined"
                            @click="actionLinkClick(row, col)">
                      <i :class="[row.disabled ? 'text-gray-200' : 'text-gray-400 cursor-pointer', ' material-icons text-4xl center align-vertical select-none']"
                         :disabled="row.disabled ?? undefined">
                        {{ col.actionLinkIcon }}
                      </i>
                    </button>
                  </div>
                </div>
              </div>
            </component>
          </div>
        </TransitionGroup>
      </div>
    </template>

    <!-- CTA for an empty table -->
    <div v-if="!showLoading && filteredData.length === 0" class="flex flex-col justify-center text-center pb-4 xl:pb-12">
      <template v-if="data.length === 0">
        <img v-if="imgLink" :src="imgLink" height="130" weight="130" class="m-auto" />
        <!-- spacer -->
        <div v-else class="mt-8 lg:mt-4"></div>
        <!-- provide a link when a link is possible otherwise some CTA text -->
        <div class="m-auto mb-8 lg:mb-4 ">
          <a v-if="callToAction && callToAction.action"
             class="mobile-link text-lg font-semibold"
             @click="callToAction.action">
            {{ callToAction === undefined ? "There is no data to display yet!" : callToAction.text }}
          </a>
          <span v-else class="text-lg font-semibold">
            {{ callToAction === undefined ? "There is no data to display yet!" : callToAction.text }}
          </span>
        </div>
        <!-- subtext for the CTA -->
        <span v-if="callToAction && callToAction.subtext"
              class="text-center px-4 pb-8 lg:pb-4 mx-auto text-sm max-w-3xl">
          {{ callToAction.subtext }}
        </span>
      </template>
      <!-- <template v-else> -->
      <span v-else class=" my-8 text-xl font-semibold">
        There is no data to show in your filter!
      </span>
      <!-- </template> -->
    </div>
    <div v-if="showLoading" class="py-2">
      <BaseLoading v-if="showLoading" />
    </div>
    <div v-if="!showLoading && pagination" class="w-full">
      <div class="flex justify-between px-4 bg-[#FAFBFC] rounded-b-lg border-t border-neutral-lighter whitespace-nowrap">
        <!-- pagination buttons -->
        <div class="py-2 pr-2 flex flex-col lg:flex-row justify-center lg:justify-start">
          <div class="flex">

            <button class="material-icons text-[#9BACB2] font-bold leading-none my-auto h-6 px-1" @click="paginationPrev">
              keyboard_arrow_left
            </button>
            <input v-model="pagination.pageTmp" type="number"
                   class="w-16 h-8 pl-4 text-center border border-[#9BACB2] rounded-lg" @change="selectPage(pagination.pageTmp)"
                   @keypress.enter="selectPage(pagination.pageTmp)"
            />
            <button class="material-icons text-[#9BACB2] font-bold leading-none my-auto h-6 px-1" @click="paginationNext">
              keyboard_arrow_right
            </button>
          </div>

          <div class="inline-block text-center mx-2 my-auto text-sm text-[#9BACB2]">
            Page {{ pagination.page }} of {{ pagination.totalPages }}
          </div>
          <div v-if="filteredData.length == 0" class="hidden xl:inline-block mx-2 py-1 my-auto lg:w-40 text-sm text-[#9BACB2]">
            showing 0 - 0 of 0
          </div>
          <div v-else-if="pagination.truePagination" class="hidden xl:inline-block mx-2 py-1 my-auto lg:w-40 text-sm text-[#9BACB2]">
            showing {{ pagination.start + 1 }} - {{ Math.min(pagination.totalCount, pagination.start + pagination.pageSize) }} of {{ pagination.totalCount }}
          </div>
          <div v-else class="hidden xl:inline-block mx-2 py-1 my-auto lg:w-40 text-sm text-[#9BACB2]">
            Showing {{ pagination.start + 1 }} - {{ filteredData.length < pagination.start + pagination.pageSize ? filteredData.length : pagination.end }} of {{ filteredData.length }}
          </div>
        </div>

        <div class="flex flex-col-reverse lg:flex-row justify-center lg:justify-start py-2 text-lg lg:text-xs whitespace-nowrap mr-2">
          <div class="inline-block text-center  mx-2 py-1 my-auto text-sm text-[#9BACB2]">
            Items
          </div>

          <select v-model="pagination.pageSize"
                  class="border text-sm h-8 w-18 bg-white pl-4
                       text-neutral border-[#9BACB2] rounded-lg"
                  @change="pageSizeSelect">
            <optgroup class="lg:text-xs">
              <option v-for="item in [15, 25, 50, 100]" :key="item"
                      :value="item">
                {{ item }}
              </option>
            </optgroup>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script  src="./BaseTable.js"></script>
<style src="./BaseTable.css" scoped></style>



import axios from 'axios';

const SERVICE_URL = __API_URL_V1 + "notes/";

//  Notes
// rpc CloseNote(NoteRequest) returns(Note)
function CloseNote (note_id) {
  return axios({
    method: 'POST',
    url: SERVICE_URL + `${note_id}/close?skip_delay=true`,
  });
}
// rpc GetNote(NoteRequest) returns(Note)
function GetNote (note_id) {
  return axios({
    method: 'GET',
    url: SERVICE_URL + note_id,
  });
}
// rpc GetNoteList(NoteListRequest) returns(NoteListResponse){
function GetNoteList (archived = false) {
  return axios({
    method: 'GET',
    url: SERVICE_URL + "list?archived=" + archived,
  });
}
// rpc GetFilteredNotes(NoteFilterRequest) returns (NoteFilterResponse){
function GetNoteFilteredList (paged = true, page_number = 0, item_count = 100, sort = [], filters = [], text_search = "") {
  return axios({
    method: 'POST',
    url: SERVICE_URL + "filter",
    data: {
      paged,
      page_number,
      item_count,
      sort,
      filters,
      text_search
    }
  });
}
// rpc UpdateNote(UpdateNoteRequest) returns(Note){
function UpdateNote ({ note_id, title, state, metadata, edited_frames, edited_transcript_string, transcript_scratch_pad }) {
  return axios({
    method: 'POST',
    url: SERVICE_URL + `${note_id}/update`,
    data: {
      title,
      state,
      metadata,
      edited_frames,
      edited_transcript_string,
      transcript_scratch_pad,
    },
  });
}
function ArchiveNote (note_id, archive) {
  return axios({
    method: 'POST',
    url: SERVICE_URL + `${note_id}/archive`,
    data: {
      archive
    }
  });
}

// Editor RPCs
// rpc GetEditor(GetEditorRequest) returns(EditorResponse){
function GetEditor (editor_id) {
  return axios({
    method: 'GET',
    url: SERVICE_URL + `/editor/${editor_id}`,
  });
}
// rpc GetEditorList(EditorListRequest) returns(EditorUserListResponse){
function GetEditorList (author_id) {
  return axios({
    method: 'Get',
    url: SERVICE_URL + `/editor/list/${author_id}`,
  });
}
// rpc GetAuthorList(google.protobuf.Empty) returns (AuthorListResponse){function GetEditorList (author_id) {
function GetAuthorList () {
  return axios({
    method: 'GET',
    url: SERVICE_URL + `editor/author/list`,
  });
}
// rpc AssignEditors(AssignEditorsRequest) returns(EditorListResponse){
function AssignEditors (user_ids) {
  return axios({
    method: 'POST',
    url: SERVICE_URL + "editors",
    data: { user_ids },
  });
}
// rpc RemoveEditors(RemoveEditorsRequest) returns(EditorListResponse){
function RemoveEditors (editor_ids) {
  return axios({
    method: 'POST',
    url: SERVICE_URL + "editors/remove",
    data: { editor_ids },
  });
}


function GetNoteAudio (note_id) {
  return axios({
    method: 'GET',
    url: SERVICE_URL + "audio/" + note_id,
    responseType: 'blob',
  });
}

function GetNoteIssues (note_id) {
  return axios({
    method: 'GET',
    url: SERVICE_URL + `${note_id}/issues?withResponses=true`,
  });
}

function CreateNoteIssue (note_id, type, description) {
  return axios({
    method: 'POST',
    url: SERVICE_URL + `${note_id}/issues/create`,
    data: {
      note_id,
      type,
      description,
    },
  });
}

function respondToNoteIssue (note_id, issue_id, message) {
  return axios({
    method: 'POST',
    url: SERVICE_URL + `${note_id}/issues/${issue_id}/create`,
    data: { message },
  });
}

function ResolveNoteIssue (note_id, issue_id) {
  return axios({
    method: 'POST',
    url: SERVICE_URL + `${note_id}/issues/${issue_id}/resolve`,
    data: {},
  });
}

function DeleteNoteIssue (note_id, issue_id) {
  return axios({
    method: 'DELETE',
    url: SERVICE_URL + `${note_id}/issues/${issue_id}`,
  });
}

// * LLM Records
function GetRecord (note_id) {
  return axios({
    method: 'GET',
    url: SERVICE_URL + `${note_id}/record`,
  });
}

function UpdateRecord ({ note_id, type_of_note, llm_name, llm_corrected_transcript, corrected_asr_transcript }) {
  let body = { note_id, type_of_note, llm_name, llm_corrected_transcript, corrected_asr_transcript };
  return axios({
    method: 'POST',
    url: SERVICE_URL + `${note_id}/record`,
    data: body
  });
}

function CreateNoteTemplate (note_id, template) {
  let body = template;

  return axios({
    method: 'POST',
    url: SERVICE_URL + `${note_id}/template`,
    data: body
  });
}

function GetNoteTemplate (note_id, template) {
  let body = {};
  return axios({
    method: 'GET',
    url: SERVICE_URL + `${note_id}/template/${template.id}`,
    data: body
  });

}
function simplifyTemplateToBody(template, removeRating) {
  let sections = template.general_template.sections;
  delete template.general_template.tags;
  delete template.general_template.template_metadata;
  delete template.general_template.show_original_transcript;
  for (var i = 0; i < sections.length; i++){
    delete sections[i].color;
    delete sections[i].template_metadata;
    delete sections[i].order;
    delete sections[i].copy_single;
    delete sections[i].copy_all;
    delete sections[i].manual_generation;
    if (removeRating) delete sections[i].ratings;
    delete sections[i].collapsed;
  }
  return template;
}
function UpdateNoteTemplate (note_id, template) {

  let body = simplifyTemplateToBody(structuredClone(template), true);
  return axios({
    method: 'POST',
    url: SERVICE_URL + `${note_id}/template/${template.id}`,
    data: body
  });
}


//broke this into its own function to strip the text from the template to just send ratings
function UpdateNoteTemplateRating (note_id, template) {

  let body = simplifyTemplateToBody(structuredClone(template), false);
  return axios({
    method: 'POST',
    url: SERVICE_URL + `${note_id}/template/${template.id}`,
    data: body
  });
}

function GetUserPreferences (userId) {
  return axios({
    method: 'GET',
    url: SERVICE_URL + `preferences/user/${userId}`,
  });
}

function UpdateUserPreferences (preferences) {
  let body = {
    id: preferences.id,
    user_id: preferences.user_id,
    language_code: preferences.language_code,
    text: preferences.text,
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL + `preferences/user/${preferences.user_id}`,
    data: body
  });
}

export {
  // Notes
  CloseNote,
  GetNote,
  GetNoteList,
  GetNoteFilteredList,
  UpdateNote,
  ArchiveNote,
  // editor
  GetEditor,
  GetEditorList,
  GetAuthorList,
  AssignEditors,
  RemoveEditors,
  GetNoteAudio,
  // Note Issues
  GetNoteIssues,
  CreateNoteIssue,
  respondToNoteIssue,
  ResolveNoteIssue,
  DeleteNoteIssue,
  // LLM Records
  GetRecord,
  UpdateRecord,
  // Note Templates
  CreateNoteTemplate,
  GetNoteTemplate,
  UpdateNoteTemplate,
  UpdateNoteTemplateRating,

  GetUserPreferences,
  UpdateUserPreferences,
};
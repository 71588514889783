<template>
  <div class="base-textarea-container relative">
    <label v-if="label" class="label" :for="name">{{ label }} </label>
    <textarea
      :id="name"
      v-model="inputValue"
      :name="name"
      :class="{
        'base-textarea': true,
        'input-error': errorMessage && errorMessage != '',
      }"
      :style="`resize:${resize? resize:'none'};`"
      :placeholder="placeholderText"
      :disabled="disabled ?? undefined"
      :required="!!required"
      :maxLength="maxLength"
      :autocomplete="autocomplete? autocomplete: 'on'"
      @input="action"
      @focus="focusIn"
      @blur="focusOut"
    />

    <p v-show="errorMessage" class="error-message absolute left-0 bottom-1">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
export default {
  name: "BaseTextArea",
  emits: ['input', 'update:modelValue', 'blur'],
  props: {
    modelValue: [String, Number],
    label: [String, undefined],
    name: [String, undefined],
    rows: [String, undefined],
    cols: [String, undefined],
    placeholderText: String,
    disabled: Boolean,
    errorMessage: String,
    required: Boolean,
    maxLength: Number,
    autocomplete: String,
    resize: String,
  },
  data () {
    return {
      inputValue: this.modelValue,
    };
  },
  methods: {
    action (e) {
      // this.modelValue = this.inputValue;
      // automatically handled though the v-model directive
      this.$emit("update:modelValue", e.target.value);
      this.$emit("input", e.target.value);
    },
    focusIn (e) {
      this.$emit("focus", e.target.value);
    },
    focusOut (e) {
      this.$emit("blur", e.target.value);
    }
  },
};
</script>
<style scoped>
.base-textarea-container .base-textarea {
  @apply px-4;
  @apply py-3;
  min-height: 3rem;
  @apply w-full;
  @apply h-full;
  @apply border-gray-600;
  font-size: 1.125rem;
  border: 1px solid;
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: inline-flex;
  transition: border 0.2s ease-out;
}

.base-textarea-container .base-textarea:focus {
  transition: border 0.2s ease-out;
  @apply border-main-dark;
}

.base-textarea-container .label {
  font-style: normal;
  font-weight: 500;
  @apply px-2;
  @apply ml-1;
  @apply absolute;
  @apply bg-white;
  font-size: 0.75rem;
  line-height: 0.75rem;
  /* identical to box height */
  transform: translate(0%, -50%);
}
</style>
<template>
  <FadeInWrapper>
    <div class="flex flex-col relative">
      <!-- heading -->
      <div :class="['flex justify-between pt-3 px-2 sm:px-3 bg-white border-b', changeMade ? 'lg:sticky top-0 z-10 mb-[-1px]' : 'border-white']">
        <div class="block flex-grow px-2 sm:px-0">
          <!-- <div class=" text-neutral-darker text-sm font-extrabold">
          Auto SOAP Template
        </div> -->
          <!-- title display and editing block -->
          <div :class="['block text-neutral-darker text-lg font-bold transition-[padding]',
                        editingTitle && (templateCopy.template_name.length >= 80 && pendingTitleChange) ? 'pb-5' : 'pb-0']">
            <div class="flex flex-grow w-full pr-4 ">
              <!-- regular title display -->
              <template v-if="!editingTitle">
                <div id="title" class="text-neutral-darker text-sm font-extrabold h-[24px]">
                  {{ templateCopy?.template_name ? templateCopy?.template_name : "Untitled Template" }}
                </div>
                <button v-if="false" class="material-icons text-gray-500 text-base ml-5 min-h-[24px] min-w-[24px] h-6 w-6 rounded-full hover:bg-gray-700 hover:bg-opacity-25 transition-colors"
                        @click="toggleTitleEditing">
                  edit
                </button>
              </template>
              <!-- editing title text area and mock div -->
              <div v-else id="title-edit-wrapper" class="relative">
                <textarea id="edit-title-input" v-model="templateCopy.template_name" maxlength="80"
                          :style="`height:${titleTarget.height}px; width:${titleTarget.width + 24}px; max-width:${titleTarget.max}px;`"
                          class="title-input min-h-[24px] break-all w-fit bg-transparent text-neutral-darker text-sm font-extrabold
                                rounded-md border-main-alt box-border lg:max-w-[40rem] overflow-clip"
                          @input="trimInput">
                </textarea>
                <button class="material-icons material-icons absolute -right-5 top-0 text-gray-500 text-base ml-2 min-h-[24px] min-w-[24px] h-6 w-6 rounded-full hover:bg-gray-700 hover:bg-opacity-25 transition-colors"
                        @click="toggleTitleEditing">
                  check
                </button>
                <!-- title editing feedback -->
                <div v-if="templateCopy.template_name.length >= 80 && pendingTitleChange" class="absolute block bottom-0 translate-y-full">
                  <div class="block leading-normal whitespace-nowrap error-message">
                    Titles can be 80 characters or less
                  </div>
                </div>
                <!-- an invisible div that fills the same space as the title, it gives the size that the text area should be rendered.
                    It is needed because text-area's don't fill space or grow/shrink/wrap to fit text now a normal div/contenteditable does-->
                <div id="title-target" class="invisible break-all absolute flex text-wrap text-sm font-extrabold min-h-[20px]"
                     :style="titleTarget.max == 0 ? '' : `width:${titleTarget.max}px;`">
                  <div>
                    {{ templateCopy.template_name }}
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="text-neutral text-xs font-roboto pt-2 pb-2">
            Personalize the way Talkatoo will report for each <br class="hidden sm:block" />
            item when not mentioned within the exam.
          </div>
        </div>

        <div class="hidden sm:block my-auto sm:my-0">
          <div class="block relative h-11  w-[120px] sm:w-[160px] mr-3">
            <TransitionGroup name="fade-pull">
              <div v-if="pendingChange"
                   class="absolute flex justify-end default-fade-duration w-full top-1/2">
                <BaseLoading class="transition-none transform scale-50 -translate-y-1/2 mr-8" />
              </div>
              <div v-else-if="changeMade" key="1" class="flex flex-grow justify-end default-fade-duration replace-fade-pull w-full my-auto">
                <button id="saveProfile" :disabled="pendingChange" class="whitespace-nowrap rounded-full py-3 px-4 sm:px-8
                        font-roboto text-sm font-semibold bg-main-alt hover:bg-main-dark transition colors text-white"
                        @click="saveChanges">
                  Save Changes
                </button>
              </div>
            </TransitionGroup>
          </div>
        </div>
      </div>

      <!-- template Blocks -->
      <div v-if="templateCopy" class="flex flex-col space-y-2 mt-4 px-2 sm:px-3">
        <!-- subjective -->
        <div :class="['template-container subjective-container', fields.subjective.show ? '' : 'closed']">
          <button class="template-header" @click="fields.subjective.show = !fields.subjective.show">
            <div class="flex my-auto">
              <div class="block h-1 my-auto mr-1">
                <div :class="['text-sm font-bold my-auto mr-1 transform transition-transform',
                              fields.subjective.show ? '' : 'quarter-z']">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                    <path d="M1.41421 0C0.523309 0 0.077142 1.07714 0.707107 1.70711L4.29289 5.29289C4.68342 5.68342 5.31658 5.68342 5.70711 5.29289L9.29289 1.70711C9.92286 1.07714 9.47669 0 8.58579 0L1.41421 0Z" fill="white" />
                  </svg>
                </div>
              </div>

              <div class="my-auto text-white font-bold sm:w-[18px]">
                Subjective
              </div>
            </div>

            <BubbleToggle class="" :selected="parseSectionSelection(templateCopy.sections[0].section_view)"
                          :selections="['subject', 'format_list_bulleted', 'format_list_numbered']"
                          icons
                          @click.stop
                          @selection-made="(index) => { selectView(index, templateCopy.sections[0]) }" />
            <BaseSwitch v-model:value="templateCopy.sections[0].show_section" class="my-auto" @update:value="(e) => { templateCopy.sections[0].hide_section = !e; }" @click.stop />
          </button>

          <Transition name="expand" :duration="300"
                      @before-enter="setEnterLeaveHeight"
                      @before-leave="setTransitionHeight"
                      @leave="setEnterLeaveHeight"
                      @enter="setTransitionHeight"
                      @after-enter="transitionDone"
                      @after-leave="transitionDone">
            <div v-if="fields.subjective.show" class="template-body">
              <div class="pt-1 pl-1 pb-3 text-neutral-alt font-roboto font-normal font-xs">
                Preview your Subjective below.
              </div>
              <div v-if="templateCopy.sections[0].section_view == 'PARAGRAPH'" class="body-preview-container">
                The patient presented for a wellness exam and vaccines. The patient is eating and drinking well with no vomiting, diarrhea, coughing, or sneezing. The owner reports no concerns.
              </div>
              <div v-else-if="templateCopy.sections[0].section_view == 'BULLETED_LIST'" class="body-preview-container">
                <ul>
                  <li>The patient presented for a wellness exam and vaccines.</li>
                  <li>The patient is eating and drinking well with no vomiting, diarrhea, coughing, or sneezing. </li>
                  <li>The owner reports no concerns.</li>
                </ul>
              </div>
              <div v-else-if="templateCopy.sections[0].section_view == 'NUMBERED_LIST'" class="body-preview-container">
                <ol>
                  <li>The patient presented for a wellness exam and vaccines.</li>
                  <li>The patient is eating and drinking well with no vomiting, diarrhea, coughing, or sneezing. </li>
                  <li>The owner reports no concerns.</li>
                </ol>
              </div>
            </div>
          </Transition>
        </div>
        <!-- objective -->
        <div :class="['template-container objective-container',
                      fields.objective.show ? '' : 'closed']">
          <button class="template-header" @click="fields.objective.show = !fields.objective.show">
            <div class="flex my-auto">
              <div class="block h-1 my-auto mr-1">
                <div :class="['text-sm font-bold my-auto mr-1 transform transition-transform',
                              fields.objective.show ? '' : 'quarter-z']">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                    <path d="M1.41421 0C0.523309 0 0.077142 1.07714 0.707107 1.70711L4.29289 5.29289C4.68342 5.68342 5.31658 5.68342 5.70711 5.29289L9.29289 1.70711C9.92286 1.07714 9.47669 0 8.58579 0L1.41421 0Z" fill="white" />
                  </svg>
                </div>
              </div>

              <div class="my-auto text-white font-bold sm:w-[18px]">
                Objective
              </div>
            </div>

            <BubbleToggle class="" :selected="previewChanges"
                          :selections="['Customize', 'Preview']"
                          @selection-made="(index) => { previewChanges = index }"
                          @click.stop />
            <BaseSwitch v-model:value="templateCopy.sections[1].show_section" class="my-auto" @update:value="(e) => { templateCopy.sections[1].hide_section = !e; }" @click.stop />
          </button>

          <template v-if="previewChanges === 1">
            <Transition name="expand" :duration="300"
                        @before-enter="setEnterLeaveHeight"
                        @before-leave="setTransitionHeight"
                        @leave="setEnterLeaveHeight"
                        @enter="setTransitionHeight"
                        @after-enter="transitionDone"
                        @after-leave="transitionDone">
              <div v-show="previewChanges === 1" v-if="fields.objective.show"
                   class="template-body default-fade-duration ease-in-out">
                <div class="pt-1 pl-1 pb-3 text-neutral-alt font-roboto font-normal font-xs">
                  Preview your Objective below.
                </div>
                <div :class="['preview-objective body-preview-container',
                              templateCopy.highlight_label ? 'bold-labels' : '']">

                  <template v-for="order in orderedOrder" :key="order.index">
                    <div v-if="isSubsectionVital(order) && subsectionDefaultVal(order).show_subsection">
                      <span>{{ subsectionDefaultVal(order).display_name }}:</span> {{ subsectionDefaultVal(order).value ? subsectionDefaultVal(order).value : '' }}
                    </div>
                    <div v-else-if="subsectionDefaultVal(order).show_subsection">
                      <span>{{ subsectionDefaultVal(order).display_name }}:</span> {{ subsectionDefaultVal(order).value ? subsectionDefaultVal(order).value : templateCopy.global_default }}
                    </div>
                  </template>

                </div>
              </div>
            </Transition>
          </template>
          <template v-else>
            <Transition name="expand" :duration="300"
                        @before-enter="setEnterLeaveHeight"
                        @before-leave="setTransitionHeight"
                        @leave="setEnterLeaveHeight"
                        @enter="setTransitionHeight"
                        @after-enter="transitionDone"
                        @after-leave="transitionDone">
              <div v-if="fields.objective.show" class="template-body default-fade-duration ease-in-out">
                <div class="flex flex-col sm:flex-row  w-full">
                  <div class="flex flex-grow sm:flex-auto py-4 px-3 mb-1 sm:mb-0 bg-white rounded border border-neutral-light sm:max-w-[232px] sm:mr-3">
                    <input id="toggle-highlight" v-model="templateCopy.highlight_label" name="toggle-highlight" type="checkbox" class="tk-check mr-3" />
                    <label for="toggle-highlight" class="font-roboto text-neutral-alt font-normal">
                      <span class="font-bold"> Highlight Labels</span>: bold
                    </label>
                  </div>
                  <div class="flex justify-between py-2 px-3 flex-grow bg-white rounded border border-neutral-light sm:w-[232px]">
                    <div class="my-auto pr-2">
                      <span class="font-roboto text-xs text-neutral-alt whitespace-nowrap">Global Default</span>
                    </div>
                    <!-- <BaseInput type="text" placeholderText="Within Normal Limits" /> -->
                    <input v-model="templateCopy.global_default" type="text" placeholder="Within Normal Limits" class="w-20 flex-grow pl-3  pr-1 text-xs font-roboto border border-[#9BACB2] rounded h-[34px]" />
                  </div>
                </div>
                <!-- Template Subsections -->
                <div class=" w-full my-3 inline-block text-center text-neutral-alt py-3 text-xs bg-white rounded border border-neutral-light">
                  <i>
                    <strong> Important Note: </strong>
                    Changing the label for body systems does not change the type<br /> of information. Labels are just a way to customize the wording of the title.
                  </i>
                </div>
                <div class="flex flex-col bg-white rounded border border-neutral-light py-1"
                     @drop.prevent="drop" @dragover.prevent="dragover"
                     @dragenter.prevent="dragenter" @dragleave.prevent="dragleave">
                  <TransitionGroup name="list">

                    <div v-for="order in orderedOrder" id="draggable-row" :key="order.id"
                         :class="['flex py-4 px-1 sm:pr-3  border-b last:border-none border-neutral-lighter transition-all',
                                  order.dragging ? 'opacity-25' : '',
                                  subsectionDefaultVal(order).hide_subsection ? 'bg-[#F5F6F8]' : '']"
                         :data-index="order.index" :data-id="order.id"
                         :draggable="allowDrag" @dragstart="dragstart" @dragend="dragend">

                      <div :class="['block my-auto mr-1 sm:mr-0',
                                    subsectionDefaultVal(order).hide_subsection ? 'opacity-25' : '']">
                        <span class="block material-icons text-neutral-light cursor-pointer selection-none"> drag_indicator </span>
                      </div>

                      <div :class="['flex flex-col sm:flex-row w-full',
                                    subsectionDefaultVal(order).hide_subsection ? 'opacity-25' : '']">
                        <!-- <input type="text" :placeholder="order.subsection"  class="min-w-[196px] mr-3 pl-3  pr-1 text-xs font-roboto border border-[#9BACB2] rounded h-[34px]" /> -->
                        <div class="tooltip-basic relative">
                          <BaseInput v-model="subsectionDefaultVal(order).display_name" type="text" :placeholderText="order.subsection" :maxLength="1000"
                                     :label="subsectionDefaultVal(order).display_name !== subsectionDefaultVal(order).subsection ? subsectionDefaultVal(order).subsection : ''"
                                     class="subsection-input sm:max-w-[204px] sm:mr-3 flex-grow mt-0 pb-0 mb-4 sm:mb-0  text-xs font-roboto" @blur="allowDrag = true"
                                     @focus="allowDrag = false" />
                          <div class="tooltip-text z-[1] text-center inline-block whitespace-nowrap">
                            Mention of <span class="lowercase">{{ order.subsection }}</span> <br class="block " /> will be matched  <br />with this subsection.
                          </div>
                        </div>
                        <div class="tooltip-basic relative flex-grow">
                          <BaseInput v-if="isSubsectionVital(order)" v-model="subsectionDefaultVal(order).value"
                                     type="text" label="Default Value" :maxLength="1000"
                                     :placeholderText="'(Blank)'"
                                     class="subsection-input flex-grow mt-0 pb-0 text-xs font-roboto"
                                     @blur="allowDrag = true"
                                     @focus="allowDrag = false" />
                          <BaseInput v-else v-model="subsectionDefaultVal(order).value" type="text" label="Default Value"
                                     :placeholderText="templateCopy.global_default == '' ? 'Within Normal Limits' : templateCopy.global_default" :maxLength="1000"
                                     class="subsection-input flex-grow mt-0 pb-0 text-xs font-roboto"
                                     @blur="allowDrag = true"
                                     @focus="allowDrag = false" />
                          <div class="tooltip-text z-[1] text-center inline-block whitespace-nowrap">
                            If no information about <span class="lowercase">{{ order.subsection }}</span>  is in the <br />note this value will be put in the subsection.
                          </div>
                        </div>
                        <!-- <BaseInput v-model="subsectionDefaultVal(order).display_name" type="text" :placeholderText="order.subsection" :maxLength="80" :label="subsectionDefaultVal(order).display_name !== subsectionDefaultVal(order).subsection ? subsectionDefaultVal(order).subsection : ''"
                                   class="subsection-input sm:max-w-[204px] sm:mr-3 flex-grow mt-0 pb-0 mb-4 sm:mb-0  text-xs font-roboto" @blur="allowDrag = true"
                                   @focus="allowDrag = false" />
                        <BaseInput v-model="subsectionDefaultVal(order).value" type="text" label="Default Value" :placeholderText="templateCopy.global_default == '' ? 'Within Normal Limits' : templateCopy.global_default" :maxLength="80"
                                   class="subsection-input flex-grow mt-0 pb-0 text-xs font-roboto" @blur="allowDrag = true"
                                   @focus="allowDrag = false" /> -->
                      </div>
                      <div class="flex flex-col-reverse sm:flex-row justify-between pl-2 pr-1">
                        <button :class="['block text-neutral-light hover:text-neutral flip-y mb-2 sm:mb-0 sm:mr-1 reset-transition',
                                         subsectionDefaultVal(order).hide_subsection ? 'opacity-25' : '']"
                                @click="setAsDefaultVal(order)">
                          <span class="block material-icons my-auto"> refresh</span>
                        </button>
                        <BaseSwitch :value="subsectionDefaultVal(order).show_subsection" class="sm:my-auto" @update:value="(e) => { toggleSubsection(e, order) }" />
                      </div>
                    </div>
                  </TransitionGroup>

                </div>
              </div>
            </Transition>
          </template>
        </div>

        <!-- Assessment -->
        <div :class="['template-container assessment-container', fields.assessment.show ? '' : 'closed']">
          <button class="template-header" @click="fields.assessment.show = !fields.assessment.show">
            <div class="flex my-auto">
              <div class="block h-1 my-auto mr-1">
                <div :class="['text-sm font-bold my-auto mr-1 transform transition-transform',
                              fields.assessment.show ? '' : 'quarter-z']">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                    <path d="M1.41421 0C0.523309 0 0.077142 1.07714 0.707107 1.70711L4.29289 5.29289C4.68342 5.68342 5.31658 5.68342 5.70711 5.29289L9.29289 1.70711C9.92286 1.07714 9.47669 0 8.58579 0L1.41421 0Z" fill="white" />
                  </svg>
                </div>
              </div>

              <div class="my-auto text-white font-bold sm:w-[18px]">
                Assessment
              </div>
            </div>

            <BubbleToggle class="" :selected="parseSectionSelection(templateCopy.sections[2].section_view)"
                          :selections="['subject', 'format_list_bulleted', 'format_list_numbered']"
                          icons
                          @click.stop
                          @selection-made="(index) => { selectView(index, templateCopy.sections[2]) }" />
            <BaseSwitch v-model:value="templateCopy.sections[2].show_section" class="my-auto" @update:value="(e) => { templateCopy.sections[2].hide_section = !e; }" @click.stop />
          </button>

          <Transition name="expand" :duration="300"
                      @before-enter="setEnterLeaveHeight"
                      @before-leave="setTransitionHeight"
                      @leave="setEnterLeaveHeight"
                      @enter="setTransitionHeight"
                      @after-enter="transitionDone"
                      @after-leave="transitionDone">
            <div v-if="fields.assessment.show" class="template-body">
              <div class="pt-1 pl-1 pb-3 text-neutral-alt font-roboto font-normal font-xs">
                Preview your Assessment below.
              </div>
              <div v-if="templateCopy.sections[2].section_view == 'PARAGRAPH'" class="body-preview-container">
                Apparently healthy adult with mild calculus.
              </div>
              <div v-else-if="templateCopy.sections[2].section_view == 'BULLETED_LIST'" class="body-preview-container">
                <ul>
                  <li>Apparently healthy adult.</li>
                  <li> mild calculus.</li>
                </ul>
              </div>
              <div v-else-if="templateCopy.sections[2].section_view == 'NUMBERED_LIST'" class="body-preview-container">
                <ol>
                  <li>Apparently healthy adult.</li>
                  <li> mild calculus.</li>
                </ol>
              </div>
            </div>
          </Transition>
        </div>

        <!-- plan -->
        <div :class="['template-container plan-container', fields.plan.show ? '' : 'closed']">
          <button class="template-header" @click="fields.plan.show = !fields.plan.show">
            <div class="flex my-auto">
              <div class="block h-1 my-auto mr-1">
                <div :class="['text-sm font-bold my-auto mr-1 transform transition-transform',
                              fields.plan.show ? '' : 'quarter-z']">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                    <path d="M1.41421 0C0.523309 0 0.077142 1.07714 0.707107 1.70711L4.29289 5.29289C4.68342 5.68342 5.31658 5.68342 5.70711 5.29289L9.29289 1.70711C9.92286 1.07714 9.47669 0 8.58579 0L1.41421 0Z" fill="white" />
                  </svg>
                </div>
              </div>

              <div class="my-auto text-left text-white font-bold sm:w-[18px] ">
                Plan
              </div>
            </div>

            <BubbleToggle class="" :selected="parseSectionSelection(templateCopy.sections[3].section_view)"
                          :selections="['subject', 'format_list_bulleted', 'format_list_numbered']"
                          icons
                          @click.stop
                          @selection-made="(index) => { selectView(index, templateCopy.sections[3]) }" />
            <BaseSwitch v-model:value="templateCopy.sections[3].show_section" class="my-auto" @update:value="(e) => { templateCopy.sections[3].hide_section = !e; }" @click.stop />
          </button>


          <Transition name="expand" :duration="300"
                      @before-enter="setEnterLeaveHeight"
                      @before-leave="setTransitionHeight"
                      @leave="setEnterLeaveHeight"
                      @enter="setTransitionHeight"
                      @after-enter="transitionDone"
                      @after-leave="transitionDone">
            <div v-if="fields.plan.show" class="template-body">
              <div class="pt-1 pl-1 pb-3 text-neutral-alt font-roboto font-normal font-xs">
                Preview your Plan below.
              </div>
              <div v-if="templateCopy.sections[3].section_view == 'PARAGRAPH'" class="body-preview-container">
                <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel lacinia sem. Mauris erat erat, viverra at ante ut, imperdiet rutrum sapien. Proin id lorem eget eros vulputate rhoncus eget vitae mauris. -->
                Discussed mild calculus with the owner and recommended dental homecare with brushing.
                Vaccines administered:
                Rabies 3-year SQ right hind,
                DHLPP SQ left hind,
                Bordetella intranasally,
                Nail trim performed.
                Dispensed a 6-month refill of Simparica Trio.
                The owner is to follow up with any questions or concerns.
              </div>
              <div v-else-if="templateCopy.sections[3].section_view == 'BULLETED_LIST'" class="body-preview-container">
                <ul>
                  <li>Discussed mild calculus with the owner and recommended dental homecare with brushing.</li>
                  <li>Vaccines administered:</li>
                  <li>Rabies 3-year SQ right hind.</li>
                  <li>DHLPP SQ left hind.</li>
                  <li>Bordetella intranasally.</li>
                  <li>Nail trim performed.</li>
                  <li>Dispensed a 6-month refill of Simparica Trio.</li>
                  <li>The owner is to follow up with any questions or concerns.</li>
                </ul>
              </div>
              <div v-else-if="templateCopy.sections[3].section_view == 'NUMBERED_LIST'" class="body-preview-container">
                <ol>
                  <li>Discussed mild calculus with the owner and recommended dental homecare with brushing.</li>
                  <li>Vaccines administered:</li>
                  <li>Rabies 3-year SQ right hind,</li>
                  <li>DHLPP SQ left hind,</li>
                  <li>Bordetella intranasally,</li>
                  <li>Nail trim performed.</li>
                  <li>Dispensed a 6-month refill of Simparica Trio.</li>
                  <li>The owner is to follow up with any questions or concerns.</li>
                </ol>
              </div>
            </div>
          </Transition>
        </div>
      </div>
      <Transition name="fade">
        <div v-if="changeMade || pendingChange" class="default-fade-duration flex lg:hidden fixed w-full bottom-0 left-0 py-3 z-10 bg-main-alt min-h-[68px]">
          <TransitionGroup name="fade-pull">
            <div v-if="pendingChange"
                 class="absolute flex default-fade-duration w-full top-1/2 left-1/2">
              <BaseLoading class="transition-none transform scale-50 -translate-y-1/2 -translate-x-1/2 mr-4" />
            </div>
            <div v-else-if="changeMade" key="1" class="flex flex-grow justify-end default-fade-duration replace-fade-pull w-full my-auto">
              <button id="saveProfile" :disabled="pendingChange"
                      class="rounded-full bg-white py-3 w-2/3 mx-auto text-main-darker font-roboto text-sm font-semibold"
                      @click="saveChanges">
                Save Changes
              </button>
            </div>
          </TransitionGroup>
        </div>
      </Transition>
    </div>
  </FadeInWrapper>

</template>
<script>
import BaseSwitch from '@/components/ui/BaseSwitch.vue';
import BubbleToggle from './BubbleToggle.vue';
import BaseLoading from 'Components/ui/BaseLoading.vue';
import BaseInput from 'Components/ui/BaseInput.vue';
import MixpanelService from 'Services/MixpanelService.js';
import FadeInWrapper from 'Components/layout/FadeInWrapper.vue';

import { toRaw } from 'vue';
export default {
  name: "SoapSettings",
  components: {
    BaseLoading,
    BubbleToggle, BaseSwitch, BaseInput, FadeInWrapper,
  },
  props: {
    template: Object,
    defaultValues: Array,
    loading: Boolean,
  },
  data () {
    let templateSubsections = [];
    if (this.template) {
      templateSubsections = this.template.default_values_order.map(x => x).sort((a, b) => { return a.index < b.index ? -1 : 1; });
    }
    let id = 0;
    let templateCopy = structuredClone(toRaw(this.template));
    templateCopy.default_values_order.forEach(x => { x.id = id++; });
    let fields = {
      subjective: { show: true },
      objective: { show: true },
      assessment: { show: true },
      plan: { show: true },
    };
    templateCopy.sections.forEach(x => {
      x.show_section = !x.hide_section;
      fields[x.name.toLowerCase()].show = x.show_section;
    });
    let rememberIds = templateCopy.default_values_order.map(x => x);
    let defaultValuesCopy = structuredClone(toRaw(this.defaultValues));
    defaultValuesCopy.forEach(x => x.show_subsection = !x.hide_subsection);
    return {
      pendingChange: false,
      changeMade: false,

      previewChanges: 0,
      fields: fields,
      templateSubsections: templateSubsections,
      templateCopy: templateCopy,
      defaultValuesCopy: defaultValuesCopy,

      // used to prevent too much swapping action going on with default value rows. without it dragenter triggers too often
      allowDrag: true,
      timeoutGuard: -1,
      draggingRow: undefined,
      // used to keep sorting after a template updates.
      rememberIds: rememberIds,

      // title editing
      editingTitle: false,
      // titleSaved: false,
      pendingTitleChange: false,
      titleSaveTimeout: -1,
      titleEndEditingTimeout: -1,
      titleTarget: { height: 0, width: 0, max: 0 },


    };
  },
  watch: {
    loading (newVal, oldVal) {
      if (newVal === false) {
        this.pendingChange = false;
        this.changeMade = false;
      }
    },
    templateCopy: {
      handler (newVal, oldVal) {

        // prevents registering a change when template navigation happens
        if (newVal.template_id === oldVal.template_id) {
          this.change();
        }
        this.templateCopy.template_name = this.templateCopy.template_name.replace('\n', '');
      }, deep: true,
    },
    defaultValuesCopy: {
      handler (newVal, oldVal) {
        this.change();
      }, deep: true,
    },
    template (newVal, oldVal) {
      this.copyTemplate();

    },
    defaultValues () {
      this.defaultValuesCopy = structuredClone(toRaw(this.defaultValues));
      this.defaultValuesCopy.forEach(x => x.show_subsection = !x.hide_subsection);
    },
  },
  computed: {
    orderedOrder () {
      let t = this.templateCopy.default_values_order.map(x => { return x; }).sort((a, b) => { return a.index < b.index ? -1 : 1; });
      return t;
    }
  },
  methods: {
    isSubsectionVital(subsection) {
      let vitals = ["Temperature","Heart Rate","Breathing","Mucus Membranes",
        "Capillary Refill Time","Weight","Body Condition Score","Pain Score"];
      if (vitals.indexOf(subsection.subsection) != -1) return true;
      return false;
    },
    change () {
      this.changeMade = true;
      if (this.editingTitle) this.toggleTitleEditing();
      // needed because we modify the tempate to add id, but api 500's on providing undefined fields
      let savableTemplate = structuredClone(toRaw(this.templateCopy));
      this.templateCopy.sections.forEach(x => {
        this.fields[x.name.toLowerCase()].show = x.show_section;
      });
      savableTemplate.default_values_order.sort((a, b) => { return a.index < b.index ? -1 : 1; });
      this.$emit("changeMade", { template: savableTemplate, defaultValues: this.defaultValuesCopy });
    },
    setAsDefaultVal (order) {
      let defaultVal = this.defaultValuesCopy.find(x => x.subsection == order.subsection);
      defaultVal.value = '';
    },
    copyTemplate () {
      let id = 0;
      this.templateCopy = structuredClone(toRaw(this.template));
      if (this.rememberIds.length === 0) {
        this.templateCopy.default_values_order.forEach(x => { x.id = id++; });
        this.rememberIds = this.templateCopy.default_values_order.map(x => x);
      } else {
        this.templateCopy.default_values_order.forEach(x => {
          x.id = this.rememberIds.find(y => { return y.subsection == x.subsection; }).id;
        });
      }
      this.templateCopy.sections.forEach(x => x.show_section = !x.hide_section);

    },
    // little function to use the order: {subsection:"", index:0} to find the related default value
    subsectionDefaultVal (order) {
      let defaultVal = this.defaultValuesCopy.find(x => x.subsection == order.subsection);
      return defaultVal;
    },
    toggleSubsection (e, order) {
      let defaultVal = this.defaultValuesCopy.find(x => x.subsection == order.subsection);
      defaultVal.hide_subsection = !e;
      defaultVal.show_subsection = e;
    },
    // translates the string view type to an integer
    parseSectionSelection (view) {
      const views = ["PARAGRAPH", "BULLETED_LIST", "NUMBERED_LIST"];
      return views.indexOf(view);
    },
    selectView (index, section) {
      this.change();
      const views = ["PARAGRAPH", "BULLETED_LIST", "NUMBERED_LIST"];
      section.section_view = views[index];
    },
    // * title editing functions
    toggleTitleEditing () {
      this.editingTitle = !this.editingTitle;
      if (this.editingTitle) {
        this.titleTarget.width = document.getElementById('title').getBoundingClientRect().width;
        this.titleTarget.height = document.getElementById('title').getBoundingClientRect().height;
        this.$nextTick(() => {
          this.titleTarget.max = document.getElementById('title-edit-wrapper').parentElement.getBoundingClientRect().width - 64;
        });
        this.$nextTick(() => document.getElementById("edit-title-input").select());
      } else {
        this.pendingTitleChange = false;
      }
    },
    // used for editing the title, resets any  notification messages
    trimInput (e) {
      if (this.titleSaveTimeout !== -1) window.clearTimeout(this.titleSaveTimeout);
      if (this.titleEndEditingTimeout !== -1) window.clearTimeout(this.titleEndEditingTimeout);
      // this.titleSaveTimeout = window.setTimeout(() => {
      //   this.pendingTitleChange = false;
      //   if (this.titleEndEditingTimeout !== -1) window.clearTimeout(this.titleEndEditingTimeout);
      //   this.titleEndEditingTimeout = window.setTimeout(() => {
      //   }, 3000);
      // }, 3000);
      // clean input, trim new line
      if (e.inputType === 'insertLineBreak' || e.data === null) {
        this.templateCopy.template_name = this.templateCopy.template_name.trim();
      }
      this.pendingTitleChange = true;
      let target = document.getElementById('title-target');
      if (target) {
        let target = document.getElementById('title-target').children[0];
        let rect = target.getBoundingClientRect();
        this.titleTarget.height = rect.height;
        this.titleTarget.width = rect.width;
      } else {
        console.warn("could not get the title mock to target for sizing");
      }

    },

    saveChanges () {
      this.pendingChange = true;

      if (this.editingTitle) this.toggleTitleEditing();
      // needed because we modify the tempate to add id, but api 500's on providing undefined fields
      let savableTemplate = structuredClone(toRaw(this.templateCopy));
      this.templateCopy.sections.forEach(x => {
        this.fields[x.name.toLowerCase()].show = x.show_section;
      });
      savableTemplate.default_values_order.sort((a, b) => { return a.index < b.index ? -1 : 1; });
      this.$emit('CommitChange', { template: savableTemplate, defaultValues: this.defaultValuesCopy });
      this.trackTemplateSave();
    },

    dragstart (e) {
      if (e.target.id !== 'draggable-row') return;
      // console.log("dragStart");
      e.dataTransfer.dropEffect = "move";
      e.dataTransfer.setData('text/plain', e.target.id);

      this.draggingRow = this.templateCopy.default_values_order.find(x => x.id == e.target.dataset.id);
      this.draggingRow.dragging = true;

      event.stopPropagation();
    },
    dragend (e) {
      // console.log(e, e.target.id);
      if (this.draggingRow === undefined) return;
      delete this.draggingRow.dragging;
      this.draggingRow = undefined;
    },

    drop (e) {
    },
    dragover (e) {
      //optional, drag enter is __ok__ but triggering this event more often seems to work best
      this.dragenter(e);
    },
    dragenter (e) {

      if (this.timeoutGuard !== -1) return;
      this.timeoutGuard = window.setTimeout(() => { this.timeoutGuard = -1; }, 100);

      if (this.draggingRow === undefined) return;
      if (e.target.classList.contains('list-move')) return;
      if (!e.target.dataset.id) return;
      if (e.target.dataset.id == this.draggingRow.id) return;

      let enteredRow = this.templateCopy.default_values_order.find(x => x.id == e.target.dataset.id);
      let floatIndex = enteredRow.index;
      let slice = [];
      // bump index of list elements between dragging and entered by 1
      if (this.draggingRow.index > enteredRow.index) {
        let floatIndex = enteredRow.index;
        slice = this.templateCopy.default_values_order.sort((a, b) => { return a.index < b.index ? -1 : 1; }).slice(enteredRow.index, this.draggingRow.index);
        slice.forEach(x => x.index++);
        this.draggingRow.index = floatIndex;
      } else {
        let floatIndex = enteredRow.index;
        // slice(this.draggingRow.index+1, enteredRow.index+1) to excluded the currently dragging row and include the entered row
        slice = this.templateCopy.default_values_order.sort((a, b) => { return a.index < b.index ? -1 : 1; }).slice(this.draggingRow.index + 1, enteredRow.index + 1);
        slice.forEach(x => x.index--);
        this.draggingRow.index = floatIndex;
      }

    },
    dragleave (e) {
      if (!e.target.dataset.id) return;
    },
    // this height transition will only work in specific cases. It doesn't handle nesting elements
    // before initial render
    setEnterLeaveHeight (el) {
      el.style.maxHeight = "0px";
    },
    // called one frame after the element is inserted.
    setTransitionHeight (el, done) {
      let height = el.clientHeight;
      for (var i = 0; i < el.childElementCount; i++) {
        height += el.children[i].clientHeight;
      }
      el.style.maxHeight = `${height}px`;
      setTimeout(done, 350);
    },

    transitionDone (el) {
      window.setTimeout(() => {
        el.style.maxHeight = '';
      }, 0);
    },
    trackTemplateSave () {
      let subId = this.$store.getters.getSubscriptionId;
      MixpanelService.Track("AdminPortal:CustomTemplateSave", {
        subscription_id: subId,
        note_id: this.templateCopy.id,
        type: this.templateCopy.template_type,
        title: this.templateCopy.template_name,
      });
    },
  },
};

</script>

<!-- unscoped style -->
<style>
.subsection-input {}

.subsection-input .base-input {
  @apply p-0;
  @apply rounded;
  height: 34px;
  @apply font-roboto;
  @apply text-black;
  @apply text-xs;
  @apply px-2;
}

.subsection-input .base-label {
  font-size: 11px;
  @apply font-roboto;
}
</style>


<style scoped>
.template-container {
  display: flex;
  flex-direction: column;
  @apply rounded-lg;
  @apply border;
}

.template-header {
  display: flex;
  @apply justify-between;
  height: 49px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  @apply px-3;
  @apply py-2;
  @apply border-b;
}

.closed .template-header {
  transition-property: border-radius;
  transition-delay: 250ms;
  transition-duration: 50ms;
  border-bottom: 0px;
  padding-bottom: 9px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.template-body {
  @apply p-2;
  @apply overflow-hidden;
  position: relative;
  transition-property: max-height, padding, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

.closed .template-body {
  @apply py-0;
}

.preview-objective {
  line-height: 23px;
  @apply flex;
  @apply flex-col;
}

.body-preview-container {
  @apply text-sm;
  @apply font-roboto;
  @apply font-normal;
  @apply text-neutral-darker;
  @apply bg-white;
  @apply px-5;
  @apply pt-5;
  @apply pb-6;
  @apply rounded;
  @apply border;
  @apply border-neutral-light;
}

.pinned-save {
  width: calc(100vw - (var(--sidebar-w) + 326px))
}

.subjective-container .template-header {
  background: linear-gradient(90deg, #5273E2 0%, #375A65 100%);
  @apply border-rare-alt;
}

.subjective-container.template-container {
  @apply border-rare-alt;
  @apply bg-pastel-purple-light;
}

.objective-container .template-header {
  background: linear-gradient(90deg, #0386B7 0%, #375A65 100%);
  @apply border-main-alt;
}

.objective-container.template-container {
  @apply border-main-alt;
  @apply bg-pastel-blue-light;
}

.assessment-container .template-header {
  background: linear-gradient(90deg, #1B9F81 0%, #375A65 100%);
  @apply border-rare2-dark;
}

.assessment-container.template-container {
  @apply border-rare2-dark;
  @apply bg-pastel-green-light;
}

.plan-container .template-header {
  background: linear-gradient(90deg, #C48D2B 0%, #375A65 100%);
  @apply border-secondary-dark;
}

.plan-container.template-container {
  @apply border-secondary-dark;
  @apply bg-pastel-yellow-light;
}


.reset-transition {
  transition-property: color, transform;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}

.reset-transition-click {
  transition-property: color, transform;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}


.bold-labels span {
  font-weight: 700;
}

.title-input {
  outline: none;
  resize: none;
  display: grid;
  /* display: inline-table; */
  cursor: text;
  background: rgba(1, 1, 1, 0);
  @apply transition-colors;
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}


.title-input::after {
  /* Note the weird space! Needed to prevent jumpy behavior */
  content: attr(data-replicated-value) " ";
  /* This is how textarea text behaves */
  white-space: pre-wrap;
  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

.tooltip-text.z-auto {
  z-index: auto;
}
</style>

<!-- custom component for displaying table title and text in the notes table.
     This isn't strictly required to be a component but it does protect from XSS attacks
-->
<script setup>
import { ref, watch, reactive } from "vue";
const props = defineProps({
  //required to use nested props objects in this case when passing props
  props: {
    type: Object,
    default () {
      return {
        title: '',
        text: '',
        notif: false,
      };
    },
  }
});
let title = ref(props.props.title);
let text = ref(props.props.text);
let notif = ref(props.props.notif);
if (title.value == '' && text.value == '') {
  title.value = "Untitled Note";
}

watch(props, (newVal, oldVal) => {
  if (oldVal) {
    // title = ref(props.props.title);
    // text = ref(props.props.text);
    notif = ref(props.props.notif);
  }
});
</script>
<template>
  <!-- notif for xl-->
  <div v-if="notif" class="hidden xl:block my-auto mr-2 notif-sizing rounded-full bg-error"></div>
  <!-- title text rendering -->
  <div id="title" class="flex flex-col px-4 xl:px-0 xl:hidden font-roboto text-neutral-darker font-bold">
    <div class="inline-block text-base font-bold pt-3 pb-1">
      <!-- notif for title-->
      <div v-if="notif && title" class="inline-block xl:hidden my-auto mr-2 notif-sizing rounded-full bg-error"></div>
      {{ title == '' ? "Untitled Note" : title }}<br />
    </div>
    <div class="clamp-line-count flex text-sm mb-1 xl:mb-0 text-neutral">
      <!-- notif for no title-->
      <div v-if="notif && !title" class="inline-block xl:hidden  my-auto mr-2 notif-sizing rounded-full bg-error"></div>
      {{ text }} ...
    </div>
  </div>
  <div class="hidden table-clamp-line-count  min-w-[10rem] overflow-hidden overflow-ellipsis font-roboto text-neutral-darker font-bold">
    {{ title == '' ? "Untitled Note" : title }}<br />
    <span class="text-neutral font-medium">{{ text }}{{ text.split(' ').length == 10 ? '...' : '.' }}</span>
  </div>
</template>

<style scoped>
/* limits the number */
.clamp-line-count {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table-clamp-line-count {}

@media (min-width: 1200px) {
  .table-clamp-line-count {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.notif-sizing {
  min-width:0.75rem;
  min-height:0.75rem;
  width:0.75rem;
  height:0.75rem;
}
</style>
import BaseInput from "Components/ui/BaseInput";
import BaseBox from 'Components/ui/BaseBox';
import BaseLoading from 'Components/ui/BaseLoading';
import LoginTemplate from 'Components/layout/LoginTemplate.vue';

export default {
  name: 'Login',
  components: {
    BaseBox,
    BaseInput,
    BaseLoading,
    LoginTemplate
  },
  data () {
    let landingData = this.$store.getters.getLandingData;
    let title = undefined; // default title is in html
    if (landingData.query && landingData.query.upgrade){
      title = "Sign in to upgrade your plan.";
    }
    return {
      email: '',
      pwd: '',
      loginError: '',
      emailError: '',
      pwdFieldError: '',
      loading: '',
      disableButton: false,
      title: title
    };
  },
  methods: {
    login () {
      if (this.email === '') {
        this.emailError = 'Field can\'t be empty';
      } else if (this.pwd === '') {
        this.pwdFieldError = 'Field can\'t be empty';
      } else {
        this.disableButton = true;
        this.$store.dispatch('login', {
          email: this.email,
          password: this.pwd
        })
          .then(res => {
            // could use this to show "logging in"
            return res;
          })
          .catch(err => {
            this.loginError = 'Incorrect email or password!';
          }).finally(() => {
            this.disableButton = false;
          });
      }
    },
    fieldChange () {
      this.emailError = '';
      this.pwdFieldError = '';
      this.loginError = '';
    },
    authenticating () {
      return this.$store.getters.authStatus.authenticating;
    }
  }
};
<template>
  <div class="base-dropdown">
    <button v-click-outside="hideMenu" class="base-dropdown-button my-auto flex-grow transition-opacity"
            :disabled="disabled ?? undefined"
            aria-haspopup="true"
            :aria-expanded="showMenu"
            @click="toggleMenu">
      <div class="dropdown">
        <span class="m-auto mr-0 center ">
          {{ menuText }}
        </span>
        <button v-if="!noArrow" :class="['material-icons mr-auto', showMenu ? 'arrow-up' : 'arrow-down']">
          keyboard_arrow_down
        </button>
      </div>
    </button>
    <Transition name="fade">
      <div v-show="showMenu"
           :class="['menu transform',
                    (menuX ? menuX : 'right-1/2 translate-x-1/2'),
                    (menuY ? menuY : ' translate-y-8')]">
        <slot></slot>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "BaseDropdown",
  props: {
    menuText: String,
    menuX: [String, undefined],
    menuY: [String, undefined],
    noArrow: Boolean,
    disabled: Boolean
  },
  data () {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleMenu () {
      if (this.disabled) return;
      this.showMenu = !this.showMenu;
    },
    hideMenu () {
      this.showMenu = false;
    },
  },
};
</script>

<style>
.base-dropdown {
  @apply relative;
  @apply flex;
}

.base-dropdown-button {
  @apply cursor-pointer;
}

.base-dropdown-button .dropdown {
  @apply flex;
  @apply select-none;
  @apply text-center;
  @apply ml-2;
  @apply h-full;
  transition: all 0.2s ease-out;
}

.base-dropdown-button .arrow-up {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: 0.2s ease-out;
}

.base-dropdown-button .arrow-down {
  transition: 0.2s ease-out;
}

.base-dropdown .menu {
  @apply flex;
  @apply flex-col;
  @apply z-10;
  @apply bg-white;
  @apply absolute;
  @apply shadow-md;
  @apply rounded-md;
  @apply py-2;
  @apply whitespace-nowrap;
  min-width: 7em;
  /* right: 50%; */
  @apply border;
  @apply border-gray-200;
}

.base-dropdown .menu * {
  @apply font-semibold;
  @apply px-4;
  @apply py-2;
}

.base-dropdown .menu *:hover {
  @apply bg-gray-200;
  @apply no-underline;
}
</style>
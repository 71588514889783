<template>

  <div class="h-[400px] w-[400px]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <g id="balloon-animals-background">
        <path d="M301.52,245.6a30.73,30.73,0,0,0-3.6-7.48,10.33,10.33,0,0,0-6.8-4.42c-2.75-.34-5.81,1.15-6.71,3.78-.39-5.93-1.23-12-4.2-17.15s-8.5-9.18-14.42-8.82c-5.59.34-10.37,4.49-13,9.42s-3.57,10.58-4.45,16.11c-2.11-2.6-6.59-1.79-8.8.74s-2.77,6-3.24,9.36l66,.69Z" style="fill:#ebebeb"></path>
        <path d="M479.8,165.15a30.37,30.37,0,0,0-3.6-7.48,10.33,10.33,0,0,0-6.8-4.42c-2.75-.34-5.81,1.15-6.71,3.78-.39-5.93-1.23-12-4.2-17.15s-8.5-9.18-14.42-8.82c-5.59.34-10.37,4.49-13,9.42s-3.57,10.58-4.45,16.11c-2.11-2.6-6.59-1.79-8.8.74s-2.77,6-3.24,9.36l66,.69Z" style="fill:#ebebeb"></path>
        <path d="M460.1,351.51c-1.73-4.6-3.48-9.26-6.38-13.23s-7.13-7.22-12-7.81-10.28,2-11.87,6.68c-.7-10.47-2.18-21.22-7.42-30.32s-15-16.23-25.51-15.59c-9.88.6-18.34,7.94-23,16.65s-6.31,18.7-7.85,28.48c-3.75-4.59-11.66-3.15-15.57,1.31s-4.89,10.68-5.73,16.55l116.74,1.22Z" style="fill:#ebebeb"></path>
        <path d="M117.76,208.29a38.92,38.92,0,0,0-4.55-9.43c-2.07-2.83-5.09-5.15-8.57-5.57s-7.32,1.45-8.46,4.76c-.5-7.47-1.56-15.13-5.29-21.62S80.17,164.85,72.7,165.31c-7,.43-13.08,5.67-16.43,11.88s-4.5,13.33-5.6,20.31c-2.67-3.28-8.31-2.25-11.1.93s-3.49,7.62-4.09,11.8l83.24.87Z" style="fill:#ebebeb"></path>
        <path d="M185.39,472.41c-1.63-4.36-3.3-8.78-6-12.54s-6.76-6.83-11.38-7.4-9.74,1.93-11.25,6.33c-.66-9.92-2.07-20.11-7-28.73s-14.24-15.39-24.17-14.78c-9.37.57-17.38,7.52-21.84,15.78s-6,17.73-7.44,27c-3.55-4.36-11-3-14.75,1.23S76.82,469.43,76,475l110.65,1.15Z" style="fill:#ebebeb"></path>
        <path d="M444.39,259.4a24.73,24.73,0,0,0-2.89-6,8.25,8.25,0,0,0-5.43-3.53,5.06,5.06,0,0,0-5.37,3c-.31-4.73-1-9.59-3.36-13.71s-6.79-7.34-11.53-7.05c-4.47.27-8.29,3.59-10.42,7.53s-2.85,8.46-3.55,12.88c-1.7-2.08-5.27-1.42-7,.59s-2.22,4.84-2.59,7.49l52.79.55Z" style="fill:#f5f5f5"></path>
        <path d="M268.79,315a24.48,24.48,0,0,0-2.89-6,8.25,8.25,0,0,0-5.43-3.54,5,5,0,0,0-5.37,3c-.31-4.74-1-9.6-3.36-13.71s-6.79-7.35-11.53-7.06c-4.47.27-8.29,3.59-10.42,7.53s-2.85,8.46-3.55,12.88c-1.7-2.08-5.27-1.42-7,.59s-2.22,4.84-2.59,7.49l52.79.55Z" style="fill:#f5f5f5"></path>
        <path d="M432.51,416.16a24.7,24.7,0,0,0-2.88-6,8.28,8.28,0,0,0-5.43-3.53,5,5,0,0,0-5.37,3c-.32-4.74-1-9.6-3.36-13.71s-6.8-7.34-11.53-7.05c-4.47.27-8.3,3.59-10.42,7.53s-2.86,8.46-3.56,12.88c-1.69-2.08-5.27-1.43-7,.59s-2.22,4.83-2.6,7.49l52.8.55Z" style="fill:#f5f5f5"></path>
        <path d="M279.2,483.33a24.48,24.48,0,0,0-2.89-6,8.23,8.23,0,0,0-5.43-3.54,5,5,0,0,0-5.36,3c-.32-4.74-1-9.6-3.36-13.71s-6.8-7.35-11.54-7.06c-4.47.27-8.29,3.59-10.42,7.53s-2.85,8.46-3.55,12.88c-1.69-2.07-5.27-1.42-7,.59s-2.21,4.84-2.59,7.49l52.79.55Z" style="fill:#f5f5f5"></path>
        <path d="M351.55,81.43c-1.34-3.57-2.7-7.19-4.95-10.27s-5.54-5.6-9.32-6.06-8,1.58-9.21,5.18c-.55-8.12-1.7-16.46-5.77-23.52s-11.66-12.6-19.79-12.11c-7.67.47-14.23,6.17-17.88,12.93s-4.9,14.51-6.1,22.1c-2.9-3.57-9-2.45-12.07,1S262.65,79,262,83.54l90.59.95Z" style="fill:#f5f5f5"></path>
        <path d="M67.64,364.36a24.13,24.13,0,0,0-2.89-6,8.23,8.23,0,0,0-5.43-3.54,5,5,0,0,0-5.36,3c-.32-4.74-1-9.6-3.36-13.71s-6.8-7.35-11.54-7.06c-4.46.27-8.29,3.59-10.42,7.53s-2.85,8.46-3.55,12.88c-1.69-2.08-5.27-1.42-7,.59s-2.21,4.84-2.59,7.49l52.79.55Z" style="fill:#f5f5f5"></path>
      </g>
      <g id="balloon-cat">
        <path d="M305,177.58s0,.15.07.46.1.79.17,1.38c.14,1.23.34,3,.6,5.29s.55,5.11.94,8.36.8,7,1.33,11.08c1,8.2,2.29,17.95,3.84,28.77s3.43,22.68,5.55,35.1,4.35,24.24,6.46,35,4.14,20.35,5.92,28.42,3.25,14.59,4.3,19.09c.51,2.24.91,4,1.19,5.19.12.58.22,1,.29,1.35s.09.47.09.47a4.4,4.4,0,0,1-.14-.45c-.08-.33-.2-.77-.35-1.34-.29-1.21-.72-2.94-1.28-5.17-1.11-4.48-2.64-11-4.46-19.06s-3.87-17.69-6-28.4-4.37-22.53-6.51-35S313,243.8,311.44,233s-2.79-20.59-3.74-28.8c-.5-4.1-.89-7.82-1.25-11.08s-.64-6.08-.86-8.38-.38-4.07-.49-5.3q-.08-.88-.12-1.38C305,177.74,305,177.58,305,177.58Z" style="fill:#263238"></path>
        <path d="M313.84,260.67c-22-6.06-43.11-23.39-58.54-40.13S230.65,180.61,233.64,158s19.44-43.61,41.54-49.1c23.67-5.88,49.48,6.95,63,27.22s12.81,51.11,4.14,73.91c-9.78,25.75-12.23,34.06-28.52,50.61" style="fill:#455a64"></path>
        <g style="opacity:0.30000000000000004">
          <path d="M318.52,117.05l-5.11-3c-5.22,11.1-6.49,23.82-4.82,36s6.19,23.79,12,34.58c5.62,10.39,10.89,24.44,18.81,33.2l8.84-23.78S323.62,120,318.52,117.05Z"></path>
        </g>
        <path d="M323.25,262.88a22.94,22.94,0,0,1-8.54-8.62l-1.58,2.7c-1.9,2.82-2.49,9.55-2.39,12.94,0,1,.31,2.17,1.25,2.45s1.92-.7,2.29-1.68a7.22,7.22,0,0,1,1.24-2.84,7.39,7.39,0,0,0,1.12,3.05,2.25,2.25,0,0,0,4.1-1.82,7.46,7.46,0,0,0-1.53-2.92,7.52,7.52,0,0,0,3.54,1.94c1.36.19,2.95-.65,3.05-2S324.47,263.64,323.25,262.88Z" style="fill:#455a64"></path>
        <path d="M317.47,256.84c.31.13-.4,1.91-2.42,2.82s-3.83.28-3.71,0,1.58-.35,3.19-1.11S317.16,256.65,317.47,256.84Z" style="fill:#263238"></path>
        <g style="opacity:0.2">
          <path d="M336.14,147.57a4,4,0,0,0-5.32,3.88l0-.15a15.23,15.23,0,0,0,2,6.93c1.29,1.94,4,3.13,6,2s2.35-4.11,1.85-6.46C340.11,151.13,338.67,148.44,336.14,147.57Z"></path>
        </g>
        <path d="M254.43,124.13s-.12.18-.4.48l-1.22,1.29-.89.92c-.32.34-.63.74-1,1.15-.7.84-1.55,1.75-2.34,2.88l-1.28,1.73c-.44.61-.84,1.28-1.28,1.95-.92,1.33-1.71,2.87-2.6,4.45a64.28,64.28,0,0,0-4.37,10.94,63.07,63.07,0,0,0-2.08,11.6c-.09,1.81-.22,3.53-.15,5.14,0,.81,0,1.59,0,2.34s.12,1.46.17,2.15c.07,1.37.31,2.59.46,3.67.08.54.14,1.05.23,1.51s.19.87.27,1.25c.14.7.26,1.27.35,1.74a2.36,2.36,0,0,1,.09.62,2.76,2.76,0,0,1-.21-.59c-.12-.46-.28-1-.47-1.72-.1-.38-.21-.79-.32-1.24s-.18-1-.28-1.51a37.31,37.31,0,0,1-.56-3.69q-.11-1-.21-2.16c-.07-.75-.06-1.54-.09-2.36-.1-1.63,0-3.36.09-5.2a56.8,56.8,0,0,1,6.52-22.76c.91-1.59,1.73-3.13,2.68-4.46.45-.67.86-1.35,1.32-1.95l1.33-1.72a34.63,34.63,0,0,1,2.42-2.84c.37-.4.7-.79,1-1.13l.94-.88,1.31-1.21A2.83,2.83,0,0,1,254.43,124.13Z" style="fill:#fff"></path>
        <path d="M271.75,115.23c.07.16-2.07,1.26-4.77,2.46s-5,2.06-5,1.9,2.06-1.26,4.77-2.47S271.68,115.07,271.75,115.23Z" style="fill:#fff"></path>
        <g style="opacity:0.1">
          <path d="M259.34,126.21c-19.81,17.81-22.42,42.84-10.92,66.88,3.66,7.66,9.19,14.83,17.66,17.47a17.61,17.61,0,0,0,6.92.76c11.81-1.09,15.89-12.11,15.34-21.81-.4-7.17-2.85-14.06-4.14-21.13s-1.26-14.89,2.77-20.83,11.75-9.69,10.92-17.81a12.58,12.58,0,0,0-6.3-9.28,20.61,20.61,0,0,0-8.53-2.75,29.52,29.52,0,0,0-21.28,6.57Z" style="fill:#fff"></path>
        </g>
        <path d="M369.66,176c.14,0-7.43,41.12-16.91,91.76s-17.27,91.69-17.41,91.67,7.42-41.11,16.9-91.77S369.52,176,369.66,176Z" style="fill:#263238"></path>
        <path d="M359,226.66c-19.55-17.74-32.38-46.73-39.6-72.12s-4.64-54.2,10.26-76,42.91-35,68.65-29.1c27.57,6.29,47.57,33.06,51,61.13S435.87,170.15,415,189.21c-23.59,21.53-30.46,28.85-56,37.45" style="fill:#FFB300"></path>
        <path d="M367.6,233.87c-2.47-4.05-4.3-8.63-4.33-13.38l-3,2c-3.44,1.92-7.57,8.58-9.24,12.14-.49,1-.82,2.41,0,3.19s2.36.28,3.25-.54,1.62-1.92,2.77-2.29a8.54,8.54,0,0,0-.43,3.74,2.61,2.61,0,0,0,5.2.26,8.7,8.7,0,0,0-.07-3.83,8.81,8.81,0,0,0,2.65,3.87c1.31.9,3.4.87,4.21-.49S368.46,235.3,367.6,233.87Z" style="fill:#FFB300"></path>
        <path d="M364.77,224.6c.26.3-1.41,1.78-4,1.66s-4.1-1.71-3.82-2,1.82.47,3.89.52S364.55,224.24,364.77,224.6Z" style="fill:#263238"></path>
        <g style="opacity:0.2">
          <path d="M435.24,146.79a4.49,4.49,0,0,0-4.93-1c-3.2,1.26-4.6,4.33-6,7.17A99.39,99.39,0,0,1,368,202c-3.52,1.19-7.75,2.92-8.17,6.6-.3,2.74,1.91,5.25,4.49,6.22a14.39,14.39,0,0,0,7.11.63,18.93,18.93,0,0,0,6.94-2.47c25.28-14.89,41.28-32.34,56.11-58.07C435.94,152.4,437.3,149,435.24,146.79Z"></path>
        </g>
        <g style="opacity:0.2">
          <path d="M441.21,121.29a4.61,4.61,0,0,0-7.54,1.23l.05-.17c-1,2.51-1.88,5.54-1.56,8.21s2.52,5.34,5.21,5.19,4.58-3,5.29-5.72C443.45,127,443.37,123.5,441.21,121.29Z"></path>
        </g>
        <path d="M368.9,54.32a3,3,0,0,1-.66.29l-1.94.69-1.4.49c-.51.19-1,.43-1.63.68-1.16.5-2.52,1-3.92,1.75l-2.23,1.12c-.78.4-1.54.89-2.35,1.35-1.64.9-3.27,2.07-5,3.25a67.46,67.46,0,0,0-18.46,20c-1,1.82-2.08,3.54-2.85,5.24-.4.85-.83,1.65-1.16,2.45s-.65,1.57-1,2.31c-.64,1.46-1,2.85-1.45,4-.19.6-.39,1.15-.55,1.67s-.25,1-.37,1.44q-.33,1.19-.54,2a2.61,2.61,0,0,1-.24.69,3,3,0,0,1,.1-.72c.11-.54.24-1.21.41-2,.09-.45.2-.93.31-1.46s.32-1.09.5-1.7a40,40,0,0,1,1.36-4.11l.9-2.35c.33-.82.75-1.63,1.14-2.49.75-1.73,1.78-3.47,2.82-5.33A70.86,70.86,0,0,1,339,72.48a71.63,71.63,0,0,1,10.38-9.09c1.77-1.17,3.43-2.33,5.1-3.22.82-.45,1.6-.94,2.39-1.32l2.27-1.09c1.43-.73,2.81-1.2,4-1.67.6-.23,1.15-.45,1.66-.63l1.43-.42,2-.57A3,3,0,0,1,368.9,54.32Z" style="fill:#fff"></path>
        <path d="M391.48,54.16c0,.2-2.8.22-6.23.06s-6.21-.47-6.2-.67,2.8-.23,6.23-.06S391.49,54,391.48,54.16Z" style="fill:#fff"></path>
        <g style="opacity:0.1">
          <path d="M372.89,59c-29.81,8.08-45.59,32.62-46.25,63.5-.22,9.85,1.76,20.16,9.14,27.32a20.45,20.45,0,0,0,6.76,4.4c12.8,5.05,22.78-4.23,27.28-14.54,3.34-7.64,4.4-16.05,6.77-24s6.46-16.06,13.75-20.11,17.22-3.88,20.6-12.72a14.56,14.56,0,0,0-1.67-12.9,23.78,23.78,0,0,0-7.38-7.3c-7.48-4.84-16.73-6.11-25.46-4.33Z" style="fill:#fff"></path>
        </g>
        <path d="M445.09,445.67a2.1,2.1,0,0,0-1.06-.94,1.54,1.54,0,0,0-1.71,1.09,4.51,4.51,0,0,0,0,2.22,12.05,12.05,0,0,0-8.81-4.67,18,18,0,0,0-9.87,2.33c-2.45,1.31-4.66,3-7.18,4.22a10.83,10.83,0,0,1-6.86,1l-.7-.22c-5.48-2.06-10.2-6.47-11.87-12.08-1.62-5.45.23-11.19,1-16.82,1.21-8.44,5.07-31.68-.34-44.31l-10.86,29.9c-5.6,11.14-8.22,20.67-7.7,33.13a38.37,38.37,0,0,0,18.47,30.65c11.56,7,32.36,9.78,43.4-.63l.05-.06a16.13,16.13,0,0,0,1.41-1.56c2.31-2.55,4.05-4.68,4.16-8.44,0-.12,0-.25,0-.37C447.94,455.25,447.87,449.84,445.09,445.67Z" style="fill:#FFB300"></path>
        <path d="M430.29,458s0,.12-.17.31a8.17,8.17,0,0,1-.6.83,12.72,12.72,0,0,1-2.87,2.54,18.92,18.92,0,0,1-5.33,2.38,53.45,53.45,0,0,1-7.24,1.41v-.49a50.21,50.21,0,0,1,7.49,2.13,53.29,53.29,0,0,1,5.17,2.26l.7.35-.77.13a51.94,51.94,0,0,1-14.65.35c-1.82-.22-3.26-.49-4.25-.71-.49-.1-.86-.21-1.12-.27a1.25,1.25,0,0,1-.38-.13,12.55,12.55,0,0,1,1.54.22c1,.17,2.43.38,4.24.56a55,55,0,0,0,14.53-.53l-.07.48a54.75,54.75,0,0,0-5.12-2.22,52.17,52.17,0,0,0-7.41-2.13l-1.4-.3,1.42-.19a56,56,0,0,0,7.2-1.33,19.77,19.77,0,0,0,5.28-2.23A15.07,15.07,0,0,0,430.29,458Z" style="fill:#263238"></path>
        <path d="M343.25,397.16a26.55,26.55,0,0,0-9.87,30c1.46,4.67,4,9.84,1.5,13.62-1.19,1.78-3.38,3-4.53,4.78a4,4,0,0,0,2.08,5.83A7.42,7.42,0,0,0,338,451c7.19-2.9,11.61-8.37,15.66-13.73q11.16-14.77,21-30.24Z" style="fill:#FFB300"></path>
        <g style="opacity:0.30000000000000004">
          <path d="M343.25,397.16a26.55,26.55,0,0,0-9.87,30c1.46,4.67,4,9.84,1.5,13.62-1.19,1.78-3.38,3-4.53,4.78a4,4,0,0,0,2.08,5.83A7.42,7.42,0,0,0,338,451c7.19-2.9,11.61-8.37,15.66-13.73q11.16-14.77,21-30.24Z"></path>
        </g>
        <path d="M350.19,336.62c-66.67-24.41-85.8,30.58-85.8,30.58l26.7,29.12c38.6,14.36,58.91,10.82,64.7,23.28s17.14,12.46,17.14,12.46l12.69-9.72C403.68,407,415.08,360.37,350.19,336.62Z" style="fill:#FFB300"></path>
        <path d="M386.52,421.49l-33.27-20.9c-6.41,6.47-10.3,24.82-8.4,37.89.24,1.69-.48,5.73-.2,7.42a11.08,11.08,0,0,1-2.27,8.09c-2.37,2.58-5.14,3.8-5.66,7.14-.48,3.11.32,7.09,5,8.78a9.82,9.82,0,0,0,2.38.51,14,14,0,0,0,8-1.95c7.85-4.17,12.73-12,12.73-12,3-4.08,3.65-12,6.16-18.8S379.64,428.1,386.52,421.49Z" style="fill:#FFB300"></path>
        <path d="M341.45,333.79c-3.68-1.39-11-2.81-16.58-2,7.3,4.17,12.41,10.48,13.46,12.55,12.13,24,4.95,40.74-8.11,63.34a46.67,46.67,0,0,0,11.94,2.82C353.22,388,363.08,349.69,341.45,333.79Z" style="fill:#263238"></path>
        <g style="opacity:0.30000000000000004">
          <path d="M357.47,393a21.83,21.83,0,0,0-10.54,18.45,35.64,35.64,0,0,1,8.2-12.63c5.88-6.31,17-7,26.71-8.52C373.31,388.05,364.16,389.06,357.47,393Z"></path>
        </g>
        <path d="M365.26,393.34a2.9,2.9,0,0,1-.64.27c-.41.17-1.05.37-1.84.69a27.75,27.75,0,0,0-6.23,3.57,26.52,26.52,0,0,0-6.89,8,30.41,30.41,0,0,0-2.53,5.76,36.11,36.11,0,0,0-1.54,6.58,87.78,87.78,0,0,0-.62,13.09c0,4.05.14,7.69.06,10.76a69,69,0,0,1-.48,7.24c-.09.85-.23,1.5-.3,1.95a3.22,3.22,0,0,1-.15.67,3.58,3.58,0,0,1,0-.69c0-.45.15-1.1.2-1.95.18-1.7.3-4.17.32-7.23s-.1-6.69-.17-10.74a83.91,83.91,0,0,1,.59-13.18,35.55,35.55,0,0,1,1.56-6.66,30.67,30.67,0,0,1,2.6-5.83,25.67,25.67,0,0,1,13.47-11.56c.81-.29,1.45-.46,1.88-.6A4.35,4.35,0,0,1,365.26,393.34Z" style="fill:#263238"></path>
        <path d="M390.76,415.07a4.9,4.9,0,0,1-.22.68l-.29.83c-.14.32-.34.66-.55,1.06a21.85,21.85,0,0,1-5,5.73c-2.41,2.11-5.57,4.28-8.67,7.11a19.32,19.32,0,0,0-4.08,5.08,22.37,22.37,0,0,0-2,6.72,40.83,40.83,0,0,1-1.63,7,32,32,0,0,1-2.83,6,36.54,36.54,0,0,1-3.51,4.91,39.48,39.48,0,0,1-3.71,3.72,36.8,36.8,0,0,1-6.17,4.42c-.76.47-1.4.76-1.81,1a3.73,3.73,0,0,1-.66.3,3.26,3.26,0,0,1,.61-.39c.4-.25,1-.56,1.76-1a41.3,41.3,0,0,0,6-4.52,40.5,40.5,0,0,0,3.62-3.73A38,38,0,0,0,365.1,455a32.58,32.58,0,0,0,2.76-6,41.14,41.14,0,0,0,1.6-6.9,22.58,22.58,0,0,1,2.07-6.86,19.68,19.68,0,0,1,4.2-5.19c3.17-2.85,6.34-5,8.77-7a23.11,23.11,0,0,0,5-5.55c.21-.39.43-.72.58-1l.34-.79A3,3,0,0,1,390.76,415.07Z" style="fill:#263238"></path>
        <path d="M328.61,377l-24.38,24.7q2.86,16,5.74,32c.43,2.38,1.11,5.08,3.67,6.22,3.13,1.38,7.14-.47,9.36-2.83,3.95-4.21,4.74-9.75,5.11-15C329.22,406.19,332.57,392.47,328.61,377Z" style="fill:#FFB300"></path>
        <path d="M284.42,371.14,265.73,390.2c1.93,11.28,3.54,31.87,5.47,43.15.43,2.52,1.12,5.37,3.63,6.59,3.06,1.48,7-.45,9.14-2.94,3.84-4.42,4.58-10.27,4.91-15.8A169.74,169.74,0,0,0,284.42,371.14Z" style="fill:#FFB300"></path>
        <g style="opacity:0.30000000000000004">
          <path d="M288.74,398.48l-24.29.74c3.95,5.23,10.39,8.17,16.89,9s13.1-.12,19.5-1.53c5.19-1.15,11.14-4,15.91-6.33s17.89-14.37,18.71-22C322.3,380.63,301.9,396.27,288.74,398.48Z"></path>
        </g>
        <path d="M276.4,330s-24.83,3.69-30.31,26.17l-1.41,14.56s-1.25,14.47,7.12,24.5c7.24,8.67,14.58,12,36.62,10.29,15-1.15,29-6.57,37.67-15.83,3.53-3.78,7.71-8.42,10.23-12.45,7.78-12.47-.69-28.73-4.78-33.73C326,336.62,307.68,323.35,276.4,330Z" style="fill:#FFB300"></path>
        <path d="M270.94,333.61s-13.65-5.21-16.37-6.33c-7.39-3-16.57-5.63-18.57.79-1.56,5,12.71,30.55,12.71,30.55L263,342.55Z" style="fill:#FFB300"></path>
        <g style="opacity:0.30000000000000004">
          <path d="M267.46,336.29s-27-11.55-28.65-9.26,10.62,23.43,10.62,23.43S265.75,344.91,267.46,336.29Z"></path>
        </g>
        <path d="M247.14,351.52c.81-4.25,3.74-8.86,6.43-12.17a8.31,8.31,0,0,0,.56,5.06c4.82-5.16,9.95-10.51,16.67-12.58l-1,5-10.3,15.89Z" style="fill:#FFB300"></path>
        <path d="M291.19,362.34h0a9.65,9.65,0,0,0-5.78-.82l-6.62,1a9.67,9.67,0,0,0-3.57,1.27,10.21,10.21,0,0,0-3.11,2.91,16.58,16.58,0,0,0-.1,17.8A14.92,14.92,0,0,0,299.5,378,16,16,0,0,0,291.19,362.34Z" style="fill:#fff;opacity:0.30000000000000004"></path>
        <path d="M287,364.25a11.77,11.77,0,0,0-9.14,1.19c-.91.37-1.59,1.92-.82,2.57l4.6,3.88a1.12,1.12,0,0,0,1.5,0l5.62-5.4C289.53,365.67,288.34,364.44,287,364.25Z" style="fill:#263238"></path>
        <path d="M282.31,369.75a3.1,3.1,0,0,0-.05.87,9.76,9.76,0,0,0,.39,2.34,9.43,9.43,0,0,0,1.62,3.14,8.05,8.05,0,0,0,3.43,2.54,7,7,0,0,0,4.2.31,6.64,6.64,0,0,0,3-1.68,6,6,0,0,0,1.34-1.93,2.67,2.67,0,0,0,.25-.84,8.08,8.08,0,0,1-1.78,2.55,6.52,6.52,0,0,1-2.9,1.53,6.71,6.71,0,0,1-4-.32,8,8,0,0,1-3.29-2.4,9.74,9.74,0,0,1-1.63-3A16.88,16.88,0,0,1,282.31,369.75Z" style="fill:#263238"></path>
        <path d="M271,376.53a3,3,0,0,0,.48.65,13.25,13.25,0,0,0,1.53,1.57,5.9,5.9,0,0,0,2.89,1.45,5.05,5.05,0,0,0,3.77-1A6.89,6.89,0,0,0,282,376a10.53,10.53,0,0,0,.61-3.22,15.22,15.22,0,0,0-.08-2.21,2.76,2.76,0,0,0-.15-.79c-.08,0,.08,1.15-.05,3a10.89,10.89,0,0,1-.68,3.1,6.72,6.72,0,0,1-2.25,3,4.79,4.79,0,0,1-3.49,1,5.77,5.77,0,0,1-2.77-1.3C271.75,377.37,271,376.47,271,376.53Z" style="fill:#263238"></path>
        <path d="M279.2,383.39s.35.56,1.21,1.12a6.62,6.62,0,0,0,3.79,1,6.52,6.52,0,0,0,3.67-1.32c.8-.62,1.14-1.17,1.1-1.21s-.48.4-1.3.92a6.94,6.94,0,0,1-7.09.27C279.72,383.75,279.26,383.33,279.2,383.39Z" style="fill:#263238"></path>
        <path d="M294.44,369.91c0,.14,16.89-.23,37.7-.83s37.69-1.19,37.68-1.33-16.88.24-37.7.83S294.44,369.77,294.44,369.91Z" style="fill:#263238"></path>
        <path d="M294.5,372.42a2.85,2.85,0,0,0,.63.1l1.81.21c1.58.17,3.86.38,6.69.61,5.65.47,13.47,1,22.11,1.26s16.46.31,22.13.23c2.83-.05,5.12-.1,6.7-.17l1.82-.08a2.54,2.54,0,0,0,.63-.06,2.83,2.83,0,0,0-.63,0h-8.53c-5.66,0-13.48-.08-22.11-.37s-16.45-.74-22.1-1.11l-6.69-.45-1.82-.12A2.6,2.6,0,0,0,294.5,372.42Z" style="fill:#263238"></path>
        <path d="M220.43,383.43c0,.14,12.13-2.2,27-5.21s27-5.58,26.93-5.71-12.12,2.2-27,5.22S220.4,383.3,220.43,383.43Z" style="fill:#263238"></path>
        <path d="M231.81,389.91c.05.13,9.19-3.24,20.41-7.52s20.29-7.86,20.24-8-9.18,3.24-20.41,7.52S231.77,389.78,231.81,389.91Z" style="fill:#263238"></path>
        <path d="M266.29,332.53c5.64-2.9,10.51-5.07,16.84-5.55a23.94,23.94,0,0,1,12-12.33l-5.54,10.83a21.44,21.44,0,0,1,9-5.61,14,14,0,0,0-3.88,7.3,81.07,81.07,0,0,1,8.94.77s19.25-22.56,28.92-19.61c7.09,2.16,2.3,41.47,2.3,41.47" style="fill:#FFB300"></path>
        <path d="M334.88,349.8a2.39,2.39,0,0,1,0-.4c0-.29.06-.68.1-1.17.1-1.05.23-2.56.4-4.48.32-3.89.77-9.53.86-16.52a82.57,82.57,0,0,0-.53-11.37,27.83,27.83,0,0,0-.57-3.06,10.28,10.28,0,0,0-1.15-2.93,3.2,3.2,0,0,0-1.05-1.11,4,4,0,0,0-1.51-.41,8.82,8.82,0,0,0-3.29.33,23.57,23.57,0,0,0-6.31,3,59.9,59.9,0,0,0-5.9,4.41A116.42,116.42,0,0,0,304.87,327l-1,1.15-.1.1-.14,0a73.73,73.73,0,0,0-8.9-.76h-.31l.06-.3a13.06,13.06,0,0,1,1.44-4.05,19.64,19.64,0,0,1,2.5-3.37l.27.41a21.28,21.28,0,0,0-8.89,5.53l-1.14,1.2.75-1.47c2-3.81,3.81-7.43,5.55-10.82l.29.3a23.85,23.85,0,0,0-12,12.19l0,.09h-.1a37,37,0,0,0-12.59,3.4c-1.39.63-2.45,1.15-3.17,1.49l-.81.38a1.1,1.1,0,0,1-.28.12,1.56,1.56,0,0,1,.26-.16l.79-.43c.71-.36,1.75-.91,3.14-1.56a36.06,36.06,0,0,1,12.64-3.57l-.14.1a24.11,24.11,0,0,1,12.07-12.46l.58-.26-.29.56-5.51,10.84-.39-.28a21.68,21.68,0,0,1,9.09-5.68l.87-.28-.61.69a18.75,18.75,0,0,0-2.44,3.28,12.75,12.75,0,0,0-1.37,3.9l-.26-.31a72.24,72.24,0,0,1,9,.77l-.23.09,1-1.16a115.19,115.19,0,0,1,11.15-10.89,57.43,57.43,0,0,1,5.95-4.44,24.25,24.25,0,0,1,6.45-3.06,9.33,9.33,0,0,1,3.48-.34,6.07,6.07,0,0,1,.85.16,2.22,2.22,0,0,1,.84.32,3.79,3.79,0,0,1,1.2,1.26,11.48,11.48,0,0,1,1.19,3.07,29.6,29.6,0,0,1,.57,3.11,81.66,81.66,0,0,1,.47,11.44c-.14,7-.65,12.64-1,16.53-.19,1.94-.36,3.45-.49,4.47-.07.49-.12.88-.16,1.16A2.77,2.77,0,0,1,334.88,349.8Z" style="fill:#263238"></path>
        <g style="opacity:0.30000000000000004">
          <path d="M328.34,344s2.27-28.9-1.06-29.61-22.7,18.25-22.7,18.25S317.69,345.56,328.34,344Z"></path>
        </g>
        <path d="M304.38,331.49c4.18-.71,9.46.49,13.46,1.92a7.94,7.94,0,0,0-4.44,2.28c6.47,2.83,13.21,5.87,17.52,11.58l-5,.75-18.26-4.34Z" style="fill:#FFB300"></path>
        <path d="M272.45,367.78c-.36.11-.94-1.12-1.84-2.87a10.82,10.82,0,0,0-1.89-2.65,4.94,4.94,0,0,0-3.07-1.51,5.34,5.34,0,0,0-3.28,1.12,10.17,10.17,0,0,0-2.22,2.35c-1.13,1.61-1.8,2.77-2.16,2.63s-.28-1.45.54-3.52a9.33,9.33,0,0,1,2.32-3.32,7.05,7.05,0,0,1,4.95-1.86,6.68,6.68,0,0,1,4.68,2.49,9.23,9.23,0,0,1,1.84,3.59C272.87,366.38,272.78,367.69,272.45,367.78Z" style="fill:#263238"></path>
        <path d="M309.28,361.32c-.29.24-1.3-.66-2.83-1.92a10.63,10.63,0,0,0-2.77-1.7,5,5,0,0,0-3.41-.19,5.33,5.33,0,0,0-2.59,2.31,10.59,10.59,0,0,0-1.12,3c-.41,1.93-.57,3.26-.95,3.27s-.84-1.22-.88-3.45a9.08,9.08,0,0,1,.83-4,7.06,7.06,0,0,1,3.82-3.65,6.72,6.72,0,0,1,5.29.46,9.38,9.38,0,0,1,3.1,2.59C309.11,359.87,309.54,361.11,309.28,361.32Z" style="fill:#263238"></path>
        <g style="opacity:0.30000000000000004">
          <path d="M287.34,351.63c.3,1.4-1.89,3.07-5,3.38s-5.56-.87-5.54-2.3,2.26-2.59,5-2.88S287,350.22,287.34,351.63Z" style="fill:#fff"></path>
        </g>
        <path d="M343.37,365.7a3.76,3.76,0,0,1-.34.72c-.25.47-.62,1.14-1.1,2-1,1.73-2.41,4.22-4.35,7.2a91.79,91.79,0,0,1-7.44,10A64.47,64.47,0,0,1,318.93,396a47.46,47.46,0,0,1-13.64,6.88,67.06,67.06,0,0,1-12.23,2.51c-3.53.42-6.4.59-8.38.71l-2.28.11a4,4,0,0,1-.8,0,5,5,0,0,1,.79-.11l2.27-.2c2-.18,4.84-.41,8.35-.88a69.8,69.8,0,0,0,12.13-2.59,48.07,48.07,0,0,0,13.49-6.86,65.37,65.37,0,0,0,11.15-10.27,98.44,98.44,0,0,0,7.5-9.91c2-2.95,3.45-5.4,4.47-7.1.5-.82.89-1.47,1.18-2S343.36,365.7,343.37,365.7Z" style="fill:#263238"></path>
        <g style="opacity:0.30000000000000004">
          <path d="M281.81,427.61a2.57,2.57,0,0,0-1.84.28h0a8.14,8.14,0,0,0-3.91,5.49,3.09,3.09,0,0,0,1.11,3.31,3.76,3.76,0,0,0,4-.39,6.88,6.88,0,0,0,2.2-3.26,6,6,0,0,0,.5-3.1A2.87,2.87,0,0,0,281.81,427.61Z"></path>
        </g>
        <path d="M276.24,426.62a8.8,8.8,0,0,1,4.47-2.67,9.23,9.23,0,0,1,5.19-.3,29.22,29.22,0,0,0-5,.8A24,24,0,0,0,276.24,426.62Z" style="fill:#263238"></path>
        <path d="M330.58,389.87a3.4,3.4,0,0,1,.08,1c0,.62,0,1.53,0,2.64,0,2.23-.18,5.32-.44,8.71s-.62,6.47-.93,8.68c-.15,1.1-.29,2-.4,2.61a3.63,3.63,0,0,1-.23.95,28.37,28.37,0,0,1,.26-3.6c.22-2.22.51-5.29.78-8.68s.45-6.46.59-8.68A29.37,29.37,0,0,1,330.58,389.87Z" style="fill:#263238"></path>
      </g>
      <g id="balloon-dog">
        <path d="M145.92,321.1a1.55,1.55,0,0,1-.09-.34c-.05-.25-.11-.58-.19-1-.17-.91-.41-2.2-.71-3.85-.64-3.36-1.56-8.18-2.68-14.11-2.31-11.91-5.66-28.33-9.35-46.48s-7-34.58-9.19-46.52c-1.11-6-2-10.8-2.52-14.14-.26-1.65-.47-2.95-.61-3.86-.06-.42-.11-.76-.15-1a1.47,1.47,0,0,1,0-.34,1.15,1.15,0,0,1,.08.33c0,.25.12.58.2,1,.17.91.41,2.2.71,3.84.64,3.37,1.55,8.18,2.68,14.12,2.31,11.91,5.66,28.33,9.34,46.48s7,34.58,9.2,46.51c1.11,6,2,10.8,2.52,14.15.26,1.65.46,2.94.61,3.86l.15,1A1.54,1.54,0,0,1,145.92,321.1Z" style="fill:#263238"></path>
        <path d="M118.74,196.4c-23.46-9-44.82-30.38-59.95-50.46s-22.74-46.56-17-71,26.11-45.8,51-49.43c26.65-3.88,53.61,13,66.3,36.78s8.51,57.55-3.49,81.65C142,171.13,138.45,180,118.74,196.4" style="fill:#455a64"></path>
        <path d="M128.84,199.86c-3.58-2.76-6.78-6.2-8.44-10.4l-2,2.79c-2.4,2.89-3.78,10.22-4,14-.08,1.08.1,2.42,1.1,2.83s2.2-.56,2.7-1.6.78-2.26,1.68-3a8.15,8.15,0,0,0,.9,3.46,2.48,2.48,0,0,0,4.7-1.55A8.05,8.05,0,0,0,124,203a8.35,8.35,0,0,0,3.69,2.52c1.47.35,3.31-.4,3.57-1.88S130.1,200.82,128.84,199.86Z" style="fill:#455a64"></path>
        <path d="M123.14,192.59c.34.18-.64,2.06-3,2.84s-4.23-.11-4.08-.45,1.78-.22,3.63-.87S122.82,192.35,123.14,192.59Z" style="fill:#263238"></path>
        <g style="opacity:0.2">
          <path d="M159,99.27a4.27,4.27,0,0,0-4.71.82c-2.41,2.22-2.6,5.43-2.89,8.44a94.67,94.67,0,0,1-33.16,62.9c-2.71,2.27-5.88,5.26-5,8.68.66,2.53,3.49,4,6.11,4a13.89,13.89,0,0,0,6.54-1.88,18,18,0,0,0,5.31-4.59c17.35-21.9,25.56-42.9,29.9-70.84C161.56,104,161.6,100.54,159,99.27Z"></path>
        </g>
        <g style="opacity:0.2">
          <path d="M155.55,74.57a4.38,4.38,0,0,0-6.27,3.68v-.17a16.8,16.8,0,0,0,1.44,7.83c1.21,2.27,4.06,3.88,6.4,2.82s3-4.26,2.74-6.89C159.52,78.91,158.23,75.79,155.55,74.57Z"></path>
        </g>
        <path d="M68.33,39.92a2.94,2.94,0,0,1-.49.49l-1.49,1.28-1.08.91c-.38.34-.77.75-1.21,1.16-.86.85-1.89,1.75-2.88,2.91l-1.6,1.76c-.55.62-1.06,1.31-1.62,2-1.15,1.36-2.19,3-3.33,4.6a70,70,0,0,0-6,11.54A70.33,70.33,0,0,0,45.08,79.1c-.29,2-.63,3.86-.73,5.64-.06.88-.17,1.74-.19,2.57s0,1.62,0,2.38c-.08,1.51.06,2.88.1,4.09,0,.6,0,1.16.09,1.68s.11,1,.16,1.4c.08.78.14,1.42.2,2a3.31,3.31,0,0,1,0,.69,2.79,2.79,0,0,1-.16-.67c-.09-.52-.2-1.16-.33-1.94-.07-.43-.14-.9-.22-1.4s-.09-1.08-.15-1.69c-.08-1.21-.24-2.58-.2-4.11v-2.4c0-.84.1-1.7.15-2.6.08-1.8.39-3.7.68-5.7A67.48,67.48,0,0,1,48,66.33,67.94,67.94,0,0,1,54.1,54.69c1.16-1.65,2.24-3.25,3.42-4.61.57-.69,1.09-1.39,1.66-2l1.65-1.75a38.12,38.12,0,0,1,3-2.85c.45-.41.86-.8,1.26-1.13l1.12-.87,1.57-1.19A2.31,2.31,0,0,1,68.33,39.92Z" style="fill:#fff"></path>
        <path d="M88.32,32c.06.18-2.41,1.16-5.51,2.19s-5.67,1.72-5.73,1.54,2.41-1.16,5.51-2.19S88.26,31.84,88.32,32Z" style="fill:#fff"></path>
        <g style="opacity:0.1">
          <path d="M73.49,42.74C49.79,60.15,44.2,87.37,54.21,115c3.2,8.82,8.5,17.3,17.51,21.12a19.64,19.64,0,0,0,7.51,1.59c13.11.08,18.79-11.58,19.24-22.29.34-7.93-1.6-15.77-2.25-23.67s.23-16.5,5.31-22.59,14-9.37,13.93-18.38a13.88,13.88,0,0,0-5.91-10.88,22.59,22.59,0,0,0-9.07-4,32.63,32.63,0,0,0-24.1,4.9Z" style="fill:#fff"></path>
        </g>
        <path d="M152.24,167c.15,0-1.21,33.54-3,74.9s-3.41,74.88-3.56,74.87,1.22-33.53,3-74.9S152.1,167,152.24,167Z" style="fill:#263238"></path>
        <path d="M149.1,214.55c-18.3-12.41-32.34-34.92-41.32-55.12s-10.21-44.38-.43-64.2,31.45-34,53.48-32.2c23.59,2,43.32,21.8,49.46,44.66s-4.14,51-19.21,69.23c-17,20.62-21.85,27.5-42,37.63" style="fill:#FFB300"></path>
        <path d="M157.06,219.51a22.13,22.13,0,0,1-5.16-10.58l-2.3,2c-2.63,2-5.26,8-6.23,11.16-.28.91-.39,2.08.38,2.63s2,0,2.63-.83,1.12-1.78,2-2.22a7.27,7.27,0,0,0,.08,3.15,2.19,2.19,0,0,0,4.34-.4,7.36,7.36,0,0,0-.5-3.16,7.43,7.43,0,0,0,2.65,2.9c1.19.59,2.92.31,3.43-.91S157.94,220.59,157.06,219.51Z" style="fill:#FFB300"></path>
        <path d="M153.62,212.16c.26.22-1,1.64-3.1,1.85s-3.6-.94-3.4-1.2,1.57.17,3.28,0S153.4,211.89,153.62,212.16Z" style="fill:#263238"></path>
        <g style="opacity:0.2">
          <path d="M202.87,139.38a3.74,3.74,0,0,0-4.19-.23c-2.5,1.42-3.31,4.12-4.15,6.65a83.17,83.17,0,0,1-40.9,47.27c-2.77,1.4-6.07,3.33-6,6.44.06,2.3,2.19,4.12,4.44,4.62a11.92,11.92,0,0,0,6-.31,15.57,15.57,0,0,0,5.46-2.87c19.21-15.31,30.41-31.65,39.68-54.72C204.11,144,204.84,141,202.87,139.38Z"></path>
        </g>
        <g style="opacity:0.2">
          <path d="M204.83,117.54a3.87,3.87,0,0,0-6.11,1.91l0-.15a14.68,14.68,0,0,0-.33,7c.59,2.18,2.71,4.13,4.92,3.69s3.44-3,3.72-5.36C207.36,122.05,206.87,119.12,204.83,117.54Z"></path>
        </g>
        <path d="M137,70.54a2.18,2.18,0,0,1-.52.32l-1.52.8-1.11.57c-.4.22-.81.48-1.27.75-.9.56-2,1.13-3,1.92l-1.72,1.19c-.6.42-1.17.92-1.79,1.4-1.25.94-2.46,2.09-3.77,3.28a56.69,56.69,0,0,0-13,18.7c-.65,1.64-1.31,3.18-1.74,4.68-.23.75-.5,1.46-.68,2.17s-.35,1.38-.51,2c-.37,1.28-.52,2.48-.73,3.52-.09.52-.19,1-.25,1.45s-.1.86-.15,1.24c-.08.68-.15,1.25-.21,1.7a2.43,2.43,0,0,1-.12.6,2.3,2.3,0,0,1,0-.6c0-.47.06-1,.1-1.73,0-.38.06-.8.09-1.24s.13-1,.21-1.47a35,35,0,0,1,.64-3.57c.15-.66.31-1.34.48-2.06s.42-1.43.65-2.19c.42-1.53,1.07-3.09,1.71-4.75A54.8,54.8,0,0,1,121.9,80.36c1.33-1.18,2.56-2.34,3.84-3.27.63-.47,1.22-1,1.83-1.38l1.75-1.17c1.1-.77,2.19-1.32,3.11-1.85.47-.26.9-.51,1.31-.72l1.13-.52,1.58-.7A2,2,0,0,1,137,70.54Z" style="fill:#fff"></path>
        <path d="M155.71,67.75c0,.17-2.29.52-5.16.78s-5.19.35-5.21.18,2.29-.52,5.16-.78S155.69,67.58,155.71,67.75Z" style="fill:#fff"></path>
        <g style="opacity:0.1">
          <path d="M140.88,74c-23.76,10.2-33.95,32.4-30.87,58.07,1,8.19,3.84,16.5,10.79,21.57a17.4,17.4,0,0,0,6.12,2.85c11.21,2.68,18.39-6.19,20.91-15.27,1.86-6.72,1.76-13.81,2.77-20.71s3.47-14.08,9-18.29,13.81-5.24,15.58-13a12.23,12.23,0,0,0-2.9-10.49,20,20,0,0,0-7-5.19,28.63,28.63,0,0,0-21.6-.58Z" style="fill:#fff"></path>
        </g>
        <path d="M190.19,373.09l-19.52-5.32c-.28,3.89-4.82,9.81-5.1,13.69-.19,2.74,2.85,9.22,2.85,9.22a18.18,18.18,0,0,0-5.17,2.58c-4,3.28-4,10-.57,13.89s8,4.14,13,2.1c4.25-1.75,7.9-2.69,10.18-6.68,1.27-2.23.3-11.52-.8-17.55C184,379.5,190.06,375,190.19,373.09Z" style="fill:#c17560"></path>
        <g style="opacity:0.30000000000000004">
          <path d="M190.19,373.09l-19.52-5.32c-.28,3.89-4.82,9.81-5.1,13.69-.19,2.74,2.85,9.22,2.85,9.22a18.18,18.18,0,0,0-5.17,2.58c-4,3.28-4,10-.57,13.89s8,4.14,13,2.1c4.25-1.75,7.9-2.69,10.18-6.68,1.27-2.23.3-11.52-.8-17.55C184,379.5,190.06,375,190.19,373.09Z"></path>
        </g>
        <path d="M199.87,307.34a26,26,0,0,1-10.93,23.06l8.5,11.2a24.58,24.58,0,0,0,2.43-34.26Z" style="fill:#ae7461"></path>
        <path d="M199.64,347.68c-.6-8.41-5.46-16.61-13-20.37-5-2.48-10.74-3-16.25-2.32a76.27,76.27,0,0,0-16.06,4.25L106.56,345.8c-2.52,9.55-2.79,20.41,2.71,28.61C113,380,119,383.67,125.4,385.63s13.15,2.3,19.83,2.18c8.43-.16,16.91-1,24.94-3.63s15.6-6.95,21.15-13.3S200.24,356.09,199.64,347.68Z" style="fill:#ae7461"></path>
        <path d="M110.82,366.33c-4.57-.55-13.09,2.91-16.71,5.75s-6.62,5.86-7.74,10.31c-1.3,5.22-.39,9.74,4,12.53s11,1.86,13.66-2.56c1.27-2.11,1.61-4.74,3.19-6.63,1.76-2.11,4.64-2.82,7.32-3.41l11.42-2.51Z" style="fill:#ae7461"></path>
        <path d="M149.38,375.74,129.19,377c1,3.77,2.65,9.42,3.64,13.19.7,2.65,1.61,9.22,1.61,9.22a18.17,18.17,0,0,0-4.05,4.11c-2.72,4.39-.53,10.74,3.94,13.33s8.91,1.34,13-2.2c3.45-3,6.6-5.1,7.47-9.61S150.48,379.88,149.38,375.74Z" style="fill:#ae7461"></path>
        <path d="M205,386.79c.35-2.53-4-10.81-7.25-16-3-4.75,1-11.18.37-13L178,360.1c-2.95,6.21-3.62,9.26-2.44,13,.83,2.62,8.84,9.14,8.84,9.14a18.17,18.17,0,0,0-3.84,4.31c-2.49,4.53,0,10.75,4.62,13.11s9,.88,12.82-2.86C201.27,393.58,204.31,391.34,205,386.79Z" style="fill:#ae7461"></path>
        <path d="M131.48,387a6.79,6.79,0,0,1-1.56-.09,33.23,33.23,0,0,1-4.2-.77,26.69,26.69,0,0,1-12.31-6.65,29.78,29.78,0,0,1-7.29-12,26.68,26.68,0,0,1-1-4.16,6.1,6.1,0,0,1-.12-1.56c.13,0,.35,2.21,1.52,5.57a31,31,0,0,0,7.32,11.65,27.74,27.74,0,0,0,12,6.67C129.27,386.69,131.49,386.91,131.48,387Z" style="fill:#263238"></path>
        <path d="M134.18,395.84c-.17.05-1.53-4.46-3-10.07s-2.59-10.2-2.42-10.25,1.54,4.47,3,10.08S134.35,395.8,134.18,395.84Z" style="fill:#263238"></path>
        <path d="M172.84,358.89c.1,0-.31,1.46-.64,3.8a19.83,19.83,0,0,0,.66,9.08,12.77,12.77,0,0,0,5.66,6.9A16.76,16.76,0,0,0,182.1,380a2.94,2.94,0,0,1-1,0,9,9,0,0,1-2.74-.86,12.57,12.57,0,0,1-6.06-7.09,18.53,18.53,0,0,1-.5-9.37,21.84,21.84,0,0,1,.69-2.76A3.16,3.16,0,0,1,172.84,358.89Z" style="fill:#263238"></path>
        <path d="M148.63,369.87c-.09.07-.7-.78-2-1.8a11.12,11.12,0,0,0-12-1.3c-1.48.72-2.26,1.43-2.33,1.35a5.56,5.56,0,0,1,2.11-1.75,10.71,10.71,0,0,1,12.52,1.35A5.62,5.62,0,0,1,148.63,369.87Z" style="fill:#263238"></path>
        <path d="M152.59,329.84l6.82-2.32s8,18.79,6.46,36.17-3.54,22.48-3.54,22.48l-4.84.78s3.52-19.06,2.79-30.58C159.42,343,152.59,329.84,152.59,329.84Z" style="fill:#263238"></path>
        <path d="M139.49,344.24c.15-3.65-2.38-6.94-5.36-9.05a22.56,22.56,0,0,0-11.78-4,13,13,0,0,0-8.45,2,10.49,10.49,0,0,0-4,7.55c-.26,2.92-2.39,5.27-1.1,7.91a29,29,0,0,0,17.75,5.17,16.85,16.85,0,0,0,8.44-2.35A9.41,9.41,0,0,0,139.49,344.24Z" style="fill:#6f4439"></path>
        <path d="M159.38,332.8l.68-6.75s.5-3.16-3-21.57c-4.59-24.3-35.44-23.18-35.44-23.18h0c-2.89-.23-30.22-.83-34.85,24.85-2.48,13.77-1,18.9-1,18.9l1.62,9s1.73,17.63,15.72,20.13c0,0,3.8,1.12,8.57-6.91,4.64-7.82,4-11.7,10.61-13.27,8.09,1,7.61,5.6,12.93,13.26s9,6.28,9,6.28C158,350,159.38,332.8,159.38,332.8Z" style="fill:#ae7461"></path>
        <path d="M103.89,318.78a4.9,4.9,0,1,1,1.22-6.82A4.89,4.89,0,0,1,103.89,318.78Z" style="fill:#263238"></path>
        <path d="M147.79,317.9a4.9,4.9,0,1,1,1.22-6.82A4.91,4.91,0,0,1,147.79,317.9Z" style="fill:#263238"></path>
        <g style="opacity:0.2">
          <path d="M158.9,335.79c-13.2-12.87-19.38-21.38-31.32-23.34l-.66-.1a23.26,23.26,0,0,0-20.55,5.45c-3.78,3.47-5.5,8.85-9.94,11.44-2,1.14-5.75,1.95-7.33,3.57a6.17,6.17,0,0,0-1.52,2.62c.75,4.23,3.94,16.65,15.51,18.72,0,0,3.8,1.12,8.57-6.91,4.64-7.82,4-11.7,10.61-13.27,8.09,1,7.61,5.6,12.93,13.26s9,6.28,9,6.28C154.41,350.94,157.81,340.88,158.9,335.79Z"></path>
        </g>
        <path d="M130,317.73a4,4,0,0,0-1.85-1.82h0l-.05,0a7.57,7.57,0,0,0-1.6-.65,11.19,11.19,0,0,0-8,0,6.14,6.14,0,0,0-3.2,2.25,3,3,0,0,0,.2,3.67c1,1,2.68.86,3.86,1.64a12.61,12.61,0,0,1,1.88,2,2.27,2.27,0,0,0,2.45.77c1-.49,1.14-2,2.06-2.73s2.17-.34,3.13-.87C130.09,321.26,130.53,319,130,317.73Z" style="fill:#263238"></path>
        <path d="M139.68,317.43s-.15-.06-.41-.21l-1.13-.65a39.88,39.88,0,0,0-4.28-2.14,31.43,31.43,0,0,0-6.77-2.06,24.33,24.33,0,0,0-8.61-.05,20.67,20.67,0,0,0-8,3.18,18,18,0,0,0-2.89,2.41,25,25,0,0,0-2.09,2.55c-1.19,1.64-2.1,3-2.79,3.91-.34.46-.62.8-.81,1a2.73,2.73,0,0,1-.31.33,1.63,1.63,0,0,1,.25-.38l.75-1.07c.65-.92,1.52-2.31,2.68-4a26.22,26.22,0,0,1,2.09-2.61,19,19,0,0,1,2.91-2.49,20.88,20.88,0,0,1,8.11-3.26,24.16,24.16,0,0,1,8.75.08,30.73,30.73,0,0,1,6.81,2.15,35.76,35.76,0,0,1,4.25,2.27c.48.3.85.54,1.09.72A3,3,0,0,1,139.68,317.43Z" style="fill:#263238"></path>
        <path d="M90.39,258.15c-2.94-3.67-6.61-7.68-11.3-7.48a9.81,9.81,0,0,0-7.5,4.74,21.94,21.94,0,0,0-2.87,8.68,60.73,60.73,0,0,0,5,34.87c2.53,5.41,6.47,10.87,12.32,12.11l24.78-27.55Z" style="fill:#ae7461"></path>
        <path d="M154.82,259.86c3.07-3.56,6.89-7.43,11.57-7.06a9.84,9.84,0,0,1,7.32,5,22.07,22.07,0,0,1,2.55,8.78A60.7,60.7,0,0,1,170,301.25c-2.73,5.32-6.87,10.63-12.75,11.66l-23.75-28.45Z" style="fill:#ae7461"></path>
        <path d="M108.22,283.6a12.48,12.48,0,0,1-1.26.46,30.8,30.8,0,0,0-3.37,1.4,28.37,28.37,0,0,0-15,17.88,31.87,31.87,0,0,0-.79,3.57,11.2,11.2,0,0,1-.22,1.32s0-.12,0-.35,0-.57.08-1a25.29,25.29,0,0,1,.68-3.61,27.42,27.42,0,0,1,15.11-18,25.05,25.05,0,0,1,3.43-1.31c.42-.13.75-.19,1-.25A1.45,1.45,0,0,1,108.22,283.6Z" style="fill:#263238"></path>
        <path d="M121.64,281.3a2.15,2.15,0,0,1,.47,0c.31,0,.76,0,1.35,0a45.32,45.32,0,0,1,4.94.34A43.52,43.52,0,0,1,144,286.32a28.18,28.18,0,0,1,7.11,5.54,24.06,24.06,0,0,1,4.06,6.14,27.07,27.07,0,0,1,1.6,4.69c.11.54.19,1,.26,1.32a2.4,2.4,0,0,1,.07.47,2.54,2.54,0,0,1-.14-.45l-.34-1.3a28.5,28.5,0,0,0-1.7-4.62,24.44,24.44,0,0,0-4.07-6,28.69,28.69,0,0,0-7-5.44,45.17,45.17,0,0,0-15.43-4.81c-2.07-.28-3.75-.4-4.92-.46l-1.34-.05A2.15,2.15,0,0,1,121.64,281.3Z" style="fill:#263238"></path>
        <path d="M159.38,332.8a3,3,0,0,1,0,.49c0,.33-.09.81-.19,1.44a33.23,33.23,0,0,1-1.38,5.35,25.24,25.24,0,0,1-4.14,7.55,18.08,18.08,0,0,1-3.94,3.61,17.6,17.6,0,0,1-5.42,2.43h0a3.5,3.5,0,0,1-1.76,0,6.7,6.7,0,0,1-1.65-.61,12.52,12.52,0,0,1-2.88-2.17,31.46,31.46,0,0,1-4.57-6.06c-.67-1.12-1.32-2.29-2-3.47a30.66,30.66,0,0,0-2.1-3.46,8.37,8.37,0,0,0-3.09-2.63,13.34,13.34,0,0,0-4-1.09h.07a11,11,0,0,0-3.34,1.36c-1.91,1.23-2.9,3.35-3.84,5.34s-1.88,4-3,5.85a25,25,0,0,1-3.73,5.11,11,11,0,0,1-2.44,1.87,4.58,4.58,0,0,1-3,.63h0a16.27,16.27,0,0,1-7.47-3.42,20,20,0,0,1-4.71-5.7,29.12,29.12,0,0,1-2.5-5.93A31.07,31.07,0,0,1,87.27,334h0c-.5-2.87-.88-5.11-1.15-6.66-.12-.75-.22-1.32-.29-1.73,0-.19,0-.33-.06-.45a.86.86,0,0,1,0-.15s0,.05,0,.15l.1.44c.08.4.19,1,.33,1.72L87.47,334h0a29.9,29.9,0,0,0,1.15,5.18,29.09,29.09,0,0,0,2.51,5.87,19.8,19.8,0,0,0,4.66,5.59,16.18,16.18,0,0,0,7.33,3.33h0a4.29,4.29,0,0,0,2.7-.61,10.4,10.4,0,0,0,2.35-1.81,24.87,24.87,0,0,0,3.66-5c1.07-1.85,2-3.82,2.94-5.84a26,26,0,0,1,1.58-3,8.25,8.25,0,0,1,2.39-2.5,11.18,11.18,0,0,1,3.47-1.41h.07a13.61,13.61,0,0,1,4.16,1.14,8.57,8.57,0,0,1,3.22,2.74,28.48,28.48,0,0,1,2.12,3.51c.64,1.19,1.28,2.34,1.94,3.46a31.35,31.35,0,0,0,4.5,6,12,12,0,0,0,2.79,2.12,6.24,6.24,0,0,0,1.57.59,3.26,3.26,0,0,0,1.58,0h0a17.61,17.61,0,0,0,5.34-2.36,18.06,18.06,0,0,0,3.9-3.54A25.06,25.06,0,0,0,157.6,340a33,33,0,0,0,1.46-5.3c.12-.62.2-1.1.24-1.42S159.38,332.8,159.38,332.8Z" style="fill:#263238"></path>
        <path d="M123.33,325.15a18,18,0,0,1-.09,4.49,17.85,17.85,0,0,1-.85,4.42c-.11,0,.21-2,.46-4.46S123.22,325.15,123.33,325.15Z" style="fill:#263238"></path>
        <path d="M103.46,338.78a.86.86,0,0,1-1,.7.87.87,0,1,1,.3-1.71A.86.86,0,0,1,103.46,338.78Z" style="fill:#263238"></path>
        <path d="M107.88,333.47a.87.87,0,1,1-1.71-.31.87.87,0,0,1,1.71.31Z" style="fill:#263238"></path>
        <path d="M112.9,337.9a.87.87,0,0,1-1.71-.31.87.87,0,0,1,1.71.31Z" style="fill:#263238"></path>
        <path d="M116.34,332.42a.87.87,0,0,1-1.71-.3.87.87,0,1,1,1.71.3Z" style="fill:#263238"></path>
        <path d="M107,343.62a.87.87,0,1,1-.7-1A.88.88,0,0,1,107,343.62Z" style="fill:#263238"></path>
        <path d="M144.23,340a.86.86,0,1,0,1.7.3.86.86,0,1,0-1.7-.3Z" style="fill:#263238"></path>
        <path d="M140.31,334.24a.86.86,0,1,0,1-.7A.86.86,0,0,0,140.31,334.24Z" style="fill:#263238"></path>
        <path d="M135,336.31a.86.86,0,1,0,1-.7A.86.86,0,0,0,135,336.31Z" style="fill:#263238"></path>
        <path d="M132.73,330.33a.87.87,0,0,0,1.71.3.87.87,0,1,0-1.71-.3Z" style="fill:#263238"></path>
        <path d="M138.83,342.53a.86.86,0,1,0,1-.7A.85.85,0,0,0,138.83,342.53Z" style="fill:#263238"></path>
        <path d="M105.43,299.26c0,.54-.63,1-1.56,1.49a28.19,28.19,0,0,1-3.63,1.42c-2.83.93-5.36,1.56-5.95.65-.28-.45,0-1.23.87-2.06A10,10,0,0,1,99,298.5a9.59,9.59,0,0,1,4.5-.47C104.72,298.24,105.42,298.74,105.43,299.26Z" style="fill:#263238"></path>
        <path d="M148.55,298.3c-.19.51-1,.73-2,.83a30.55,30.55,0,0,1-3.9,0c-3-.15-5.56-.47-5.79-1.53-.11-.51.47-1.13,1.55-1.61a10.09,10.09,0,0,1,4.42-.73,9.65,9.65,0,0,1,4.37,1.17C148.26,297.09,148.74,297.81,148.55,298.3Z" style="fill:#263238"></path>
        <path d="M129.09,309.58c0,.06-.85-.16-2.2-.39a27.9,27.9,0,0,0-5.34-.37,28.31,28.31,0,0,0-5.31.67c-1.35.31-2.16.57-2.18.51a2.35,2.35,0,0,1,.55-.26,15.11,15.11,0,0,1,1.56-.51,22.63,22.63,0,0,1,10.77-.3,14.79,14.79,0,0,1,1.59.42A2.19,2.19,0,0,1,129.09,309.58Z" style="fill:#263238"></path>
        <g style="opacity:0.2">
          <path d="M88.69,302.43c-1.25,4.61-9.14-3.08-11.64-7.15s-3.47-8.87-4.4-13.55c-1.23-6.16-2.46-12.53-1.13-18.68a12.64,12.64,0,0,1,2.61-5.75,6.75,6.75,0,0,1,5.62-2.46c2.39.25,4.31,2,5.89,3.85a47.73,47.73,0,0,1,10.83,21.9c.63,3.13,1.75,7.16-.18,9.7A27.29,27.29,0,0,0,88.69,302.43Z"></path>
        </g>
        <g style="opacity:0.2">
          <path d="M157.12,303.56c.84,4.7,7.71-2.92,10.55-6.75s4.24-8.53,5.58-13.12a39.54,39.54,0,0,0,1.16-17.24,26.67,26.67,0,0,0-2.47-8c-.92-1.92-3.4-4-5.53-4-2.4,0-4.79,1.64-6.53,3.3-5.95,5.7-7.93,14.79-10.26,22.69-.9,3.06-2.37,7-.67,9.67C149,290.14,155.79,296.12,157.12,303.56Z"></path>
        </g>
      </g>
    </svg>
  </div>
</template>
<style scoped>
#balloon-cat {
  animation: float 20s ease-in-out ;
  animation-iteration-count: infinite;
}

#balloon-dog {
  animation: float 20s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  /* animation-range-start: 10%; can be used once browsers suppport this is 20 years */
}



@keyframes float {
  10%,
  90% {
    transform: translate3d(1px, -10px, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, -12px, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(1px, -3px, 0);
  }
  40%,
  60% {
    transform: translate3d(2px, 8px, 0);
  }
}
</style>
<template>
  <LoginTemplate
    :title="title">

    <p class="text-center pb-1 lg:mx-12 text-2xl font-bold">
      Sign in to manage your users, billing and account information
    </p>
    <form class="flex-col sm:m-2" @submit.prevent="login">
      <p :class="[loginError ? 'error-message' : '', 'h-4 text-center']">
        {{ loginError }}
      </p>

      <BaseInput v-model="email"
                 class="pt-4" label="Email Address"
                 name="email"
                 type="email"
                 :errorMessage="emailError"
                 required
                 @input="fieldChange"
      />

      <BaseInput v-model="pwd"
                 class="mb-2" label="Password"
                 name="password"
                 type="password"
                 :errorMessage="pwdFieldError"
                 required
                 @input="fieldChange"
      />

      <button id="login-button" :disabled="disableButton ?? undefined" :class="[disableButton ? 'disabled' : '', 'btn-long btn-primary h-12 text-sm']">
        Sign In
      </button>
    </form>
    <div class="flex justify-center">
      <p class="font-normal text-sm text-center p-2">
        Did you forget your password?
        <RouterLink to="/password-reset" class="text-sm text-gray-500 underline inline-block">
          Fix that here
        </RouterLink>
      </p>
    </div>
  </LoginTemplate>
</template>

<script src="./Login.js"></script>
<script setup>
import { ref, reactive } from "vue";
const props = defineProps({
  props: {
    type: Object,
    default () {
      return {
        state: '',
        dateString: '',
      };
    },
  }
});
const state = ref(props.props.state);
const dateString = ref(props.props.dateString ?? '');
</script>

<template>
  <div class="flex justify-start text-sm xl:text-[12px] 2xl:text-[13px] mt-3 mb-4 xl:my-0 font-roboto">
    <div v-if="state === 'enabled'" class="label bg-main-dark">
      Enabled
    </div>
    <div v-else-if="state === 'disabled'" class="label bg-main">
      Disabled
    </div>
    <div v-else-if="state === 'invited'" class="label bg-main-light">
      Invited {{ dateString }}
    </div>
    <div v-else-if="state === 'expired'" class="label bg-main-light">
      Expired {{ dateString }}
    </div>
  </div>
</template>

<style>
.label {
  @apply rounded-md;
  @apply py-2;
  min-width: 10rem;
  @apply text-center;
  @apply text-white;
}


</style>
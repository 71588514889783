<template>
  <LoginTemplate class="pb-6">
    <span v-show="!resetSent" class="text-center">
      <p class="text-2xl font-bold pb-4">Reset your password</p>
      <p class="text-lg mx-4 ">Please verify your email below so that we can send you a password reset link.</p>
    </span>
    <div class="flex-col m-6">

      <p :class="[message == '' ? 'hidden':'', 'text-rare2-dark text-center text-3xl mb-4']">
        {{ message }}
      </p>
      <p v-show="resetSent" class="center text-lg mx-10 mt-1">
        We have sent a reset link to your email. You should receive it momentarily. If you do not see an email in your inbox, make sure you typed your email properly.
      </p>

      <BaseInput
        v-show="!resetSent"
        v-model="email"
        class="py-4"
        label="Email Address"
        name="email"
        type="email"
        :errorMessage="errorMessage" required
        @keyup.enter="sendPasswordReset" />
      <button
        v-show="!resetSent"
        :disabled="(!validateEmail(email) || disableButton) ?? undefined"
        :class="[!validateEmail(email) || disableButton? 'disabled': '', 'btn-long btn-primary min-h-[3rem] text-sm mt-2']"
        @click="sendPasswordReset">
        Send Password Reset Email
      </button>
    </div>
    <div v-show="resetSent" class="flex justify-center my-8">
      <RouterLink to="/login" class="text-lg">
        Back to Sign In
      </RouterLink>
    </div>
    <div class="flex flex-col justify-center">
      <p class="text-sm text-center mt-2 text-gray-500">
        Not Working? Contact support at
        <a href="tel:18558862156" class="inline-anchor"> 1-855-886-2156</a>
      </p>
      <p class="text-sm text-center text-gray-500"> Wait, I think I remember now.
        <RouterLink to="/login" class="inline-anchor text-gray-500 underline">
          cancel
        </RouterLink>
      </p>
    </div>
  </LoginTemplate>
</template>

<script src="./PasswordReset.js"></script>
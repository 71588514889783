<template>

  <div class="flex py-2 px-3 lg:hidden bg-[#F5F6F8] rounded-t-md">
    <RouterLink v-if="currentUser == userId" to="/settings/templates"
                class="pl-3 pr-1 py-2 bg-pastel-blue-light text-main-alt font-roboto text-xs font-extrabold rounded no-underline">
      Template Settings<span class="material-icons leading-3 w-5">keyboard_arrow_right</span>
    </RouterLink>
    <RouterLink v-if="currentUser == userId" to="/settings/talkatexts"
                class="ml-2 pl-3 pr-1 py-2 bg-pastel-blue-light text-main-alt font-roboto text-xs font-extrabold rounded no-underline">
      Talkatext Settings<span class="material-icons leading-3 w-5">keyboard_arrow_right</span>
    </RouterLink>
    <RouterLink v-else to="/users"
                class="px-3 py-2 bg-pastel-blue-light hover:bg-pastel-blue-lighter text-main-alt font-roboto text-xs font-extrabold rounded">
      <span class="material-icons leading-3 w-5">keyboard_arrow_left</span>User List
    </RouterLink>
  </div>
  <div class="flex flex-col flex-grow bg-white rounded-md mb-32">

    <div class="flex justify-between min-h-[110px] bg-[#FAFBFC] border-y lg:border-t-0 border-[#E7EBF2]  px-4 lg:pr-7 lg:pl-14 py-8">
      <Transition name="fade" appear>
        <div v-if="userProfile" class="delay-150 default-fade-duration inline-flex mx-auto lg:mx-0">
          <div class="block my-auto text-neutral-darker font-extrabold text-xl">
            <span class="clamp-line-count break-all">
              <template v-if="userProfile.firstName">
                {{ userProfile.firstName }}'s Profile
              </template>
              <template v-else>
                {{ userProfile.email }}
              </template>
            </span>
          </div>
        </div>
      </Transition>
      <div class="hidden lg:flex relative items-end w-32 md:w-52">
        <TransitionGroup name="fade-pull">
          <div v-if="pendingChange"
               class="absolute flex justify-end default-fade-duration w-full top-1/2">
            <BaseLoading class="transition-none transform scale-50 -translate-y-1/2 mr-4" />
          </div>
          <div v-else-if="changeMade" key="1" class="flex flex-grow justify-end default-fade-duration replace-fade-pull w-full my-auto">
            <button id="saveProfile" :disabled="pendingChange" class="whitespace-nowrap rounded-full py-3 px-4
                      font-roboto text-sm font-semibold bg-main-alt hover:bg-main-dark transition colors text-white"
                    @click="saveChanges">
              Save Changes
            </button>
          </div>
        </TransitionGroup>
      </div>
    </div>
    <FadeInWrapper>
      <Transition name="fade">
        <div v-if="userProfile" class="default-fade-duration  p-4 md:py-9 md:pl-7  md:pr-7 xl:pr-14">

          <div class="flex flex-col-reverse md:flex-row">
            <!-- left section -->
            <div class="flex flex-col">

              <!-- profile editing fields -->
              <div class="flex flex-col p-6 border border-neutral-lighter rounded-xl mb-3">
                <BaseLoading v-if="!tmpProfile" />
                <template v-else>
                  <!-- first and last name edit -->
                  <div class="flex flex-col sm:flex-row pt-2">
                    <div class="sm:basis-1/2  md:pb-1 sm:mr-3">
                      <span class="input-label"> First Name*</span>
                      <baseInput v-model="tmpProfile.firstName" name="firstName" class="profile-input"
                                 placeholderText="Terry" @input="change" />
                    </div>
                    <div class="sm:basis-1/2 md:pb-1">
                      <span class="input-label"> Last Name*</span>
                      <baseInput v-model="tmpProfile.lastName" name="lastName" class="profile-input"
                                 placeholderText="Talkatoo" @input="change" />
                    </div>
                  </div>
                  <!-- job title phone number -->
                  <div class="flex flex-col sm:flex-row">
                    <div class="sm:basis-1/2 md:pb-1 sm:mr-3">
                      <span class="input-label"> Job Title (optional)</span>
                      <baseInput v-model="tmpProfile.jobTitle" name="jobTitle" class="profile-input"
                                 placeholderText="ex: Vet Assistant" @input="change" />
                    </div>
                    <div class="sm:basis-1/2 md:pb-1">
                      <span class="input-label"> Phone Number (optional)</span>
                      <baseInput v-model="tmpProfile.phoneNumber" name="phoneNumber" class="profile-input"
                                 placeholderText="(XXX)-XXX-XXXX" @input="change" />
                    </div>
                  </div>

                  <div :class="['flex flex-col sm:flex-row',userId == currentUser ? 'md:pb-1': ''] ">
                    <div class="basis-full">
                      <span class="input-label"> Email *</span>
                      <baseInput v-model="tmpProfile.email" name="email" class="profile-input opacity-30 pb-5"
                                 :disabled="true" />
                    </div>
                    <div v-if="userId != currentUser && $store.getters.getUserPerms.user_management"
                         class="flex sm:ml-4 my-auto">
                      <button
                        class="text-sm font-bold mt-2 whitespace-nowrap py-2 px-7 rounded-full  border-2 transition-colors border-main-alt text-main-alt hover:border-main-dark hover:text-main-dark hover:bg-gray-200 hover:bg-opacity-20"
                        @click="sendPasswordReset">
                        Send Password Reset
                      </button>
                    </div>
                  </div>
                  <!-- password editing -->
                  <div v-if="userId == currentUser" class=" flex w-full flex-col xl:flex-row">
                    <div class="basis-full xl:basis-1/2 xl:pr-3">
                      <span class="input-label"> Current Password *</span>
                      <baseInput v-model="currentPass" name="currentPassword" class="profile-input tracking-widest"
                                 type="password"
                                 :errorMessage="currPassError" />
                    </div>
                    <div class="block">
                      <span class="input-label"> New Password *</span>
                      <div class="relative flex basis-full xl:basis-1/2">
                        <div class="tooltip-basic relative">
                          <baseInput v-model="newPass" name="newPassword" class="profile-input new-pass-field flex-grow tracking-widest"
                                     :type="showPass ? 'text' : 'password'"
                                     :errorMessage="newPassError" />
                          <button aria-hidden="true"
                                  class="absolute right-2 top-[18px] transform  text-xs w-14 py-1
                                    rounded-full font-roboto font-semibold text-neutral-light hover:text-neutral hover:bg-gray-400 hover:bg-opacity-20 active:bg-opacity-40 transition-all"
                                  @click="showPass = !showPass">
                            {{ showPass ? "hide" : "show" }}
                          </button>
                          <div class="tooltip-text whitespace-nowrap ">
                            <span class="pb-1">requirements: </span>
                            <ul class="pr-2">
                              <li>6 characters long. </li>
                              <!-- <li>1 capital letter.</li> -->
                              <!-- <li>1 number.</li> -->
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mx-auto xl:mt-7 xl:ml-3">
                      <button id="savePassword"
                              class="text-sm font-bold h-11 py-2 px-7 rounded-full  border-2 transition-colors border-main-alt text-main-alt hover:border-main-dark hover:text-main-dark hover:bg-gray-200 hover:bg-opacity-20"
                              :disabled="!newPassValid || !currentPass || pendingChange"
                              @click="submitPassword">
                        Update
                      </button>
                    </div>
                  </div>
                </template>
              </div>
              <!-- roles and permissions -->
              <div class="flex flex-col p-6 border border-neutral-lighter rounded-xl mb-16 lg:mb-3">
                <template v-if="permissions">
                  <span class="pt-1 pb-5 font-extrabold text-sm">
                    Roles and Permissions
                  </span>
                  <div class="flex flex-wrap">
                    <button class="permission-button" :data-active="permissions.dictation" @click="togglePerm('dictation')">
                      Dictation
                    </button>
                    <button class="permission-button" :data-active="permissions.billing_management" @click="togglePerm('billing_management')">
                      Billing Management
                    </button>
                    <button class="permission-button" :data-active="permissions.user_management" @click="togglePerm('user_management')">
                      User Management
                    </button>
                    <button v-if="isUserScribe" class="permission-button" data-active="true" :disabled="true">
                      Scribe
                    </button>
                  </div>
                </template>

              </div>
            </div>
            <!-- right section -->
            <div class="flex md:flex-col md:ml-3 md:basis-80">
              <!-- change profile image -->
              <div class="flex flex-row flex-grow md:flex-grow-0 items-center pt-6 pb-5 pl-4 2xl:pl-12 pr-4
                            border border-neutral-lighter rounded-xl mb-3">
                <div class="flex mx-auto md:mx-0">
                  <img :src="`https://storage.googleapis.com/assets.talkatoo.ai/profile-photos/${tmpProfile?.profilePhoto}`"
                       class="min-h-[4rem] min-w-[4rem] h-16 w-16 rounded-2xl">
                  <button class="as-anchor font-bold ml-4 mt-2 md:mt-0" @click="togglePickerModal">
                    Change image
                  </button>
                </div>
              </div>
              <!-- change team collaboration -->
              <!-- <div class="flex flex-col p-6 border border-neutral-lighter rounded-xl mb-3">

                </div> -->
              <img src="@/assets/images/setup_wizard.svg" class="hidden md:block" />
            </div>

          </div>
        </div>
      </Transition>
      <Transition name="fade">
        <div v-if="changeMade || pendingChange" class="default-fade-duration flex lg:hidden fixed bottom-0 left-0 w-full py-3 z-10 bg-main-alt min-h-[68px]">
          <TransitionGroup name="fade-pull">
            <div v-if="pendingChange"
                 class="absolute flex default-fade-duration w-full top-1/2 left-1/2">
              <BaseLoading class="transition-none transform scale-50 -translate-y-1/2 -translate-x-1/2 mr-4" />
            </div>
            <div v-else-if="changeMade" key="1" class="flex flex-grow justify-end default-fade-duration replace-fade-pull w-full my-auto">
              <button id="saveProfile" :disabled="pendingChange"
                      class="rounded-full bg-white py-3 w-2/3 mx-auto text-main-darker font-roboto text-sm font-semibold"
                      @click="saveChanges">
                Save Changes
              </button>
            </div>
          </TransitionGroup>
        </div>
      </Transition>
    </FadeInWrapper>

  </div>

  <BaseModal v-if="!loading" title="Select your profile image" :toDisplay="showPickerModal" @toggleEvent="togglePickerModal">
    <div class="flex flex-wrap justify-center sm:grid sm:grid-cols-4 md:grid-cols-5 gap-4 p-4 max-h-[80vh] overflow-y-scroll">
      <button v-for="index in 20" :key="index"
              class="inline-block border-3 border-white
                 aria-selected:border-main-alt aria-selected:rounded-sm
                 hover:border-main-alt hover:border-opacity-50"
              :aria-selected="`profile-${index}.png` === selectedImage"
              @click="selectImage(index)">
        <img
          :src="`https://storage.googleapis.com/assets.talkatoo.ai/profile-photos/profile-${index}.png`"
          class="min-h-[8rem] min-w-[8rem] max-h-[8rem] max-w-[8rem] m-[1px] rounded-sm
                 "
          :aria-selected="`profile-${index}.png` === selectedImage" />
      </button>
      <!-- <button class="inline-block relative min-h-[8rem] min-w-[8rem] h-32 w-32 aria-selected:border-3 aria-selected:border-main-alt aria-selected:rounded-sm"
                :aria-selected="'' == selectedImage"
                @click="selectImage('none')">
          <baseIcon :text="initials" themeName="main" diameter="6.5">
          </baseIcon>
        </button> -->
    </div>
    <div class="flex justify-center p-5 border-t border-neutral-light">
      <button class="w-40  mr-3 py-2 px-4 text-sm font-bold border rounded-lg text-neutral-dark border-neutral-dark"
              @click="cancelPickerModal">
        CANCEL
      </button>
      <button class="btn-primary w-40 text-sm py-2 px-4 font-bold"
              @click="confirmImage">
        SELECT IMAGE
      </button>
    </div>
  </BaseModal>
</template>

<script src="./UserProfile.js"></script>
<style scoped src="./UserProfile.css"></style>
<style>
/* unscoped styling */
.profile-input {
  padding-bottom:inherit;
  @apply mt-1;
}
.profile-input.new-pass-field input {
  padding-right:4rem;
}
/* .profile-input input[type="password"] {
  letter-spacing: 0.1em;
} */

@media (min-width: 1024px) {
  .profile-input {
    padding-bottom:0px;
  }
}
</style>

import { computed } from 'vue';
const NotesService = require("Services/NotesService");
const UserService = require("Services/UserService");
const TemplateService = require("Services/TemplateService");

import MixpanelService from "Services/MixpanelService";
import BaseLoading from "Components/ui/BaseLoading.vue";
import BaseIcon from "Components/ui/BaseIcon.vue";
import BaseBox from "Components/ui/BaseBox.vue";
import BaseInput from "Components/ui/BaseInput.vue";
import BaseTextArea from "Components/ui/BaseTextArea.vue";
import BaseIconDropdown from "Components/ui/BaseIconDropdown";
import BaseDropdown from "Components/ui/BaseDropdown";
import BaseSelectList from 'Components/ui/BaseSelectList.vue';
import BaseModal from "Components/ui/BaseModal.vue";
import SoapTemplateRender from "./SoapTemplateRender.vue";
import RichTextField from "../RichTextField";
import NoteTimeline from "../NoteTimeline";
import CopyButton from "../CopyButton.vue";
import StandardTemplate from '@/components/layout/StandardTemplate.vue';

import NoteStatesMap from "Modules/NoteStatesMap";
import { ProseMirrorView } from './../ProseMirror.js';

export default {
  name: "Templated Note View",
  components: {
    BaseBox, BaseLoading, BaseInput, BaseIcon, BaseTextArea, BaseIconDropdown, BaseDropdown, BaseSelectList, BaseModal, RichTextField, NoteTimeline, CopyButton, StandardTemplate,
    SoapTemplateRender,
  },
  emits: ["noteViewChange", "refreshNote"],
  // TODO actually make use of the inNote
  props: { inNote: Object },
  data () {
    // * removed settings by always resetting the content of setting to default. avoids messing up any current settings applied or weird behavior
    const currUserId = this.$store.getters.getUserId;
    let config = localStorage.getItem(`BladedNote:${currUserId}-config`);
    if (config == null) {
      config = {
        defaultNoteAction: 'Complete and Archive',
      };
    } else {
      config = JSON.parse(config);
    }
    const defaultHighlightColor = "#fdd999";
    const defaultSettings = { showOriginal: true, showHighlighting: true, selectHighlightColor: false, highlightColor: defaultHighlightColor, altCopy: false };
    let noteSettings = defaultSettings;
    const params = new window.URLSearchParams(window.location.search);
    const hasIssue = params.get("has_issue") ?? false;
    const urlView = params.get("view") ?? undefined;  // don't want null
    return {
      urlView,
      config,
      currUserId,

      index: 0,
      loading: true,
      closingNote: false,

      note: undefined,

      volumeDragging: false,
      audioConfig: undefined,
      noAudio: false,
      // used to control playback bar dragging
      playbackBarDragging: false,
      // used to prevent keydown event
      focusedField: false,
      // toggle on copying text success message
      copiedAllText: false,
      copiedAllHtml: false,
      saveTimeout: 0,
      //flag to prevent double updating note on input
      ignoreInputEvents: false,
      // note view settings
      noteSettings: noteSettings,
      defaultHighlightColor,
      // page state bool
      showTranscript: false,
      // note requires attention modal props
      showAttentionModal: false,
      submittedNeedsAttn: false,
      selectedProblem: 1,
      problemDescription: '',
      otherProblem: '',
      problemsList: [
        { text: 'There is difficulty understanding a section of audio', value: 1 },
        { text: 'I had trouble understanding a word', value: 2 },
        { text: 'I believe the speaker misspoke', value: 3 },
        // {text: 'foo bar baz buzz', value: 4 },
        { text: 'Other', value: 999 },
      ],
      hasIssue: hasIssue == 'true',
      windowWidth: window.innerWidth,

      toggleShowAllIssuesMessaging: false,

      showNoteActions: false,

      templateKey: [],
      templateStyles: {},
      // properties for managing scribe data-collection state
      record: undefined,
      templateList: [],
      templateLoading: false,
      copiedAllLlmText: false,
      selectInitialValue: undefined,

      // websocket props
      wsIds: [],
      simuEditingWarning: false,

      editingTitle: false,
      titleTarget: { height: 0, width: 0, max: 0 },
      pendingTitleChange: false,
      titleSaveTimeout: -1,
      titleEndEditingTimeout: -1,
      titleSaved: false,

      settings: [],

      preferences: undefined,
      languageSelection: [
        { text: "English (United States)", value: 'en-us' },
        { text: "English (Canada)", value: 'en-ca' },
        { text: "English (United Kingdom)", value: 'en-uk' },
        { text: "English (Australia)", value: 'en-au' }],

      showTemplateOptions: false,
      tempSelectedTemplateType: '',
      templates: [],
      templateSettings: {},

      // for copying the full note
      draftCopyOfNote: undefined,
      copyableFullNotePmView: undefined,

      pendingChangeTimeout: -1,

      showComm: false,
      generatingComm: false,
    };
  },
  provide () {
    return {
      settings: computed(() => this.noteSettings),
      defaultHighlightColor: this.defaultHighlightColor,
    };
  },
  async created () {
    if (this.inNote !== undefined) {
      this.note = this.parseNoteInts(this.inNote);
      this.draftCopyOfNote = window.structuredClone(this.note);
      await this.getPageData();
      await Promise.allSettled([this.getCustomNilValues(),
      this.getCustomTemplateSettings()]);
      this.setTemplateKey(this.selectedTemplateType);
      this.tempSelectedTemplateType = this.selectedTemplateType;
    }
  },

  mounted () {
    // removing event listeners to avoid n > 1  number of listeners on the same event with HMR while developing
    document.removeEventListener('keydown', this.keyHandler);
    document.addEventListener('keydown', this.keyHandler);
    document.removeEventListener('mouseup', this.mouseUp);
    document.addEventListener('mouseup', this.mouseUp);
    // removing this one incase if it got left around from dragging while naving somehow
    document.removeEventListener('mousemove', this.volumeChange);
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onNoteStateEvent(this.noteStateEventHandler));
    // this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onNoteIssueEvent(this.noteIssueEventHandler));
    this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onInternalEvent(this.noteInternalEvent));
  },
  watch: {
    async inNote (newVal, oldVal) {
      // commit any of the previous notes changes
      let saved = false;
      for (let i = 0; i < this.templateKey.length; i++) {
        if (this.templateKey[i].timeout !== -1) {
          window.clearTimeout(this.templateKey[i].timeout);
          this.templateKey[i].timeout = -1;
          let { field, text, noteId, templateToUpdate, templateType } = this.templateKey[i].props;
          delete this.templateKey[i].props;
          this.commitTemplateChange(field, text, noteId, templateToUpdate, templateType);
          this.saved = true;
          break; // break because only one really needs to save
        }
      }

      if (!saved && this.selectedTemplate?.props && this.selectedTemplate.timeout !== -1) {
        window.clearTimeout(this.selectedTemplate.timeout);
        this.selectedTemplate.timeout = -1;
        let { field, text, noteId, templateToUpdate, templateType } = this.selectedTemplate.props;
        delete this.selectedTemplate.props;
        this.commitTemplateChange(field, text, noteId, templateToUpdate, templateType);
      }


      // scroll to the top of the window
      let noteView = document.getElementsByClassName("note-view-container");
      if (noteView && noteView[0]) {
        noteView[0].scrollTo(0, 0);
      }
      // reset page configuration
      this.titleSaved = false;
      this.editingTitle = false;
      this.loading = true;
      this.showComm = false;
      this.pausePlayback();
      this.note = this.parseNoteInts(newVal);
      this.draftCopyOfNote = window.structuredClone(this.note);

      const params = new window.URLSearchParams(window.location.search);
      this.urlView = params.get("view") ?? undefined;  // don't want null
      let availableTemplates = this.note.general_templates.map(x => x.type);
      // validate url view and preferred view
      if (availableTemplates.length == 0) {
        this.urlView = "no_template"; // this is a fallback
      } else if (availableTemplates.length == 1) {
        this.urlView = availableTemplates[0];
        // if not url view check preferred view then just pick soap if available. fallback is no template.
      } else if (!availableTemplates.some(x => x == this.urlView)) {
        this.urlView = availableTemplates.some(x => x == this.note.metadata.user_preferred_view)
          ? this.note.metadata.user_preferred_view :
          this.note.general_templates.find(x => x.type == 'soap')
            ? 'soap' : this.note.general_templates[0].type; // default on trying to get soap, otherwise just take the first template
      }

      this.tempSelectedTemplateType = this.selectedTemplateType;
      this.setTemplateKey(this.selectedTemplateType);

      await this.getPageData();
      // todo get by notes author's custom nil value
      await this.getCustomNilValues();
      this.getCustomTemplateSettings();
    },
    templateKey: {
      handler: function (newVal, oldVal) {
        // // check for collapsed sections of a note
        // console.log("templateKey handler");

        // if (newVal.length !== oldVal.length) return;
        // for (let i = 0; i < oldVal.length; i++) {
        //   if (oldVal[i].timeout !== -1) {
        //     window.clearTimeout(oldVal[i].timeout);
        //     oldVal[i].timeout = -1;
        //     let { field, text, noteId, templateToUpdate, templateType } = oldVal[i].props;
        //     delete oldVal[i].props;
        //     console.log("templateKey handler commit");
        //     this.commitTemplateChange(field, text, noteId, templateToUpdate, templateType);
        //     break; // break because only one really needs to save
        //   }

        // }
      }, deep: true
    }
  },
  computed: {
    // #region User Permissions state management
    isUserBillingManager () {
      return this.$store.getters.getUserPerms.billing_management;
    },
    isTrialExpired () {
      let sub = this.$store.getters.getSubscription;

      if (sub.subscription_state === "paused" || sub.subscription_state === "cancelled" && sub.trial_expires_at < new Date().getTime() / 1000)
        return true;
      return false;
    },
    isUserScribe () {
      if (!this.$store.getters.getUserGroups) return false;
      return this.$store.getters.getUserGroups.findIndex(x => x === "Scribe") !== -1;
    },
    isUserVerified () {
      return this.$store.getters.getUserPerms.verified;
    },
    showVerifiedFeatures () {
      return this.isUserScribe || this.isUserVerified || this.note?.verification_flag;
    },
    hasLmmTestingFlag () {
      return this.$store.getters.getFeatureFlags.llmtesting;
    },
    //#endregion

    //#region Note property display helpers
    noteState () {
      if (!this.note) return '';
      if (this.note.needs_attention) return 'Needs Attention';
      if (this.note?.archived) return 'Archived';
      let key = this.note.state;
      return NoteStatesMap[key];
    },
    noteCreatedOn () {
      if (!this.note) return '';
      const createdAt = new Date(this.note.created_at * 1000);
      return {
        date: Intl.DateTimeFormat(undefined, { year: "numeric", month: "long", day: "numeric" }).format(createdAt),
        time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", second: "numeric" }).format(createdAt),
      };
    },
    notePublishedOn () {
      if (!this.note) return '';
      let ts = this.note.published_at == 0 ? this.note.last_edited : this.note.published_at;
      const publishedAt = new Date(ts * 1000);
      return {
        date: Intl.DateTimeFormat(undefined, { year: "numeric", month: "long", day: "numeric" }).format(publishedAt),
        time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", second: "numeric" }).format(publishedAt),
      };
    },
    noteEditedOn () {
      if (!this.note || this.note.last_edited == 0) return '';
      const editedAt = new Date(this.note.last_edited * 1000);
      const now = new Date();

      let res = {
        time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", second: "numeric" }).format(editedAt),
      };
      // if edited in the last day, use a different format
      res.date = Intl.DateTimeFormat(undefined, { year: "numeric", month: "long", day: "numeric" }).format(editedAt);
      // if (now.getTime() - (24 * 60 * 60 * 1000) - editedAt.getTime() > 0) {
      // } else {
      //   // res.date = now.getTime() - (24 * 60 * 60 * 1000) - editedAt.getTime();
      // }
      return res;
    },


    //#endregion

    // #region Template key functions for selecting template to display by type
    preferredTemplate () {
      return this.note.metadata.user_preferred_view;
    },
    availableTemplates () {
      if (!this.note) return [];
      let list = [/* 'no_template' */];
      if (this.note.edited_transcript) {
        list = list.concat(this.note.general_templates.map(x => x.type));
      }
      return list;
    },
    selectedTemplateType () {
      // take url Query param first
      if (this.urlView) {
        return this.urlView;
      }
      // then the metadata preferred view
      if (this.preferredTemplate) {
        return this.preferredTemplate;
      }
      // otherwise try to default to soap
      if (this.note) {
        if (this.note.general_templates > 0) {
          if (this.note.general_templates.find(x => x.type == "soap")) {
            return "soap";
          } else {
            return this.note.general_templates[0].type; // just show the first template ig
          }
        }
      }
      return 'no_template';
    },

    selectedTemplateFields () {
      if (!this.note || this.selectedTemplateType === "no_template") return undefined;
      // if (this.selectedTemplateType == "soap")
      //   return this.note.note_templates.find(x => x.type == this.selectedTemplateType)[this.selectedTemplateType];

      return this.note.general_templates.find(x => x.type == this.selectedTemplateType && x.general_template).general_template.sections;
    },
    selectedTemplate () {
      if (!this.note || this.selectedTemplateType === "no_template") return undefined;
      return this.note.general_templates.find(x => x.type == this.selectedTemplateType && x.general_template);
    },

    // #endregion

    // #region NoteAction dropdown computed properties
    // provides a list of strings which represent the actions a user may take on the note

    possibleNoteActions () {
      if (!this.note) return [];
      let list = [];
      if (this.userOwnsNote) {
        if (this.note.archived) {
          list.push("Unarchive Note");
        } else {
          list.push("Archive Note");
        }
      }
      if (NoteStatesMap[this.note.state] === 'In PMS') {
        // un complete. is that something a user will do
      } else {
        list.push("Mark as Complete");
      }
      if (this.userOwnsNote && !this.note.archived && NoteStatesMap[this.note.state] !== 'In PMS') {
        list.push("Complete and Archive");
      }
      // let i = list.findIndex((el) => el == this.config.defaultNoteAction);

      // if (i !== -1) {
      //   list.splice(i,1)
      // }
      return list;

    },
    selectedNoteAction () {
      // const validActions = ['Complete and Archive', 'Mark as Complete', 'Archive Note', 'Unarchive Note'];
      if (this.possibleNoteActions.length === 1) return this.possibleNoteActions[0];
      let i = this.possibleNoteActions.findIndex((el) => { return el === this.config.defaultNoteAction; });
      if (i !== -1) return this.config.defaultNoteAction;
      // wont be in possible actions if state has changed. Eg note is archived and user's default action is to archive
      switch (this.config.defaultNoteAction) {
        case ('Complete and Archive'): return 'Unarchive Note';
        case ('Unarchive Note'): return 'Archive Note';
        case ('Archive Note'): return 'Unarchive Note';
        case ('Mark as Complete'): return 'Archive Note';

      }
    },
    userOwnsNote () {
      if (!this.note) return false;
      return this.$store.getters.getUserId == this.note.user_id;
    },
    //#endregion


    // #region Media controls, playback state rendering
    canPlay () {
      return this.audioConfig && this.audioConfig.canPlay;
    },
    isPlaying () {
      return this.audioConfig?.isPlaying ?? false;
    },

    // used to pass through to RTF to highlight the currently playing word
    currentTime () {
      return this.audioConfig?.currTime ?? 0;
    },
    currTimeMinutes () {
      if (!this.audioConfig) {
        return "0:00";
      }
      return this.secondsToMinutesString(this.audioConfig.currTime);
    },
    durationMinutes () {
      if (!this.audioConfig) {
        return "0:00";
      }
      return this.secondsToMinutesString(this.audioConfig.duration);
    },
    volumeIcon () {
      if (!this.audioConfig) return 'volume_up';
      if (this.audioConfig.volume > .75) return 'volume_up';
      if (this.audioConfig.volume > 0) return 'volume_down';
      return 'volume_off';  //'volume_mute'
    },
    // #endregion

    // #region editing helpers
    // tri-state return for displaying loading animation
    mdSaveState () {
      switch (this.saveTimeout) {
        case -1: return "done";
        case 0: return "none";
      }
      return "pending";
    },
    enableEditing () {
      if (!this.note) return false;
      return !this.isTrialExpired && !this.note.archived && this.noteState !== "Draft";
    },
    // #endregion

    // #region Copying and computed text output of note

    // parses notes(plain text) transcript or templates into a string
    // copyableNote () {
    //   let note = '';
    //   if (this.selectedTemplateType === "no_template") return this.note.edited_transcript_string;
    //   if (!this.selectedTemplateFields)
    //     return note;
    //   Object.keys(this.selectedTemplateFields).forEach((key, index) => {
    //     if (key == "ratings") return;
    //     note += `${key.slice(0, 1).toUpperCase()}${key.slice(1)}: \n`;
    //     note += this.selectedTemplateFields[key];
    //     if (index != note.length - 1) {
    //       note += '\n';
    //     }
    //   });
    //   return note;
    // },

    // parses notes (plain text) transcript or templates into a html string for rendering and copying
    // copyableRichTextNote () {
    //   let note = '';
    //   if (navigator.userAgent.match("Firefox")) {
    //     Object.keys(this.selectedTemplateFields).forEach((key, index) => {
    //       if (key == "ratings") return;
    //       note += `<strong>${key.slice(0, 1).toUpperCase()}${key.slice(1)}</strong> <br/>`;
    //       if (key == "objective") {
    //         note += "<br/>";
    //         let section = "";
    //         this.selectedTemplateFields[key].split('\n').forEach(seg => {
    //           const regex = /(^|\t)?([\w\s/]+?:)/gm;
    //           let m = regex.exec(seg);
    //           if (m !== null) {
    //             m.lastIndex = m.index + m[0].length;
    //             let tmp = seg.substr(0, m.index);
    //             tmp += `<strong>${m[2]}</strong>`;
    //             tmp += seg.substr(m.lastIndex);
    //             section += `<span>${tmp} </span><br/>`;
    //           } else {
    //             section += `<span>${seg} </span><br/>`;

    //           }
    //         });
    //         note += section;
    //       } else {
    //         note += this.selectedTemplateFields[key].replaceAll('\n', "<br/>");
    //       }
    //       if (index != note.length - 1) {
    //         note += '<br/>';
    //       }
    //     });
    //   } else {
    //     if (!this.selectedTemplateFields) return;
    //     Object.keys(this.selectedTemplateFields).forEach((key, index) => {
    //       if (key == "ratings") return;
    //       note += `<span class="font-bold">${key.slice(0, 1).toUpperCase()}${key.slice(1)}</span> <br/>`;
    //       if (key == "objective") {
    //         let section = "";
    //         this.selectedTemplateFields[key].split('\n').forEach(seg => {
    //           const regex = /(^|\t)?([\w\s/]+?:)/gm;
    //           let m = regex.exec(seg);
    //           if (m !== null) {
    //             m.lastIndex = m.index + m[0].length;
    //             let tmp = seg.substr(0, m.index);
    //             tmp += `<span class="font-bold">${m[2]}</span>`;
    //             tmp += seg.substr(m.lastIndex);
    //             section += `<span class="no-style">${tmp} </span><br/>`;
    //           } else {
    //             section += `<span class="no-style">${seg} </span><br/>`;

    //           }
    //         });
    //         note += section;
    //       } else {
    //         this.selectedTemplateFields[key].split('\n').forEach(seg => {
    //           note += `<span class="no-style">${seg} </span><br/>`;
    //         });
    //       }

    //       if (index != note.length - 1) {
    //         note += '<br/>';
    //       }
    //     });
    //   }

    //   return note;
    // },
    originalTranscript () {
      return this.note.transcript_string;
    },

    // performs update before copying takes place
    copyableFullNotePlainText () {
      if (!this.draftCopyOfNote || !this.note) return;
      // no need to update anything with the no template option
      let fullNote = "";
      if (this.note.state == 0 /* draft */ ||
        this.note.state == 5/* processing */) return;
      if (this.selectedTemplateType === "no_template") {
        fullNote = this.note.edited_transcript_string;
      } else {
        let tmpTemplate = this.draftCopyOfNote.general_templates.find(x => x.type == this.selectedTemplateType);
        if (tmpTemplate) {
          let template = tmpTemplate.general_template;
          // new templating
          if (template) {
            template.sections.forEach((x, index) => {
              if (x.copy_all) {
                if (!this.settingsShow(x.header.toLowerCase().replace(" ", "-"))) return;

                if (index !== 0) fullNote += '\n\n\n\n';
                fullNote += `${x.header.slice(0, 1).toUpperCase()}${x.header.slice(1)}** \n\n\n\n`;
                fullNote += x.content;

              }
            });
          } else {
            // old format
            template = tmpTemplate[this.selectedTemplateType];
            fullNote = Object.keys(template).reduce((prop, field, index) => {
              // join all the bits of the note together for a full note
              if (field == "ratings") return prop;
              if (index !== 0) prop += '\n\n\n\n';
              prop += `**${field.slice(0, 1).toUpperCase()}${field.slice(1)}** \n\n\n\n`;
              if (template[field]) {
                prop += template[field];
              }
              return prop;
            }, '');
          }
        } else { //this.selectedTemplateType == "no_template", essentially
          fullNote = this.draftCopyOfNote.edited_transcript_string;
        }
      }

      // take out regex special characters
      fullNote = fullNote.replaceAll(/\n{2}/g, '\n');
      // .replaceAll(/(?<!\\)\*+/g, '')
      // find all unescaped * and remove them
      for (let i = 1; i < fullNote.length - 1; i++) {
        if (fullNote.charCodeAt(i) == 42 /* '*' */ && fullNote.charCodeAt(i - 1) != 92/* \ */) {
          fullNote = fullNote.substring(0, i) + fullNote.substring(i + 1);
          i--;
        }
      }
      if (fullNote.charCodeAt(0) == 42) fullNote = fullNote.substring(1);
      fullNote = fullNote
        .replace(/\\([*_`{}\[\]()#+\-.!])/g, '$1').trim();
      return fullNote;
    },
    //#endregion

  },
  methods: {
    // #region Copying and computed text output of note
    // performs update before copying takes place
    updateFullNoteCopyField () {
      // no need to update anything with the no template option
      if (this.selectedTemplateType == "no_template") return;
      let template = this.draftCopyOfNote.general_templates.find(x => x.type == this.selectedTemplateType).general_template;

      let fullNote = "";
      // join all the bits of the note together for a full note
      for (let i = 0; i < template.sections.length; i++) {
        let section = template.sections[i];
        if (!this.settingsShow(section.header.toLowerCase().replace(" ", "-"))) continue;
        if (!section.copy_all) continue;
        if (i !== 0) fullNote += '\n\n\n\n';
        fullNote += `**${section.header}**\n\n\n\n`;
        fullNote += section.content;
      }

      fullNote = fullNote.trimEnd();
      this.copyableFullNotePmView = undefined;
      // remove and rebuild copyable rich text field
      let editor = document.getElementById(`text-area-rich-note`);
      while (editor.children.length > 0) {
        editor.children[0].remove();
      }
      this.copyableFullNotePmView = new ProseMirrorView(editor, fullNote);
    },
    copyableDraftPlainText (field) {
      let text = "";
      if (field === 'no_template') {
        text = this.note.edited_transcript_string;
        text = text.replaceAll(/\n{2}/g, '\n');
      } else {
        text = field.section.content.replaceAll(/\n{2}/g, '\n');
      }
      // .replaceAll(/(?<!\\)\*+/g, '')
      // find all unescaped * and remove them
      for (let i = 1; i < text.length; i++) {
        if (text.charCodeAt(i) == 42 /* '*' */ && text.charCodeAt(i - 1) != 92/* \ */) {
          text = text.substring(0, i) + text.substring(i + 1);
          i--;
        }
      }
      if (text.charCodeAt(0) == 42) text = text.substring(1);
      text = text.replace(/\\([*_`{}\[\]()#+\-.!])/g, '$1').trim();
      return text;
    },

    copyRichField (field) {
      field.show = true;
      let content = field.section.content;

      // join all the bits of the note together for a full note
      let copyContent = "";
      // section title. Not wanted in section copy?
      // copyContent = `**${field.field.slice(0, 1).toUpperCase()}${field.field.slice(1)}** \n\n`;
      if (content) {
        copyContent += content;//.replaceAll(/\n{2}/g, "  \n  \n");
        // copyContent += content.replaceAll(/\n{2}/g, "  \n");
      }

      // remove and rebuild copyable rich text field
      copyContent = copyContent.trimEnd();
      let editor = document.getElementById(`copyable-rich-${field.tag}`);
      while (editor.children.length > 0) {
        editor.children[0].remove();
      }
      new ProseMirrorView(editor, copyContent);
    },
    //#endregion

    // #region Template selection action handlers and mutators
    // takes in a field from a template section and determines whether to have the section expanded or collapsed
    showField (field) {
      return (field.show && !field.section.manual_generation) ||
        (field.show && field.section.manual_generation && field.section.content !== '');
    },

    setTemplateKey (templateName) {
      switch (templateName) {

        case ("no_template"):
          this.templateKey = [];
          break;
        default:
          let template = this.draftCopyOfNote.general_templates.find(x => x.type == templateName);

          if (!template) {
            console.warn("unexpected template type, cannot provide template key");
            this.templateKey = [];
            return;
          }

          this.templateKey = template.general_template.sections.sort((a, b) => a.order - b.order)
            .map(x => {
              return {
                header: x.header,
                tag: x.header.toLowerCase().replace(" ", "-"),
                show: true,
                timeout: -1,
                section: x
              };
            });
      }
    },

    // toggle for template selection dropdown
    toggleTemplateSelection () {
      this.showTemplateOptions = !this.showTemplateOptions;
    },
    // Action taken when a user selects a template in the template dropdown. This is the placeholder before nav
    selectTemplate (templateName) {
      this.tempSelectedTemplateType = templateName;
    },
    navSelectedTemplate () {
      this.showTemplateOptions = false;
      this.urlView = this.tempSelectedTemplateType;
      this.$emit("noteViewChange", this.tempSelectedTemplateType);
      this.setTemplateKey(this.tempSelectedTemplateType);
    },
    prettyTemplateName (key) {
      const map = {
        "SOAP": "Auto-SOAP",
        "soap": "Auto-SOAP",
        "text": "No Template",//"Text View",
        "no_template": "No Template",
        "transcript": "Transcript",
        "assistant": "Assistant",
        "telephony": "Call Summary",
      };
      return map[key] ? map[key] : key;
    },
    // #endregion

    // provides boolean to display a template's field based on the user's template settings
    settingsShow (field) {
      if (this.selectedTemplate.type !== "soap") return true;
      if (!this.templateSettings?.sections) return true;
      return !this.templateSettings.sections.find(x => x.name.toLowerCase() == field.toLowerCase())?.hide_section;
    },

    // returns a format of 0:00 from a number of seconds
    secondsToMinutesString (time) {
      if (!time) return '0:00';
      if (time === Infinity || isNaN(time)) return '0:00';
      const minutes = parseInt(time / 60);
      const seconds = parseInt(time % 60);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },



    // #region emitter functions
    refreshNote () {
      this.$emit('refreshNote');
    },
    navOldNoteView () {
      this.$emit("noteViewChange");
    },
    //  #endregion

    // #region websocket event handlers
    noteStateEventHandler (event) {
      // if current user triggered this event. ignore the event
      if (event.noteId != this.note.id) return;
      if (event.eventUserId != this.$store.getters.getUserId) {
        this.simuEditingWarning = true;
        this.$toast.error({
          message: `There has been a change to this note!`,
          action: { fn: this.refreshNote, message: "Refresh" }
        });
      }
      // oldstate is processing
      if (event.oldState == 5 && this.note.state) {
        NotesService.GetNote(this.note.id).then((resp) => {
          this.note = this.parseNoteInts(resp.data);
          this.draftCopyOfNote = window.structuredClone(this.note);
          let availableTemplates = this.note.general_templates.map(x => x.type);
          // validate url view and preferred view
          if (availableTemplates.length == 0) {
            this.urlView = "no_template"; // this is a fallback
          } else if (availableTemplates.length == 1) {
            this.urlView = availableTemplates[0];
            // if not url view check preferred view then just pick soap if available. fallback is no template.
          } else if (!availableTemplates.some(x => x == this.urlView)) {
            this.urlView = availableTemplates.some(x => x == this.note.metadata.user_preferred_view)
              ? this.note.metadata.user_preferred_view :
              this.note.general_templates.find(x => x.type == 'soap')
                ? 'soap' : this.note.general_templates[0].type; // default on trying to get soap, otherwise just take the first template
          }

          this.tempSelectedTemplateType = this.urlView;
          this.navSelectedTemplate();
        }).then(() => {
          this.getAudio();
        }).catch(() => {
          this.$toast.success({
            message: "note has been processed! refresh to update this page",
            action: { fn: this.refreshNote, message: "Refresh" }
          });
        });
      } else {
        this.note.state = event.newState;
      }
    },
    noteInternalEvent (event) {
      if (event.type === "Note:ArchiveChange") {
        if (this.note.id == event.noteId) {
          this.note.archived = event.archived;
        }
      }
    },
    // #endregion

    // #region api GET Requests

    async getPageData () {
      // note Data is provided by NoteViewEntry, so we don't actually get the note here, just audio, issues etc.
      if (this.note && this.note.general_templates.length === 0) {
        this.showTranscript = true;
      }
      let issuesPromise;
      this.getAudio();
      if (this.note.general_templates.length === 0) {
        this.$router.push(`/notes/${this.note.id}${this.note.needs_attention ? '?has_issue=true' : ''}`);
      }

      // if there is no issues, or we are pre-requesting issues, return, do not get issues
      if (!this.note.needs_attention || this.hasIssue) return;
      NotesService.GetNoteIssues(this.note.id)
        .then((resp) => {
          this.note.issues = this.parseIssuesInts(resp.data.issues.filter(x => !x.resolved));
          this.sortNNA();
        });

      let noteIssues;
      if (this.hasIssue) {
        issuesPromise = NotesService.GetNoteIssues(this.$route.params.noteId)
          .then((resp) => {
            noteIssues = resp.data.issues.filter(x => !x.resolved);
            noteIssues = this.parseIssuesInts(noteIssues);
          });
        Promise.all([issuesPromise])
          .then((resps) => {
            this.note.issues = noteIssues;
          }).catch((err) => {
            console.log(err);
          });
      }
    },

    // #region Audio request handling
    // get all audio for segments in the note.
    getAudio () {
      if (!this.note.assistant_generated) {
        return NotesService.GetNoteAudio(this.note.id)
          .then((resp) => this.handleBlob(resp, this.note.id))
          .catch((err) => {
            this.noAudio = true;
            console.log("no audio found", err);
          });
      }
    },

    async handleBlob (resp, noteId) {
      let blobURL;
      // using relative assets for testing because testing framework destroys response of passthrough request
      if (__ENV === 'test') {
        blobURL = require(`@/tests/audio/lorem_ipsum.wav`);
      } else {
        blobURL = window.URL.createObjectURL(resp.data);
      }
      let audio = new Audio(blobURL);
      audio.src = blobURL;
      audio.id = `audio-${noteId}`;
      audio.load();

      let rate = localStorage.getItem("playbackRate");
      if (rate !== null) {
        audio.playbackRate = parseFloat(rate);
      }
      let tl = document.getElementById('timeline');
      if (tl == undefined) return; // user change the view while promise is running
      tl.appendChild(audio);
      // push latest audio clip data into
      let audioConfig = {
        // data: blob,
        dataURL: blobURL,
        id: noteId,
        audioTag: audio,
        currTime: 0,
        duration: audio.duration,
        volume: audio.volume,
        playbackRate: audio.playbackRate,
        isPlaying: false,
        canPlay: false,
        order: undefined, //gets set once all audio is gathered and sorted, also acts like a 1 indexed unique id
        yPos: 0,          // gets set once all audio is gathered and sorted, and dom is rendered with RTF to match the position to align with the fields
        // these function handlers the the events from the audio tag and pull them out for the v-dom to react on
        _loadedHandler: (e) => {
          this.audioConfig.duration = this.audioConfig.audioTag.duration;
          this.audioConfig.canPlay = true;
          this.audioConfig.playbackRate = this.audioConfig.audioTag.playbackRate;
        },
        _playingHandler: () => {
          /* the audio is now playable; play it if permissions allow */
          this.audioConfig.isPlaying = true;
        },
        _pauseHandler: () => {
          this.audioConfig.isPlaying = false;
        },
        _currentTimeHandler: () => {
          this.audioConfig.currTime = this.audioConfig.audioTag.currentTime;
        },
        _volumeChangeHandler: () => {
          this.audioConfig.vol = this.audioConfig.audioTag.volume;
        },
        _rateChangeHandler: () => {
          this.audioConfig.playbackRate = this.audioConfig.audioTag.playbackRate;
        },
        _durationChangeHandler: () => {
          this.audioConfig.duration = this.audioConfig.audioTag.duration;
        },
      };
      this.audioConfig = audioConfig;
      this.noAudio = false;
      audio.addEventListener("canplaythrough", audioConfig._loadedHandler);
      audio.addEventListener('playing', audioConfig._playingHandler);
      audio.addEventListener('pause', audioConfig._pauseHandler);
      audio.addEventListener('timeupdate', audioConfig._currentTimeHandler);
      audio.addEventListener('volumechange', audioConfig._volumeChangeHandler);
      audio.addEventListener('ratechange', audioConfig._rateChangeHandler);
      audio.addEventListener('durationchange', audioConfig._durationChangeHandler);
    },
    // #endregion
    getCustomNilValues () {
      return UserService.GetCustomNullValues()
        .then((results) => {
          const emptyValues = [
            // vitals
            { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Temperature", display_name: "Temperature" },
            { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Heart Rate", display_name: "Heart Rate" },
            { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Breathing", display_name: "Breathing" },
            { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Mucus Membranes", display_name: "Mucus Membranes" },
            { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Capillary Refill Time", display_name: "Capillary Refill Time" },
            { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Weight", display_name: "Weight" },
            { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Body Condition Score", display_name: "Body Condition Score" },
            { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Pain Score", display_name: "Pain Score" },
            // physical
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Attitude/Behavior" },
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Hydration" },
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Nose/Throat" },
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Eyes" },
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Ears" },
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Mouth/Teeth" },
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Heart/Blood Vessels" },
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Lungs/Airways" },
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Abdomen" },
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Gastrointestinal System" },
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Anal Glands" },
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Coat/Skin/Nails" },
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Lymph Nodes" },
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Musculoskeletal" },
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Central Nervous System" },
            { template_type: "SOAP", value: "Within normal limits", section: "objective", subsection: "Urinary/Genitals" },

            // ??
            // { template_type: "SOAP", value: "", section: "objective", subsection: "Cardiovascular" },
            // { template_type: "SOAP", value: "", section: "objective", subsection: "Respiratory" },
            // { template_type: "SOAP", value: "", section: "objective", subsection: "Neurological" },
            // { template_type: "SOAP", value: "", section: "objective", subsection: "Lymphatic" },
            // { template_type: "SOAP", value: "", section: "objective", subsection: "Gastrointestinal" },
            // { template_type: "SOAP", value: "", section: "objective", subsection: "Reproductive/Urinary" },
            // { template_type: "SOAP", value: "", section: "objective", subsection: "Fur/Skin" },
            // { template_type: "SOAP", value: "", section: "objective", subsection: "Respiratory Rate" },
          ];
          let tmp = results.data.default_values;

          tmp = tmp
            .filter(x => x.subsection !== "")
            .filter(x => x.section !== "Objective");// temporary TODO phase 3 work with
          // get unique records
          tmp = tmp.filter((x, index) => {
            return index === tmp.findIndex(y => y.subsection == x.subsection);
          });

          for (let i = 0; i < tmp.length; i++) {
            let v = emptyValues.findIndex((val) => val.subsection == tmp[i].subsection);
            if (v !== -1) {
              // doing this value swap gives us the preferred ordering of the emptyValues set
              emptyValues[v] = tmp[i];
              tmp.splice(i, 1);
              i--;
            }
          }
          this.settings = [...emptyValues, ...tmp];
          this.settings = this.settings.map((x) => x.subsection.toLowerCase());
        });
    },
    getCustomTemplateSettings () {
      return UserService.ListUserTemplateSettings()
        .then((resp) => {
          this.templates = resp.data.templates;
          if (this.templates.length !== 0) {
            this.templateSettings = this.templates[0];
          }
        });
    },

    getAllIssues () {
      if (this.toggleShowAllIssuesMessaging) {
        this.toggleShowAllIssuesMessaging = !this.toggleShowAllIssuesMessaging;
        this.note.issues = this.note.issues.filter(x => !x.resolved);
        return;
      }
      NotesService.GetNoteIssues(this.note.id)
        .then(resp => {
          if (resp.data.issues.length == 0) {
            this.$toast.success({ message: "There were no issues on this note!" });
            return;
          }
          this.toggleShowAllIssuesMessaging = !this.toggleShowAllIssuesMessaging;
          this.note.issues = this.parseIssuesInts(resp.data.issues);
          this.sortNNA();
        }).catch(err => {
          console.log(err);
        });
    },
    // #endregion

    // #region playback controls
    // listener for the keyboard events to control playback

    keyHandler (event) {
      let code = event.code;
      let ctrlKey = event.ctrlKey;
      if (!code || !ctrlKey) return;

      // if (this.focusedField) return;
      switch (code) {
        case ("KeyK"):
          if (this.audioConfig) this.togglePlayback();
          event.preventDefault();
          event.stopPropagation();
          break;
        case ("KeyJ"):
          this.changeCurrTime(-5);
          event.preventDefault();
          event.stopPropagation();
          break;
        case ("KeyL"):
          this.changeCurrTime(5);
          event.preventDefault();
          event.stopPropagation();
          break;
      }
    },
    changePlaybackRate (rate) {
      if (!this.audioConfig) return;
      this.audioConfig.audioTag.playbackRate = rate;
      localStorage.setItem("playbackRate", rate);
    },
    togglePlayback () {
      if (this.audioConfig.isPlaying) {
        this.audioConfig.audioTag.pause();
      } else {
        this.audioConfig.audioTag.play();
      }
    },
    pausePlayback () {
      if (this.audioConfig && this.audioConfig.isPlaying) {
        this.audioConfig.audioTag.pause();
      }
    },
    // sets default playback values for changing from one clip to the next or selecting clips
    resetClip () {
      this.pausePlayback();
      // resetting on tag, all eventHandlers should set any reactive props as well
      this.audioConfig.audioTag.currentTime = 0;
    },    // sets default playback values for changing from one clip to the next or selecting clips
    endClip () {
      this.pausePlayback();
      // resetting on tag, all eventHandlers should set any reactive props as well
      this.audioConfig.audioTag.currentTime = this.audioConfig.audioTag.duration;
    },
    seekClip (event) {
      if (!this.canPlay) return;
      if (!this.playbackBarDragging && event.type === "mousemove") return;
      if (event.type === "mousedown") {
        this.playbackBarDragging = true;
        document.addEventListener('mousemove', this.seekClip);
      }
      else if (event.type === "mouseup") {
        this.playbackBarDragging = false;
        document.removeEventListener('mousemove', this.seekClip);
      }
      const rect = document.getElementById("playback-bar").getBoundingClientRect();
      const absX = event.x;
      // get relative click position in the timeline
      const relX = absX - rect.x;
      // translate relative click position to percentage to seek to the relative audio time
      const setTime = (this.audioConfig.audioTag.duration * (relX / rect.width));
      this.audioConfig.audioTag.currentTime = setTime;
      if (!this.audioConfig.isPlaying)
        this.audioConfig.audioTag.play();
    },
    mouseUp () {
      this.volumeDragging = false;
      this.playbackBarDragging = false;
    },
    volumeChange (event) {
      if (!this.volumeDragging && event.type === "mousemove") return;

      // adding the mouse move event handler fsor when the user drags outside of the volume div
      if (event.type === "mousedown") {
        this.volumeDragging = true;
        document.addEventListener('mousemove', this.volumeChange);
      }
      else if (event.type === "mouseup") {
        this.volumeDragging = false;
        document.removeEventListener('mousemove', this.volumeChange);
      }
      const rect = document.getElementById("volume-slider").getBoundingClientRect();
      const absX = event.x;
      // get relative click position in the timeline
      const relX = absX - rect.x;
      // translate relative click position to percentage to seek to the relative audio time
      let setVolume = (relX / rect.width);
      if (setVolume < 0.1) setVolume = 0;
      else if (setVolume > 0.95) setVolume = 1;
      this.audioConfig.audioTag.volume = setVolume < 0.1 ? 0 : setVolume;
      this.audioConfig.volume = setVolume < 0.1 ? 0 : setVolume;
    },
    // this adds some positive or negative value to the current time to seek through playback. bound to arrow keys for +/- 5 seconds
    changeCurrTime (val) {
      let newTime = 0;
      if (val < 0) {
        newTime = Math.max(0, this.audioConfig.audioTag.currentTime + val);
      } else {
        newTime = Math.min(this.audioConfig.audioTag.currentTime + val, this.audioConfig.duration);
      }
      this.audioConfig.audioTag.currentTime = newTime;
    },

    playClip (id) {
      this.pausePlayback();
      this.index = id;
      this.resetClip(this.index);
      this.togglePlayback();
    },
    // #endregion

    // #region title editing functions
    toggleTitleEditing () {
      this.editingTitle = !this.editingTitle;
      if (this.editingTitle) {
        this.titleTarget.width = document.getElementById('title').getBoundingClientRect().width;
        this.titleTarget.height = document.getElementById('title').getBoundingClientRect().height;
        this.$nextTick(() => {
          this.titleTarget.max = document.getElementById('title-edit-wrapper').parentElement.getBoundingClientRect().width - 64;
        });
        this.$nextTick(() => document.getElementById("edit-title-input").select());

      } else {
        this.titleSaved = false;
        this.pendingTitleChange = false;
      }
    },
    //so much text input hacker-y bs to get this to work right
    trimInput (e) {
      if (this.titleSaveTimeout !== -1) window.clearTimeout(this.titleSaveTimeout);
      if (this.titleEndEditingTimeout !== -1) window.clearTimeout(this.titleEndEditingTimeout);
      // clean input, trim new line, properly place cursor
      if (e.inputType === 'insertLineBreak') {
        this.note.title = this.note.title.replace('\n', '');
        if (e.target.selectionStart < this.note.title.length) {
          let start = e.target.selectionStart - 1;
          setTimeout(() => {
            document.getElementById("edit-title-input").setSelectionRange(start, start);
          }, 0);
        }
      }
      let id = this.note.id;
      let title = this.note.title;
      let authorId = this.note.user_id;
      this.titleSaved = false;
      this.titleSaveTimeout = window.setTimeout(() => {
        this.saveTitle(id, title, authorId);
      }, 3000);

      this.pendingTitleChange = true;
      let target = document.getElementById('title-target');
      if (target) {
        target = target.children[0];
        let rect = target.getBoundingClientRect();
        this.titleTarget.height = rect.height;
        this.titleTarget.width = rect.width;
      } else {
        console.warn("could not get the title mock to target for sizing");
      }

    },
    saveTitle (id, title, authorId) {
      if (this.titleSaveTimeout !== -1) window.clearTimeout(this.titleSaveTimeout);
      this.pendingTitleChange = false;
      this.titleSaved = true;
      NotesService.UpdateNote({ note_id: id, title: title })
        .then((resp) => {
          // this.$toast.success({ message: "Title successfully updated" });
        })
        .catch((err) => {
          console.log("failed editing title", err);
          this.$toast.error({ message: "Something when wrong when saving!" });
        });
      this.$store.getters.getWebsocketEventHandler.sendInternalUpdateEvent({
        type: "Notes:TitleUpdate",
        newTitle: title,
        noteId: id,
        eventUserId: this.currUserId,
        eventTs: parseInt(new Date().getTime() / 1000), // dividing this by 1000 so our timestamps are consistent
        authorId: authorId
      });
      this.trackTitleEdit();
      if (this.titleEndEditingTimeout !== -1) window.clearTimeout(this.titleEndEditingTimeout);
    },
    onTitleBlur () {
      this.saveTitle(this.note.id, this.note.title, this.note.user_id);
      if (this.editingTitle) this.toggleTitleEditing();
    },
    // #endregion

    // #region note POST functions

    // on template section change
    handleTemplateChange (field, text, note) {

      field.section.content = text;
      // this is to handle the case of changing notes
      note = note ?? this.note;
      // let key = this.templateKey.find(x => x.field == field);
      if (field.timeout) window.clearTimeout(field.timeout);
      let noteId = note.id;
      let templateToUpdate = note.general_templates.find(x => x.type == this.selectedTemplateType);
      // for committing changes on close
      let templateType = this.selectedTemplateType;
      // this gets used to update the note immediately on leave
      field.props = { field, text, noteId, templateToUpdate, templateType };

      field.timeout = window.setTimeout(() => {
        field.timeout = -1;
        delete field.props;
        this.commitTemplateChange(field, text, noteId, templateToUpdate, templateType);
      }, 3000);
    },
    commitTemplateChange (field, text, noteId, templateToUpdate, templateType) {

      if (text == '') text = ' ';
      templateToUpdate.general_template.sections.find(x => x.header == field.header).content = text;
      // this just lets a user delete the whole transcript.
      NotesService.UpdateNoteTemplate(noteId, templateToUpdate, field.section.header)
        .then(resp => {
        })
        .catch(err => {
          console.error(err);
        });
      this.trackTranscriptChange(field);
    },

    handleTranscriptChange (text) {
      if (this.pendingChangeTimeout) window.clearTimeout(this.pendingChangeTimeout);
      this.pendingChangeTimeout = window.setTimeout(() => {
        this.pendingChangeTimeout = -1;
        this.commitTranscriptChange(text);
      }, 3000);
    },
    commitTranscriptChange (text) {
      // this just lets a user delete the whole transcript.
      if (text == '') text = ' ';
      this.note.edited_transcript_string = text;
      NotesService.UpdateNote({ note_id: this.note.id, title: this.note.title, edited_transcript_string: text })
        .then(resp => {
          this.note.last_edited = resp.data.last_edited;
        })
        .catch(err => {
          console.error(err);
        });
      this.trackTranscriptChange("Transcript");
    },


    // used handle RTF text input events. moves state of the note to in review if review is pending or verified
    checkState () {
      const state = this.note.state;
      if (!this.ignoreInputEvents && NoteStatesMap[state] !== "In Review") {
        this.setInReview(state);
        this.ignoreInputEvents = true;
      }
    },

    closeNote () {
      this.closingNote = true;
      NotesService.CloseNote(this.note.id)
        .then(resp => {
          this.note = this.parseNoteInts(resp.data);
          if (this.note.general_templates.length === 0) {
            this.$router.push(`/notes/${this.note.id}`);
          }
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error({ message: e.data.message });
        })
        .finally(() => {
          this.closingNote = false;
        });
    },
    // #region note state change functions
    setAwaitingReview () {
      const state = NoteStatesMap.indexOf("Awaiting Review");
      NotesService.UpdateNote({ note_id: this.note.id, title: this.note.title, state: state })
        .then(resp => {
          this.note.last_edited = parseInt(resp.data.last_edited);
          this.note.state = state;
          this.ignoreInputEvents = false;
        })
        .catch((e) => { console.log(e); });
    },

    setInReview () {
      const state = NoteStatesMap.indexOf("In Review");
      return NotesService.UpdateNote({ note_id: this.note.id, title: this.note.title, state: state })
        .then(resp => {
          this.note.last_edited = parseInt(resp.data.last_edited);
          this.note.state = state;
        })
        .catch((e) => { console.log(e); });
    },

    verifyNote () {
      const state = NoteStatesMap.indexOf("Verified");
      NotesService.UpdateNote({ note_id: this.note.id, title: this.note.title, state: state })
        .then(resp => {
          this.note.last_edited = parseInt(resp.data.last_edited);
          this.note.state = state;
        })
        .catch((e) => { console.log(e); });
    },

    copiedToPms () {
      const state = NoteStatesMap.indexOf("In PMS");
      NotesService.UpdateNote({ note_id: this.note.id, title: this.note.title, state: state })
        .then(resp => {
          this.note.last_edited = parseInt(resp.data.last_edited);
          this.note.state = state;
          // check if the user is an editor
          // TODO When we have the Verified and in PMS state move this check to that update
          if (this.$store.getters.getUserId != this.note.user_id) {
            this.$toast.success({
              message: "Note marked as Verified.",
              action: {
                fn: () => {
                  this.setInReview()
                    .then(() => this.$router.push("/notes/" + this.note.id));
                },
                message: "UNDO",
              }
            });
            this.$router.push("/notes");
          }
        })
        .catch((e) => { console.log(e); });
    },

    archiveNote (archive) {
      if (!this.userOwnsNote) return;
      this.loading = true;
      return NotesService.ArchiveNote(this.note.id, archive)
        .then(resp => {
          this.note.last_edited = parseInt(resp.data.last_edited);
          this.note.archived_on = parseInt(resp.data.archived_on);
          this.note.archived = archive;
          this.$store.getters.getWebsocketEventHandler.sendInternalUpdateEvent({
            type: "Note:ArchiveChange",
            noteId: this.note.id,
            archived: archive,
          });
          if (archive) {
            this.$toast.success({
              message: "The note has been archived.",
              // action: {
              //   fn: () => {
              //     this.archiveNote(false)
              //       .then(() => this.$router.push("/notes/" + this.note.id));
              //   },
              //   message: "UNDO",
              // }
            });
          } else {
            this.$toast.success({ message: "The note has been restored." });
          }

        })
        .catch((e) => { console.log(e); })
        .finally(() => {
          this.loading = false;
        });
    },
    // #endregion

    upThumb (e, field) {
      let rating = 1;
      if (field.section.ratings === 1) rating = 0;
      else {
        e.target.classList.add('animate');
        window.setTimeout(() => { e.target.classList.remove('animate'); }, 1000);
      }
      field.section.ratings = rating;
      NotesService.UpdateNoteTemplateRating(this.note.id, this.draftCopyOfNote.general_templates.find(x => x.type == this.selectedTemplateType))
        .then((resp) => {
          // this.$toast.success({ message: "Feedback received!" });
        }).catch((err) => {
          console.log(err);
          this.$toast.success({ message: "Error receiving feedback!" });
        });
    },

    downThumb (e, field) {
      let rating = -1;
      if (field.section.ratings === -1) rating = 0;
      else {
        e.target.classList.add('animate');
        window.setTimeout(() => e.target.classList.remove('animate'), 1000);
      }
      field.section.ratings = rating;
      NotesService.UpdateNoteTemplateRating(this.note.id, this.note.general_templates.find(x => x.type == this.selectedTemplateType))
      .then((resp) => {
        // this.$toast.success({ message: "Feedback received!" });
      }).catch((err) => {
        console.log(err);
        this.$toast.success({ message: "Error receiving feedback!" });
      });
    },

    // #region note issue CRUD functions

    SubmitIssue () {
      this.submittedNeedsAttn = true;
      let problem = this.problemsList.find(x => x.value == this.selectedProblem).text;
      problem += '.';
      if (this.selectedProblem == 999) {
        problem = this.otherProblem;
        // add punctuation if the user doesn't add any.
        const lastChar = problem.substring(problem.length - 1);
        if ([".", ",", ":", ";", "!", "?"].some(x => x !== lastChar)) {
          problem += '.';
        }
      }
      NotesService.CreateNoteIssue(this.note.id, problem, this.problemDescription)
        .then((resp) => {
          this.note.issues.push(...this.parseIssuesInts([resp.data]));
          this.sortNNA();
          this.note.needs_attention = true;
          this.$toast.success({ message: "This note has been flagged as requiring attention and the author has been notified." });
          this.toggleAttentionModal();
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error({ message: "There was an issue with sending your issue." });
        }).finally(() => {
          this.submittedNeedsAttn = false;
        });
    },

    respondIssue (problemResponse, issueId) {
      NotesService.respondToNoteIssue(this.note.id, issueId, problemResponse)
        .then((resp) => {
          this.note.issues.find(x => x.id === issueId)
            .responses.push(...this.parseResponsesInts([resp.data]));
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error({ message: "There was a problem responding to the issue." });
        });
    },

    resolveIssue (issueId) {
      NotesService.ResolveNoteIssue(this.note.id, issueId)
        .then(() => {
          if (this.toggleShowAllIssuesMessaging) {
            this.note.issues.find(x => x.id === issueId).resolved = true;
          } else {
            this.note.issues = this.note.issues.filter(x => x.id !== issueId);
          }
          if (this.note.issues.every(x => x.resolved)) this.note.needs_attention = false;
          this.$toast.success({ message: "You have marked this issue as resolve." });
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error({ message: "There was an issue with resolving the note." });
        });
    },

    deleteIssue (issueId) {
      NotesService.DeleteNoteIssue(this.note.id, issueId)
        .then(() => {
          this.note.issues = this.note.issues.filter(x => x.id !== issueId);
          if (this.note.issues.every(x => x.resolved)) this.note.needs_attention = false;
          this.$toast.success({ message: "You have deleted the issue. The note is back in the scribes queue." });
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error({ message: "There was a problem with deleting the note." });
        });
    },
    // #endregion

    // #region clientCommunications CRUD functions
    generateSection (field) {
      if (field.tag == "client-communication") {
        this.generateClientCommunication(field);
      } else {
        console.warn("unexpected generation call", field);
      }

    },

    generateClientCommunication (field) {
      let note = this.note;
      this.generatingComm = true;
      TemplateService.GenerateClientCommunication(this.copyableFullNotePlainText)
        .then((resp) => {
          field.section.content = resp.data.client_communication.replaceAll('\n', '\n\n');
          // this.selectedTemplate.client_communication = resp.data.client_communication.replaceAll('\n', '\n\n');
          field.show = true;
          this.handleTemplateChange(field, field.section.content, note);
          window.setTimeout(() => {
            this.scrollToClientComm();
          }, 250);
          // this.handleClientCommunicationChange(this.selectedTemplate.client_communication);
          // window.setTimeout(() => {
          //   this.scrollToClientComm();
          // }, 250);
        })
        .catch(err => {
          console.log(err);
          this.$toast.error({ message: "There was an issue with generating the client communication!" });

        })
        .finally(() => {

          this.generatingComm = false;
        });
    },


    // #endregion

    // #endregion

    // #region issue modal actions

    toggleAttentionModal () {
      this.selectedProblem = 1;
      this.problemDescription = '';
      this.otherProblem = '';
      this.showAttentionModal = !this.showAttentionModal;
    },
    toggleIssueResponseModal () {
      this.showIssueResponseModal = !this.showIssueResponseModal;
    },

    sortNNA () {
      this.note.issues.forEach(x => x.ts = x.created_at_unix);
      // order date desc
      this.note.issues = this.note.issues.sort((a, b) => a.ts == b.ts ? 0 : a.ts > b.ts ? -1 : 1);
      // put unresolved issues first
      this.note.issues = this.note.issues.sort((a, b) => a.resolved == b.resolved ? 0 : a.resolved ? 1 : -1);
    },

    // #endregion

    // #region api response cleanup functions
    // clean up note json int64 formatting for JS
    parseNoteInts (note) {
      note.issues = note.issues.filter(x => !x.resolved);
      // note.transcript.forEach(x => { x.start = parseInt(x.start); x.end = parseInt(x.end); });
      // note.edited_transcript.forEach(x => { x.start = parseInt(x.start); x.end = parseInt(x.end); });
      // parsing numbers from strings to numbers
      note = this.parseNoteDates(note);
      note.state = parseInt(note.state);
      return note;
    },

    parseNoteDates (note) {
      note.created_at = parseInt(note.created_at);// * 1000
      note.archived_on = parseInt(note.archived_on);// * 1000
      note.last_edited = parseInt(note.last_edited);// * 1000
      note.published_at = parseInt(note.published_at);// * 1000
      return note;
    },

    parseIssuesInts (issues) {
      issues.forEach(issue => {
        issue.created_at_unix = parseInt(issue.created_at_unix);
        issue.responses = this.parseResponsesInts(issue.responses);
      });
      return issues;
    },

    parseResponsesInts (responses) {
      responses.forEach(response => {
        response.responded_by = parseInt(response.responded_by);
        response.responded_at_unix = parseInt(response.responded_at_unix);
      });
      return responses;
    },

    // #endregion

    // #region Page Actions


    navBack () {
      if (this.$router.options.history.state.back) this.$router.back();
      else this.$router.push('/notes');
    },

    toggleShowTranscript () {
      this.showTranscript = !this.showTranscript;
    },

    onResize () {
      this.windowWidth = window.innerWidth;
    },


    // #region client communications helper functions

    scrollToClientComm () {
      // let rect = document.getElementById("client-comm-editor")?.getBoundingClientRect();
      // let cont = document.body.getBoundingClientRect();
      // if (rect) {
      window.scrollTo({
        top: 9999,
        behavior: "smooth",
      });
      // }
    },

    toggleClientCommunication () {
      if (!this.selectedTemplate) return;
      if (this.selectedTemplate.client_communication === undefined) return;
      this.showComm = !this.showComm;
      if (this.showComm) {
        window.setTimeout(() => {
          this.scrollToClientComm();
        }, 250);
      }
    },

    // #endregion

    // #endregion

    // #region note action dropdown/button handlers
    toggleNoteActions (e, set) {
      if (set !== undefined) {
        this.showNoteActions = set;
      } else {
        this.showNoteActions = !this.showNoteActions;
      }
    },

    selectDefaultNoteAction (action) {
      this.config.defaultNoteAction = action;
      this.showNoteActions = false;
      this.saveConfig();
    },

    takeNoteAction () {
      switch (this.selectedNoteAction) {
        case ("Complete and Archive"):
          this.archiveNote(true).then(() => { this.copiedToPms(); }); break;
        case ("Mark as Complete"): this.copiedToPms(); break;
        case ("Archive Note"):
          this.archiveNote(true); break;
        case ("Unarchive Note"):
          this.archiveNote(false); break;
        default: console.warn('unknown note Action taken', this.config.defaultNoteAction, this.selectedNoteAction);
      }
    },

    // #endregion

    // #region transitions
    // this height transition will only work in specific cases. It doesn't handle nesting elements
    // before initial render
    setEnterLeaveHeight (el) {
      el.style.maxHeight = "0px";
    },
    // called one frame after the element is inserted.
    setTransitionHeight (el, done) {
      let height = 0;
      for (var i = 0; i < el.childElementCount; i++) {
        height = Math.max(el.children[i].clientHeight, height);
      }
      el.style.maxHeight = `${height}px`;
      setTimeout(done, 250);
    },

    transitionDone (el) {
      window.setTimeout(() => {
        el.style.maxHeight = '';
      }, 0);
    },
    // #endregion

    // #region Tracking functions
    trackTranscriptChange (field) {
      let subId = this.$store.getters.getSubscriptionId;
      MixpanelService.Track("AdminPortal:TranscriptChange", {
        subscription_id: subId,
        note_id: this.note.id,
        field: field,
        view: "auto-soap",
      });
    },
    trackCopy (field) {
      let subId = this.$store.getters.getSubscriptionId;
      MixpanelService.Track("AdminPortal:TranscriptCopy", {
        subscription_id: subId,
        note_id: this.note.id,
        field: field,
        view: "auto-soap",
      });
    },
    trackTitleEdit () {
      let subId = this.$store.getters.getSubscriptionId;
      MixpanelService.Track("AdminPortal:TitleChange", {
        subscription_id: subId,
        note_id: this.note.id,
        view: "auto-soap",
      });
    },
    // #endregion

    saveConfig () {
      localStorage.setItem(`BladedNote:${this.currUserId}-config`, JSON.stringify(this.config));
    },
  },

  beforeUnmount () {
    // revoke blob urls
    // remove listeners, especially space listener
    document.removeEventListener('keydown', this.keyHandler);
    document.removeEventListener('mouseup', this.mouseUp);
    document.removeEventListener('mousemove', this.volumeChange);
    window.removeEventListener('resize', this.onResize);
    if (this.titleEndEditingTimeout !== -1) window.clearTimeout(this.titleEndEditingTimeout);
    if (this?.audioConfig?.audioTag) {
      window.URL.revokeObjectURL(this.audioConfig.audioTag.src);
      let tl = document.getElementById('timeline');
      if (tl) tl.removeChild(this.audioConfig.audioTag);
    }
    this.$store.getters.getWebsocketEventHandler.removeEvents(this.wsIds);
  },
};
import axios from 'axios';

const SERVICE_URL = __API_URL_V1 + 'confirm_email';

const ConfirmEmail = (email, userId, token) => {
  const payload = {
    "email": email,
    "user_id": userId,
    "token": token
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL,
    data: payload
  });
};


const ConfirmEmailCreate = (email) => {
  const payload = {
    "email": email,
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL + "/create",
    data: payload
  });
};

const ConfirmEmailVerify = (email, token) => {
  const payload = {
    "email": email,
    "token": token
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL + "/verify",
    data: payload
  });
};


export {
  ConfirmEmail,
  ConfirmEmailCreate,
  ConfirmEmailVerify,
};
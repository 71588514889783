const PasswordResetService = require("@/services/PasswordResetService");
import BaseInput from "@/components/ui/BaseInput";
import BaseBox from '@/components/ui/BaseBox';
import LoginTemplate from 'Components/layout/LoginTemplate.vue';

export default {
  name: 'Reset',
  components: {
    BaseBox,
    BaseInput,
    LoginTemplate
  },
  data () {
    return {
      email: this.$route.query.email ?? '',
      message: '',
      errorMessage: '',
      disableButton: false,
      resetSent: false,
    };
  },
  methods: {
    sendPasswordReset () {
      if (!this.validateEmail(this.email) || this.disableButton) return;
      this.disableButton = true;
      PasswordResetService.SendPasswordReset(this.email.toLowerCase()).then(() => {
        this.resetSent = true;
        this.message = "Password reset sent!";
      }).catch((err) => {
        console.log(err);
        this.errorMessage = "Something went wrong! please get in contact with our support team";
      }).then(() => {
        this.disableButton = false;
      });
    },
    validateEmail (email){
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

  },
};
const PasswordResetService = require("@/services/PasswordResetService");
const UserService = require('Services/UserService');
import BaseInput from "@/components/ui/BaseInput";
import BaseBox from '@/components/ui/BaseBox';
import LoginTemplate from 'Components/layout/LoginTemplate.vue';

export default {
  name: 'PasswordUpdate',
  components: {
    BaseBox,
    BaseInput,
    LoginTemplate
  },
  data () {
    return {
      loading: true,
      message: '',
      errorMessage: '',
      resetSuccess: false,
      passwordError: '',
      token: "",
      email: "",
      userId: "",
      prevPwd1: "",
      prevPwd2: "",
      pwd1: { value: "", error: "" },
      pwd2: { value: "", error: "" },
      validPwd: false,
      matchingPwd: false,
      showPasswordToggle1: false,
      showPasswordToggle2: false,
    };
  },
  created () {
    this.token = this.$route.query.token;
    this.email = this.$route.query.email;
    if (this.token === undefined || this.email === undefined) {
      this.errorMessage = "Please resend your password reset, have your admin send a password reset to you or contact us for help resetting.";
      this.loading = false;
    }
    else {
      PasswordResetService.VerifyResetToken(this.email, this.token)
        .then((resp) => {
          this.userId = resp.data.id;
        })
        .catch((err) => {
          console.log(err);
          this.passwordError = err.data.error;
          this.errorMessage = "Please resend your password reset, have your admin send a password reset to you or contact us for help resetting.";
        })
        .finally(() => this.loading = false);
    }
  },
  unmounted () {
    //sanitizing password content
    this.pwd1 = {};
    this.pwd2 = {};
  },

  methods: {
    // function avoids throwing errors while the user is typing forward but
    // will throw errors when they go below the character threshold or focus
    // out below character threshold
    validatePassword (text) {
      //this is ignored on blur (focusOut event)
      if (this.prevPwd1.length < text.length) {
        this.pwd1.error = "";
        this.validPwd = false;
      } else if (this.pwd1.value.length < 6) {
        this.validPwd = false;
        this.pwd1.error = "Please use a longer password";
      }
      else if (this.pwd1.value.length > 128) {
        this.pwd1.error = "password no larger than 128 characters";
      }
      else {
        this.pwd1.error = "";
        this.validPwd = true;
      }
      this.prevPwd1 = text;
    },

    checkPasswords (text) {
      if (this.pwd2.value.length === 0 || !this.validPwd) {
        this.matchingPwd = false;
        this.pwd2.error = "";
        return;
      }

      //this triggers on blur (focusOut event in field)
      if (this.prevPwd2 === text && this.pwd1.value !== this.pwd2.value) {
        this.pwd2.error = "The two passwords do not match!";
        this.matchingPwd = false;
        return;
      }

      const tmpString = this.pwd1.value.substring(0, this.pwd2.value.length);
      //user has not typed in the same beginning sequence of the first password
      if (tmpString != this.pwd2.value) {
        this.pwd2.error = "The two passwords do not match!";
        this.matchingPwd = false;
      }
      //user is on the right track to putting in the correct password
      else if (this.pwd1.value === this.pwd2.value) {
        this.matchingPwd = true;
        this.pwd2.error = "";
      } else {
        this.matchingPwd = false;
        this.pwd2.error = "";
      }
      this.prevPwd2 = text;
    },

    // this function is called when a focus is changed out of a field to run a validation
    // acts like a pseudo-submit to make sure the user correctly entered their password
    focusChange (fieldText) {
      this.validatePassword(this.prevPwd1);
      this.checkPasswords(this.prevPwd2);
    },

    resetPassword (event) {
      event.preventDefault();
      this.disableButton = true;
      this.validatePassword(this.prevPwd1);
      this.checkPasswords(this.prevPwd2);
      if (!this.validPwd || !this.matchingPwd) {
        return;
      }
      UserService.ResetUserPassword(this.userId, this.token, this.pwd1.value)
        .then(() => {
          this.resetSuccess = true;
          this.passwordError = "";
        }).catch((err) => {
          console.log(err);
          this.passwordError = err.data.error;
          this.disableButton = false;
        });
    },
    loginRedirect () {
      if (this.$store.getters.isLoggedIn) {
        this.$store.dispatch('logout');
      } else {
        this.$router.push("login");
      }
    }

  },
};
<template>
  <div>
    <label>
      <input v-model="bool" type="checkbox" class="hidden base-checkbox-switch" />
      <div class="base-switch"></div>
    </label>
  </div>
</template>

<script>
export default {
  name: "BaseSwitch",
  props: { value: Boolean },
  data () {
    return {
      bool: this.value,
    };
  },
  watch: {
    value (newVal) {
      this.bool = newVal;
    },
    bool (newVal) {
      this.$emit("update:value", newVal);
    },
  },
};
</script>

<style scoped>
.base-switch {
  transition: all 0.15s;
}

.base-switch::before {
  transition: all 0.15s;
}

.base-checkbox-switch+.base-switch {
  @apply rounded-full;
  @apply bg-gray-400;
  @apply bg-opacity-75;
  @apply relative;
  width: 32px;
  height: 20px;
}

.base-checkbox-switch+.base-switch::before {
  @apply absolute;
  @apply rounded-full;
  @apply bg-gray-100;
  content: "";
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
  transform:translate(0%, -50%);
  left: 2px;
  top: 50%;
}

.base-checkbox-switch:checked+.base-switch {
  @apply bg-rare2-alt;
}

.base-checkbox-switch:checked+.base-switch::before {
  left: calc(100% - 2px - 16px);
  @apply bg-white2;
}
</style>
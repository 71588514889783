
<template>
  <!--
  Unsurpisingly, the Input[type=range] is a mess to style and to try and use in any capacity outside of default styling it is a fools errand.
  so: Ye be warned, here be dragons, etc, etc,.

  The majority of this component is an input range, with some custom styling on when the input changes
  to move the label with the slider knob, and to butt the progress track div up to the knob which sits over the input
 -->

  <div class="relative h-6">

    <input v-model="value" type="range" :min="min" :max="max" :step="step" @input="action" />
    <div class="slider-progress rounded-l-full absolute pointer-events-none cursor-pointer bg-main-alt h-1 my-auto top-1/2 -translate-y-1/4"
         :style="progressStyle">
    </div>
    <label class="absolute font-bold font-roboto-slab text-base whitespace-nowrap -translate-x-1/2" :style="labelStyle">
      {{ label }}
    </label>
  </div>
</template>
<script>
export default {
  emits: ['update:modelValue'],
  props: {
    min: Number,
    max: Number,
    step: Number,
    modelValue: Number,
    label: String
  },
  data () {
    return {
      value: this.modelValue ?? 0,
    };
  },
  watch: {
    modelValue: function (newVal) {
      this.value = newVal;
    }
  },
  computed: {
    labelStyle () {
      const numberOfSteps = this.max - this.min;
      // 1 indexed step numbers, step 1 is the lowest value
      if (numberOfSteps === 0) {
        return "left: calc(100% + 0.75rem); bottom:-1.5rem;";
      }
      const stepNumber = (this.value - this.min);
      // center label under the knob
      // left by percent of curr step in total steps, minus percent of step * width of slider knob
      return `left: calc(${((stepNumber) / (numberOfSteps)) * 100}% + 0.75rem - ${((stepNumber) / (numberOfSteps)) * 1.5}rem);
       bottom:-1.5rem;`;
    },

    progressStyle () {
      const numberOfSteps = this.max - this.min;
      if (numberOfSteps === 0) {
        return "width:100%";
      }
      // 1 indexed step numbers, step 1 is the lowest value
      const stepNumber = (this.value - this.min);
      return `width:calc(${((stepNumber) / (numberOfSteps)) * 100}% - ${((stepNumber) / (numberOfSteps)) * 1.5}rem);`;
    }
  },
  methods: {
    action (e) {
      // automatically handled though the v-model directive
      this.$emit("update:modelValue", e.target.valueAsNumber);
    },
  },
};
</script>

<style scoped>
/* https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/ */
input[type="range"] {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.thumb-styling {
  border: 0.1875rem solid;
  @apply border-main-alt;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 1.5rem;
  background: #ffffff;
  cursor: pointer;
  z-index: 1;
}

/* Special styling for WebKit/Blink */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  @apply thumb-styling;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  margin-top: -0.6rem;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  @apply thumb-styling;
  height: 1.25rem;
  width: 1.25rem;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  @apply thumb-styling;
}

.track-styling {
  width: 100%;
  height: 0.25rem;
  cursor: pointer;
  background: #e5e5e5;
  border-radius: 0.125rem;
}

input[type="range"]::-webkit-slider-runnable-track {
  @apply track-styling;
}

input[type="range"]::-moz-range-progress {
  @apply track-styling;
}

@-moz-document url-prefix() {
  .slider-progress {
    margin-top: -0.25rem;
  }
}

input[type="range"]::-moz-range-track {
  @apply track-styling;
}

input[type="range"]::-ms-track {
  @apply track-styling;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  @apply track-styling;
}

input[type="range"]::-ms-fill-upper {
  @apply track-styling;
}
</style>
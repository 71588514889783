<template>
  <div v-click-outside="hideMenu" :class="{'base-kebab':true, 'kebab-error': kebabError}">
    <button class="kebab" :selected="showMenu ? true : undefined" @click="toggleMenu">
      <i class="material-icons kebab-icon">more_vert</i>
    </button>
    <div v-show="showMenu" class="menu">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: "BaseKebab",
  props: { kebabError: Boolean },
  data () {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleMenu () {
      this.showMenu = !this.showMenu;
    },
    hideMenu (e) {
      this.showMenu = false;
      e.stopPropagation();
    },
  },
};
</script>
<style scoped>
.base-kebab {
  height: 2rem;
  width: 2rem;
}

.base-kebab .kebab {
  @apply flex;
  @apply relative;
  @apply text-gray-600;
  @apply cursor-pointer;
  @apply select-none;
  @apply text-center;
  @apply align-middle;
  @apply rounded-full;
  height: 2rem;
  width: 2rem;
  @apply transition-colors;
}

.base-kebab .kebab:hover,
.base-kebab .kebab[selected] {
  @apply bg-gray-300;
  @apply text-gray-700;
}

.base-kebab.kebab-error .kebab:hover,
.base-kebab.kebab-error .kebab[selected] {
  @apply bg-red-300;
}
.base-kebab.kebab-error .kebab-icon {
  @apply text-white;
}

.base-kebab .kebab-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 1.5rem;
  transform: translate(-50%, -50%);
}

.base-kebab.kebab-error .kebab-icon {
  @apply text-white;
}

.base-kebab .menu {
  @apply z-10;
  @apply bg-white;
  @apply absolute;
  @apply py-2;
  @apply rounded;
  @apply shadow;
  @apply whitespace-nowrap;
  min-width: 5em;
  transform: translate(-50%, 0);
  @apply border;
  @apply border-gray-200;
}

.base-kebab .menu * {
  @apply font-semibold;
  @apply py-2;
}

</style>
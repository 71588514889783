<template>
  <div class="relative">
    <label class="select-label"> {{ label }} </label>
    <select v-model="selected" size="1"
            :class="['base-select border cursor-pointer pr-10 text-neutral-alt',
                     selected == '' ? 'placeholder' : '',
                     errorMessage ? 'border-error' : 'border-black']"
            :disabled="disabled"
            :error="errorMessage ?? false"
            @change="select">
      <option class="text-gray-200" value="" disabled>
        {{ placeholderText }}
      </option>
      <option v-for="item in list" :key="item.value" :value="item.value">
        {{ item.text }}
      </option>
    </select>
    <p v-show="errorMessage" class="error-message footer-message absolute left-4 -bottom-5">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
export default {
  name: "BaseSelectList",
  emits: ['select'],
  props: {
    list: Array,
    placeholderText: String,
    label: String,
    initialValue: String,
    errorMessage: String,
    disabled: Boolean,
  },
  data () {
    return {
      selected: this.initialValue ?? "",
    };
  },
  watch: {
    initialValue (newVal) {
      this.selected = newVal;
    }
  },
  methods: {
    select () {
      this.$emit("select", this.selected);
    },
  },
};
</script>
<style scoped>
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black' opacity='0.40'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 12px;
  background-position: calc(100% - 20px) 60%;
  background-repeat: no-repeat;
  background-color: #ffffff;
}

.base-select:focus {
  outline: none;
}

.base-select {
  @apply px-4;
  @apply py-3;
  @apply w-full;
  @apply h-12;
  border: 1px solid;
  border-color: #9BACB2;
  font-size: 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  display: inline-flex;
  transition: all 0.2s ease-out;
}

@media (min-width: 640px) {
  .base-select {
    @apply h-11;
  }
}

.base-select:focus {
  @apply border-main-dark;
  transition: all 0.2s ease-out;
}

.base-select:error {
  @apply border-error;
}

.select-label {
  @apply font-roboto;
  font-style: normal;
  font-weight: 400;
  @apply px-1;
  @apply ml-2;
  @apply absolute;
  @apply bg-white;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #9BACB2;
  @apply z-10;
  /* identical to box height */
  transform: translate(0%, -50%);
}

@media (max-width: 640px) {
  .select-label {
    font-size: 1rem;
    line-height: 1rem;
  }
}

.base-select:required:invalid {
  color: gray;
}

option[value=""][disabled] {
  display: none;
}

option {
  padding: 0px;
  color: black;
}

.placeholder {
  font-style:normal;
  font-weight: 400;
  font-size: 1rem;
  color: #9BACB2;
}

@media (max-width: 640px) {
  .select-label {
    font-size: 1rem;
    line-height: 1rem;
  }

  .placeholder {
    font-size: 1.4rem;
  }
}
</style>
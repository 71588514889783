// importing this has the side affect of creating a local ref to proto
import _ from "@/proto/js/notification_pb.js";

const status = {
    SUSPENDED: 0,
    OPEN: 1,
    CLOSED: 2,
};
const MessageTypes = proto.talkatoo.notification.v1.WebsocketMessage.EventCase;
const IssueMessageTypes = proto.talkatoo.notification.v1.NoteIssueEvent.issue_event_type;
const websocketStatusMap = status;
let ws = undefined;
class WebsocketEventHandler {
    constructor(jwt, userId) {
        if (ws) {
            return false;
        }
        this.ws = ws;
        this.id = 0;
        this.callbacks = {
            noteState: [],
            noteIssue: [],
            vocabUpdate: [],
            talkatextUpdate: [],
            refresh: [],
            InternalUpdate: [],
        };
        this.status = status.SUSPENDED;
        this.clientId = undefined;
        this.userId = userId ?? 0;
        this.platform = "web";
        // TBD if this is going to be needed
        // this.heartbeatIntervalId = -1;
        if (jwt !== '') {
            this._init(jwt);
        }
    }
    connect (jwt, userId) {
        this.userId = userId ?? 0;
        if (!userId) return false;
        if (jwt === '') return false;
        this._init(jwt);
        return true;
    }
    _init (jwt) {
        this.ws = new WebSocket(`${__WS_API_URL}?x-jwt=${jwt}`);
        this.ws.binaryType = 'arraybuffer';
        this.ws.onopen = (evt) => {
            // window.setInterval(()=>{console.log(this.status,"id: ", this.id, " callback count: ", this.callbacks.noteIssue.length + this.callbacks.noteState.length, this.ws);},3000);
            this.status = status.OPEN;
            this.clientId = new Date().getTime();

            let config = new proto.talkatoo.notification.v1.ConfigMessage();
            config.setUserId(this.userId);
            config.setPlatform(this.platform);
            config.setClientId(this.clientId);
            this.ws.send(config.serializeBinary(), { binary: true });
            // this.heartbeatIntervalId = window.setInterval(()=>{this.ws.send(10);}, 10000);
        };
        this.ws.onclose = (evt) => {
            // if (this.heartbeatIntervalId !== -1) window.clearInterval(this.heartbeatIntervalId);
            // this.heartbeatIntervalId = -1;
            this.status = status.CLOSED;
            this.ws = undefined;
        };
        this.ws.onmessage = (evt) => {
            let arrayBuffer = evt.data;
            let message = proto.talkatoo.notification.v1.WebsocketMessage.deserializeBinary(arrayBuffer);
            let event = {};
            switch (message.getEventCase()) {
                case (MessageTypes.NOTE_STATE_EVENT):
                    event = message.toObject().noteStateEvent;
                    this._sendNoteStateEvent(event);
                    break;
                case (MessageTypes.NOTE_ISSUE_EVENT):
                    event = message.toObject().noteIssueEvent;
                    this._sendNoteIssueEvent(event);
                    break;
                case (MessageTypes.VOCABULARY_UPDATE_EVENT):
                    event = message.toObject().vocabularyUpdateEvent;
                    this._sendVocabUpdateEvent(event);
                    break;
                    case (MessageTypes.TALKATEXT_UPDATE_EVENT):
                    event = message.toObject().talkatextUpdateEvent;
                    this._sendTalkatextUpdateEvent(event);
                    break;
                    case (MessageTypes.REAUTH_EVENT):
                    event = message.toObject().reAuthEvent;
                    this._sendReAuthEvent(event);
                    break;
                default:
                    console.log("unknown event from notif service:", message.getEventCase(), message);
                    break;
            }
        };
        this.ws.onerror = function (evt) {
            this.status = status.SUSPENDED;
            this.close();
            console.log("ERROR: ", evt);
        };
    }
    // * event hooking functions
    //hook all events
    onEvent (fn) {
        let ids = [0, 0, 0, 0, 0];
        ids[0] = this.onNoteStateEvent(fn);
        ids[1] = this.onNoteIssueEvent(fn);
        ids[2] = this.onVocabUpdateEvent(fn);
        ids[3] = this.onTalkatextUpdateEvent(fn);
        ids[4] = this.onReAuthEvent(fn);
        return ids;
    }
    removeEvent (id) {
        this.removeNoteStateEvent(id);
        this.removeNoteIssueEvent(id);
        this.removeVocabUpdateEvent(id);
        this.removeInternalUpdateEvent(id);
        this.removeTalkatextUpdateEvent(id);
        this.removeReAuthEvent(id);
    }
    removeEvents (ids) {
        if (!Array.isArray(ids)) return false;
        ids.forEach(id => {
            this.removeNoteStateEvent(id);
            this.removeNoteIssueEvent(id);
            this.removeVocabUpdateEvent(id);
            this.removeInternalUpdateEvent(id);
            this.removeTalkatextUpdateEvent(id);
            this.removeReAuthEvent(id);
        });
        return true;
    }

    // hook note State Update
    onNoteStateEvent (fn) {
        let id = this.id;
        this.id++;
        this.callbacks.noteState.push({ id: id, callback: fn });
        return id;
    }
    removeNoteStateEvent (id) {
        const i = this.callbacks.noteState.findIndex(cb => cb.id === id);
        if (i < 0) { return false; }
        this.callbacks.noteState.splice(i, 1);
        return true;
    }
    // arrow function to prevent copying the stackframe and heap references.]
    // makes the `this` binding the correct one and not a snapshot from when cb is saved as a reference.
    _sendNoteStateEvent = (event) => {
        this.callbacks.noteState.forEach(hook => {
            if (hook.callback) hook.callback(event);
        });
    };

    // hook note issue update
    onNoteIssueEvent (fn) {
        let id = this.id;
        this.id++;
        this.callbacks.noteIssue.push({ id: id, callback: fn });
        return id;
    }
    removeNoteIssueEvent (id) {
        const i = this.callbacks.noteIssue.findIndex(cb => cb.id === id);
        if (i < 0) { return false; }
        this.callbacks.noteIssue.splice(i, 1);
        return true;
    }
    _sendNoteIssueEvent = (event) => {
        event.eventTypeString = Object.keys(IssueMessageTypes)[event.eventType];
        this.callbacks.noteIssue.forEach((hook) => {
            if (hook.callback) hook.callback(event);
        });
    };

    // hook vocab update
    onVocabUpdateEvent (fn) {
        let id = this.id;
        this.id++;
        this.callbacks.vocabUpdate.push({ id: id, callback: fn });
        return id;
    }
    removeVocabUpdateEvent (id) {
        const i = this.callbacks.vocabUpdate.findIndex(cb => cb.id === id);
        if (i < 0) { return false; }
        this.callbacks.vocabUpdate.splice(i, 1);
        return true;
    }
    _sendVocabUpdateEvent = (event) => {
        this.callbacks.vocabUpdate.forEach((hook) => {
            if (hook.callback) hook.callback(event);
        });
    };
    // hook talkatext update
    onTalkatextUpdateEvent (fn) {
        let id = this.id;
        this.id++;
        this.callbacks.talkatextUpdate.push({ id: id, callback: fn });
        return id;
    }
    removeTalkatextUpdateEvent (id) {
        const i = this.callbacks.talkatextUpdate.findIndex(cb => cb.id === id);
        if (i < 0) { return false; }
        this.callbacks.talkatextUpdate.splice(i, 1);
        return true;
    }
    _sendTalkatextUpdateEvent = (event) => {
        this.callbacks.talkatextUpdate.forEach((hook) => {
            if (hook.callback) hook.callback(event);
        });
    };

    // hook reauth update
    onReAuthEvent (fn) {
        let id = this.id;
        this.id++;
        this.callbacks.refresh.push({ id: id, callback: fn });
        return id;
    }
    removeReAuthEvent (id) {
        const i = this.callbacks.refresh.findIndex(cb => cb.id === id);
        if (i < 0) { return false; }
        this.callbacks.refresh.splice(i, 1);
        return true;
    }
    _sendReAuthEvent = (event) => {
        this.callbacks.refresh.forEach((hook) => {
            if (hook.callback) hook.callback(event);
        });
    };

    // * special function to provide an internal event to update notes though the registered handlers.
    // * this is a little "tacked on" but for now it provides a means to send events throughout the app
    // * and fill in the gaps of the notification service
    // function designed to handle internal messaging. messages passed will be passed with an event name
    // hook internal events
    // {
    //     "type": "event_type"
    //     "noteId": 15807,
    //     "eventUserId": 5723076784291840,
    //     "oldState": 5,
    //     "newState": 1,
    //     "verify": false,
    //     "eventTs": 1712859863,
    //     "authorId": 5723076784291840
    // }
    onInternalEvent (fn) {
        let id = this.id;
        this.id++;
        this.callbacks.InternalUpdate.push({ id: id, callback: fn });
        return id;
    }
    removeInternalUpdateEvent (id) {
        const i = this.callbacks.InternalUpdate.findIndex(cb => cb.id === id);
        if (i < 0) { return false; }
        this.callbacks.InternalUpdate.splice(i, 1);
        return true;
    }
    sendInternalUpdateEvent (event) {
        if (!event.type) {
            console.warn("improper use of internals Event provide an event type");
            return;
        }
        const validEvent = ["Notes:TitleUpdate", "Note:ArchiveChange", "UserManagement:UpdateProfile"];
        if (!validEvent.includes(event.type)) {
            console.warn("improper use of internals Event: provide a valid event type or write a new one", "provided event", event.type);
            return;
        }
        this.callbacks.InternalUpdate.forEach((hook) => {
            if (hook.callback) window.setTimeout(() => { hook.callback(event); }, 0);
        });
    }

    close (evt) {
        if (!this.ws) {
            return false;
        }
        this.status = status.CLOSED;
        this.ws.close();
        this.ws = undefined;
        return true;
    }
}
export {
    WebsocketEventHandler,
    websocketStatusMap,
    IssueMessageTypes,

};
import BaseToast from "./BaseToast.vue";
import BaseNotify from "./BaseNotify.vue";
import { createApp } from 'vue';


export default {
  install (app) {
    // creating a second vue app to host the toasts in a fixed container
    const toastContainer = document.createElement('div');
    toastContainer.classList.add("toast-container");
    document.body.appendChild(toastContainer);

    const notifyContainer = document.createElement('div');
    notifyContainer.classList.add("notify-container");
    document.body.appendChild(notifyContainer);

    const toast = createApp(BaseToast).mount(toastContainer);
    const notify = createApp(BaseNotify).mount(notifyContainer);
    app.config.globalProperties.$toast = {
      notify: ({ message, action, timeout }) => {
        notify.notify("notify", message, action, timeout);
      },

      success: ({ message, action, timeout }) => {
        toast.addToast("success", message, action, timeout);
      },

      error: ({ message, action, timeout }) => {
        toast.addToast("error", message, action, timeout);
      },
    };
  }
};

<template>
  <BaseModal
    :toDisplay="toDisplay"
    title="Purchase Authentication"
    class="m-auto"
    @toggleEvent="toggleModal">

    <div class="flex flex-col m-8 justify-center">
      <div class="text-lg mb-4 text-center">
        <div class="text-xl font-bold text-gray-500 mb-4">
          Your payment provider wants to verify that  <br />
          this is an authentic purchase. <br />
        </div>
        Please click the button to confirm the purchase with your <br />
        provider. You will be momentarily redirected to your provider's  <br />
        confirmation form.
      </div>
      <a class="btn-primary btn-lg font-base text-decoration-none"
         :href="link"
         @click="threeDSecureNav">
        Proceed to Form
      </a>
    </div>

  </BaseModal>

</template>
<script>
import BaseModal from "@/components/ui/BaseModal.vue";
import MixpanelService from "Services/MixpanelService";

export default {
  name: "ThreeDSecureModal",
  components: { BaseModal },
  props: ["toDisplay", "link"],

  methods: {
    toggleModal: function () {
      this.$emit("threeDSecureToggleEvent", this.toDisplay);
    },
    threeDSecureNav () {
      MixpanelService.Track("AdminPortal:3dsNavigation", { "subscription_id": this.$store.getters.getSubscriptionId });
    }
  },
};
</script>
import BaseBox from "Components/ui/BaseBox";
import BaseLoading from "Components/ui/BaseLoading";
import StandardTemplate from "Components/layout/StandardTemplate";
import MixpanelService from "Services/MixpanelService";

export default {
  name: "GettingStarted",
  components: {
    BaseBox,
    BaseLoading,
    StandardTemplate,
  },
  data () {
    return {
      arch: "",
      userOS: "",
      downloads: [],
      options:
      {
        "windows": [
          { arch: "64", archDisplay: "64 Bit", url: "https://storage.googleapis.com/assets.talkatoo.ai/releases/x64/latest/TalkatooSetup.exe" },
          { arch: "32", archDisplay: "32 Bit", url: "https://storage.googleapis.com/assets.talkatoo.ai/releases/ia32/latest/TalkatooSetup.exe" },
        ],
        "mac": [
          { arch: "intel", archDisplay: "Intel", url: "https://nucleus.talkatoo.ai/Talkatoo/60baa14d773490b1038f83c2cb234b34/latest/darwin/x64/Talkatoo.dmg" },
          { arch: "m1", archDisplay: "M1", url: "https://nucleus.talkatoo.ai/Talkatoo/60baa14d773490b1038f83c2cb234b34/latest/darwin/arm64/Talkatoo.dmg" },
        ]
      },
    };
  },
  created () {
    // userOS () {
    this.userOS = "windows";
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgent
    // UA format: appCodeName/appVersion number (Platform; Security; OS-or-CPU; ...
    // this is maybe yikes-y because as there are different os's and architecture created this could break
    const UA = navigator.userAgent.toLowerCase();
    if (UA.indexOf("win") != -1) {
      this.userOS = "windows";
      this.arch = UA.indexOf("win64; x64") != -1 ? "64" : "32";
    }
    else if (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|Blackberry|CrOS/i)) this.userOS = "mobile";
    else if (UA.indexOf("mac") != -1) { this.userOS = "mac"; this.arch = "intel"; }
    else if (UA.indexOf("x11") != -1) this.userOS = "unix";
    // else if (UA.indexOf("linux") != -1) OS = "linux";
    else {
      // M1 gpu detection
      // This works on the assumption that the only GPUs available for new apple devices will be well defined.
      // Since these devices can also run x64 code in a pinch (albeit worse), we can be confident that a best guess will be good enough
      const webGL = document.createElement("canvas").getContext("webgl");
      const renderInfo = webGL && webGL.getExtension("WEBGL_debug_renderer_info") || "";
      const g = renderInfo && webGL.getParameter(renderInfo.UNMASKED_RENDERER_WEBGL) || "";
      if (g.match(/Apple/) && !g.match(/Apple GPU/)) {
        this.userOS = "mac";
        this.arch = "m1";
      }
      // check if a user is on safari, this is a guess at best
      if (g.getSupportedExtensions().indexOf("WEBGL_compressed_texture_s3tc_srgb") == -1) {
        this.userOS = "mac";
        this.arch = "m1";
      }
      else {
        this.userOS = 'mac';
        this.arch = "intel";
      }
    }
  },
  methods: {
    provisioningDownload () {
      MixpanelService.Track("AdminPortal:ProvisioningDownload",
        {
          "platform": this.userOS,
          "arch": this.arch
        });
    },
    primaryDownload (i) {
      if (this.options[this.userOS]) {
        return this.arch === this.options[this.userOS][i].arch ? "btn-primary" : "btn-secondary";
      }
    }
  }
};
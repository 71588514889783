<template>
  <div class="bg-white flex flex-col rounded-t-md lg:rounded-t-0 mb-32">
    <div class="heading mb-5 flex-col lg:flex-row justify-between">
      <div class="whitespace-nowrap text-3xl font-bold my-auto">
        Billing History
      </div>
    </div>
    <div class="body-container pb-4 lg:flex 2xl:max-w-[1280px] 4xl:max-w-[1442px] ">
      <!-- <template v-if="$store.getters.getBillingProvider !== 'stripe'"> -->
      <div class="container-gray">
        <BaseTable
          title="STATEMENTS"
          searchPlaceholder="Search Statements"
          :colDef="colDef"
          :data="tableData"
          :showLoading="loading"
          :callToAction="callToAction"
          :pagination="pagination"
          showSearch
          @row-action-click="actionClick"
        />
      </div>
      <!-- </template>
      <template v-else>
        <div class="flex flex-col">

          <div class="flex flex-col-reverse md:flex-row p-3 sm:py-6 lg:pr-12 border border-[#E7EBF2] rounded-[3px]">
            <div class="flex flex-col justify-center mr-2 sm:mr-6">
              <img src="@/assets/images/workman.svg" class="block h-full w-[295px] mx-auto md:min-w-[295px]" />
            </div>
            <div class="inline-block font-roboto md:mt-8 lg:mt-10 2x:whitespace-nowrap">
              <div class="font-montserrat text-xl font-extrabold mb-3 text-neutral-alt">
                We're currently making some updates.
              </div>
              While these changes are taking place your invoices will be unavailable.<br class="hidden 2xl:block" />
              Check back shortly, but if you need your invoices right away please email<br class="hidden 2xl:block" />
              <a class="inline-anchor" href="mailto:support@talkatoo.com"> support@talkatoo.com</a> or call <a class="inline-anchor" href="tel:18558862156">1-855-886-2156</a> our support team to<br class="hidden 2xl:block" />
              have them emailed to you.
            </div>
          </div>
        </div>
      </template> -->
    </div>
  </div>

</template>
<script src="./BillingHistory.js"></script>
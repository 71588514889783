import axios from 'axios';

const SERVICE_URL = __API_URL_V1 + "feedback/";

const VerifySurveyToken = (token, userId) => {
  const body = {
    "token": token,
    "user_id": userId
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL + 'survey/verify',
    data: body
  });
};

const NpsSurveyResponse = (surveyId, token, userId, score, feedback) => {
  const body = {
    "user_id": userId,
    "survey_id": surveyId,
    "token": token,
    "feedback": feedback,
    "score": score,
    "survey_type": "NPS",
    "platform": "email", //originating platform for the user
  };

  return axios({
    method: 'POST',
    url: SERVICE_URL + 'survey/response',
    data: body
  });
};
const CesSurveyResponse = (surveyId, token, userId, score, cesScore, feedback) => {
  const body = {
    "user_id": userId,
    "survey_id": surveyId,
    "token": token,
    "feedback": feedback,
    "score": score,
    "ces_score": cesScore,
    "survey_type": "CES",
    "platform": "email", //originating platform for the user
  };

  return axios({
    method: 'POST',
    url: SERVICE_URL + 'survey/response',
    data: body
  });
};


export {
  VerifySurveyToken,
  NpsSurveyResponse,
  CesSurveyResponse
};



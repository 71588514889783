<template>
  <FeedbackTemplate>
    <template v-if="error">
      <p id="error-message" class="feedback-error">
        Uh oh! There seems to be a problem.
      </p>
      <p class="pt-12 text-center">
        If you're seeing this message we have broken something! If you'd still like to give us some feedback to help us improve how we help you
        please email any feedback you have to:
        <a class="mt-4 text-center block text-2xl" href="mailto:support@talkatoo.com"> support@talkatoo.com</a>
      </p>
    </template>
    <template v-else-if="!feedbackFinished">
      <p class="feedback-heading">
        How likely are you to recommend Talkatoo?
      </p>
      <!-- score display -->
      <div>
        <div class="flex justify-between text-sm text-gray-400">
          <span>
            Not at all likely
          </span>
          <span>
            Extremely Likely
          </span>
        </div>
        <div class="flex justify-between pt-2">
          <div v-for="(n,index) in 11" :key="n" class="feedback-score-radio-container">
            <button :id="'score-btn-' + index" class="btn-feedback" :selected="score == index" @click="score = index">
              {{ index }}
            </button>
          </div>
        </div>
        <!-- feedback box -->
        <div class="mt-10 flex flex-col">
          <p class="feedback-heading">
            What is the main reason for your score?
          </p>
          <textarea id="feedback-area" v-model="feedback" :placeholder="placeholder" rows="4" class="border border-gray-300 flex p-2  text-black">
          </textarea>
        </div>

        <button id="feedback-submit" class="py-12 mt-12 btn-lg btn-primary w-full"
                :disabled="disableSubmit || feedbackSubmit || feedbackFinished"
                @click="submitFeedback">
          Send Feedback
        </button>
      </div>
    </template>
    <template v-else>
      <img src="@/assets/images/talkatoo_emblem.svg" class="w-40 m-auto mb-4">
      <p id="success-message" class="feedback-success">
        Your feedback is appreciated!
      </p>
      <p class="pt-12 text-center ">
        Your feedback will help us build a better experience for you and your colleagues, we may be in touch to help you or to get more details on the feedback you have provided!
      </p>
    </template>

  </FeedbackTemplate>
</template>

<script src="./NpsSurvey.js"></script>
<style src="./NpsSurvey.css" scoped></style>
<template>
  <!-- * rtf container *
  The following Div contains the popup for showing the original transcript and the editable field where we manage the original and the edited transcript -->
  <div :id="`rtf-${id}`" v-click-outside="toggleOff" :class="['base-rtf block h-fit', diff ? 'pt-7' : '']"
       @mouseover="showToggle = true"
       @mouseleave="showToggle = false"
       @mouseup="setWidgetLocation"
  >
    <!-- previewing the original transcripts -->
    <div v-if="diff" class="relative">
      <!-- original text toggle button. shows on hover -->
      <Transition name="expand" appear
                  @before-enter="setEnterLeaveHeight"
                  @before-leave="setEnterLeaveHeight"
                  @enter="setTransitionHeight"
      >
        <div v-if="pinOriginal || showToggle || fieldFocused || toggleDiff"
             class="absolute -translate-y-[115%] flex w-full h-6 text-gray-400 cursor-pointer select-none
            transition-all duration-500 ease-in-out overflow-hidden"
             @click="toggleShowOriginal">
          <div class="h-[1px] flex-grow my-auto border-b border-gray-200" />
          <div class="flex whitespace-nowrap h-5 ml-2">
            <div class="material-icons text-base align-middle">
              pending_actions
            </div>
            <div class="flex justify-center w-24">
              <div class="flex my-auto  text-xs">
                {{ toggleDiff ? "Hide" : "Show" }} Original
              </div>
            </div>
            <div :class="['material-icons mr-auto text-gray-500', toggleDiff ? 'arrow-up' : 'arrow-down']">
              arrow_drop_down
            </div>
          </div>

          <div class="h-[1px] flex-grow my-auto border-b border-gray-200" />
        </div>
      </Transition>

      <Transition name="expand" appear :duration="300"
                  @before-enter="setEnterLeaveHeight"
                  @before-leave="setEnterLeaveHeight"
                  @enter="setTransitionHeight"
                  @after-enter="transitionDone"
                  @after-leave="transitionDone">
        <div v-if="toggleDiff"
             class="orig-match-width block h-full w-full text-gray-700  font-normal border bg-gray-100
            border-gray-500 rounded-sm transition-all duration-300 overflow-hidden">
          <div class="h-auto p-2">
            <span v-html="originalText" />
          </div>
        </div>
      </Transition>
    </div>
    <!-- editing area of the RTF  -->
    <div :class="['transition-colors font-normal',
                  textDiff && !(fieldFocused || showToggle) ? 'text-rare2-darker' : '',
                  toggleDiff ? 'mt-2' : '',]">
      <div :id="`pm-editor-${id}`" class=""></div>

      <div v-if="(noteState == 0 || noteState == 5)" class="cursor-not-allowed flex text-center">
        <div class="flex flex-col mx-auto my-8">
          <template v-if="noteState == 5">
            <span class="text-xl mb-2 font-bold"> This note is currently being processed! </span>
            <span class="text-base font-normal text-center">Don't worry, there's no work for you to do here yet.<br> Give it a few minutes and come back to this note <br> for it to be submitted for review. </span>
          </template>
          <template v-else>
            <span class="text-xl mb-2 font-bold"> Submit your note for review for it to show up here! </span>
            <span class="text-base font-normal text-center">We have to do additional processing once all of your note is recorded.<br> Give it a few minutes or wait for this draft <br> to be submitted for review. </span>
          </template>
        </div>
      </div>

    </div>
  </div>
  <div class="relative float-right">
    <TransitionGroup name="fade">
      <i v-if="saveState == 'done'"
         :key="0" style="transition-duration:200ms;" class="material-icons select-none -translate-x-1/2  absolute">
        done
      </i>
      <BaseLoading v-if="saveState == 'pending'"
                   :key="1"
                   style="transition-duration:200ms;" class=" transform scale-[40%] -translate-x-1/2 -top-2 absolute"
      />
    </TransitionGroup>
  </div>
</template>

<script>
import BaseLoading from "Components/ui/BaseLoading.vue";
import { EditorState } from "prosemirror-state";
import { schema } from "prosemirror-markdown";
import { ProseMirrorView } from './ProseMirror.js';

// compiled regex
const replaceSpecialRegex = /\\\/?d|\\\/?-|\\\/?ul|\\\/?ol|\\\/?i|\\\/?b|\\\/?u|\\n|\n|\t/igm;
const replaceSpecialFunc = function (match) {
  switch (match) {
    case "\n": return "<br/>";
    case "\\n": return "<br/>";
    case "\\b": return "<b>";
    case "\\/b": return "</b>";
    case "\\i": return "<i>";
    case "\\/i": return "</i>";
    case "\\u": return "<u>";
    case "\\/u": return "</u>";
    default: return " ";
  }
};

export default {
  name: "RichTextField",
  emits: ["update:modelValue", "toggleOriginal", "focus", "blur", "editedFrames", "textChangeEvent", "transcriptEditedEvent"],
  components: { BaseLoading },
  inject: ['settings', 'defaultHighlightColor'],
  props: {
    id: String,
    tabIndex: Number,
    // v-model binding for text changes
    modelValue: Object,
    // used to enable/disable changes to the text
    editable: Boolean,
    // flag to display -the diff between original and modified
    diff: Boolean,
    // original is the initial text show the user
    original: Array,
    edited: Array,
    transcriptString: String,
    //used in highlighting the current word while playing
    currentSecond: Number,
    isPlaying: Boolean,
    noteId: [Number, String],
    noteState: Number,
    updateOnTranscriptChange: Boolean,
  },
  data () {

    return {
      editableTranscriptString: this.transcriptString,
      fieldElement: undefined,
      changeMade: false,

      showToggle: false,
      fieldFocused: false,
      toggleDiff: false,

      mousePoint: { x: 0, y: 0 },
      mouseDown: false,

      observer: undefined,
      lastTouchedEl: undefined,
      saveTimeout: 0,
      editBuffer: [],

      highlightedFrames: [],
      // observer config
      config: { childList: true, subtree: true, characterData: true },
      pmView: undefined,
      pmState: undefined,
    };
  },

  mounted () {
    // initial creation of the elements in the RTF
    this.fieldElement = document.getElementById(`text-area-${this.id}`);
    // part 1 of a HAK. See input handling for comments on it
    if (this.fieldElement) this.fieldElement.parentNode.dataset.replicatedValue = this.editableTranscriptString;

    this.initPmView();
  },
  beforeUnmount () {
    if (this.saveTimeout == -1) return;
    this.savePmChanges();
  },
  watch: {
    settings: {
      handler (newVal) {
        while (this.highlightedFrames.length !== 0) {
          let frame = this.highlightedFrames.pop();
          frame.el.style = `background-color: ${frame.prevColor};`;
        }
      }, deep: true,
    },
    noteId (newVal) {
      this.editableTranscriptString = this.transcriptString;
      this.pmView.updateContent(this.transcriptString);
      clearTimeout(this.saveTimeout);
      this.saveTimeout = 0;
    },
    editable (newVal) {
      if (newVal) {
        this.fieldElement = document.getElementById(`pm-editor-${this.id}`);
        this.pmView.setEditable(this.editable);
      }

    },
    modelValue (newVal) {
      if (!this.changeMade && newVal) {
        this.changeMade = true;

      }
    },
    transcriptString (newVal) {
      if (this.updateOnTranscriptChange) {
        this.editableTranscriptString = this.transcriptString;
        this.pmView.updateContent(this.transcriptString);
      }
      // ??
    },
  },
  computed: {
    pinOriginal () {
      return this.settings?.showOriginal ?? false;
    },
    originalText () {
      return this.original.reduce((prev, x) => {
        return prev += x.text.replaceAll(replaceSpecialRegex, replaceSpecialFunc) + " ";
      }, "");
    },
    textDiff () {
      return this.diff && (this.changeMade);
    },
    saveState () {
      switch (this.saveTimeout) {
        case -1: return "done";
        case 0: return "none";
      }
      return "pending";
    },
  },
  methods: {
    setEditable () {
      this.pmView.setEditable(this.editable);
    },
    initPmView () {
      this.pmState = EditorState.create({ schema });
      let target = document.getElementById(`pm-editor-${this.id}`);
      this.pmView = new ProseMirrorView(target, this.editableTranscriptString, {});
      if (this.tabIndex !== undefined) {
        document.querySelector(`#pm-editor-${this.id} > div[contenteditable="true"]`).tabIndex = this.tabIndex;
      }
      this.pmView.onChange(this.pmInputDetect);
      this.setEditable();
    },
    pmInputDetect (t) {
      // on cursor position change
      if (t.updated === 1) return;
      this.$emit("textChangeEvent");
      if (this.saveTimeout > 0) {
        clearTimeout(this.saveTimeout);
        this.saveTimeout = -1;
      }
      // actually wait for content to be updated before notifiying the parent prop
      this.saveTimeout = setTimeout(() => {
        this.$emit("transcriptEditedEvent", this.pmView.content);
      }, 0);
      this.saveTimeout = setTimeout(() => {
        this.savePmChanges();
      }, 3000);

      return;
    },
    savePmChanges () {
      this.editBuffer = [];
      this.saveTimeout = -1;
    },

    // * animation and visual control functions
    textAreaBlur () {
      this.$emit('blur');
      this.fieldFocused = false;
    },
    textAreaFocus (e, a, b, c) {
      this.$emit('focus');
      this.showToggle = true;
      this.fieldFocused = true;
    },
    // this height transition will only work in specific cases. It doesn't handle nesting elements
    // before inital render
    setEnterLeaveHeight (el) {
      el.style.maxHeight = "0px";
    },
    // called one frame after the element is inserted.
    setTransitionHeight (el, done) {
      let height = 0;
      for (var i = 0; i < el.childElementCount; i++) {
        height = Math.max(el.children[i].clientHeight, height);
      }
      el.style.maxHeight = `${height}px`;
      setTimeout(done, 150);
    },

    transitionDone () {
      this.$emit('toggleOriginal');
    },
    // triggered on clickout of the full rtf element, not just the field
    toggleOff () {
      this.toggleDiff = false;
      this.showToggle = false;
    },
    toggleShowOriginal () {
      this.toggleDiff = !this.toggleDiff;
    },
  }
};
</script>
<style scoped>
.custom-area,
.content-editable,
.custom-area>* {
  outline: none;
  resize: none;
  display: inline-table;
  cursor: text;
  background: rgba(1, 1, 1, 0);
  @apply transition-colors;
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

.custom-area[disabled] {
  cursor: not-allowed;
}

.orig-match-width {
  width: calc(100% + 1rem);
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.growing-textarea {
  display: grid;
}

.growing-textarea::after {
  /* Note the weird space! Needed to prevent jumpy behavior */
  content: attr(data-replicated-value) " ";
  /* This is how textarea text behaves */
  white-space: pre-wrap;
  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

.arrow-down {
  transform: matrix(1, 0, 0, 1, 0, -1);
  transition: 0.2s ease-out;
}

.arrow-up {
  transform: matrix(1, 0, 0, -1, 0, 1);
  transition: 0.2s ease-out;
}
</style>
<style >
.ProseMirror {
  outline: none !important;
}

.ProseMirror {
  width: 100%;
  resize: none;
  display: inline-table;
  cursor: text;
  background: rgba(1, 1, 1, 0);
  @apply transition-colors;
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ProseMirror * {
  @apply text-sm;
}
.ProseMirror[contenteditable="false"] {
   cursor:default;
}

/* .ProseMirror-icon,
.ProseMirror-menubar {
  display: none;
} */
</style>
<template>
  <BladeHeader>
    <div class="flex text-neutral-alt justify-center lg:justify-left mx-auto lg:mx-0 font-extrabold text-base lg:pl-6 my-auto">
      <BubbleToggle
        :selected="bubbleSelected"
        :selections="['Note Inbox', 'Archived']"
        setWidth="8rem"
        @selection-made="(val) => { changeNotesList(val) }"
      />
    </div>
  </BladeHeader>
  <div class="notes-blade w-full flex flex-col mx-auto pl-2 lg:pt-6 lg:pl-6">
    <!-- draft collapsable section-->
    <div class="pr-2 xl:pr-6">

      <transition name="fade-pull" appear>
        <div v-if="draftNotes.totalCount !== 0" class="header-transition-duration replace-fade-pull flex flex-col w-full bg-pastel-purple-light hover:bg-rare-lightest border border-rare-light rounded-xl ">
          <div id="draft-list-collapsable" class="clickable-element p-2.5 flex flex-grow items-center"
               role="button" @click="showDrafts = !showDrafts">
            <!-- draft count -->
            <div :class="['count-icon flex flex-col justify-center text-center bg-rare rounded text-white font-extrabold text-sm transition-colors',
                          draftNotes.draftNotif ? 'bg-rare-dark' : '']">
              {{ draftNotes.totalCount }}
            </div>
            <div class="relative">
              <Transition name="fade-pull">
                <span v-if="draftNotes.draftNotif" class="replace-fade-pull duration-150 pl-3.5 pr-2 text-rare-dark text-sm font-roboto whitespace-nowrap">
                  A new draft is being recorded!
                </span>
                <span v-else class="replace-fade-pull block duration-150 pl-3.5 pr-2 text-neutral text-sm font-roboto">
                  Drafts waiting to be submitted.
                </span>
              </Transition>
            </div>
            <button id="submit-all-drafts" class="as-anchor font-bold leading-4 whitespace-nowrap" :disabled="submittingDrafts" @click.stop="submitDrafts">
              Submit All
            </button>
            <button class="ml-auto flex items-center" @click.stop="showDrafts = !showDrafts">
              <span :class="['material-icons  text-rare transform transition-transform',
                             showDrafts ? 'flip-x' : '']">
                keyboard_arrow_down</span>
            </button>
          </div>

          <!-- listing draft Notes -->
          <Transition name="expand"
                      @before-enter="setEnterLeaveHeight"
                      @before-leave="setEnterLeaveHeight"
                      @enter="setTransitionHeight">
            <div v-show="showDrafts" class="transition-all overflow-hidden">
              <div class="max-h-[138px] overflow-y-auto">
                <RouterLink
                  v-for="(note,index) in draftNotes.notes" :id="`draft-note-${index}`" :key="note.id"
                  :to="`/notes/${note.id}`" class="note-button round-more pl-3 py-3 flex font-roboto invisible-anchor items-center
                hover:bg-gray-100 duration-150 transition-colors"
                  @click="trackNoteNav(note)">
                  <span class="whitespace-nowrap font-bold pl-2 pr-4 text-xs">{{ note.displayDate.time }}</span>
                  <!-- editors note: holy heck actually working ellipsis overflow? I put way too many thought cycles into these 2 lines of HTML/CSS -->
                  <div class="table table-fixed w-full pr-2">
                    <span class="table-cell whitespace-nowrap overflow-ellipsis overflow-hidden text-sm font-roboto font-semibold">
                      {{ note.transcript_preview ? removeMdCharacters(note.transcript_preview) : note.title ? note.title : 'untitled note' }}
                    </span>
                  </div>
                </RouterLink>
              </div>
            </div>
          </Transition>

        </div>
        <!-- <div v-else-if="loadingNotes" class="replace-fade-pull h-[52px]"></div> -->
      </transition>
    </div>

    <!-- actions filters search -->

    <div class="relative  flex justify-between flex-grow pr-2 lg:pr-6 mt-5 mb-6 h-[34px] max-h-[34px]">
      <ActionMenu class="max-w-[124px]"
                  :checkVal="allSelected"
                  @check-click="selectAll(!allSelected)">
        <div class="flex">
          <button class="whitespace-nowrap font-medium p-3 text-neutral-alt hover:bg-pastel-blue-light hover:bg-opacity-25"
                  @click="archiveSelection(bubbleSelected === 0)">
            {{ bubbleSelected === 0 ? 'Archive' : 'Unarchive' }} Selection
          </button>
        </div>
      </ActionMenu>

      <!-- filter and search block-->
      <div :class="['filter-wrapper', showSearch ? 'expand-search' : '']">
        <BaseIconDropdown
          id="note-list-filter-dropdown"
          class="text-xl rounded-xl border h-[34px] w-[112px] ml-2.5 bg-white border-[#9BACB2] hover:bg-gray-100 transition-colors"
          menuText="Filters"
          icon="filter_list"
          no-border
          menuX="right-0"
          menuY="top-8"
          :notifCount="filterCount"
          square
        >

          <div class="px-2 w-60">
            <div class="flex justify-between">
              <span class="font-bold text-xl text-black my-2">Filters</span>
              <button class="as-anchor text-base font-bold my-2" @click="clearHeaderFilters">
                Clear Filters
              </button>
            </div>
            <BaseDatepicker
              id="note-date-range-input"
              class="pb-2"
              placeholder="Date Range"
              :date="dateFilter"
              @update:date="queryDate" />

            <!-- v-if="authors.length > 1" -->
            <BaseMultiSelect
              id="note-author-filter-multiselect"
              class="pb-2"
              :value="authorsMultiselect"
              placeholderText="Recorded By"
              fullMenu
              showCount
              ellipsis
              showSearch
              @update:value="queryUser"
            />

            <BaseMultiSelect
              id="note-status-filter-multiselect"
              class="pb-2"
              :value="statusMultiselect"
              placeholderText="Status"
              fullMenu
              showCount
              ellipsis
              showSearch
              @update:value="queryStatus"
            />
          </div>

        </BaseIconDropdown>

        <div class="blade-search flex ml-2.5 border border-[#9BACB2] bg-white rounded-xl">
          <div :class="['search-wrapper overflow-hidden']">
            <input id="note-search-input" v-model="searchText" type="text"
                   class="text-search-input rounded-xl h-full w-full flex flex-grow" />
          </div>
          <button :class="['my-auto px-2']" @click="toggleSearch">
            <span :class="'material-icons text-[#9BACB2] text-lg'"> search</span>
          </button>
        </div>
      </div>
    </div>
    <!-- <button @click="spliceIntoRecent"> test</button> -->
    <!-- notes list -->
    <div id="blade-notes-list" class="overflow-y-scroll flex-grow overflow-x-hidden pr-2">

      <div v-if="!loadingNotes && notesList.length == 0" class="hidden last:flex flex-col rounded-md p-3 text-center
                    bg-white border border-rare font-bold">
        {{ notesList }}
        <span class="text-base pb-2">Try out Talkatoo Notes!</span> Record patient notes on your phone and they will be automatically processed as a SOAP note.
      </div>
      <TransitionGroup name="list" appear>
        <div v-for="(day, index) in notesList " :key="day.key"
             class="flex-col transition-all last:mb-16">
          <div class="sticky top-0 z-10 bg-pastel-purple-lighter">

            <div class="pb-2 text-sm font-roboto font-bold text-neutral">
              {{ day.heading }}
            </div>
          </div>
          <div class="note-group mb-4">
            <TransitionGroup name="list" appear>
              <RouterLink
                v-for="(note, noteIndex) in day.notes" :id="`note-link-${index}-${noteIndex}`" :key="note.id"
                :to="`/notes/${note.id}`"
                :class="['note-button pl-3 py-3 flex font-roboto invisible-anchor items-center',
                         'hover:bg-gray-100 duration-150 transition-colors',
                         note.state == 3 || note.state == 4 ? 'bg-pastel-green-light' : 'bg-white',
                         note.state == 5 ? 'bg-pastel-yellow-light opacity-50 pr-16' : 'bg-white',
                         note.changeNotif || note.state == 5 ? 'pr-16' : '']
                "
                @click="trackNoteNav(note)">
                <div class="block">
                  <input
                    :checked="note.selected"
                    type="checkbox"
                    class="tk-check"
                    :value="note.selected"
                    @click.stop="selectNote(note)" />
                </div>

                <span class="block whitespace-nowrap font-bold pl-2 pr-2 text-sm min-w-[4.5rem]" :style="`min-width:${note.displayDate.time.length * 10}px;`">
                  {{ note.displayDate.time }}
                </span>
                <!-- editors note: holy heck actually working ellipsis overflow? I put way too many thought cycles into these 2 lines of HTML/CSS -->
                <div class="table table-fixed w-full pr-2">
                  <span :class="['table-cell w-full whitespace-nowrap overflow-ellipsis overflow-hidden text-sm font-roboto font-semibold ',
                                 note.state != 1 ? 'text-neutral' : 'text-neutral-darker']
                  ">
                    <template v-if="note.state == 5">
                      Generating Title...
                    </template>
                    <template v-else>
                      <div v-if="note.assistant_generated" class="text-neutral-alt font-bold inline-block ">
                        <div class="inline-block my-auto">
                          <svg class="inline-block" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                            <path d="M3.27599 2.28346L4.40326 2.89964C4.54798 2.97825 4.6013 3.15829 4.52259 3.30282C4.49466 3.35354 4.45404 3.39411 4.40326 3.422L3.27599 4.03565C3.22521 4.06354 3.18459 4.10411 3.15666 4.15482L2.53971 5.28068C2.461 5.42522 2.28074 5.47847 2.13602 5.39986C2.08524 5.37197 2.04462 5.3314 2.01669 5.28068L1.40228 4.15482C1.37435 4.10411 1.33373 4.06354 1.28295 4.03565L0.155677 3.422C0.0109591 3.34339 -0.0423579 3.16336 0.0363481 3.01882C0.0642761 2.96811 0.104899 2.92754 0.155677 2.89964L1.28295 2.28346C1.33373 2.25557 1.37435 2.215 1.40228 2.16428L2.01923 1.04096C2.09794 0.896424 2.28074 0.843174 2.42546 0.924317C2.4737 0.95221 2.51432 0.992781 2.54225 1.04096L3.1592 2.16428C3.18459 2.215 3.22521 2.25557 3.27599 2.28346Z" fill="#52BAE2" />
                            <path d="M11.6672 8.29362L12.7944 8.9098C12.9392 8.98841 12.9925 9.16844 12.9138 9.31298C12.8858 9.36369 12.8452 9.40427 12.7944 9.43216L11.6672 10.0483C11.6164 10.0762 11.5758 10.1168 11.5478 10.1675L10.9309 11.2934C10.8522 11.4379 10.6694 11.4912 10.5247 11.4126C10.4739 11.3847 10.4333 11.3441 10.4053 11.2934L9.79345 10.165C9.76552 10.1143 9.7249 10.0737 9.67412 10.0458L8.54685 9.42962C8.40213 9.35102 8.34882 9.16844 8.42752 9.02391C8.45545 8.97319 8.49607 8.93262 8.54685 8.90473L9.67412 8.28855C9.7249 8.26066 9.76552 8.22008 9.79345 8.16937L10.4104 7.04605C10.4891 6.90151 10.6719 6.84826 10.8166 6.92687C10.8674 6.95476 10.908 6.99533 10.936 7.04605L11.5529 8.17191C11.5758 8.22516 11.6164 8.26573 11.6672 8.29362Z" fill="#52BAE2" />
                            <path d="M6.78731 0.994116L7.52613 1.3973C7.62007 1.45055 7.65561 1.56972 7.60483 1.66355C7.58706 1.69651 7.55913 1.72187 7.52613 1.74215L6.78731 2.14533C6.7543 2.16308 6.72637 2.19098 6.7086 2.22394L6.30491 2.96183C6.2516 3.05566 6.13227 3.09116 6.03833 3.04044C6.00532 3.02269 5.97993 2.9948 5.95962 2.96183L5.55594 2.22394C5.53817 2.19098 5.51024 2.16308 5.47723 2.14533L4.73841 1.74215C4.64447 1.69144 4.60893 1.57226 4.6597 1.4759C4.67748 1.44294 4.7054 1.41505 4.73841 1.3973L5.47723 0.994116C5.51024 0.976366 5.53817 0.948473 5.55594 0.915508L5.95962 0.177614C6.01294 0.0837921 6.13227 0.048292 6.22621 0.0990064C6.25921 0.116756 6.2846 0.144649 6.30491 0.177614L6.7086 0.915508C6.72637 0.948473 6.7543 0.976366 6.78731 0.994116Z" fill="#52BAE2" />
                            <path d="M12.7106 1.33252L11.6671 0.290343C11.2812 -0.0950864 10.6541 -0.0976221 10.2656 0.287807L10.2631 0.290343L0.960554 9.58376C0.574641 9.96919 0.572102 10.5955 0.960554 10.9835L2.00404 12.0257C2.38996 12.4111 3.01707 12.4136 3.40552 12.0257L12.7106 2.73224C13.0965 2.34681 13.0965 1.72049 12.7106 1.33252ZM2.72636 11.1952L1.79204 10.2621L7.59674 4.46211L8.53106 5.39526L2.72636 11.1952Z" fill="#52BAE2" />
                          </svg>
                        </div>
                        Assistant Generated:
                      </div>
                      <div v-else-if="note.telephony_generated" class="text-neutral-alt font-bold inline-block pr-1">
                        <div class="inline-block my-auto">
                          <svg class="inline-block mb-1" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                            <path d="M9.88354 8.59955C9.45882 8.18023 8.92859 8.18023 8.50658 8.59955C8.18465 8.91877 7.86273 9.238 7.54622 9.56264C7.45965 9.65191 7.38661 9.67085 7.2811 9.61133C7.0728 9.49771 6.85097 9.40573 6.65078 9.28128C5.71747 8.69423 4.93566 7.93946 4.24312 7.08999C3.89955 6.66796 3.59386 6.21618 3.38015 5.70758C3.33686 5.60478 3.34498 5.53715 3.42884 5.45328C3.75077 5.14217 4.06457 4.82295 4.38109 4.50372C4.82204 4.06005 4.82204 3.54063 4.37838 3.09426C4.12679 2.83996 3.87521 2.59107 3.62362 2.33677C3.36392 2.07707 3.10692 1.81465 2.84451 1.55765C2.41979 1.14374 1.88956 1.14374 1.46754 1.56035C1.14292 1.87958 0.831814 2.20692 0.501775 2.52073C0.196083 2.8102 0.0418842 3.1646 0.00942133 3.57851C-0.0419782 4.25213 0.123041 4.88787 0.355692 5.50739C0.831814 6.7897 1.55682 7.92863 2.43602 8.97288C3.62362 10.385 5.04116 11.5023 6.69948 12.3085C7.44612 12.671 8.21982 12.9497 9.06115 12.9957C9.64007 13.0281 10.1432 12.882 10.5463 12.4303C10.8223 12.1219 11.1334 11.8405 11.4255 11.5456C11.8584 11.1074 11.8611 10.5771 11.4309 10.1443C10.9169 9.62756 10.4002 9.11356 9.88354 8.59955Z" fill="#52BAE2" />
                            <path d="M9.36692 6.44361L10.3651 6.27317C10.2082 5.35608 9.77541 4.52555 9.11803 3.86545C8.42279 3.17019 7.54358 2.73193 6.57511 2.59667L6.43444 3.60033C7.18379 3.70584 7.86551 4.044 8.40385 4.58236C8.91243 5.09096 9.24518 5.73482 9.36692 6.44361Z" fill="#52BAE2" />
                            <path d="M10.9278 2.10473C9.77536 0.952267 8.31723 0.22454 6.70762 0L6.56694 1.00367C7.95744 1.19845 9.21808 1.82878 10.2136 2.82163C11.1577 3.76578 11.7772 4.95882 12.0018 6.27089L13 6.10046C12.7376 4.58008 12.0207 3.20037 10.9278 2.10473Z" fill="#52BAE2" />
                          </svg>
                        </div>
                        Call Summary:
                      </div>
                      {{ note.title ? note.title : note.transcript_preview ? removeMdCharacters(note.transcript_preview) : 'untitled note' }}

                    </template>
                  </span>
                  <BaseLoading v-if="note.state == 5" class="table-cell absolute top-0 right-0 transform scale-50 -translate-y-1/4" />
                  <div v-else-if="note.changeNotif" class="table-cell absolute top-1/2 right-2 transform -translate-y-1/2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 21 16" fill="none">
                      <path d="M10.5 3.77L17.2095 14H3.7905L10.5 3.77ZM10.5 0L0 16H21L10.5 0Z" fill="#52BAE2" />
                    </svg>
                  </div>
                </div>
              </RouterLink>
            </TransitionGroup>
          </div>
        </div>
      </TransitionGroup>
    </div>

  </div>

</template>
<script>
const NotesService = require("Services/NotesService");
import MixpanelService from "@/services/MixpanelService";
import BladeHeader from "./BladeHeader.vue";
import BubbleToggle from "@/components/ui/BubbleToggle.vue";
import { QueryManager, Operators, SupportedTypes, Sorting, NoteViews } from "Modules/NotesQueryManager.js";
import BaseLoading from "@/components/ui/BaseLoading.vue";
import BaseIconDropdown from "@/components/ui/BaseIconDropdown.vue";
import BaseDatepicker from "@/components/ui/BaseDatepicker.vue";
import BaseMultiSelect from "@/components/ui/BaseMultiSelect.vue";
import NoteStatesMap from "@/modules/NoteStatesMap";
import ActionMenu from "Components/ui/ActionMenu";
import { golden_retriever } from "@/tests/mock/seeds/ProductSeeds";

export default {
  name: "NotesBlade",
  components: {
    BladeHeader, BubbleToggle, BaseLoading, BaseIconDropdown,
    BaseDatepicker, BaseMultiSelect, ActionMenu
  },
  props: {},
  data () {
    const currUserId = this.$store.getters.getUserId;
    let userConfig = localStorage.getItem(`NotesBlade:${currUserId}-UserConfig`);
    if (!userConfig) userConfig = { cachedFilters: {} };
    else userConfig = JSON.parse(userConfig);
    if (userConfig?.cachedFilters?.dateFilter && userConfig.cachedFilters.dateFilter.length === 2) {
      userConfig.cachedFilters.dateFilter[0] = new Date(userConfig.cachedFilters.dateFilter[0]);
      userConfig.cachedFilters.dateFilter[1] = new Date(userConfig.cachedFilters.dateFilter[1]);
    }

    return {
      currUserId,
      userConfig,
      //0 == active 1 == archived
      bubbleSelected: 0,

      loadingNotes: true,

      showDrafts: false,
      submittingDrafts: false,
      showSearch: false,
      notesList: [],
      recentNotes: {
        queryManager: new QueryManager({ paged: true, pageSize: 30, asTable: true, noteView: NoteViews.All }),
        defaultFilters: [{ field: 'state', value: [NoteStatesMap.indexOf("Draft")], op: Operators.NE, type: SupportedTypes.INT },
        { field: 'archived', value: [false], op: Operators.EQ, type: SupportedTypes.BOOL },],
        totalCount: 0,
        page: 1,
        list: [
          // { heading: "Today", notes: [] }, { heading: "Yesterday", notes: [] } // sample data
        ],
        notes: [],
      },
      archivedNotes: {
        queryManager: new QueryManager({ paged: true, pageSize: 30, asTable: true, noteView: NoteViews.ARCHIVED }),
        defaultFilters: [],
        totalCount: 0,
        page: 1,
        list: [
          // { heading: "Today", notes: [] }, { heading: "Yesterday", notes: [] } // sample data
        ],
        notes: [],
      },
      draftNotes: {
        queryManager: new QueryManager({ paged: false, asTable: true, noteView: NoteViews.ACTIVE }),
        defaultFilters: [{ field: 'state', value: [NoteStatesMap.indexOf("Draft")], op: Operators.EQ, type: SupportedTypes.INT }],
        totalCount: 0,
        page: 1,
        // list: [
        //   // { heading: "Today", notes: [] }, { heading: "Yesterday", notes: [] } // sample data
        // ],
        notes: [],
        draftNotif: false,
      },

      dateFilter: userConfig?.cachedFilters?.dateFilter ?? [],
      authors: [],
      authorsMultiselect: {},
      authorsIdMap: {},
      statusMultiselect: {},
      allSelected: false,

      searchText: '',

      activeRequest: 0,

      wsIds: [],
    };
  },
  mounted () {
    this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onNoteStateEvent(this.noteStateEventHandler));
    this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onNoteIssueEvent(this.noteIssueEventHandler));
    this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onInternalEvent(this.internalEventHandler));

  },
  created () {
    this.loadingNotes = true;

    let cachedFilters = undefined; // todo add cached filters here
    let authorProm = NotesService.GetAuthorList()
      .then((resp) => {
        let i = 0;
        const names = resp.data.authors.map(x => `${x.first_name} ${x.last_name}`);
        this.authors = resp.data.authors.map((x, index, self) => {
          // add integer to names when there are duplicates. Not great but its something
          let dup = names.indexOf(`${x.first_name} ${x.last_name}`) !== index;
          return {
            firstName: x.first_name, lastName: x.last_name,
            name: `${x.first_name} ${x.last_name}${dup ? ` ${(++i)}` : ''}`,
            userId: parseInt(x.user_id)
          };
        });
        let filter = this.userConfig?.cachedFilters?.userFilter;
        this.authorsMultiselect = this.authors
          .reduce((prop, curr) => {
            if (filter && filter[curr.name]) {
              prop[curr.name] = true;
            } else {
              prop[curr.name] = false;
            }
            return prop;
          }, {}),
          this.authorsIdMap = this.authors.reduce((prop, x) => {
            prop[x.name] = x.userId;
            return prop;
          }, {});
      });

    // handle notes once author list is resolved
    authorProm.then(() => {
      let noteProm;
      if (this.bubbleSelected === 0) noteProm = this.getRecentNotes();
      else noteProm = this.getArchivedNotes();
      this.getDraftNotes();

      noteProm
        .then((resps) => {
          if (this.bubbleSelected === 0) this.notesList = this.recentNotes.list;
          else this.notesList = this.archivedNotes.list;
          this.initInfiniteScroll();
        }).finally(()=>{
          this.loadingNotes = false;
        });
    });


    this.statusMultiselect = this.userConfig.cachedFilters.statusFilter ?? {
      // "Draft": false,
      "Awaiting Review": false,
      "In Review": false,
      "Completed": false,
      // "In PMS": false,
      // "Processing": false,
      // "Needs Attention": false,
    };
  },
  beforeUnmount () {
    this.$store.getters.getWebsocketEventHandler.removeEvents(this.wsIds);
  },
  watch: {
    searchText (newVal, oldVal) {
      this.getFilteredNotes();
    },
  },
  computed: {
    parsedFilters () {
      let queryFilters = [];
      let filters = this.userConfig.cachedFilters;
      if (!filters) return [];
      if (filters.dateFilter && filters.dateFilter.length == 2) {
        const start = new Date(filters.dateFilter[0]).setHours(0, 0, 0, 0) / 1000;
        const end = new Date(filters.dateFilter[1].getTime()).setHours(23, 59, 59, 999) / 1000;
        queryFilters.push({ field: 'date_filter', value: [start, end], op: Operators.BT, type: SupportedTypes.INT });
      }
      if (filters.userFilter) {
        let userIds = [];
        Object.keys(filters.userFilter).forEach(key => {
          if (filters.userFilter[key]) {
            userIds.push(this.authorsIdMap[key]);
          }
        });
        if (userIds.length > 0) {
          queryFilters.push({ field: 'user_id', value: userIds, op: Operators.IN, type: SupportedTypes.INT });
        }
      }
      if (filters.statusFilter) {
        let states = [];
        let na = false;
        NoteStatesMap.forEach((state, index) => {
          if (filters.statusFilter[state] && state == "Needs Attention") { na = true; }
          else if (filters.statusFilter[state]) states.push(index);
        });
        if (filters.statusFilter["Completed"]) {
          states.push(3, 4);
        }
        if (states.length > 0) {
          queryFilters.push({ field: 'state', value: states, op: Operators.IN, type: SupportedTypes.INT });
        } if (na) {
          queryFilters.push({ field: 'issue_count', value: [0], op: Operators.NE, type: SupportedTypes.INT });
        }
      }
      return queryFilters;
    },
    filterCount () {
      let count = 0;
      if (this.userConfig.cachedFilters.dateFilter && this.userConfig.cachedFilters.dateFilter.length === 2) count++;
      if (this.userConfig.cachedFilters.userFilter) {
        Object.keys(this.userConfig.cachedFilters.userFilter).forEach(key => { if (this.userConfig.cachedFilters.userFilter[key]) count++; });
      }
      if (this.userConfig.cachedFilters.statusFilter) {
        Object.keys(this.userConfig.cachedFilters.statusFilter).forEach(key => { if (this.userConfig.cachedFilters.statusFilter[key]) count++; });
      }
      return count;
    }
  },
  methods: {
    getRecentNotes () {
      return this.recentNotes.queryManager.getNotesWith({
        filters: [
          ...this.recentNotes.defaultFilters,
          ...this.parsedFilters
        ],
        sorting: { field: "date", sortBy: Sorting.DESCENDING },
        noteView: NoteViews.ALL,
        page: 1,
      })
        .then(resp => {
          this.recentNotes.notes = resp.data.notes;
          this.recentNotes.list = this.parseNotesToPreview(resp.data.notes);
          this.recentNotes.totalCount = resp.data.total_count;
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          // this.loadingNotes = false;
        });

    },
    getArchivedNotes () {
      return this.archivedNotes.queryManager.getNotesWith({
        filters: [
          ...this.archivedNotes.defaultFilters,
          ...this.parsedFilters
        ],
        sorting: { field: "date", sortBy: Sorting.DESCENDING },
        noteView: NoteViews.ARCHIVED,
        page: 1,
      })
        .then(resp => {
          this.archivedNotes.notes = resp.data.notes;
          this.archivedNotes.list = this.parseNotesToPreview(resp.data.notes);
          this.archivedNotes.totalCount = resp.data.total_count;
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          // this.loadingNotes = false;
        });

    },
    getDraftNotes () {
      // just get drafts. use a new query manager just to format the request
      this.draftNotes.queryManager.getNotesWith({
        filters: [
          { field: 'state', value: [NoteStatesMap.indexOf("Draft")], op: Operators.EQ, type: SupportedTypes.INT }
        ],
        sorting: { field: "date", sortBy: Sorting.DESCENDING },
        noteView: NoteViews.ACTIVE,
        page: 1,
      })
        .then(resp => {
          // this.draftNotes.list = resp.data.notes;
          this.draftNotes.notes = resp.data.notes;
          this.draftNotes.totalCount = resp.data.total_count;
          this.parseDraftNotesToPreview();
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          // this.loadingNotes = false;
        });
    },
    getNextNotePage () {
      let listRef;

      if (this.bubbleSelected === 0) listRef = this.recentNotes;
      else listRef = this.archivedNotes;

      if (listRef.notes.length >= listRef.totalCount) return;
      this.loadingNotes = true;
      // this.loading = true;
      listRef.page++;
      listRef.queryManager.getNotesWith({
        filters: [
          ...listRef.defaultFilters,
          ...this.parsedFilters
        ],
        sorting: { field: "date", sortBy: Sorting.DESCENDING },
        page: listRef.page,
      }).then(resp => {
        listRef.notes.push(...resp.data.notes);
        listRef.list = this.parseNotesToPreview(listRef.notes);
        listRef.totalCount = resp.data.total_count;
        this.notesList = listRef.list;
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        this.loadingNotes = false;
      });
    },
    getFilteredNotes () {
      this.allSelected = false;

      this.activeRequest += 1;
      const currRequest = this.activeRequest;

      let listRef;
      if (this.bubbleSelected === 0) listRef = this.recentNotes;
      else listRef = this.archivedNotes;

      this.loadingNotes = true;
      // this.loading = true;
      listRef.page = 1;
      listRef.queryManager.getNotesWith({
        filters: [
          ...listRef.defaultFilters,
          ...this.parsedFilters
        ],
        textSearch: this.searchText,
        sorting: { field: "date", sortBy: Sorting.DESCENDING },
        page: 1,
      }).then(resp => {
        if (this.activeRequest !== currRequest) return;
        listRef.list = this.parseNotesToPreview(resp.data.notes);
        listRef.notes = resp.data.notes;
        listRef.totalCount = resp.data.total_count;
        this.notesList = listRef.list;
      }).catch((err) => {
        console.error(err);
      }).finally(() => {
        this.loadingNotes = false;
      });
    },
    initInfiniteScroll () {
      const notesList = document.getElementById(`blade-notes-list`);
      if (notesList) {
        notesList.addEventListener("scroll", (event) => { this.scrollHandler(event); });
      } else {
        console.warn("Failed to init infinite scroll");
        window.setTimeout(this.initInfiniteScroll, 1000);
      }
    },
    scrollHandler (event) {
      const cont = event.target;
      const contHeight = event.target.getBoundingClientRect().height;
      const scrollTriggerPadding = 64;
      if (cont.scrollHeight - (cont.scrollTop + contHeight + scrollTriggerPadding) <= 0) {
        if (!this.loading && this.recentNotes.list.length !== this.recentNotes.totalCount) {
          this.getNextNotePage();
        }
      }
    },
    // 0 == active 1 == archived
    changeNotesList (view) {
      this.bubbleSelected = view;
      this.loadingNotes = true;
      this.notesList = [];
      if (this.showSearch) this.toggleSearch();
      this.clearHeaderFilters();
      this.selectAll(false);

      let noteProm;
      if (this.bubbleSelected === 0) noteProm = this.getRecentNotes();
      else noteProm = this.getArchivedNotes();
      this.getDraftNotes();

      Promise.all([noteProm])
        .then((resps) => {
          if (this.bubbleSelected === 0) this.notesList = this.recentNotes.list;
          else this.notesList = this.archivedNotes.list;
          this.loadingNotes = false;
        });
    },
    // temporary for show
    parseNotesToPreview (notes) {
      let map = {};
      notes.forEach((note) => {
        let ts = 0;
        if (note.state == 0 || note.state == 5) {
          ts = note.created_at;
        } else if (note.published_at == 0)
          ts = note.last_edited;
        else {
          ts = note.published_at;
        }

        note.sortDate = ts;
        // ts in ms at midnight
        note.displayDate = {
          date: Intl.DateTimeFormat(undefined, { month: "short", day: "numeric", year: "numeric", }).format(ts * 1000),
          time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", }).format(ts * 1000),
        };
        note.mapDate = new Date(ts * 1000).setHours(0, 0, 0, 0);
      });
      if (this.isUserScribe) {
        notes.sort((a, b) => {
          if (a.needs_attention) {
            return -1;
          }
        });
      }
      notes.sort((a, b) => {
        return a.sortDate === b.sortDate ? 0 :
          a.sortDate < b.sortDate ? 1 : -1;
      });
      if (!this.isUserScribe) {
        notes.sort((a, b) => {
          if (a.needs_attention) {
            return -1;
          }
        });
      }
      let now = new Date();
      notes.forEach((note) => {
        let label = "";
        if (note.needs_attention && !this.isUserScribe) {
          label = 'Needs Attention';
        } else {
          let d = new Date(note.mapDate);
          if (d.getFullYear() === now.getFullYear() &&
            d.getMonth() === now.getMonth()) {
            if (d.getDate() === now.getDate()) {
              label = "Today";
            } else if (d.getDate() === now.getDate() - 1) {
              label = "Yesterday";
            } else {
              label = new Date(note.mapDate).toString().slice(0, 'Fri Jan 01 1970'.length);
            }
          } else {
            label = new Date(note.mapDate).toString().slice(0, 'Fri Jan 01 1970'.length);
          }
        }
        if (!map[label]) {
          map[label] = [];
        }
        map[label].push(note);
      });

      let list = [];
      Object.keys(map).forEach((key) => {
        list.push({ heading: key === 'Needs Attention' ? key : key.slice(0, 'Fri Jan 01'.length), key: key, notes: map[key] });
      });
      return list;
    },
    parseDraftNotesToPreview () {

      this.draftNotes.notes.forEach((note) => {
        let ts = 0;
        if (note.state == 0 || note.state == 5) {
          ts = note.created_at;
        } else if (note.published_at == 0)
          ts = note.last_edited;
        else {
          ts = note.published_at;
        }
        note.sortDate = ts;
        // ts in ms at midnight
        note.displayDate = {
          date: Intl.DateTimeFormat(undefined, { month: "short", day: "numeric", year: "numeric", }).format(ts * 1000),
          time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", }).format(ts * 1000),
        };
        note.mapDate = new Date(ts * 1000).setHours(0, 0, 0, 0);
      });
    },
    archiveSelection (archive) {
      let cancelSplice = false;
      let selectedNotes = this.notesList.reduce((list, group) => {
        list.push(...group.notes);
        return list;
      }, []).filter(note => note.selected);

      let proms = [];
      selectedNotes.forEach(note => {
        note.selected = false;
        this.$store.getters.getWebsocketEventHandler.sendInternalUpdateEvent({
          type: "Note:ArchiveChange",
          noteId: note.id,
          archived: archive,
        });
        // TODO: archive the notes based on bubble selection
        let archived = NotesService.ArchiveNote(note.id, archive)
          .then(resp => {
            note = resp.data;
          })
          .catch((e) => {
            console.log(e);
            cancelSplice = true;
            this.$toast.error({ message: "Issue with submitting note for processing! make sure all audio is uploaded." });

          });
        proms.push(archived);
      });

      let removeFrom, addTo;

      if (archive) {
        removeFrom = this.recentNotes;
        addTo = this.archivedNotes;
      } else {
        removeFrom = this.archivedNotes;
        addTo = this.recentNotes;
      }

      Promise.all(proms).then(() => {
        if (cancelSplice) return;
        selectedNotes.forEach(note => {
          let i = removeFrom.notes.findIndex(x => x.id == note.id);
          if (i !== -1) {
            removeFrom.notes.splice(i, 1);
          }
          else {
            console.log('failed to find note');
          }
          addTo.notes.push(note);
        });
        removeFrom.list = this.parseNotesToPreview(removeFrom.notes);
        addTo.list = this.parseNotesToPreview(addTo.notes);
        if (this.bubbleSelected === 0) this.notesList = this.recentNotes.list;
        else this.notesList = this.archivedNotes.list;
        this.selectAll(false);
      });

    },
    selectNote (note) {
      note.selected = !note.selected;
      if (note.selected) {
        this.allSelected = true;
      } else {
        let listRef = undefined;
        if (this.bubbleSelected === 0) listRef = this.recentNotes;
        else listRef = this.archivedNotes;
        if (listRef.notes.some(x => x.selected)) this.allSelected = true;
        else this.allSelected = false;
      }

    },
    selectAll (val) {
      this.allSelected = val;
      this.notesList.forEach(group => group.notes.forEach(note => note.selected = val));
    },
    submitDrafts () {
      this.submittingDrafts = true;
      let proms = [];
      let cancelSplice = false;
      this.draftNotes.notes.forEach(note => {
        let closed = NotesService.CloseNote(note.id)
          .then(resp => {
            note = resp.data;
          })
          .catch((e) => {
            console.log(e);
            cancelSplice = true;
            this.$toast.error({ message: "Issue with submitting note for processing! make sure all audio is uploaded." });

          });
        proms.push(closed);
      });
      Promise.all(proms).then(() => {
        if (cancelSplice) return;
        this.draftNotes.totalCount = 0;
        this.draftNotes.notes.forEach(note => {
          note.changeNotif = true;
          note.state = NoteStatesMap.indexOf("Processing");
          this.recentNotes.notes.splice(0, 0, note);
          this.recentNotes.totalCount++;
        });
        this.recentNotes.list = this.parseNotesToPreview(this.recentNotes.notes);
        if (this.bubbleSelected === 0) this.notesList = this.recentNotes.list;
        this.draftNotes.notes = [];
      }).finally(() => {
        this.submittingDrafts = false;
      });

    },
    toggleSearch () {
      this.showSearch = !this.showSearch;
      if (!this.showSearch) this.searchText = '';
      if (this.showSearch) {
        setTimeout(() => { document.getElementById("note-search-input").focus(); }, 100);
      }
    },
    noteStateEventHandler (e) {
      // new Draft note
      if (e.oldState == 0 && e.oldState == e.newState) {
        this.draftNotes.queryManager.invalidateCache();
        // this.getDraftNotes();
        NotesService.GetNote(e.noteId).then((resp) => {
          this.draftNotes.notes.push(resp.data);
          this.parseDraftNotesToPreview();
          this.draftNotes.totalCount++;
          this.draftNotes.draftNotif = true;
          setTimeout(() => {
            this.draftNotes.draftNotif = false;
          }, 3000);
        });
      }
      // draft submitted for processing
      else if (e.oldState == 0 && e.newState == 5) {
        let timeout = 0;
        if (this.draftNotif) {
          timeout = 3000;
        }
        window.setTimeout(() => {
          this.draftNotes.queryManager.invalidateCache();
          this.recentNotes.queryManager.invalidateCache();

          let index = this.draftNotes.notes.findIndex(x => x.id == e.noteId);
          if (index !== -1) {
            let noteArr = this.draftNotes.notes.splice(index, 1);
            this.draftNotes.totalCount--;
            noteArr[0].changeNotif = true;
            noteArr[0].state = 5;
            noteArr.published_at = e.eventTs;
            // note not in the list of recent notes. draft => processing
            if (this.recentNotes.notes.findIndex(x => x.id == e.noteId) === -1) {
              this.recentNotes.notes.splice(0, 0, ...noteArr);
              this.recentNotes.totalCount++;
            }

            if (this.draftNotes.notes.length > 0) {
              this.draftNotes.draftNotif = true;
              setTimeout(() => {
                this.draftNotes.draftNotif = false;
              }, 3000);
            }
            this.draftNotes.list = this.parseNotesToPreview(this.draftNotes.notes);
            this.recentNotes.list = this.parseNotesToPreview(this.recentNotes.notes);
            if (this.bubbleSelected === 0) this.notesList = this.recentNotes.list;
          }
          this.draftNotes.draftNotif = true;
          setTimeout(() => {
            this.draftNotes.draftNotif = false;
          }, 3000);
        }, timeout);

      }
      //  processing finished
      else if ((e.oldState == 5 && e.newState == 1) /* note done processing */ ||
               (e.oldState == 0 && e.newState == 1) /* assistant generated */ ) {
        let note = this.recentNotes.notes.find(x => x.id == e.noteId);
        if (note !== undefined) {
          NotesService.GetNote(e.noteId).then(resp => {
            note.title = resp.data.title;
            note.transcript_preview = resp.data.edited_transcript_string.substr(0, 200);
            note.state = 1;
            note.changeNotif = true;
            note.created_at = resp.data.created_at;
            note.last_edited = resp.data.last_edited;
            note.published_at = resp.data.published_at;
          });
          // we're pretty out of date if the note is missing from the notes list
        } else {
          this.recentNotes.queryManager.invalidateCache();
          this.draftNotes.queryManager.invalidateCache();
          this.getDraftNotes();
          this.getRecentNotes().then(() => {
            if (this.bubbleSelected === 0) this.notesList = this.recentNotes.list;
          });
        }
      }
      else if (e.newState == 3 || e.newState == 4) {
        for (let i = 0; i < this.notesList.length; i++) {
          let note = this.notesList[i].notes.find(x => x.id == e.noteId);
          if (note && note.state != e.newState) {
            note.state = e.newState;
            note.changeNotif = true;
            return;
          }
        }
      }
    },
    noteIssueEventHandler (e) {
    },
    internalEventHandler (e) {
      if (e.type === "Notes:TitleUpdate") {
        let listRef;
        if (this.bubbleSelected === 0) listRef = this.recentNotes;
        else listRef = this.archivedNotes;

        let note = listRef.notes.find(x => x.id == e.noteId);
        if (note) {
          note.title = e.newTitle;
        }
      } else if (e.type === "Note:ArchiveChange") {
        let listRef, hiddenRef;
        // if archived move from recent to archived. visversa
        if (e.archived) {
          listRef = this.recentNotes;
          hiddenRef = this.archivedNotes;
          this.archivedNotes.queryManager.invalidateCache();
        }
        else {
          listRef = this.archivedNotes;
          hiddenRef = this.recentNotes;
          this.recentNotes.queryManager.invalidateCache();
        }
        let index = listRef.notes.findIndex(x => x.id == e.noteId);
        if (index !== -1) {
          let note = listRef.notes.splice(index, 1)[0];
          note.archived = e.archived;
          listRef.list = this.parseNotesToPreview(listRef.notes);
          if (this.bubbleSelected === 0 && e.archived) this.notesList = listRef.list;
          else if (this.bubbleSelected === 1 && !e.archived) this.notesList = listRef.list;
          else {
            hiddenRef.notes.push(note);
            hiddenRef.list = this.parseNotesToPreview(hiddenRef.notes);
            this.notesList = hiddenRef.list;
          }
        } else {
          // no reference to the note in available lists, get it from server
          this.getFilteredNotes();
        }
      }
    },
    removeMdCharacters (str) {
      // find all unescaped * and remove them
      str = str.replaceAll(/\n{2}/g, '\n');
      // .replaceAll(/(?<!\\)\*+/g, '')
      for (let i = 1; i < str.length-1; i++) {
        if (str.charCodeAt(i) == 42 /* '*' */ && str.charCodeAt(i - 1) != 92/* \ */) {
          str = str.substring(0, i) + str.substring(i+1);
          i--;
        }
      }
      if (str.charCodeAt(0) == 42) str = str.substring(1);

      return str.replace(/\\([*_`{}\[\]()#+\-.!])/g, '$1').trim();
    },
    trackNoteNav (note) {
      let subId = this.$store.getters.getSubscriptionId;
      MixpanelService.Track("AdminPortal:NoteNavigation", {
        subscription_id: subId,
        note_id: note.id,
        source: "dashboard",
      });
    },
    parseCachedFilters () {

    },
    clearHeaderFilters () {
      this.userConfig.cachedFilters = {};
      this.dateFilter = [];
      this.authorsMultiselect = this.authors
        .reduce((prop, curr) => {
          prop[curr.name] = false;
          return prop;
        }, {}),
        this.statusMultiselect = {
          // "Draft": false,
          "Awaiting Review": false,
          "In Review": false,
          "Completed": false,
          // "In PMS": false,
          // "Processing": false,
          // "Needs Attention": false,
        };
      this.saveUserConfig();
      this.getFilteredNotes();
    },
    queryDate (range) {
      this.dateFilter = range;
      this.userConfig.cachedFilters.dateFilter = range;
      this.saveUserConfig();
      this.getFilteredNotes();

    },
    queryUser (filter) {
      this.userConfig.cachedFilters.userFilter = filter;
      this.saveUserConfig();
      this.getFilteredNotes();

    },
    queryStatus (filter) {
      this.userConfig.cachedFilters.statusFilter = filter;
      this.saveUserConfig();
      this.getFilteredNotes();
    },
    saveUserConfig () {
      localStorage.setItem(`NotesBlade:${this.currUserId}-UserConfig`, JSON.stringify(this.userConfig));
    },
    clearCustomTableFilters () {
      this.userConfig.cachedFilters = {};
      this.saveUserConfig();
      // localStorage.removeItem("Notes:CustomTableFilters");
      this.setCustomTableFilter();
    },
    // this height transition will only work in specific cases. It doesn't handle nesting elements
    // before initial render
    setEnterLeaveHeight (el) {
      el.style.maxHeight = "0px";
    },
    // called one frame after the element is inserted.
    setTransitionHeight (el, done) {
      this.$nextTick(() => {

        let height = 0;
        for (var i = 0; i < el.childElementCount; i++) {
          height = Math.max(el.children[i].clientHeight, height);
        }
        height += el.getBoundingClientRect().height; // adds padding
        el.style.maxHeight = `${height}px`;
        setTimeout(done, 150);
      });
    },
  }
};
</script>
<style scoped>
.notes-blade {
  max-height: calc(100vh - var(--header-h) - 56px);
  @apply flex-grow;
}

@media (min-width: 1024px) {
  .notes-blade {
    max-height: calc(100vh - var(--header-h));
  }
}

.count-icon {
  height: 2rem;
  width: 2rem;
  min-height: 2rem;
  min-width: 2rem;
}

.note-group {
  @apply w-full;
  @apply border;
  @apply border-main-alt;
  @apply bg-white;
  @apply rounded;
}


.note-button:first-child {
  @apply rounded-t-lg;
}

.note-button:last-child {
  @apply rounded-b-lg;
}

.note-button {
  position: relative;
  @apply text-black;
  @apply font-bold;
}

.note-button:hover {
  @apply text-black;
  @apply bg-neutral-100;
  text-decoration: none;
}

.note-button:not(:last-child) {
  @apply border-b;
  @apply border-neutral-lighter
}

.note-button.router-link-active {
  @apply bg-pastel-blue-lighter;
  @apply bg-opacity-50;
  @apply transition-colors;
}

.note-button {
  max-height: 45px;
}

.note-button.list-leave-to {
  max-height: 0px;
  padding: 0px;
  overflow-y: hidden;
}

.filter-wrapper {
  display: flex;
  @apply justify-end;
  @apply flex-shrink;
}

/* some controls over the expandable search bar */
.search-wrapper {
  display: grid;
  grid-template-columns: 0fr;
  transition: grid-template-columns 300ms ease-in-out;
}

.filter-wrapper.expand-search .search-wrapper {
  grid-template-columns: 1fr;
}

.blade-search {
  display: flex;
  @apply justify-end;
}

.blade-search button {
  height: 100%;
  @apply rounded-xl;
  @apply hover:bg-gray-100;
  transition-property: background-color;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}

.blade-search input {
  @apply pl-0;
  min-width: 0px;
  flex-grow: 1;
  transition-property: padding;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}

.filter-wrapper.expand-search .blade-search input {
  @apply pl-2;
  @apply w-auto;
}

.note-button.round-more:last-child {
  @apply rounded-b-xl;
}


.header-transition-duration {
  transition-duration: 300ms;
}
</style>
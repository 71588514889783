import axios from 'axios';
import { store } from '../store';

const AUTH_SERVICE = __API_URL_V4 + 'auth';

function Login (email, password, clientId) {
  const payload = {
    email: email,
    password: password,
    client_id: clientId,
  };
  return axios({
    method: 'POST',
    url: AUTH_SERVICE,
    data: payload
  })
    .catch(err => {
      store.commit('reset');
      return Promise.reject(err);
    });
}

function Logout () {
  if (!store.getters.authStatus.isLoggedIn) {
    store.commit('reset');
    return Promise.reject("User Not Logged in");
  }
  const payload = {
    user_id: store.getters.getUserId.toString(),
    client_id: store.getters.getClientId.toString(),
  };
  return axios({
    method: 'POST',
    url: `${AUTH_SERVICE}/logout`,
    data: payload
  })
    .catch(err => {
      console.log("UserAuth Logout:", err);
      return Promise.reject(err);
    });
}

function ValidateSUT (token, userId, clientId) {
  const payload = {
    user_id: userId,
    client_id: clientId,
    token: token,
  };

  return axios({
    method: 'POST',
    url: `${AUTH_SERVICE}/single_use_token`,
    data: payload
  })
    .catch(err => {
      store.commit('reset');
      return Promise.reject(err);
    });
}

function GetSUT (userId) {
  return axios({
    method: 'POST',
    url: `${AUTH_SERVICE}/single_use_token/create`,
    data: { user_id: userId }
  })
    .catch(err => {
      return Promise.reject(err);
    });
}

function Refresh (userId, clientId) {
  const payload = {
    user_id: userId,
    client_id: clientId,
  };
  return axios({
    method: 'POST',
    url: `${AUTH_SERVICE}/refresh`,
    data: payload
  })
    .catch(err => {
      store.commit('reset');
      return Promise.reject(err);
    });
}

function Spoof (userId, subscriptionId, clientId) {
  const payload = {
    user_id: userId,
    subscription_id: subscriptionId,
    client_id: clientId,
  };
  return axios({
    method: 'POST',
    url: `${AUTH_SERVICE}/spoof`,
    data: payload
  })
    .catch(err => {
      return Promise.reject(err);
    });

}

export default {
  Login,
  Logout,
  ValidateSUT,
  GetSUT,
  Refresh,
  Spoof
};

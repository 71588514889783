<template>
  <div class="feedback-wrapper flex items-center flex-col">
    <img class="fixed w-full" src="" />
    <span class=" z-10">
      <h2 class="text-center my-12 text-white">Thank you for your feedback</h2>
    </span>
    <div class="feedback-container blue-shadow-lg py-16 px-3 sm:px-6 lg:px-12 lg:m-2 ">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeedbackTemplate",
  mounted () {
    document.body.classList.add('feedback');
  },
  beforeUnmount () {
    document.body.classList.remove('feedback');
  }
};
</script>

<style scoped>
.feedback-container {
  min-height: 27rem;
  width: 40rem;
  @apply bg-white;
  @apply border-main-dark;
  @apply border-2;
  @apply box-border;
  @apply block;
  @apply rounded-lg;
}

@media (max-width: 640px) {
  .feedback-container {
    width: calc(100vw - 2rem);
  }
}
</style>
<style>
body.feedback {
  @apply bg-white;
  background: url("@/assets/images/clouds-no-background-no-shadow.png"),
    linear-gradient(0.25turn, #0ab8da, #0087b3, #00475d, #003a4a);
  background-position: bottom ;
  background-size: contain;
  background-repeat: no-repeat;
}

</style>
// source: notification.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.talkatoo.notification.v1.ConfigMessage', null, global);
goog.exportSymbol('proto.talkatoo.notification.v1.NoteIssueEvent', null, global);
goog.exportSymbol('proto.talkatoo.notification.v1.NoteIssueEvent.issue_event_type', null, global);
goog.exportSymbol('proto.talkatoo.notification.v1.NoteStateEvent', null, global);
goog.exportSymbol('proto.talkatoo.notification.v1.NoteStateEvent.note_state', null, global);
goog.exportSymbol('proto.talkatoo.notification.v1.ReAuthEvent', null, global);
goog.exportSymbol('proto.talkatoo.notification.v1.ReAuthEvent.AuthInfoCase', null, global);
goog.exportSymbol('proto.talkatoo.notification.v1.TalkatextUpdateEvent', null, global);
goog.exportSymbol('proto.talkatoo.notification.v1.VocabularyUpdateEvent', null, global);
goog.exportSymbol('proto.talkatoo.notification.v1.WebsocketMessage', null, global);
goog.exportSymbol('proto.talkatoo.notification.v1.WebsocketMessage.EventCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.talkatoo.notification.v1.ConfigMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.talkatoo.notification.v1.ConfigMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.talkatoo.notification.v1.ConfigMessage.displayName = 'proto.talkatoo.notification.v1.ConfigMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.talkatoo.notification.v1.WebsocketMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.talkatoo.notification.v1.WebsocketMessage.oneofGroups_);
};
goog.inherits(proto.talkatoo.notification.v1.WebsocketMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.talkatoo.notification.v1.WebsocketMessage.displayName = 'proto.talkatoo.notification.v1.WebsocketMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.talkatoo.notification.v1.NoteStateEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.talkatoo.notification.v1.NoteStateEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.talkatoo.notification.v1.NoteStateEvent.displayName = 'proto.talkatoo.notification.v1.NoteStateEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.talkatoo.notification.v1.NoteIssueEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.talkatoo.notification.v1.NoteIssueEvent.repeatedFields_, null);
};
goog.inherits(proto.talkatoo.notification.v1.NoteIssueEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.talkatoo.notification.v1.NoteIssueEvent.displayName = 'proto.talkatoo.notification.v1.NoteIssueEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.talkatoo.notification.v1.VocabularyUpdateEvent.repeatedFields_, null);
};
goog.inherits(proto.talkatoo.notification.v1.VocabularyUpdateEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.talkatoo.notification.v1.VocabularyUpdateEvent.displayName = 'proto.talkatoo.notification.v1.VocabularyUpdateEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.talkatoo.notification.v1.TalkatextUpdateEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.talkatoo.notification.v1.TalkatextUpdateEvent.displayName = 'proto.talkatoo.notification.v1.TalkatextUpdateEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.talkatoo.notification.v1.ReAuthEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.talkatoo.notification.v1.ReAuthEvent.oneofGroups_);
};
goog.inherits(proto.talkatoo.notification.v1.ReAuthEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.talkatoo.notification.v1.ReAuthEvent.displayName = 'proto.talkatoo.notification.v1.ReAuthEvent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.talkatoo.notification.v1.ConfigMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.talkatoo.notification.v1.ConfigMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.talkatoo.notification.v1.ConfigMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.talkatoo.notification.v1.ConfigMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    platform: jspb.Message.getFieldWithDefault(msg, 2, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.talkatoo.notification.v1.ConfigMessage}
 */
proto.talkatoo.notification.v1.ConfigMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.talkatoo.notification.v1.ConfigMessage;
  return proto.talkatoo.notification.v1.ConfigMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.talkatoo.notification.v1.ConfigMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.talkatoo.notification.v1.ConfigMessage}
 */
proto.talkatoo.notification.v1.ConfigMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatform(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.talkatoo.notification.v1.ConfigMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.talkatoo.notification.v1.ConfigMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.talkatoo.notification.v1.ConfigMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.talkatoo.notification.v1.ConfigMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPlatform();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.talkatoo.notification.v1.ConfigMessage.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.ConfigMessage} returns this
 */
proto.talkatoo.notification.v1.ConfigMessage.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string platform = 2;
 * @return {string}
 */
proto.talkatoo.notification.v1.ConfigMessage.prototype.getPlatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.talkatoo.notification.v1.ConfigMessage} returns this
 */
proto.talkatoo.notification.v1.ConfigMessage.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string client_id = 3;
 * @return {string}
 */
proto.talkatoo.notification.v1.ConfigMessage.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.talkatoo.notification.v1.ConfigMessage} returns this
 */
proto.talkatoo.notification.v1.ConfigMessage.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.talkatoo.notification.v1.WebsocketMessage.oneofGroups_ = [[4,5,6,7,8]];

/**
 * @enum {number}
 */
proto.talkatoo.notification.v1.WebsocketMessage.EventCase = {
  EVENT_NOT_SET: 0,
  NOTE_STATE_EVENT: 4,
  NOTE_ISSUE_EVENT: 5,
  VOCABULARY_UPDATE_EVENT: 6,
  TALKATEXT_UPDATE_EVENT: 7,
  REAUTH_EVENT: 8
};

/**
 * @return {proto.talkatoo.notification.v1.WebsocketMessage.EventCase}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.getEventCase = function() {
  return /** @type {proto.talkatoo.notification.v1.WebsocketMessage.EventCase} */(jspb.Message.computeOneofCase(this, proto.talkatoo.notification.v1.WebsocketMessage.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.talkatoo.notification.v1.WebsocketMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.talkatoo.notification.v1.WebsocketMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.talkatoo.notification.v1.WebsocketMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    platform: jspb.Message.getFieldWithDefault(msg, 3, ""),
    noteStateEvent: (f = msg.getNoteStateEvent()) && proto.talkatoo.notification.v1.NoteStateEvent.toObject(includeInstance, f),
    noteIssueEvent: (f = msg.getNoteIssueEvent()) && proto.talkatoo.notification.v1.NoteIssueEvent.toObject(includeInstance, f),
    vocabularyUpdateEvent: (f = msg.getVocabularyUpdateEvent()) && proto.talkatoo.notification.v1.VocabularyUpdateEvent.toObject(includeInstance, f),
    talkatextUpdateEvent: (f = msg.getTalkatextUpdateEvent()) && proto.talkatoo.notification.v1.TalkatextUpdateEvent.toObject(includeInstance, f),
    reauthEvent: (f = msg.getReauthEvent()) && proto.talkatoo.notification.v1.ReAuthEvent.toObject(includeInstance, f),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage}
 */
proto.talkatoo.notification.v1.WebsocketMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.talkatoo.notification.v1.WebsocketMessage;
  return proto.talkatoo.notification.v1.WebsocketMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.talkatoo.notification.v1.WebsocketMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage}
 */
proto.talkatoo.notification.v1.WebsocketMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatform(value);
      break;
    case 4:
      var value = new proto.talkatoo.notification.v1.NoteStateEvent;
      reader.readMessage(value,proto.talkatoo.notification.v1.NoteStateEvent.deserializeBinaryFromReader);
      msg.setNoteStateEvent(value);
      break;
    case 5:
      var value = new proto.talkatoo.notification.v1.NoteIssueEvent;
      reader.readMessage(value,proto.talkatoo.notification.v1.NoteIssueEvent.deserializeBinaryFromReader);
      msg.setNoteIssueEvent(value);
      break;
    case 6:
      var value = new proto.talkatoo.notification.v1.VocabularyUpdateEvent;
      reader.readMessage(value,proto.talkatoo.notification.v1.VocabularyUpdateEvent.deserializeBinaryFromReader);
      msg.setVocabularyUpdateEvent(value);
      break;
    case 7:
      var value = new proto.talkatoo.notification.v1.TalkatextUpdateEvent;
      reader.readMessage(value,proto.talkatoo.notification.v1.TalkatextUpdateEvent.deserializeBinaryFromReader);
      msg.setTalkatextUpdateEvent(value);
      break;
    case 8:
      var value = new proto.talkatoo.notification.v1.ReAuthEvent;
      reader.readMessage(value,proto.talkatoo.notification.v1.ReAuthEvent.deserializeBinaryFromReader);
      msg.setReauthEvent(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.talkatoo.notification.v1.WebsocketMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.talkatoo.notification.v1.WebsocketMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.talkatoo.notification.v1.WebsocketMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPlatform();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNoteStateEvent();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.talkatoo.notification.v1.NoteStateEvent.serializeBinaryToWriter
    );
  }
  f = message.getNoteIssueEvent();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.talkatoo.notification.v1.NoteIssueEvent.serializeBinaryToWriter
    );
  }
  f = message.getVocabularyUpdateEvent();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.talkatoo.notification.v1.VocabularyUpdateEvent.serializeBinaryToWriter
    );
  }
  f = message.getTalkatextUpdateEvent();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.talkatoo.notification.v1.TalkatextUpdateEvent.serializeBinaryToWriter
    );
  }
  f = message.getReauthEvent();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.talkatoo.notification.v1.ReAuthEvent.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional string event_type = 1;
 * @return {string}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.getEventType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage} returns this
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.setEventType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 user_id = 2;
 * @return {number}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage} returns this
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string platform = 3;
 * @return {string}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.getPlatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage} returns this
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional NoteStateEvent note_state_event = 4;
 * @return {?proto.talkatoo.notification.v1.NoteStateEvent}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.getNoteStateEvent = function() {
  return /** @type{?proto.talkatoo.notification.v1.NoteStateEvent} */ (
    jspb.Message.getWrapperField(this, proto.talkatoo.notification.v1.NoteStateEvent, 4));
};


/**
 * @param {?proto.talkatoo.notification.v1.NoteStateEvent|undefined} value
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage} returns this
*/
proto.talkatoo.notification.v1.WebsocketMessage.prototype.setNoteStateEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.talkatoo.notification.v1.WebsocketMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage} returns this
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.clearNoteStateEvent = function() {
  return this.setNoteStateEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.hasNoteStateEvent = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NoteIssueEvent note_issue_event = 5;
 * @return {?proto.talkatoo.notification.v1.NoteIssueEvent}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.getNoteIssueEvent = function() {
  return /** @type{?proto.talkatoo.notification.v1.NoteIssueEvent} */ (
    jspb.Message.getWrapperField(this, proto.talkatoo.notification.v1.NoteIssueEvent, 5));
};


/**
 * @param {?proto.talkatoo.notification.v1.NoteIssueEvent|undefined} value
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage} returns this
*/
proto.talkatoo.notification.v1.WebsocketMessage.prototype.setNoteIssueEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.talkatoo.notification.v1.WebsocketMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage} returns this
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.clearNoteIssueEvent = function() {
  return this.setNoteIssueEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.hasNoteIssueEvent = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional VocabularyUpdateEvent vocabulary_update_event = 6;
 * @return {?proto.talkatoo.notification.v1.VocabularyUpdateEvent}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.getVocabularyUpdateEvent = function() {
  return /** @type{?proto.talkatoo.notification.v1.VocabularyUpdateEvent} */ (
    jspb.Message.getWrapperField(this, proto.talkatoo.notification.v1.VocabularyUpdateEvent, 6));
};


/**
 * @param {?proto.talkatoo.notification.v1.VocabularyUpdateEvent|undefined} value
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage} returns this
*/
proto.talkatoo.notification.v1.WebsocketMessage.prototype.setVocabularyUpdateEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.talkatoo.notification.v1.WebsocketMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage} returns this
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.clearVocabularyUpdateEvent = function() {
  return this.setVocabularyUpdateEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.hasVocabularyUpdateEvent = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TalkatextUpdateEvent talkatext_update_event = 7;
 * @return {?proto.talkatoo.notification.v1.TalkatextUpdateEvent}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.getTalkatextUpdateEvent = function() {
  return /** @type{?proto.talkatoo.notification.v1.TalkatextUpdateEvent} */ (
    jspb.Message.getWrapperField(this, proto.talkatoo.notification.v1.TalkatextUpdateEvent, 7));
};


/**
 * @param {?proto.talkatoo.notification.v1.TalkatextUpdateEvent|undefined} value
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage} returns this
*/
proto.talkatoo.notification.v1.WebsocketMessage.prototype.setTalkatextUpdateEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.talkatoo.notification.v1.WebsocketMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage} returns this
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.clearTalkatextUpdateEvent = function() {
  return this.setTalkatextUpdateEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.hasTalkatextUpdateEvent = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ReAuthEvent reauth_event = 8;
 * @return {?proto.talkatoo.notification.v1.ReAuthEvent}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.getReauthEvent = function() {
  return /** @type{?proto.talkatoo.notification.v1.ReAuthEvent} */ (
    jspb.Message.getWrapperField(this, proto.talkatoo.notification.v1.ReAuthEvent, 8));
};


/**
 * @param {?proto.talkatoo.notification.v1.ReAuthEvent|undefined} value
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage} returns this
*/
proto.talkatoo.notification.v1.WebsocketMessage.prototype.setReauthEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.talkatoo.notification.v1.WebsocketMessage.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage} returns this
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.clearReauthEvent = function() {
  return this.setReauthEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.hasReauthEvent = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 subscription_id = 9;
 * @return {number}
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.WebsocketMessage} returns this
 */
proto.talkatoo.notification.v1.WebsocketMessage.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.talkatoo.notification.v1.NoteStateEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.talkatoo.notification.v1.NoteStateEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.talkatoo.notification.v1.NoteStateEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    noteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    oldState: jspb.Message.getFieldWithDefault(msg, 5, 0),
    newState: jspb.Message.getFieldWithDefault(msg, 6, 0),
    verify: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    eventTs: jspb.Message.getFieldWithDefault(msg, 8, 0),
    authorId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    archived: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.talkatoo.notification.v1.NoteStateEvent}
 */
proto.talkatoo.notification.v1.NoteStateEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.talkatoo.notification.v1.NoteStateEvent;
  return proto.talkatoo.notification.v1.NoteStateEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.talkatoo.notification.v1.NoteStateEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.talkatoo.notification.v1.NoteStateEvent}
 */
proto.talkatoo.notification.v1.NoteStateEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNoteId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventUserId(value);
      break;
    case 5:
      var value = /** @type {!proto.talkatoo.notification.v1.NoteStateEvent.note_state} */ (reader.readEnum());
      msg.setOldState(value);
      break;
    case 6:
      var value = /** @type {!proto.talkatoo.notification.v1.NoteStateEvent.note_state} */ (reader.readEnum());
      msg.setNewState(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVerify(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventTs(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAuthorId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.talkatoo.notification.v1.NoteStateEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.talkatoo.notification.v1.NoteStateEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.talkatoo.notification.v1.NoteStateEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNoteId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEventUserId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOldState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getNewState();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getVerify();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getEventTs();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAuthorId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getArchived();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.talkatoo.notification.v1.NoteStateEvent.note_state = {
  RECORDING: 0,
  PENDING_REVIEW: 1,
  IN_REVIEW: 2,
  COMPLETE: 3,
  IN_PMS: 4,
  PROCESSING: 5
};

/**
 * optional int64 note_id = 1;
 * @return {number}
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.getNoteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.NoteStateEvent} returns this
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.setNoteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 event_user_id = 2;
 * @return {number}
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.getEventUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.NoteStateEvent} returns this
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.setEventUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional note_state old_state = 5;
 * @return {!proto.talkatoo.notification.v1.NoteStateEvent.note_state}
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.getOldState = function() {
  return /** @type {!proto.talkatoo.notification.v1.NoteStateEvent.note_state} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.talkatoo.notification.v1.NoteStateEvent.note_state} value
 * @return {!proto.talkatoo.notification.v1.NoteStateEvent} returns this
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.setOldState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional note_state new_state = 6;
 * @return {!proto.talkatoo.notification.v1.NoteStateEvent.note_state}
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.getNewState = function() {
  return /** @type {!proto.talkatoo.notification.v1.NoteStateEvent.note_state} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.talkatoo.notification.v1.NoteStateEvent.note_state} value
 * @return {!proto.talkatoo.notification.v1.NoteStateEvent} returns this
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.setNewState = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool verify = 7;
 * @return {boolean}
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.getVerify = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.talkatoo.notification.v1.NoteStateEvent} returns this
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.setVerify = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int64 event_ts = 8;
 * @return {number}
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.getEventTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.NoteStateEvent} returns this
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.setEventTs = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 author_id = 9;
 * @return {number}
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.getAuthorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.NoteStateEvent} returns this
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.setAuthorId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool archived = 10;
 * @return {boolean}
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.getArchived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.talkatoo.notification.v1.NoteStateEvent} returns this
 */
proto.talkatoo.notification.v1.NoteStateEvent.prototype.setArchived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.talkatoo.notification.v1.NoteIssueEvent.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.talkatoo.notification.v1.NoteIssueEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.talkatoo.notification.v1.NoteIssueEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.talkatoo.notification.v1.NoteIssueEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    noteId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    noteAuthorId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    authorId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    issueId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    eventType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    eventTs: jspb.Message.getFieldWithDefault(msg, 10, 0),
    issueText: jspb.Message.getFieldWithDefault(msg, 15, ""),
    responseText: jspb.Message.getFieldWithDefault(msg, 16, ""),
    respondentsList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.talkatoo.notification.v1.NoteIssueEvent}
 */
proto.talkatoo.notification.v1.NoteIssueEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.talkatoo.notification.v1.NoteIssueEvent;
  return proto.talkatoo.notification.v1.NoteIssueEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.talkatoo.notification.v1.NoteIssueEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.talkatoo.notification.v1.NoteIssueEvent}
 */
proto.talkatoo.notification.v1.NoteIssueEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNoteId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNoteAuthorId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAuthorId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIssueId(value);
      break;
    case 5:
      var value = /** @type {!proto.talkatoo.notification.v1.NoteIssueEvent.issue_event_type} */ (reader.readEnum());
      msg.setEventType(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventTs(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueText(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponseText(value);
      break;
    case 20:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRespondents(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.talkatoo.notification.v1.NoteIssueEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.talkatoo.notification.v1.NoteIssueEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.talkatoo.notification.v1.NoteIssueEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNoteId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNoteAuthorId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAuthorId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getIssueId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getEventType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getEventTs();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getIssueText();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getResponseText();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getRespondentsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      20,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.talkatoo.notification.v1.NoteIssueEvent.issue_event_type = {
  NEW_ISSUE: 0,
  ISSUE_RESPONSE: 1,
  ISSUE_RESOLVED: 2
};

/**
 * optional int64 note_id = 1;
 * @return {number}
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.getNoteId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.NoteIssueEvent} returns this
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.setNoteId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 note_author_id = 2;
 * @return {number}
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.getNoteAuthorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.NoteIssueEvent} returns this
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.setNoteAuthorId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 author_id = 3;
 * @return {number}
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.getAuthorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.NoteIssueEvent} returns this
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.setAuthorId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 issue_id = 4;
 * @return {number}
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.getIssueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.NoteIssueEvent} returns this
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.setIssueId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional issue_event_type event_type = 5;
 * @return {!proto.talkatoo.notification.v1.NoteIssueEvent.issue_event_type}
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.getEventType = function() {
  return /** @type {!proto.talkatoo.notification.v1.NoteIssueEvent.issue_event_type} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.talkatoo.notification.v1.NoteIssueEvent.issue_event_type} value
 * @return {!proto.talkatoo.notification.v1.NoteIssueEvent} returns this
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.setEventType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 event_ts = 10;
 * @return {number}
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.getEventTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.NoteIssueEvent} returns this
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.setEventTs = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string issue_text = 15;
 * @return {string}
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.getIssueText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.talkatoo.notification.v1.NoteIssueEvent} returns this
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.setIssueText = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string response_text = 16;
 * @return {string}
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.getResponseText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.talkatoo.notification.v1.NoteIssueEvent} returns this
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.setResponseText = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated int64 respondents = 20;
 * @return {!Array<number>}
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.getRespondentsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.talkatoo.notification.v1.NoteIssueEvent} returns this
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.setRespondentsList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.talkatoo.notification.v1.NoteIssueEvent} returns this
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.addRespondents = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.talkatoo.notification.v1.NoteIssueEvent} returns this
 */
proto.talkatoo.notification.v1.NoteIssueEvent.prototype.clearRespondentsList = function() {
  return this.setRespondentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.talkatoo.notification.v1.VocabularyUpdateEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.talkatoo.notification.v1.VocabularyUpdateEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vocabularyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    removedWordsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    addedWordsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.talkatoo.notification.v1.VocabularyUpdateEvent}
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.talkatoo.notification.v1.VocabularyUpdateEvent;
  return proto.talkatoo.notification.v1.VocabularyUpdateEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.talkatoo.notification.v1.VocabularyUpdateEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.talkatoo.notification.v1.VocabularyUpdateEvent}
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVocabularyId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addRemovedWords(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addAddedWords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.talkatoo.notification.v1.VocabularyUpdateEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.talkatoo.notification.v1.VocabularyUpdateEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getVocabularyId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRemovedWordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getAddedWordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.VocabularyUpdateEvent} returns this
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 vocabulary_id = 2;
 * @return {number}
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.prototype.getVocabularyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.VocabularyUpdateEvent} returns this
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.prototype.setVocabularyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string removed_words = 5;
 * @return {!Array<string>}
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.prototype.getRemovedWordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.talkatoo.notification.v1.VocabularyUpdateEvent} returns this
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.prototype.setRemovedWordsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.talkatoo.notification.v1.VocabularyUpdateEvent} returns this
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.prototype.addRemovedWords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.talkatoo.notification.v1.VocabularyUpdateEvent} returns this
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.prototype.clearRemovedWordsList = function() {
  return this.setRemovedWordsList([]);
};


/**
 * repeated string added_words = 6;
 * @return {!Array<string>}
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.prototype.getAddedWordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.talkatoo.notification.v1.VocabularyUpdateEvent} returns this
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.prototype.setAddedWordsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.talkatoo.notification.v1.VocabularyUpdateEvent} returns this
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.prototype.addAddedWords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.talkatoo.notification.v1.VocabularyUpdateEvent} returns this
 */
proto.talkatoo.notification.v1.VocabularyUpdateEvent.prototype.clearAddedWordsList = function() {
  return this.setAddedWordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.talkatoo.notification.v1.TalkatextUpdateEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.talkatoo.notification.v1.TalkatextUpdateEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    talkatextId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updatedKeyword: jspb.Message.getFieldWithDefault(msg, 3, ""),
    updatedTalkatext: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isPinned: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.talkatoo.notification.v1.TalkatextUpdateEvent}
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.talkatoo.notification.v1.TalkatextUpdateEvent;
  return proto.talkatoo.notification.v1.TalkatextUpdateEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.talkatoo.notification.v1.TalkatextUpdateEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.talkatoo.notification.v1.TalkatextUpdateEvent}
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTalkatextId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedKeyword(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedTalkatext(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPinned(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.talkatoo.notification.v1.TalkatextUpdateEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.talkatoo.notification.v1.TalkatextUpdateEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTalkatextId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedKeyword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUpdatedTalkatext();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsPinned();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.TalkatextUpdateEvent} returns this
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 talkatext_id = 2;
 * @return {number}
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.prototype.getTalkatextId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.TalkatextUpdateEvent} returns this
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.prototype.setTalkatextId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string updated_keyword = 3;
 * @return {string}
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.prototype.getUpdatedKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.talkatoo.notification.v1.TalkatextUpdateEvent} returns this
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.prototype.setUpdatedKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string updated_talkatext = 4;
 * @return {string}
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.prototype.getUpdatedTalkatext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.talkatoo.notification.v1.TalkatextUpdateEvent} returns this
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.prototype.setUpdatedTalkatext = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_pinned = 5;
 * @return {boolean}
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.prototype.getIsPinned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.talkatoo.notification.v1.TalkatextUpdateEvent} returns this
 */
proto.talkatoo.notification.v1.TalkatextUpdateEvent.prototype.setIsPinned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.talkatoo.notification.v1.ReAuthEvent.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.talkatoo.notification.v1.ReAuthEvent.AuthInfoCase = {
  AUTH_INFO_NOT_SET: 0,
  USER_ID: 1,
  SUBSCRIPTION_ID: 2
};

/**
 * @return {proto.talkatoo.notification.v1.ReAuthEvent.AuthInfoCase}
 */
proto.talkatoo.notification.v1.ReAuthEvent.prototype.getAuthInfoCase = function() {
  return /** @type {proto.talkatoo.notification.v1.ReAuthEvent.AuthInfoCase} */(jspb.Message.computeOneofCase(this, proto.talkatoo.notification.v1.ReAuthEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.talkatoo.notification.v1.ReAuthEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.talkatoo.notification.v1.ReAuthEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.talkatoo.notification.v1.ReAuthEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.talkatoo.notification.v1.ReAuthEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.talkatoo.notification.v1.ReAuthEvent}
 */
proto.talkatoo.notification.v1.ReAuthEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.talkatoo.notification.v1.ReAuthEvent;
  return proto.talkatoo.notification.v1.ReAuthEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.talkatoo.notification.v1.ReAuthEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.talkatoo.notification.v1.ReAuthEvent}
 */
proto.talkatoo.notification.v1.ReAuthEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubscriptionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.talkatoo.notification.v1.ReAuthEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.talkatoo.notification.v1.ReAuthEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.talkatoo.notification.v1.ReAuthEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.talkatoo.notification.v1.ReAuthEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.talkatoo.notification.v1.ReAuthEvent.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.ReAuthEvent} returns this
 */
proto.talkatoo.notification.v1.ReAuthEvent.prototype.setUserId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.talkatoo.notification.v1.ReAuthEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.talkatoo.notification.v1.ReAuthEvent} returns this
 */
proto.talkatoo.notification.v1.ReAuthEvent.prototype.clearUserId = function() {
  return jspb.Message.setOneofField(this, 1, proto.talkatoo.notification.v1.ReAuthEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.talkatoo.notification.v1.ReAuthEvent.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 subscription_id = 2;
 * @return {number}
 */
proto.talkatoo.notification.v1.ReAuthEvent.prototype.getSubscriptionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.talkatoo.notification.v1.ReAuthEvent} returns this
 */
proto.talkatoo.notification.v1.ReAuthEvent.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.talkatoo.notification.v1.ReAuthEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.talkatoo.notification.v1.ReAuthEvent} returns this
 */
proto.talkatoo.notification.v1.ReAuthEvent.prototype.clearSubscriptionId = function() {
  return jspb.Message.setOneofField(this, 2, proto.talkatoo.notification.v1.ReAuthEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.talkatoo.notification.v1.ReAuthEvent.prototype.hasSubscriptionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.talkatoo.notification.v1);

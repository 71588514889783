<template>
  <LoginTemplate :title="'Enterprise Sign-in'" hideFooter>
    <div class="flex flex-col h-full flex-grow">
      <div>
        <form id="signin-request-form" class="hidden" method="post" :action="apiUrl + `auth/sso`">
          <input id="sso-form-client-id" type="hidden" name="client_id" value="" />
          <input id="sso-form-submit" type="submit" value="Login" />
        </form>
      </div>
      <div class="flex flex-col mb-20 text-center text-2xl">
        <span class="text-2xl mt-2 font-bold">
          Sign in with your Federated Talkatoo Account.
        </span>
      </div>
      <div class="block w-72 m-auto">
        <button class="flex container-blue" @click="sendSso">
          <img class="mr-2 my-auto ml-2" src="@/assets/images/Google-g-Logo.svg" height="30" width="30" />
          <div class="flex-grow my-auto py-4 px-2 bg-main-alt font-bold text-white">
            Sign in with Google
          </div>
        </button>
      </div>
      <div class="flex flex-grow justify-center">
        <div class="flex flex-col text-center mt-auto">
          <span> Don't have an enterprise account? </span>
          <div>
            <RouterLink id="regular-signin-nav" to="/login" class="inline-anchor text-gray-500 underline" @click="clearSsoUser">
              Back to sign-in page
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  </LoginTemplate>
</template>

<script setup>
import LoginTemplate from 'Components/layout/LoginTemplate.vue';

var apiUrl =  __API_URL_V4;

function sendSso () {
  const clientId = Date.now().toString();
  localStorage.setItem("clientId", clientId);
  document.getElementById("sso-form-client-id").value = clientId;
  document.getElementById("sso-form-submit").click();
}

function clearSsoUser () {
  localStorage.setItem('isSsoUser', false);
}

</script>

<style scoped></style>

<template>
  <Transition name="fade">
    <div v-if="toDisplay" class="fade-duration relative z-[60]">
      <div class="modal-background" @click="(e) => toggleDisplay(e, 'background')">
        <div class="my-auto flex justify-center">
          <div class="modal" @click="innerDisplay">
            <!-- header of the modal -->
            <div :class="['modal-header justify-between', + noHeader ? '' : 'blue-gradient gradient-border']">
              <div class="flex justify-between w-full">
                <h5 class="p-4 ml-2 text-2xl lg:text-xl lg:leading-5">
                  {{ title }}
                </h5>
                <button id="modal-close" class="close-btn" @click="(e) => toggleDisplay(e, 'button')">
                  <i class="material-icons text-2xl">close</i>
                </button>
              </div>
            </div>
            <!-- body of the modal -->
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "BaseModal",
  emits: ["toggleEvent"],
  props: {
    title: String,
    toDisplay: Boolean,
    noHeader: { type: Boolean, default: false },
  },
  data () {
    return {
      guardToggle: false,
      modalBackground: undefined,

    };
  },
  mounted () {
    this.modalBackground = document.getElementsByClassName('modal-background');
    if (this.modalBackground.length > 0) {
      this.modalBackground = this.modalBackground[0];
    }
  },

  methods: {
    toggleDisplay (event, source) {
      if (!this.guardToggle || source === "button") {
        this.$emit("toggleEvent", this.toDisplay, source);
      }
      if (this.modalBackground && this.modalBackground == event.target) {
        this.$emit("toggleEvent", this.toDisplay, source);
      }
      this.guardToggle = false;
    },
    innerDisplay (e) {
      this.guardToggle = true;
    },
  },
};
</script>

<style scoped>
.modal-background {
  @apply bg-black;
  @apply flex;
  @apply fixed;
  @apply z-40;
  @apply bg-opacity-75;
  @apply justify-center;
  @apply h-full;
  @apply w-full;
  min-height: 100vh;
  top: 0px;
  left: 0px;
  overflow: auto;
}

.modal {
  @apply relative;
  @apply z-50;
  @apply bg-white;
  @apply flex;
  @apply flex-col;
  border: 1px solid;
  border-radius: 4px;
  box-sizing: border-box;
  height: fit-content;
  max-width: 100%;
  max-height: 100%;
}

.close-btn {
  @apply text-black;
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply my-3;
  @apply pr-4;
  cursor: pointer;
}

.modal-header.blue-gradient .close-btn,
.modal-header.blue-gradient h5 {
  @apply text-white;
}

.gradient-border {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.fade-duration {
  transition-duration: 0.1s;
}
</style>


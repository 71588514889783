<!-- this component can be used to wrap views to allow them to fade in transition,
this component additionally adds a delay when the blade is transitioning in width, hiding any
loading reflow between page navigations -->
<template>
  <transition appear name="fade" @after-enter="transitionDone">
    <div v-if="showContent" :class="['default-fade-duration',
                                     fadeDelay ? 'fade-delay' : '']">
      <slot></slot>
    </div>
  </transition>
</template>
<script>
export default {
  name:"FadeInWrapper",
  emits: ['transitionDone'],
  data() {
    return {
      fadeDelay: false,
      showContent: false,
    };
  },
  created() {
    let histRoute = this.$router.options.routes.find(x => x.path === this.$router.options.history.state.back);
    if (histRoute && histRoute?.meta?.blade !== this.$route.meta.blade) {
      this.fadeDelay = true;
    }
    this.showContent = true;
  },
  methods: {
    transitionDone(){
      this.$emit("transitionDone");
    }
  }
};

</script>
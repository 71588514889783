<template>
  <FadeInWrapper>
    <div class="flex py-2 px-3 lg:hidden bg-[#F5F6F8] rounded-t-md">
      <button class="flex pl-2 pr-3 py-2 bg-pastel-blue-light text-main-alt font-roboto text-xs font-extrabold rounded"
              @click="navBack">
        <span class="material-icons my-auto leading-3 w-5">keyboard_arrow_left</span>
        <span class="my-auto">back</span>
      </button>
    </div>
    <div class="flex flex-col bg-white min-h-[60vh] ">

      <div id="settings-body" class="body-container relative flex flex-col 2xl:flex-row pt-7 ">
        <div class="block 2xl:mr-3 flex-shrink pb-5">
          <SettingsNav :settings-page="'Templates'"
                       :selectedTemplate="selectedTemplate"
                       :templates="templates"
                       :loading="loading"
                       @templateSelected="selectTemplate" />
        </div>

        <template v-if="loading"></template>
        <div v-else class="flex flex-col flex-grow basis-full rounded-xl border border-neutral-lighter  sm:py-3 max-w-[60rem] mb-32">
          <TransitionGroup name="fade-in-out">

            <SoapSettings v-if="selectedTemplate.template_type === 'SOAP'" class="default-fade-duration"
                          :template="selectedTemplate"
                          :defaultValues="selectedDefaultValues"
                          :loading="pendingChange"
                          @CommitChange="updateTemplateSettings"
                          @changeMade="recordChange" />
          </TransitionGroup>

        </div>
      </div>
    </div>
  </FadeInWrapper>
  <BaseModal title="You have unsaved changes" :toDisplay="showConfirmModal" @toggleEvent="toggleConfirmModal">
    <div class="px-24 pt-12 pb-16 text-center font-roboto font-normal text-base leading-tight text-neutral-darker opacity-60">
      Are you sure you want to leave without saving your changes?
      <!-- It looks like you're in the middle of making <br />
      changes to your template and haven't saved yet.<br />
      If you don't save, you will lose your changes. -->
    </div>
    <div class="flex justify-end px-5 py-6 border-t">
      <button class="as-anchor mr-4 font-roboto font-extrabold text-sm discard" @click="leaveChanges">
        Discard Changes
      </button>
      <button class="btn-primary rounded-full font-bold py-3 px-5" @click="saveAndLeave">
        Save and Continue
      </button>

    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/ui/BaseModal.vue';
import SettingsNav from './SettingsNav.vue';
import SoapSettings from './SoapSettings.vue';
import FadeInWrapper from 'Components/layout/FadeInWrapper.vue';
const UserService = require('Services/UserService');

export default {
  name: "Settings",
  components: { SettingsNav, SoapSettings, FadeInWrapper, BaseModal },
  beforeRouteLeave (to, from, next) {
    // logged in exception made here. routing doesn't happen when a user is logged out so no hook for saving changes.
    if (!this.changeMade || this.pendingChange || !this.$store.getters.isLoggedIn) {
      next();
      return;
    }
    next(false);
    this.confirmedLeave = to;
    this.toggleConfirmModal();
  },
  data () {
    return {
      defaultValues: null,
      loading: true,
      changeMade: false,
      pendingChange: false,
      selectedTemplate: {},
      selectedDefaultValues: [],
      templates: [],
      toSaveSettings: undefined,
      confirmedLeave: undefined,
      showConfirmModal: false,
    };

  },

  created () {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    let templatesProm = UserService.ListUserTemplateSettings()
      .then((resp) => {
        this.templates = resp.data.templates;
        this.selectedTemplate = this.templates[0];
      });
    let nullValProm = UserService.GetCustomNullValues()
      .then((results) => {
        const emptyValues = [
          // vitals
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Temperature", display_name: "Temperature" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Heart Rate", display_name: "Heart Rate" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Breathing", display_name: "Breathing" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Mucus Membranes", display_name: "Mucus Membranes" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Capillary Refill Time", display_name: "Capillary Refill Time" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Weight", display_name: "Weight" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Body Condition Score", display_name: "Body Condition Score" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, subsection: "Pain Score", display_name: "Pain Score" },
          // physical
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Attitude/Behavior", subsection: "Attitude/Behavior" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Hydration", subsection: "Hydration" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Nose/Throat", subsection: "Nose/Throat" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Eyes", subsection: "Eyes" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Ears", subsection: "Ears" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Mouth/Teeth", subsection: "Mouth/Teeth" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Heart/Blood Vessels", subsection: "Heart/Blood Vessels" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Lungs/Airways", subsection: "Lungs/Airways" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Abdomen", subsection: "Abdomen" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Gastrointestinal System", subsection: "Gastrointestinal System" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Anal Glands", subsection: "Anal Glands" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Coat/Skin/Nails", subsection: "Coat/Skin/Nails" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Lymph Nodes", subsection: "Lymph Nodes" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Musculoskeletal", subsection: "Musculoskeletal" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Central Nervous System", subsection: "Central Nervous System" },
          { template_type: "SOAP", value: "", section: "objective", hide_subsection: false, display_name: "Urinary/Genitals", subsection: "Urinary/Genitals" },

          // ??
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Cardiovascular" },
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Respiratory" },
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Neurological" },
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Lymphatic" },
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Gastrointestinal" },
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Reproductive/Urinary" },
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Fur/Skin" },
          // { template_type: "SOAP", value: "", section: "objective", subsection: "Respiratory Rate" },
        ];
        let tmp = results.data.default_values;

        tmp = tmp
          .filter(x => x.subsection !== "");
        // .filter(x => x.section !== "Objective");// temporary TODO phase 3 work with
        // get unique records
        tmp = tmp.filter((x, index) => {
          return index === tmp.findIndex(y => y.subsection == x.subsection);
        });

        for (let i = 0; i < tmp.length; i++) {
          let v = emptyValues.findIndex((val) => val.subsection == tmp[i].subsection);
          if (v !== -1) {
            // doing this value swap gives us the preferred ordering of the emptyValues set
            emptyValues[v] = tmp[i];
            tmp.splice(i, 1);
            i--;
          }
        }
        this.defaultValues = [...emptyValues, ...tmp];
      }).finally(() => {
      });
    Promise.all([templatesProm, nullValProm])
      .then(() => {
        this.selectedDefaultValues = this.defaultValues.filter(x => x.template_type == this.selectedTemplate.template_type);
        this.loading = false;
      });
  },
  beforeUnmount () {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },

  methods: {

    navBack () {
      if (!this.$router.options.history.state.back) return this.$router.push("/profile");
      else if (this.$router.options.history.state.back !== '/settings/templates') return this.$router.back();
    },
    recordChange (settings) {
      if (this.pendingChange) return;
      this.changeMade = true;
      this.toSaveSettings = settings;
    },
    toggleConfirmModal () {
      this.showConfirmModal = !this.showConfirmModal;
    },
    leaveChanges () {
      if (!this.confirmedLeave) return;
      this.changeMade = false;
      this.$router.push(this.confirmedLeave.fullPath);
    },
    saveAndLeave () {
      if (!this.confirmedLeave) return;
      this.updateTemplateSettings(this.toSaveSettings);
      this.$router.push(this.confirmedLeave.fullPath);
    },

    selectTemplate (t) {
      if (t.template_id === this.selectedTemplate.template_id) return;
      this.selectedTemplate = this.templates.find(x => x.template_id === t.template_id);
      this.selectedDefaultValues = this.defaultValues.filter(x => x.template_type == this.selectedTemplate.template_type);
    },
    updateTemplateSettings (settings) {
      this.changeMade = false;
      this.pendingChange = true;
      // prevent a user from inputting "" as the display name for a subsection
      settings.defaultValues.forEach(x => {
        if (x.display_name == "") {
          x.display_name = x.subsection;
        }
      });
      let prom1 =
        UserService.UpdateCustomNullValues(settings.defaultValues)
          .then((resp) => {
            this.defaultValues = resp.data.default_values;
            this.selectedDefaultValues = this.defaultValues.filter(x => x.template_type == this.selectedTemplate.template_type);
          });

      let prom2 =
        UserService.UpdateUserTemplateSettings(settings.template)
          .then((resp) => {
            this.selectedTemplate = resp.data;
            let i = this.templates.findIndex(x => x.template_id == resp.data.template_id);
            if (i !== -1) {
              this.templates[i] = resp.data;
            }
          });

      Promise.all([prom1, prom2])
        .then(() => {
          this.pendingChange = false;
          this.$toast.success({ message: "Template Updated!" });
        }).catch((e) => {
          this.pendingChange = false;
          console.log(e);
          this.$toast.error({ message: "Template failed to update!" });
        });
    },
    handleBeforeUnload (event) {
      if (!this.changeMade || this.pendingChange || !this.$store.getters.isLoggedIn) {
        return;
      }
      const message = 'You have unsaved changes. Do you really want to leave?';
      event.returnValue = message; // Standard way to set return message
      return message;
    },
  },
};


</script>

<style scoped>
input:focus-visible~div>* {
  visibility: visible;
}

h4 {
  text-transform: none;
}

.discard {
  @apply transition-colors;
  transition-duration: 150ms;
  @apply text-error;
}

.discard:hover {
  @apply text-error-dark;
}
</style>
<template>
  <div class="flex 2xl:flex-col space-x-5 2xl:space-x-0 2xl:space-y-5 justify-between min-w-[296px] 2xl:max-w-[296px] mb-20 lg:mb-0">
    <div class="inline-flex flex-grow flex-col p-4 rounded-xl border border-neutral-lighter">
      <div class="font-extrabold text-sm text-neutral-darker px-2 pt-2">
        Talkatext Commands
      </div>
      <div class="font-roboto font-normal text-xs text-neutral mt-4 px-2 pt-2 pb-4">
        <div class="inline-block">
          Create templates, insert blocks of &nbsp;
        </div>
        <div class="inline-block">
          text and much more with custom &nbsp;
        </div>
        <div class="inline-block">
          voice commands.
        </div>
      </div>
      <BaseLoading v-if="loading" />
      <div v-else class="relative flex flex-col space-y-1 lg:overflow-y-auto lg:max-h-[312px] 2xl:max-h-[500px]">

        <TransitionGroup name="list" appear>
          <button v-for="talkatext in talkatexts" :key="talkatext.talkatext_id"
                  class="talkatext-button  flex flex-grow w-full justify-between transition-all default-fade-duration"
                  :data-selected="selectedTalkatext.talkatext_id == talkatext.talkatext_id"
                  @click="talkatextSelect(talkatext)">
            <div :class="['flex h-full relative',
                          selectedTalkatext.talkatext_id == talkatext.talkatext_id ? 'w-[90%]' : 'w-full']">
              <div v-if="talkatext.is_pinned" :class="['text-2xl transform rotate-12 -ml-2', selectedTalkatext.talkatext_id == talkatext.talkatext_id ? 'fill-main-alt' : 'fill-neutral']">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="m640-480 80 80v80H520v240l-40 40-40-40v-240H240v-80l80-80v-280h-40v-80h400v80h-40v280Z" /></svg>
              </div>
              <!-- capitalize? -->
              <div class="my-auto capitalize keyword-overflow">
                {{ talkatext.keyword }}
              </div>
            </div>
            <div v-if="selectedTalkatext.talkatext_id == talkatext.talkatext_id"
                 class="inline-block material-icons text-xl leading-3 my-auto">
              east
            </div>
            <span v-else-if="talkatext.changeMade" class="my-auto">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.1097 7.49969C14.3415 7.49969 14.5605 7.39244 14.7023 7.20945C14.844 7.02646 14.8935 6.78797 14.8365 6.56373C14.6032 5.65101 14.1277 4.81705 13.4618 4.15108L10.848 1.53744C9.8565 0.545977 8.538 0 7.1355 0H3.74925C1.68225 0 0 1.68218 0 3.74984V14.2494C0 16.3171 1.68225 17.9993 3.75 17.9993H6C6.414 17.9993 6.75 17.6633 6.75 17.2493C6.75 16.8353 6.414 16.4993 6 16.4993H3.75C2.5095 16.4993 1.5 15.4899 1.5 14.2494V3.74984C1.5 2.5094 2.5095 1.49994 3.75 1.49994H7.13625C7.2585 1.49994 7.38 1.50594 7.5 1.51719V5.24978C7.5 6.49023 8.5095 7.49969 9.75 7.49969H14.1097ZM9 5.24978V1.99417C9.28425 2.15766 9.549 2.36015 9.7875 2.59864L12.4012 5.21228C12.6368 5.44777 12.8385 5.71326 13.0035 5.99975H9.75C9.33675 5.99975 9 5.66301 9 5.24978ZM17.3415 8.90888C16.491 8.05841 15.009 8.05841 14.1593 8.90888L9.129 13.9389C8.56275 14.5051 8.25 15.2589 8.25 16.0606V17.25C8.25 17.664 8.586 18 9 18H10.1895C10.9913 18 11.7442 17.6873 12.3105 17.121L17.3407 12.091C17.766 11.6658 18 11.101 18 10.4996C18 9.89809 17.766 9.33336 17.3415 8.90888ZM16.2803 11.0298L11.2492 16.0598C10.9665 16.3433 10.59 16.4993 10.1888 16.4993H9.74925V16.0598C9.74925 15.6593 9.90525 15.2829 10.1888 14.9994L15.2197 9.96933C15.5025 9.68585 15.9968 9.68585 16.2803 9.96933C16.422 10.1103 16.5 10.2986 16.5 10.4996C16.5 10.7006 16.422 10.888 16.2803 11.0298Z" fill="#7C7C7C" />
              </svg>
            </span>
          </button>
        </TransitionGroup>

      </div>
      <div v-if="windowWidth >= 1024" class="pt-4">
        <button class="as-anchor font-medium" @click="createTalkatext">
          + Add a talkatext
        </button>
      </div>
    </div>

  </div>

</template>

<script>
import BaseLoading from '@/components/ui/BaseLoading.vue';

export default {
  name: "SettingsNav",
  emits: ["talkatextSelected", "createTalkatext"],
  components: { BaseLoading },
  props: {
    settingsPage: String,
    selectedTalkatext: Object,
    talkatexts: Array,
    loading: Boolean,
    windowWidth: Number,
  },
  data () {
    return {};
  },
  methods: {
    talkatextSelect (t) {
      this.$emit("talkatextSelected", t);
    },
    createTalkatext () {
      this.$emit("createTalkatext");
    }
  }
};
</script>

<style scoped>
.sublink-item {
  color: #0386b7;
  font-weight: 700;
  height: 2.5rem;
  padding: .5rem 1.75rem;
  /*padding: auto 0;*/
}

.nav-section:hover {
  cursor: pointer;
  @apply bg-main-light;
}

.nav-section:hover .sublink-item {
  color: #C4C4C4;
}

h3 {
  height: 2.5rem;
}

.talkatext-button {
  @apply rounded-full;
  @apply text-neutral-darker;
  background-color: #F5F6F8;
  @apply text-left;
  @apply text-sm;
  @apply font-bold;
  @apply font-roboto;
  @apply py-4;
  @apply pl-5;
  @apply pr-4;
  height: 56px;

}

.talkatext-button[data-selected="true"] {

  @apply bg-pastel-blue-lighter;
  @apply text-main-darker;

}

.talkatext-button:hover {
  @apply bg-neutral-200;
  @apply bg-opacity-50;
}

.talkatext-button[data-selected="true"]:hover {
  @apply bg-pastel-blue-light;
}

.keyword-overflow {
  @apply overflow-y-hidden;
  height: 20px;
}

@media (min-width:1400px) {
  .keyword-overflow {
    max-width: 100%;
    @apply overflow-hidden;
    @apply overflow-ellipsis;
    @apply whitespace-nowrap;
  }
}
</style>
const ConfirmationService = require("@/services/ConfirmationService");
import BaseInput from "@/components/ui/BaseInput";
import BaseBox from '@/components/ui/BaseBox';
import LoginTemplate from 'Components/layout/LoginTemplate.vue';

export default {
  name: 'Reset',
  components: {
    BaseBox,
    BaseInput,
    LoginTemplate
  },
  data () {
    return {
      successMessage: '',
      errorMessage: '',
      token: '',
      email: '',
      confirmSuccess: false,
      showResendButton: false,
      loading: true,
    };
  },
  created () {
    this.token = this.$route.query.token;
    this.email = this.$route.query.email;
    if (this.token === undefined || this.email === undefined) {
      this.confirmSuccess = false;
      this.loading = false;
      if (this.email !== undefined) {
        this.showResendButton = true;
        this.errorMessage = "Your confirmation has expired, please try sending another and check your email.";
      } else {
        this.$router.push("/not-found").catch(err => { console.log(err); });
      }
    }
    else {
      ConfirmationService.ConfirmEmailVerify(this.email, this.token)
        .then((resp) => {
            ConfirmationService.ConfirmEmail(this.email, resp.data.user_id, this.token)
              .then(() => {
                this.confirmSuccess = true;
                this.successMessage = "Your email has been verified. Download Talkatoo to begin dictating.";
              })
              .catch((err) => {
                console.error(err);
                this.showResendButton = true;
                this.errorMessage = "Your confirmation has expired, please try sending another and check your email.";
              });
        })
        .catch((err) => {
          console.error(err);
          this.showResendButton = true;
          this.errorMessage = "Your confirmation has expired, please try sending another and check your email.";
        })
        .finally(() => this.loading =false );
    }
  },
  methods: {
    sendConfirmation(){
      ConfirmationService.ConfirmEmailCreate(this.email)
      .then(() => {
        this.successMessage = "Please check your email for a new confirmation link!";
        this.errorMessage = "";
      })
      .catch((err) => {
          console.error(err);
          this.errorMessage = "Sorry, there was an error sending the confirmation email. Please contact support.";
      });
    },
    downloadTalkatoo() {
      window.open('https://talkatoo.com/downloads/');
    }
  }
};
import { createRouter, createWebHistory } from 'vue-router';

import UserProfile from '@/views/UserProfile/UserProfile.vue';
import BillingHistory from '@/views/BillingHistory/BillingHistory.vue';
import Subscription from '@/views/Subscription/Subscription.vue';
import UserManagement from '@/views/UserManagement/UserManagement.vue';
import NotFound from '@/views/NotFound/NotFound.vue';

import Login from '@/views/Login/Login.vue';
import SSO from '@/views/SSO/SSO.vue';
import NpsSurvey from '@/views/Feedback/NpsSurvey/NpsSurvey.vue';
import CesSurvey from '@/views/Feedback/CesSurvey/CesSurvey.vue';
import GettingStarted from '@/views/GettingStarted/GettingStarted.vue';
import PasswordReset from '@/views/PasswordReset/PasswordReset.vue';
import PasswordUpdate from '@/views/PasswordUpdate/PasswordUpdate.vue';
import TemplateSettings from '@/views/TemplateSettings/TemplateSettings.vue';
import TalkatextSettings from '@/views/TalkatextSettings/TalkatextSettings.vue';
import ConfirmEmail from '@/views/ConfirmEmail/ConfirmEmail.vue';
import NotesEntry from '@/views/Notes/NotesEntry.vue';
import Notes from '@/views/Notes/Notes.vue';
import Kanban from '@/views/NotesKanban/Kanban.vue';
import NoteView from '@/views/NoteView/Text/NoteView.vue';
import TemplatedNoteView from '@/views/NoteView/Templated/TemplatedNoteView.vue';
import NoteViewEntry from '@/views/NoteView/NoteViewEntry.vue';
import ChargifyReactivate from "@/views/ChargifyReactivate/ChargifyReactivate.vue";
import SuperUserSpoof from '@/views/SuperUserSpoof/SuperUserSpoof.vue';


const blades = {admin:0, notes:1, profile:2, none:999};

const routes = [
  { path: '/index.html', redirect: '/' },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      PreAuthPage: true,
      requiresAuth: false,
      hideHeading: true,
    }
  },
  {
    path: '/login/sso',
    name: 'SSO',
    component: SSO,
    meta: {
      PreAuthPage: true,
      hideHeading: true,
      requiresAuth: false,
    }
  },
  {
    path: '/getting-started',
    name: 'GettingStarted',
    component: GettingStarted,
    meta: {
      requiresAuth: true,
      allowProvisioning: true,
      simpleNav:true,
      blade:blades.none,
    }
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: {
      hideSidebar: true,
      hideHeading: true,
    }
  },
  // support for the desktop app having the wrong link
  {
    path: '/forgot-password',
    name: 'PasswordResetDesktop',
    component: PasswordReset,
    meta: {
      hideSidebar: true,
      hideHeading: true,
    }
  },
  {//path matches with old webapp
    path: '/reset',
    name: 'PasswordUpdate',
    component: PasswordUpdate,
    meta: {
      hideSidebar: true,
      hideHeading: true,
    }
  },
  {//path matches with old webapp
    path: '/confirm',
    name: 'ConfirmEmail',
    component: ConfirmEmail,
    meta: {
      hideSidebar: true,
      hideHeading: true,
    }
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      requiresAuth: true,
      allowProvisioning: true,
      blade: blades.profile,
    }
  },
  {
    path: '/profile/:userId',
    name: 'UserProfileId',
    component: UserProfile,
    meta: {
      requiresAuth: true,
      allowProvisioning: true,
      blade: blades.profile,
    }
  },
  {
    path: '/edit-profile',
    name: 'editProfile',
    component: UserProfile,
    meta: {
      requiresAuth: true,
      allowProvisioning: true,
      blade: blades.profile,
    }
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: Subscription,
    meta: {
      requiresAuth: true,
      requiredPerms: (perms) => { return perms.billing_management; },
      blade: blades.admin,
    }
  },
  {
    path: '/',
    alias: '/notes',
    name: 'notesHome',
    component: NotesEntry,
    meta: {
      requiresAuth: true,
      blade: blades.notes,
    }
  },
  {
    path: '/admin-dashboard',
    name: 'AdminDashboard',
    redirect: to => {
      return { path: `/notes` };
    },

  },
  {
    path: '/billing-history',
    name: 'BillingHistoryOld',
    component: BillingHistory,
    meta: {
      requiresAuth: true,
      requiredPerms: (perms) => { return perms.billing_management; },
      blade: blades.admin,
    }
  },
  {
    path: '/billing',
    name: 'BillingHistory',
    component: BillingHistory,
    meta: {
      requiresAuth: true,
      requiredPerms: (perms) => { return perms.billing_management; },
      blade: blades.admin,
    }
  },
  {
    path: '/user-management',
    name: 'UserManagementOld',
    component: UserManagement,
    meta: {
      requiresAuth: true,
      requiredPerms: (perms) => { return perms.user_management; },
      blade: blades.admin,
    }
  },
  {
    path: '/users',
    name: 'UserManagement',
    component: UserManagement,
    meta: {
      requiresAuth: true,
      requiredPerms: (perms) => { return perms.user_management; },
      blade: blades.admin,
    }
  },
  {
    path: '/invitations',
    redirect: to => {
      return { path: `/user-management` };
    },
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: NpsSurvey,
    meta: {
      requiresAuth: false,
      hideSidebar: true,
      simpleNav: true,
    }
  },
  {
    path: '/nps-survey',
    name: 'NpsSurvey',
    component: NpsSurvey,
    meta: {
      requiresAuth: false,
      hideSidebar: true,
      simpleNav: true,
    }
  },
  {
    path: '/ces-survey',
    name: 'CesSurvey',
    component: CesSurvey,
    meta: {
      requiresAuth: false,
      hideSidebar: true,
      simpleNav: true,
    }
  },
  {
    path: '/notes',
    name: 'NotesEntry',
    component: NotesEntry,
    meta: {
      requiresAuth: true,
      keepAlive: true,
      blade: blades.notes,
    }
  }, {
    path: '/notes/:noteId',
    name: 'NotesEntryId',
    component: NotesEntry,
    meta: {
      requiresAuth: true,
      keepAlive: true,
      blade: blades.notes,
    }
  }, {
    path: '/notes/list',
    name: 'NotesList',
    component: Notes,
    meta: {
      requiresAuth: true,
      keepAlive: true,
      blade: blades.notes,
    }
  }, {
    path: '/notes/Kanban',
    name: 'NotesKanban',
    component: Kanban,
    meta: {
      requiresAuth: true,
      keepAlive: true,
      blade: blades.notes,
    }
  }, {
    path: '/notes/templated/:noteId',
    name: 'Templated Note View',
    component: TemplatedNoteView,
    meta: {
      requiresAuth: true,
      blade: blades.notes,
    }
  },
  {
    path: '/notes/text/:noteId',
    name: 'NoteView',
    component: NoteView,
    meta: {
      requiresAuth: true,
      blade: blades.notes,
    }
  },
  {
    path: '/notes/:noteId',
    name: 'NoteViewEntry',
    component: NoteViewEntry,
    meta: {
      requiresAuth: true,
      blade: blades.notes,
    }
  },
  {
    path: '/notes/note/:noteId',
    redirect: to => {
      return { path: `/notes/${to.params.noteId}` };
    },
  },
  {
    path: '/settings',
    name: 'SettingsRoot',
    component: TemplateSettings,
    meta: {
      requiresAuth: true,
      blade: blades.profile,
    }
  },
  {
    path: '/settings/templates',
    name: 'TemplateSettings',
    component: TemplateSettings,
    meta: {
      requiresAuth: true,
      blade: blades.profile,
    }
  },
  {
    path: '/settings/talkatexts',
    name: 'TalkatextSettings',
    component: TalkatextSettings,
    meta: {
      requiresAuth: true,
      blade: blades.profile,
    }
  },
  {
    path: '/reactivate',
    name: 'Reactivate',
    component: ChargifyReactivate,
    meta: {
      requiresAuth: true,
      simpleNav:true,
      blade: blades.none,
    }
  },{
    path: '/spoof',
    name: 'SuperUserSpoof',
    component: SuperUserSpoof,
    meta: {
      requiresAuth: true,
      blade: blades.none,
    }
  },
  {
    path: '/not-found',
    name: 'NotFound',
    component: NotFound,
    meta: {
      requiresAuth: false,
      blade: blades.none,
    }
  },
  {
    path: '/:pathMatch(.*)*',
    meta: {
      hideSidebar: true,
      simpleNav: true,
      blade: blades.none,
    },
    redirect: '/not-found',
  },
];

const router = new createRouter({
  history: createWebHistory(__BASE_URL),
  // mode: 'history', // now a property history using createWebHistory
  // base: __BASE_URL, // now passed to the history
  routes
});

export default router;
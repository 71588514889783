<template>
  <div class="block lg:w-140">
    <div v-show="loading" class="h-140">
    </div>
    <div v-show="!loading" class="flex flex-col w-full max-w-[42rem] pt-6">
      <!-- card pictures -->
      <form id="payment-form" class="stripe-payment-form flex-grow w-full px-12"
            @submit="handleFormSubmit">

        <!-- credit card field -->
        <div class="block min-h-11 pb-7">
          <div class="flex w-full space-x-4">
            <div class="block relative w-full">
              <label class="billing-label">Credit Card * </label>
              <div id="card-number-element" class="element"></div>
            </div>
            <div class="block relative w-full">
              <label class="billing-label">Expiry * </label>
              <div id="card-expiry-element" class="element"></div>
            </div>
          </div>
        </div>
        <div class="block min-h-11 pb-7">
          <div class="flex w-full">
            <div class="block relative w-full">
              <label class="billing-label">Security Code * </label>
              <div id="card-cvv-element" class="element"></div>
            </div>
          </div>
        </div>
        <!-- <div class="flex w-full h-9">
          <div class="relative w-full">
            <div class="element" id="address-element"></div>
          </div>
        </div> -->
        <!-- <button id="submit">Submit</button> -->
        <!-- Display error message to your customers here -->
        <!-- <div id="error-message">
          {{ errorMessage }}
        </div> -->
        <!-- first and last name -->
        <div class="inline-flex w-full">
          <BaseInput v-model="name.value"
                     class="w-full" label="Full Name *" :errorMessage="name.error" :placeholderText="'Full Name'" type="text" required @input="(e) => fieldChange(e, 'name')"
          />
          <!-- <BaseInput v-model="firstName.value"
                     class="w-full mr-4" label="First Name *" :errorMessage="firstName.error" :placeholderText="'First Name'" type="text" required @input="(e) => fieldChange(e, 'firstName')"
          />
          <BaseInput v-model="lastName.value"
                     class="w-full" label="Last Name *" :errorMessage="lastName.error" :placeholderText="'Last Name'" type="text" required @input="(e) => fieldChange(e, 'lastName')"
          /> -->
        </div>

        <!-- billing address -->
        <div class="inline-flex w-full pb-2">
          <BaseInput v-model="address1.value"
                     class="w-full" label="Address *" :errorMessage="address1.error" :placeholderText="'Address'" type="text" required @input="(e) => fieldChange(e, 'address1')"
          />
        </div>
        <div class="inline-flex w-full">
          <BaseInput v-model="address2.value"
                     class="w-full" label="Address 2 (Optional)" :errorMessage="address2.error" :placeholderText="'Apt 2, Suite 6'" type="text" @input="(e) => fieldChange(e, 'address2')"
          />
        </div>

        <!-- country  -->
        <div class="inline-flex w-full pt-4 pb-7">
          <BaseSelectList class="w-full"
                          label="Country *" :list="countryList" :initialValue="country.value" :errorMessage="country.error" placeholderText="Select a Country" @select="countryChange" @input="(e) => fieldChange(e, 'country')"
          />
        </div>

        <!-- state -->
        <div v-if="showStatePicker" class="inline-flex w-full pb-7">
          <BaseSelectList :class="['w-full', 'mt-2', stateList.length == 0 ? 'disabled' : '']"
                          label="State *" :list="stateList" :initialValue="state.value" :errorMessage="state.error" placeholderText="Select a State" @select="stateChange"
          />
        </div>
        <div v-if="!showStatePicker" class="inline-flex w-full pb-2">
          <BaseInput v-model="state.value"
                     class="w-full" label="State *" :errorMessage="state.error" :placeholderText="'State'" type="text" required @input="(e) => { fieldChange(e, 'state'); state.error = ''; }"
          />
        </div>
        <!-- billing city and zip -->
        <div class="inline-flex w-full pb-2">
          <BaseInput v-model="city.value"
                     class="w-full mr-4" label="City *" :errorMessage="city.error" :placeholderText="'City'" type="text" required @input="(e) => fieldChange(e, 'city')"
          />
          <BaseInput v-model="zip.value"
                     class="w-full" label="Zip Code *" :errorMessage="zip.error" :placeholderText="'Zipcode'" type="text" required @input="(e) => fieldChange(e, 'zip')"
          />
        </div>
      </form>
      <!-- general error message from the form input -->
      <div class="relative w-full">
        <div v-show="errorMessage" class="absolute h-8 lg:h-4 w-full top-2 mx-8 text-center text-error">
          {{ errorMessage }}
        </div>
      </div>
      <!-- form input -->
      <div class="inline-flex w-full justify-end modal-end pt-4 px-6 ">
        <a class="lg:h-8 cancel-button" @click="cancelEvent">
          <template v-if="back">
            <i class="material-icons">keyboard_arrow_left</i><span class="text-xs">BACK</span>
          </template>
          <template v-else>
            <span> CANCEL </span>
          </template>
        </a>
        <input
          class="text-xs text-white bg-main-dark rounded-md px-6 center float-right lg:h-8"
          type="submit"
          :value="continueText ?? 'Continue'"
          :disabled="loading ?? undefined"
          @click="handleFormSubmit"
        />
      </div>
    </div>
  </div>
</template>


<script>
/* global Chargify */
import BaseSelectList from "Components/ui/BaseSelectList.vue";
import BaseInput from "Components/ui/BaseInput.vue";

export default {
  name: "StripeForm",
  emits: ["toggleLoading", "validTokenEvent", "cancelEvent"],
  components: {
    BaseSelectList,
    BaseInput,
  },
  props: {
    back: Boolean,
    loading: Boolean,
    billingAddress: Object,
    countryList: Array,
    subscription: Object,
    continueText: { type: String, optional: true },
  },
  data () {
    //! handle case where user may have plaintext name of the country. this is a catch for maybe better UX
    let country = this.billingAddress?.country ?? "";
    let state = this.billingAddress?.state ?? "";
    let stateList = [];
    let showStatePicker = false;
    if (country) {
      let contMap = this.countryList.find(x => x.text.toLowerCase() == country.toLowerCase());
      // if user has plaintext country name
      if (contMap) {
        country = contMap.value;
        // if user has plaintext state name
        if (contMap.states) {
          let stateMap = contMap.states.find(x => x.state.toLowerCase() == state.toLowerCase());
          if (stateMap) {
            state = stateMap.code;
            showStatePicker = true;
            stateList = contMap.states.map((el) => { return { text: el.state, value: el.code }; });
          }
        }
      }
    }
    return {
      stripe: undefined,
      elements: undefined,
      // stripe's 'createToken function requires that the card number element be passed to it.
      // so we need a component reference specifically to it. https://docs.stripe.com/js/tokens/create_token?type=cardElement#stripe_create_token-tokenType
      cardNumber: undefined,

      name: { value: this.billingAddress?.name ?? "", error: "" },
      firstName: { value: this.billingAddress?.first_name ?? "", error: "" },
      lastName: { value: this.billingAddress?.last_name ?? "", error: "" },
      address1: { value: this.billingAddress?.address ?? "", error: "" },
      address2: { value: this.billingAddress?.address_2 ?? "", error: "" },
      country: { value: country, error: "" },
      state: { value: state, error: "" },
      city: { value: this.billingAddress?.city ?? "", error: "" },
      zip: { value: this.billingAddress?.zip ?? "", error: "" },

      stateList: stateList,
      showStatePicker: showStatePicker,
      errorMessage: "",

    };
  },
  async created () {

  },
  mounted () {
    this.$emit("toggleLoading");

    this.stripe = Stripe(__STRIPE_PUBLIC_KEY);
    this.elements = this.stripe.elements({
      fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap' }],
      mode: "setup",
      currency: this.subscription.currency,
    });

    // Create an instance of the card Element
    var style = {
      base: {
        color: '#375a65',
        fontFamily: 'Montserrat',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#9BACB2'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };
    let count = 3;
    this.cardNumber = this.elements.create('cardNumber', { style: style });
    this.cardNumber.mount('#card-number-element');
    let cardExpiry = this.elements.create('cardExpiry', { style: style });
    cardExpiry.mount('#card-expiry-element');
    let cardCvv = this.elements.create('cardCvc', { style: style });
    cardCvv.mount('#card-cvv-element');
    this.cardNumber.addEventListener('ready', () => {
      count--;
      if (count === 0) this.$emit("toggleLoading");
    });
    cardExpiry.addEventListener('ready', () => {
      count--;
      if (count === 0) this.$emit("toggleLoading");
    });
    cardCvv.addEventListener('ready', () => {
      count--;
      if (count === 0) this.$emit("toggleLoading");
    });
  },
  methods: {
    countryChange (event) {
      this.country.value = event;
      this.country.error = "";
      this.state.value = "";
      this.state.error = "";
      const states = this.countryList.find(x => x.value == event)?.states;
      if (states) {
        this.stateList.length = 0;
        this.stateList = states.map((el) => { return { text: el.state, value: el.code }; });
        this.showStatePicker = true;
      } else {
        this.showStatePicker = false;
      }
    },

    stateChange (event) {
      this.state.value = event;
      this.state.error = "";
    },


    validateForm () {
      var valid = true;
      if (this.name.value === '') {
        this.name.error = "Please write your name";
        valid = false;
      }
      if (this.address1.value === '') {
        this.address1.error = "Please write your billing address";
        valid = false;
      }
      let contMap = this.countryList.find(x => x.value == this.country.value);
      if (!contMap || this.country.value === '') {
        this.country.error = "Please select a country";
        valid = false;
      }

      let stateOk = this.state.value !== '';
      if (contMap && contMap.states) {
        let stateMap = contMap.states.find(x => x.code == this.state.value);
        stateOk = stateMap != undefined;
      }
      if (!stateOk) {
        this.state.error = "Please select a state";
        if (!this.showStatePicker) {
          this.state.error = "Please write your billing state";
        }
        valid = false;
      }
      if (this.city.value === '') {
        this.city.error = "Please write your billing city";
        valid = false;
      }
      if (this.zip.value === '') {
        this.zip.error = "Please write your billing zipcode";
        valid = false;
      }
      return valid;
    },

    //when a field changes, just remove a
    fieldChange (event, field) {
      switch (field) {
        case ("name"): this.name.error = ""; break;
        case ("address1"): this.address1.error = ""; break;
        case ("address2"): this.address1.error = ""; break;
        // case ("country"): this.country.error = ""; break;
        // case ("state"): this.state.error = ""; break;
        case ("city"): this.city.error = ""; break;
        case ("zip"): this.zip.error = ""; break;
      }
    },
    async handleFormSubmit (event) {
      event.preventDefault();
      if (!this.validateForm()) {
        return;
      }
      // submit event
      this.$emit("toggleLoading");
      const { token, error } = await this.stripe.createToken(this.cardNumber,
        {
          name: this.name.value,
          // name: `${this.firstName.value} ${this.lastName.value}`,
          address_line1: this.address1.value,
          address_line2: this.address2.value,
          address_city: this.city.value,
          address_zip: this.zip.value,
          address_state: this.state.value,
          address_country: this.country.value, // use country code
        });

      if (error === undefined) {
        const billingInfo = {
          // name: `${this.firstName.value} ${this.lastName.value}`,
          name: this.name.value,
          // first_name: this.firstName.value,
          // last_name: this.lastName.value,
          address: this.address1.value,
          address_2: this.address2.value,
          country: this.country.value,
          state: this.state.value,
          city: this.city.value,
          zip: this.zip.value,
        };
        this.$emit("validTokenEvent", { token, billingInfo });
      } else {
        this.$emit("toggleLoading");
        console.log("token ERROR - err: ", error);
        if (error.code == "incomplete_number") {
          this.errorMessage = error.message;
          window.setTimeout(() => {
            this.errorMessage = "";
          }, 5000);
        }

      }
    },
    cancelEvent () {
      this.$emit("cancelEvent");
    },
  }
};
</script>

<style scoped>
.element {
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  border: 1px solid #9BACB2;
  border-radius: 8px;
  @apply basis-full;
  height: 44px;
  padding-left: 12px
}

.stripe-payment-form>div {
  /* height: 44px; */
  /* margin-bottom: 28px; */
}

.billing-label {

  @apply absolute;
  @apply -translate-y-1/2;
  @apply left-2;
  @apply px-1;
  @apply bg-white;
  @apply font-roboto;
  color: #9BACB2;
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-style: normal;
  font-weight: 400;
}

form {}
</style>
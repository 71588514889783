module.exports = {

  // New Products monthly(standard)/annual(premium) price points not included
  solo: {
    product_id: 100,
    handle: 'solo',
    chargify_id: '14',
    name: 'Solo',
    number_of_users: 1,
    number_of_seats: 1,
    price_in_cents: '9500',
    interval_months: 1,
    trial_length_days: 7,
    description: 'Single User',
    tier: 1,
    price_points: [
      {
        id: 1,
        product_id: 100,
        handle: "standard",
        chargify_id: 1,
        name: "standard",
        tier: 1,
        archived: false,
        price_in_cents: "9500",
        formatted_price: "$95.00 USD",
        interval_months: "1",
        currencies: [
          {
            code: "AUD",
            price: 160,
            formatted_price: "$160.00 AUD"
          }, {
            code: "USD",
            price: 140,
            formatted_price: "$140.00 USD"
          }, {
            code: "GBP",
            price: 120,
            formatted_price: "$120.00 GBP"
          }
        ]
      },
      {
        id: 2,
        product_id: 100,
        handle: "premium",
        chargify_id: 2,
        name: "Annual",
        tier: 2,
        archived: false,
        price_in_cents: "114000",
        formatted_price: "$1,140.00 USD",
        interval_months: "12",
        currencies: [
          {
            code: "AUD",
            price: 1260,
            formatted_price: "$1260.00 AUD"
          },
          {
            code: "USD",
            price: 1140,
            formatted_price: "$1140.00 USD"
          },
          {
            code: "GBP",
            price: 1000,
            formatted_price: "$1000.00 GBP"
          }
        ]

      },
    ],
    metadata: {
      main_image_svg: 'chihuahua',
      min_number_of_users: 1,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$95.00 USD',
      display: true,
      image_label: 'chihuahua'
    },
    additional_attributes: {}
  },
  duo: {
    product_id: 200,
    handle: 'duo',
    chargify_id: '14',
    name: 'duo',
    number_of_users: 2,
    number_of_seats: 2,
    price_in_cents: '18900',
    interval_months: 1,
    trial_length_days: 7,
    description: 'Two Users',
    tier: 2,
    price_points: [
      {
        id: 3,
        product_id: 200,
        handle: "standard",
        chargify_id: 3,
        name: "Monthly",
        tier: 1,
        archived: false,
        price_in_cents: "18900",
        formatted_price: "$189.00 USD",
        interval_months: "1",
        currencies: [
          {
            code: "AUD",
            price: 200,
            formatted_price: "$200.00 AUD"
          }, {
            code: "USD",
            price: 189,
            formatted_price: "$189.00 USD"
          }, {
            code: "GBP",
            price: 172,
            formatted_price: "$172.00 GBP"
          }
        ]
      },
      {
        id: 4,
        product_id: 200,
        handle: "premium",
        chargify_id: 4,
        name: "Annual",
        tier: 2,
        archived: false,
        price_in_cents: "199000",
        formatted_price: "$1,990.00 USD",
        interval_months: "12",
        currencies: [
          {
            code: "AUD",
            price: 2050,
            formatted_price: "$2050.00 AUD"
          }, {
            code: "USD",
            price: 1990,
            formatted_price: "$1990.00 USD"
          }, {
            code: "GBP",
            price: 1850,
            formatted_price: "$1850.00 GBP"
          }
        ]
      },
    ],
    metadata: {
      main_image_svg: 'chihuahua',
      min_number_of_users: 1,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$95.00 USD',
      display: true,
      image_label: 'chihuahua'
    },
    additional_attributes: {}
  },
  group: {
    product_id: 300,
    handle: 'group',
    chargify_id: '14',
    name: 'Group',
    number_of_users: 5,
    number_of_seats: 6,
    price_in_cents: '27900',
    interval_months: 1,
    trial_length_days: 7,
    description: 'Five Users',
    tier: 3,
    price_points: [
      {
        id: 5,
        product_id: 300,
        handle: "standard",
        chargify_id: 5,
        name: "Monthly",
        tier: 1,
        archived: false,
        price_in_cents: "27900",
        formatted_price: "$279.00 USD",
        interval_months: "1",
        currencies: [
          {
            code: "AUD",
            price: 160,
            formatted_price: "$279.00 AUD"
          }, {
            code: "USD",
            price: 140,
            formatted_price: "$279.00 USD"
          }, {
            code: "GBP",
            price: 120,
            formatted_price: "$279.00 GBP"
          }
        ]
      },
      {
        id: 6,
        product_id: 300,
        handle: "premium",
        chargify_id: 6,
        name: "Annual",
        tier: 2,
        archived: false,
        price_in_cents: "299000",
        formatted_price: "$2,990.00 USD",
        interval_months: "12",
        currencies: [
          {
            code: "AUD",
            price: 160,
            formatted_price: "$2,990.00 AUD"
          }, {
            code: "USD",
            price: 140,
            formatted_price: "$2,990.00 USD"
          }, {
            code: "GBP",
            price: 120,
            formatted_price: "$2,990.00 GBP"
          }
        ]
      },
    ],
    metadata: {
      main_image_svg: 'chihuahua',
      min_number_of_users: 1,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$95.00 USD',
      display: true,
      image_label: 'chihuahua'
    },
    additional_attributes: {}
  },

  // old products
  chihuahua_monthly: {
    product_id: 4,
    handle: 'chihuahua_monthly',
    chargify_id: '14',
    name: 'Chihuahua Monthly',
    number_of_users: 1,
    number_of_seats: 1,
    price_in_cents: '9500',
    interval_months: 1,
    trial_length_days: 7,
    description: 'Single User',
    tier: 1,
    metadata: {
      main_image_svg: 'chihuahua',
      min_number_of_users: 1,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$95.00 USD',
      display: true,
      image_label: 'chihuahua'
    },
    additional_attributes: {}
  },
  chihuahua_monthly_no_trial: {
    product_id: 5,
    handle: 'chihuahua_monthly_no_trial',
    chargify_id: '15',
    name: 'Chihuahua Monthly No Trial',
    number_of_users: 1,
    number_of_seats: 1,
    price_in_cents: '9500',
    interval_months: 1,
    trial_length_days: 0,
    description: 'Single User',
    tier: 1,
    metadata: {
      main_image_svg: 'chihuahua',
      min_number_of_users: 1,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$95.00 USD',
      display: false,
      image_label: 'chihuahua'
    },
    additional_attributes: {}
  },
  border_collie: {
    product_id: 6,
    handle: 'border_collie',
    chargify_id: '16',
    name: 'Border Collie',
    number_of_users: 5,
    number_of_seats: 6,
    price_in_cents: '19500',
    interval_months: 1,
    trial_length_days: 0,
    description: '2-5 Users',
    tier: 5,
    metadata: {
      main_image_svg: 'border_collie',
      min_number_of_users: 2,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$195.00 USD',
      display: true,
      image_label: 'border_collie'
    },
    additional_attributes: {}
  },
  golden_retriever: {
    product_id: 7,
    handle: 'golden_retriever',
    chargify_id: '17',
    name: 'Golden Retriever',
    number_of_users: 10,
    number_of_seats: 11,
    price_in_cents: '35000',
    interval_months: 1,
    trial_length_days: 0,
    description: '6-10 Users',
    tier: 10,
    metadata: {
      main_image_svg: 'golden_retriever',
      min_number_of_users: 6,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$350.00 USD',
      display: true,
      image_label: 'golden_retriever'
    },
    additional_attributes: {}
  },
  st_bernard: {
    product_id: 8,
    handle: 'st_bernard',
    chargify_id: '18',
    name: 'St. Bernard',
    number_of_users: 15,
    number_of_seats: 16,
    price_in_cents: '49500',
    interval_months: 1,
    trial_length_days: 0,
    description: '11-15 Users',
    tier: 15,
    metadata: {
      main_image_svg: 'st_bernard',
      min_number_of_users: 11,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$495.00 USD',
      display: true,
      image_label: 'st_bernard'
    },
    additional_attributes: {}
  },
  great_dane: {
    product_id: 9,
    handle: 'great_dane',
    chargify_id: '19',
    name: 'Great Dane',
    number_of_users: 300,
    number_of_seats: 305,
    price_in_cents: '49500',
    interval_months: 1,
    trial_length_days: 0,
    description: '16-300 Users',
    tier: 20,
    metadata: {
      main_image_svg: 'great_dane',
      min_number_of_users: 16,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$495.00 USD',
      display: false,
      image_label: 'great_dane'
    },
    additional_attributes: {}
  },
  chihuahua_annual: {
    product_id: 16,
    handle: "chihuahua_annual",
    chargify_id: "5521105",
    name: "Chihuahua Annual",
    number_of_users: 1,
    number_of_seats: 1,
    price_in_cents: "114000",
    interval_months: "12",
    trial_length_days: 0,
    description: "Single User",
    tier: 1,
    metadata: {
      main_image_svg: "",
      min_number_of_users: 1,
      archived: false,
      start_time_unix_seconds: "0",
      end_time_unix_seconds: "0",
      formatted_price: "$1,140.00 USD",
      display: true,
      image_label: "chihuahua"
    },
    additional_attributes: {}
  },
  border_collie_annual: {
    product_id: 17,
    handle: "border_collie_annual",
    chargify_id: "5521106",
    name: "Border Collie Annual",
    number_of_users: 5,
    number_of_seats: 6,
    price_in_cents: "234000",
    interval_months: "12",
    trial_length_days: 0,
    description: "2 - 5 User Plan",
    tier: 5,
    metadata: {
      main_image_svg: "",
      min_number_of_users: 2,
      archived: false,
      start_time_unix_seconds: "0",
      end_time_unix_seconds: "0",
      formatted_price: "$2,340.00 USD",
      display: false,
      image_label: "border_collie"
    },
    additional_attributes: {}
  },
  golden_retriever_annual: {
    product_id: 18,
    handle: "golden_retriever_annual",
    chargify_id: "5521107",
    name: "Golden Retriever Annual",
    number_of_users: 10,
    number_of_seats: 11,
    price_in_cents: "420000",
    interval_months: "12",
    trial_length_days: 0,
    description: "6 - 10 User Plan",
    tier: 10,
    metadata: {
      main_image_svg: "",
      min_number_of_users: 6,
      archived: false,
      start_time_unix_seconds: "0",
      end_time_unix_seconds: "0",
      formatted_price: "$4,200.00 USD",
      display: false,
      image_label: "golden_retriever"
    },
    additional_attributes: {}
  },
  st_bernard_annual: {
    product_id: 19,
    handle: "st_bernard_annual",
    chargify_id: "5521108",
    name: "St. Bernard Annual",
    number_of_users: 15,
    number_of_seats: 16,
    price_in_cents: "594000",
    interval_months: "12",
    trial_length_days: 0,
    description: "11 - 15 User Plan",
    tier: 15,
    metadata: {
      main_image_svg: "",
      min_number_of_users: 11,
      archived: false,
      start_time_unix_seconds: "0",
      end_time_unix_seconds: "0",
      formatted_price: "$5,940.00 USD",
      display: false,
      image_label: "st_bernard"
    },
    additional_attributes: {}
  },

  talkatoo_monthly: {
    product_id: "23",
    handle: "talkatoo_monthly",
    chargify_id: "6576126",
    name: "Talkatoo Monthly",
    number_of_users: 0,
    number_of_seats: 2,
    price_in_cents: "0",
    interval_months: "1",
    trial_length_days: 0,
    description: "Talkatoo plan billed monthly.",
    tier: 1,
    commitment_period: 0,
    price_points: [
      {
        id: "8",
        product_id: "23",
        handle: "monthly",
        chargify_id: "2510819",
        name: "Monthly",
        tier: "1",
        archived: false,
        price_in_cents: "0",
        interval_months: "1",
        formatted_price: "$0.00 USD",
        currencies: [
          {
            code: "AUD",
            price: 0,
            formatted_price: "$0.00 AUD"
          },
          {
            code: "CAD",
            price: 0,
            formatted_price: "$0.00 CAD"
          },
          {
            code: "GBP",
            price: 0,
            formatted_price: "£0.00"
          }
        ]
      }
    ],
    metadata: {
      main_image_svg: "solo",
      min_number_of_users: 0,
      archived: false,
      start_time_unix_seconds: "0",
      end_time_unix_seconds: "0",
      formatted_price: "$0.00 USD",
      display: true,
      image_label: "solo"
    },
    additional_attributes: {}
  },
  talkatoo_annual: {
    product_id: "24",
    handle: "talkatoo_annual",
    chargify_id: "6576127",
    name: "Talkatoo Annual",
    number_of_users: 0,
    number_of_seats: 2,
    price_in_cents: "0",
    interval_months: "12",
    trial_length_days: 0,
    description: "Talkatoo plan billed annually.",
    tier: 1,
    commitment_period: 0,
    price_points: [
      {
        id: "9",
        product_id: "24",
        handle: "annual",
        chargify_id: "2510820",
        name: "Annual",
        tier: "5",
        archived: false,
        price_in_cents: "0",
        interval_months: "12",
        formatted_price: "$0.00 USD",
        currencies: [
          {
            code: "AUD",
            price: 0,
            formatted_price: "$0.00 AUD"
          },
          {
            code: "CAD",
            price: 0,
            formatted_price: "$0.00 CAD"
          },
          {
            code: "GBP",
            price: 0,
            formatted_price: "£0.00"
          }
        ]
      }
    ],
    metadata: {
      main_image_svg: "solo",
      min_number_of_users: 0,
      archived: false,
      start_time_unix_seconds: "0",
      end_time_unix_seconds: "0",
      formatted_price: "$0.00 USD",
      display: true,
      image_label: "solo"
    },
    additional_attributes: {}
  },
  verified_usage1: {
    product_id: "prod_QeN5ObgFGCsy2S",
    handle: "",
    name: "Verified Usage",
    description: "",
    prices: [
      {
        id: "price_1Pn4LMIGl7aET9vnOsM7I3B1",
        product_id: "prod_QeN5ObgFGCsy2S",
        handle: "",
        name: "",
        description: "",
        archived: false,
        price_in_cents: "1000",
        interval_months: "1",
        formatted_price: "10.00",
        tiers: [],
        currency: "usd",
        additional_currencies: []
      }
    ],
    metadata: {
      archived: false,
      display: false
    },
    additional_attributes: null
  },
  verified_usage2: {
    product_id: "prod_QCMAoPsrHQNCBB",
    handle: "",
    name: "Verified Usage",
    description: "",
    prices: [
      {
        id: "price_1PbITuIGl7aET9vnGyvHu1fA",
        product_id: "prod_QCMAoPsrHQNCBB",
        handle: "",
        name: "",
        description: "",
        archived: true,
        price_in_cents: "0",
        interval_months: "1",
        formatted_price: "0.00",
        tiers: [
          {
            id: "",
            price_in_cents: "0",
            formatted_price: "0.00",
            up_to_amount: "50"
          },
          {
            id: "",
            price_in_cents: "500",
            formatted_price: "5.00",
            up_to_amount: "0"
          }
        ],
        currency: "usd",
        additional_currencies: []
      },
      {
        id: "price_1PQrRJIGl7aET9vn3qfZUNzc",
        product_id: "prod_QCMAoPsrHQNCBB",
        handle: "",
        name: "",
        description: "Custom 10 CAD verified usage",
        archived: false,
        price_in_cents: "0",
        interval_months: "1",
        formatted_price: "0.00",
        tiers: [
          {
            id: "",
            price_in_cents: "0",
            formatted_price: "0.00",
            up_to_amount: "50"
          },
          {
            id: "",
            price_in_cents: "1000",
            formatted_price: "10.00",
            up_to_amount: "0"
          }
        ],
        currency: "cad",
        additional_currencies: [
          {
            currency: "cad",
            tiers: [
              {
                id: "",
                price_in_cents: "0",
                formatted_price: "0.00",
                up_to_amount: "50"
              },
              {
                id: "",
                price_in_cents: "1000",
                formatted_price: "10.00",
                up_to_amount: "0"
              }
            ]
          }
        ]
      },
      {
        id: "price_1PLxSDIGl7aET9vnyh9PYV2n",
        product_id: "prod_QCMAoPsrHQNCBB",
        handle: "verified_usage_monthly",
        name: "",
        description: "",
        archived: false,
        price_in_cents: "0",
        interval_months: "1",
        formatted_price: "0.00",
        tiers: [
          {
            id: "",
            price_in_cents: "0",
            formatted_price: "0.00",
            up_to_amount: "50"
          },
          {
            id: "",
            price_in_cents: "1000",
            formatted_price: "10.00",
            up_to_amount: "0"
          }
        ],
        currency: "usd",
        additional_currencies: [
          {
            currency: "cad",
            tiers: [
              {
                id: "",
                price_in_cents: "0",
                formatted_price: "0.00",
                up_to_amount: "50"
              },
              {
                id: "",
                price_in_cents: "1350",
                formatted_price: "13.50",
                up_to_amount: "0"
              }
            ]
          },
          {
            currency: "gbp",
            tiers: [
              {
                id: "",
                price_in_cents: "0",
                formatted_price: "0.00",
                up_to_amount: "50"
              },
              {
                id: "",
                price_in_cents: "800",
                formatted_price: "8.00",
                up_to_amount: "0"
              }
            ]
          },
          {
            currency: "aud",
            tiers: [
              {
                id: "",
                price_in_cents: "0",
                formatted_price: "0.00",
                up_to_amount: "50"
              },
              {
                id: "",
                price_in_cents: "1500",
                formatted_price: "15.00",
                up_to_amount: "0"
              }
            ]
          }
        ]
      }
    ],
    metadata: {
      archived: false,
      display: false
    },
    additional_attributes: null
  },
  verified_seat: {
    product_id: "prod_QCM7Q3jjT3IcVE",
    handle: "",
    name: "Verified Seat",
    description: "",
    prices: [
      {
        id: "price_1Pb9YjIGl7aET9vnZxujwqY4",
        product_id: "prod_QCM7Q3jjT3IcVE",
        handle: "",
        name: "",
        description: "",
        archived: false,
        price_in_cents: "49900",
        interval_months: "0",
        formatted_price: "499.00",
        tiers: [],
        currency: "usd",
        additional_currencies: []
      },
      {
        id: "price_1PQrQCIGl7aET9vninQwW1PJ",
        product_id: "prod_QCM7Q3jjT3IcVE",
        handle: "",
        name: "",
        description: "Custom 499 CAD verified",
        archived: false,
        price_in_cents: "49900",
        interval_months: "1",
        formatted_price: "499.00",
        tiers: [],
        currency: "cad",
        additional_currencies: []
      },
      {
        id: "price_1PNbxSIGl7aET9vnz5pilfCj",
        product_id: "prod_QCM7Q3jjT3IcVE",
        handle: "verified_monthly",
        name: "",
        description: "",
        archived: false,
        price_in_cents: "49900",
        interval_months: "1",
        formatted_price: "499.00",
        tiers: [],
        currency: "usd",
        additional_currencies: []
      },
      {
        id: "price_1PLxOmIGl7aET9vnlsiu22Y6",
        product_id: "prod_QCM7Q3jjT3IcVE",
        handle: "",
        name: "",
        description: "",
        archived: true,
        price_in_cents: "40000",
        interval_months: "1",
        formatted_price: "400.00",
        tiers: [],
        currency: "usd",
        additional_currencies: []
      }
    ],
    metadata: {
      archived: false,
      display: false
    },
    additional_attributes: null
  },
  free_seat: {
    product_id: "prod_QCM3W6QNca4wBx",
    handle: "",
    name: "Free Seat",
    description: "",
    prices: [
      {
        id: "price_1PLxLdIGl7aET9vnjHN7Yr5n",
        product_id: "prod_QCM3W6QNca4wBx",
        handle: "free_quarterly",
        name: "",
        description: "Additional management seat for quarterly billing cadence",
        archived: false,
        price_in_cents: "0",
        interval_months: "3",
        formatted_price: "0.00",
        tiers: [],
        currency: "usd",
        additional_currencies: []
      },
      {
        id: "price_1PLxLdIGl7aET9vnhDqkfx9e",
        product_id: "prod_QCM3W6QNca4wBx",
        handle: "free_monthly",
        name: "",
        description: "Additional management seat for monthly billing cadence",
        archived: false,
        price_in_cents: "0",
        interval_months: "1",
        formatted_price: "0.00",
        tiers: [],
        currency: "usd",
        additional_currencies: []
      },
      {
        id: "price_1PLxLdIGl7aET9vnaWYNsyvc",
        product_id: "prod_QCM3W6QNca4wBx",
        handle: "free_annual",
        name: "",
        description: "Additional management seat for annual billing cadence",
        archived: false,
        price_in_cents: "0",
        interval_months: "1",
        formatted_price: "0.00",
        tiers: [],
        currency: "usd",
        additional_currencies: []
      }
    ],
    metadata: {
      archived: false,
      display: false
    },
    additional_attributes: null
  },
  talkatoo_seat: {
    product_id: "prod_QCLzfMY95S4gzV",
    handle: "",
    name: "Talkatoo Seat",
    description: "",
    prices: [
      {
        id: "price_1Pb9hnIGl7aET9vnrZlBS9vY",
        product_id: "prod_QCLzfMY95S4gzV",
        handle: "",
        name: "",
        description: "",
        archived: false,
        price_in_cents: "100000",
        interval_months: "0",
        formatted_price: "1000.00",
        tiers: [],
        currency: "usd",
        additional_currencies: []
      },
      {
        id: "price_1PaBRWIGl7aET9vndVblKBFk",
        product_id: "prod_QCLzfMY95S4gzV",
        handle: "internal",
        name: "",
        description: "Internal/External/Free",
        archived: false,
        price_in_cents: "0",
        interval_months: "1",
        formatted_price: "0.00",
        tiers: [],
        currency: "usd",
        additional_currencies: []
      },
      {
        id: "price_1PTqP6IGl7aET9vnF1nh1EEi",
        product_id: "prod_QCLzfMY95S4gzV",
        handle: "",
        name: "",
        description: "Should be archived. Base annual price when migrating all historical subscriptions to component billing in chargify",
        archived: false,
        price_in_cents: "0",
        interval_months: "1",
        formatted_price: "0.00",
        tiers: [
          {
            id: "",
            price_in_cents: "128000",
            formatted_price: "1280.00",
            up_to_amount: "1"
          },
          {
            id: "",
            price_in_cents: "113500",
            formatted_price: "1135.00",
            up_to_amount: "2"
          },
          {
            id: "",
            price_in_cents: "94000",
            formatted_price: "940.00",
            up_to_amount: "3"
          },
          {
            id: "",
            price_in_cents: "80000",
            formatted_price: "800.00",
            up_to_amount: "4"
          },
          {
            id: "",
            price_in_cents: "73800",
            formatted_price: "738.00",
            up_to_amount: "5"
          },
          {
            id: "",
            price_in_cents: "72150",
            formatted_price: "721.50",
            up_to_amount: "6"
          },
          {
            id: "",
            price_in_cents: "70971",
            formatted_price: "709.71",
            up_to_amount: "7"
          },
          {
            id: "",
            price_in_cents: "70088",
            formatted_price: "700.88",
            up_to_amount: "8"
          },
          {
            id: "",
            price_in_cents: "69400",
            formatted_price: "694.00",
            up_to_amount: "9"
          },
          {
            id: "",
            price_in_cents: "68850",
            formatted_price: "688.50",
            up_to_amount: "10"
          },
          {
            id: "",
            price_in_cents: "68400",
            formatted_price: "684.00",
            up_to_amount: "11"
          },
          {
            id: "",
            price_in_cents: "68025",
            formatted_price: "680.25",
            up_to_amount: "12"
          },
          {
            id: "",
            price_in_cents: "67708",
            formatted_price: "677.08",
            up_to_amount: "13"
          },
          {
            id: "",
            price_in_cents: "67436",
            formatted_price: "674.36",
            up_to_amount: "14"
          },
          {
            id: "",
            price_in_cents: "67200",
            formatted_price: "672.00",
            up_to_amount: "0"
          }
        ],
        currency: "usd",
        additional_currencies: [
          {
            currency: "aud",
            tiers: [
              {
                id: "",
                price_in_cents: "199500",
                formatted_price: "1995.00",
                up_to_amount: "1"
              },
              {
                id: "",
                price_in_cents: "176900",
                formatted_price: "1769.00",
                up_to_amount: "2"
              },
              {
                id: "",
                price_in_cents: "146500",
                formatted_price: "1465.00",
                up_to_amount: "3"
              },
              {
                id: "",
                price_in_cents: "124700",
                formatted_price: "1247.00",
                up_to_amount: "4"
              },
              {
                id: "",
                price_in_cents: "115000",
                formatted_price: "1150.00",
                up_to_amount: "5"
              },
              {
                id: "",
                price_in_cents: "112400",
                formatted_price: "1124.00",
                up_to_amount: "6"
              },
              {
                id: "",
                price_in_cents: "110600",
                formatted_price: "1106.00",
                up_to_amount: "7"
              },
              {
                id: "",
                price_in_cents: "109200",
                formatted_price: "1092.00",
                up_to_amount: "8"
              },
              {
                id: "",
                price_in_cents: "108200",
                formatted_price: "1082.00",
                up_to_amount: "9"
              },
              {
                id: "",
                price_in_cents: "107300",
                formatted_price: "1073.00",
                up_to_amount: "10"
              },
              {
                id: "",
                price_in_cents: "106600",
                formatted_price: "1066.00",
                up_to_amount: "11"
              },
              {
                id: "",
                price_in_cents: "106000",
                formatted_price: "1060.00",
                up_to_amount: "12"
              },
              {
                id: "",
                price_in_cents: "105500",
                formatted_price: "1055.00",
                up_to_amount: "13"
              },
              {
                id: "",
                price_in_cents: "105100",
                formatted_price: "1051.00",
                up_to_amount: "14"
              },
              {
                id: "",
                price_in_cents: "104700",
                formatted_price: "1047.00",
                up_to_amount: "0"
              }
            ]
          },
          {
            currency: "cad",
            tiers: [
              {
                id: "",
                price_in_cents: "176500",
                formatted_price: "1765.00",
                up_to_amount: "1"
              },
              {
                id: "",
                price_in_cents: "156700",
                formatted_price: "1567.00",
                up_to_amount: "2"
              },
              {
                id: "",
                price_in_cents: "129700",
                formatted_price: "1297.00",
                up_to_amount: "3"
              },
              {
                id: "",
                price_in_cents: "110400",
                formatted_price: "1104.00",
                up_to_amount: "4"
              },
              {
                id: "",
                price_in_cents: "101900",
                formatted_price: "1019.00",
                up_to_amount: "5"
              },
              {
                id: "",
                price_in_cents: "99500",
                formatted_price: "995.00",
                up_to_amount: "6"
              },
              {
                id: "",
                price_in_cents: "98000",
                formatted_price: "980.00",
                up_to_amount: "7"
              },
              {
                id: "",
                price_in_cents: "96700",
                formatted_price: "967.00",
                up_to_amount: "8"
              },
              {
                id: "",
                price_in_cents: "95800",
                formatted_price: "958.00",
                up_to_amount: "9"
              },
              {
                id: "",
                price_in_cents: "95000",
                formatted_price: "950.00",
                up_to_amount: "10"
              },
              {
                id: "",
                price_in_cents: "94400",
                formatted_price: "944.00",
                up_to_amount: "11"
              },
              {
                id: "",
                price_in_cents: "93900",
                formatted_price: "939.00",
                up_to_amount: "12"
              },
              {
                id: "",
                price_in_cents: "93500",
                formatted_price: "935.00",
                up_to_amount: "13"
              },
              {
                id: "",
                price_in_cents: "93100",
                formatted_price: "931.00",
                up_to_amount: "14"
              },
              {
                id: "",
                price_in_cents: "92800",
                formatted_price: "928.00",
                up_to_amount: "0"
              }
            ]
          },
          {
            currency: "gbp",
            tiers: [
              {
                id: "",
                price_in_cents: "105000",
                formatted_price: "1050.00",
                up_to_amount: "1"
              },
              {
                id: "",
                price_in_cents: "93250",
                formatted_price: "932.50",
                up_to_amount: "2"
              },
              {
                id: "",
                price_in_cents: "77230",
                formatted_price: "772.30",
                up_to_amount: "3"
              },
              {
                id: "",
                price_in_cents: "65730",
                formatted_price: "657.30",
                up_to_amount: "4"
              },
              {
                id: "",
                price_in_cents: "60630",
                formatted_price: "606.30",
                up_to_amount: "5"
              },
              {
                id: "",
                price_in_cents: "59280",
                formatted_price: "592.80",
                up_to_amount: "6"
              },
              {
                id: "",
                price_in_cents: "58310",
                formatted_price: "583.10",
                up_to_amount: "7"
              },
              {
                id: "",
                price_in_cents: "57580",
                formatted_price: "575.80",
                up_to_amount: "8"
              },
              {
                id: "",
                price_in_cents: "57020",
                formatted_price: "570.20",
                up_to_amount: "9"
              },
              {
                id: "",
                price_in_cents: "56560",
                formatted_price: "565.60",
                up_to_amount: "10"
              },
              {
                id: "",
                price_in_cents: "56200",
                formatted_price: "562.00",
                up_to_amount: "11"
              },
              {
                id: "",
                price_in_cents: "55900",
                formatted_price: "559.00",
                up_to_amount: "12"
              },
              {
                id: "",
                price_in_cents: "55630",
                formatted_price: "556.30",
                up_to_amount: "13"
              },
              {
                id: "",
                price_in_cents: "55400",
                formatted_price: "554.00",
                up_to_amount: "14"
              },
              {
                id: "",
                price_in_cents: "55210",
                formatted_price: "552.10",
                up_to_amount: "0"
              }
            ]
          }
        ]
      },
      {
        id: "price_1PTqEFIGl7aET9vnEFyglqF2",
        product_id: "prod_QCLzfMY95S4gzV",
        handle: "",
        name: "",
        description: "Should be archived. Base monthly price when migrating all historical subscriptions to component billing in chargify",
        archived: false,
        price_in_cents: "0",
        interval_months: "1",
        formatted_price: "0.00",
        tiers: [
          {
            id: "",
            price_in_cents: "10700",
            formatted_price: "107.00",
            up_to_amount: "1"
          },
          {
            id: "",
            price_in_cents: "9450",
            formatted_price: "94.50",
            up_to_amount: "2"
          },
          {
            id: "",
            price_in_cents: "7833",
            formatted_price: "78.33",
            up_to_amount: "3"
          },
          {
            id: "",
            price_in_cents: "6675",
            formatted_price: "66.75",
            up_to_amount: "4"
          },
          {
            id: "",
            price_in_cents: "6160",
            formatted_price: "61.60",
            up_to_amount: "5"
          },
          {
            id: "",
            price_in_cents: "6017",
            formatted_price: "60.17",
            up_to_amount: "6"
          },
          {
            id: "",
            price_in_cents: "5914",
            formatted_price: "59.14",
            up_to_amount: "7"
          },
          {
            id: "",
            price_in_cents: "5838",
            formatted_price: "58.38",
            up_to_amount: "8"
          },
          {
            id: "",
            price_in_cents: "5789",
            formatted_price: "57.89",
            up_to_amount: "9"
          },
          {
            id: "",
            price_in_cents: "5740",
            formatted_price: "57.40",
            up_to_amount: "10"
          },
          {
            id: "",
            price_in_cents: "5700",
            formatted_price: "57.00",
            up_to_amount: "11"
          },
          {
            id: "",
            price_in_cents: "5667",
            formatted_price: "56.67",
            up_to_amount: "12"
          },
          {
            id: "",
            price_in_cents: "5646",
            formatted_price: "56.46",
            up_to_amount: "13"
          },
          {
            id: "",
            price_in_cents: "5621",
            formatted_price: "56.21",
            up_to_amount: "14"
          },
          {
            id: "",
            price_in_cents: "5600",
            formatted_price: "56.00",
            up_to_amount: "0"
          }
        ],
        currency: "usd",
        additional_currencies: [
          {
            currency: "aud",
            tiers: [
              {
                id: "",
                price_in_cents: "16610",
                formatted_price: "166.10",
                up_to_amount: "1"
              },
              {
                id: "",
                price_in_cents: "14700",
                formatted_price: "147.00",
                up_to_amount: "2"
              },
              {
                id: "",
                price_in_cents: "12100",
                formatted_price: "121.00",
                up_to_amount: "3"
              },
              {
                id: "",
                price_in_cents: "10400",
                formatted_price: "104.00",
                up_to_amount: "4"
              },
              {
                id: "",
                price_in_cents: "9570",
                formatted_price: "95.70",
                up_to_amount: "5"
              },
              {
                id: "",
                price_in_cents: "9341",
                formatted_price: "93.41",
                up_to_amount: "6"
              },
              {
                id: "",
                price_in_cents: "9181",
                formatted_price: "91.81",
                up_to_amount: "7"
              },
              {
                id: "",
                price_in_cents: "9063",
                formatted_price: "90.63",
                up_to_amount: "8"
              },
              {
                id: "",
                price_in_cents: "8987",
                formatted_price: "89.87",
                up_to_amount: "9"
              },
              {
                id: "",
                price_in_cents: "8911",
                formatted_price: "89.11",
                up_to_amount: "10"
              },
              {
                id: "",
                price_in_cents: "8849",
                formatted_price: "88.49",
                up_to_amount: "11"
              },
              {
                id: "",
                price_in_cents: "8797",
                formatted_price: "87.97",
                up_to_amount: "12"
              },
              {
                id: "",
                price_in_cents: "8765",
                formatted_price: "87.65",
                up_to_amount: "13"
              },
              {
                id: "",
                price_in_cents: "8726",
                formatted_price: "87.26",
                up_to_amount: "14"
              },
              {
                id: "",
                price_in_cents: "8693",
                formatted_price: "86.93",
                up_to_amount: "0"
              }
            ]
          },
          {
            currency: "cad",
            tiers: [
              {
                id: "",
                price_in_cents: "14750",
                formatted_price: "147.50",
                up_to_amount: "1"
              },
              {
                id: "",
                price_in_cents: "13030",
                formatted_price: "130.30",
                up_to_amount: "2"
              },
              {
                id: "",
                price_in_cents: "10800",
                formatted_price: "108.00",
                up_to_amount: "3"
              },
              {
                id: "",
                price_in_cents: "9210",
                formatted_price: "92.10",
                up_to_amount: "4"
              },
              {
                id: "",
                price_in_cents: "8496",
                formatted_price: "84.96",
                up_to_amount: "5"
              },
              {
                id: "",
                price_in_cents: "8299",
                formatted_price: "82.99",
                up_to_amount: "6"
              },
              {
                id: "",
                price_in_cents: "8151",
                formatted_price: "81.51",
                up_to_amount: "7"
              },
              {
                id: "",
                price_in_cents: "8052",
                formatted_price: "80.52",
                up_to_amount: "8"
              },
              {
                id: "",
                price_in_cents: "7985",
                formatted_price: "79.85",
                up_to_amount: "9"
              },
              {
                id: "",
                price_in_cents: "7917",
                formatted_price: "79.17",
                up_to_amount: "10"
              },
              {
                id: "",
                price_in_cents: "7862",
                formatted_price: "78.62",
                up_to_amount: "11"
              },
              {
                id: "",
                price_in_cents: "7816",
                formatted_price: "78.16",
                up_to_amount: "12"
              },
              {
                id: "",
                price_in_cents: "7788",
                formatted_price: "77.88",
                up_to_amount: "13"
              },
              {
                id: "",
                price_in_cents: "7753",
                formatted_price: "77.53",
                up_to_amount: "14"
              },
              {
                id: "",
                price_in_cents: "7724",
                formatted_price: "77.24",
                up_to_amount: "0"
              }
            ]
          },
          {
            currency: "gbp",
            tiers: [
              {
                id: "",
                price_in_cents: "8800",
                formatted_price: "88.00",
                up_to_amount: "1"
              },
              {
                id: "",
                price_in_cents: "7700",
                formatted_price: "77.00",
                up_to_amount: "2"
              },
              {
                id: "",
                price_in_cents: "6420",
                formatted_price: "64.20",
                up_to_amount: "3"
              },
              {
                id: "",
                price_in_cents: "5470",
                formatted_price: "54.70",
                up_to_amount: "4"
              },
              {
                id: "",
                price_in_cents: "5047",
                formatted_price: "50.47",
                up_to_amount: "5"
              },
              {
                id: "",
                price_in_cents: "4930",
                formatted_price: "49.30",
                up_to_amount: "6"
              },
              {
                id: "",
                price_in_cents: "4846",
                formatted_price: "48.46",
                up_to_amount: "7"
              },
              {
                id: "",
                price_in_cents: "4784",
                formatted_price: "47.84",
                up_to_amount: "8"
              },
              {
                id: "",
                price_in_cents: "4743",
                formatted_price: "47.43",
                up_to_amount: "9"
              },
              {
                id: "",
                price_in_cents: "4703",
                formatted_price: "47.03",
                up_to_amount: "10"
              },
              {
                id: "",
                price_in_cents: "4670",
                formatted_price: "46.70",
                up_to_amount: "11"
              },
              {
                id: "",
                price_in_cents: "4643",
                formatted_price: "46.43",
                up_to_amount: "12"
              },
              {
                id: "",
                price_in_cents: "4626",
                formatted_price: "46.26",
                up_to_amount: "13"
              },
              {
                id: "",
                price_in_cents: "4606",
                formatted_price: "46.06",
                up_to_amount: "14"
              },
              {
                id: "",
                price_in_cents: "4589",
                formatted_price: "45.89",
                up_to_amount: "0"
              }
            ]
          }
        ]
      },
      {
        id: "price_1PLxHWIGl7aET9vngtxqjr2h",
        product_id: "prod_QCLzfMY95S4gzV",
        handle: "retail_monthly",
        name: "",
        description: "",
        archived: false,
        price_in_cents: "0",
        interval_months: "1",
        formatted_price: "0.00",
        tiers: [
          {
            id: "",
            price_in_cents: "13900",
            formatted_price: "139.00",
            up_to_amount: "1"
          },
          {
            id: "",
            price_in_cents: "11650",
            formatted_price: "116.50",
            up_to_amount: "2"
          },
          {
            id: "",
            price_in_cents: "9650",
            formatted_price: "96.50",
            up_to_amount: "3"
          },
          {
            id: "",
            price_in_cents: "8200",
            formatted_price: "82.00",
            up_to_amount: "4"
          },
          {
            id: "",
            price_in_cents: "7550",
            formatted_price: "75.50",
            up_to_amount: "5"
          },
          {
            id: "",
            price_in_cents: "7350",
            formatted_price: "73.50",
            up_to_amount: "6"
          },
          {
            id: "",
            price_in_cents: "7275",
            formatted_price: "72.75",
            up_to_amount: "7"
          },
          {
            id: "",
            price_in_cents: "7200",
            formatted_price: "72.00",
            up_to_amount: "8"
          },
          {
            id: "",
            price_in_cents: "7125",
            formatted_price: "71.25",
            up_to_amount: "9"
          },
          {
            id: "",
            price_in_cents: "7050",
            formatted_price: "70.50",
            up_to_amount: "10"
          },
          {
            id: "",
            price_in_cents: "7025",
            formatted_price: "70.25",
            up_to_amount: "11"
          },
          {
            id: "",
            price_in_cents: "6975",
            formatted_price: "69.75",
            up_to_amount: "12"
          },
          {
            id: "",
            price_in_cents: "6950",
            formatted_price: "69.50",
            up_to_amount: "13"
          },
          {
            id: "",
            price_in_cents: "6925",
            formatted_price: "69.25",
            up_to_amount: "14"
          },
          {
            id: "",
            price_in_cents: "6900",
            formatted_price: "69.00",
            up_to_amount: "15"
          },
          {
            id: "",
            price_in_cents: "5825",
            formatted_price: "58.25",
            up_to_amount: "49"
          },
          {
            id: "",
            price_in_cents: "5408",
            formatted_price: "54.08",
            up_to_amount: "149"
          },
          {
            id: "",
            price_in_cents: "4992",
            formatted_price: "49.92",
            up_to_amount: "0"
          }
        ],
        currency: "usd",
        additional_currencies: [
          {
            currency: "gbp",
            tiers: [
              {
                id: "",
                price_in_cents: "10947",
                formatted_price: "109.47",
                up_to_amount: "1"
              },
              {
                id: "",
                price_in_cents: "9175",
                formatted_price: "91.75",
                up_to_amount: "2"
              },
              {
                id: "",
                price_in_cents: "7600",
                formatted_price: "76.00",
                up_to_amount: "3"
              },
              {
                id: "",
                price_in_cents: "6458",
                formatted_price: "64.58",
                up_to_amount: "4"
              },
              {
                id: "",
                price_in_cents: "5946",
                formatted_price: "59.46",
                up_to_amount: "5"
              },
              {
                id: "",
                price_in_cents: "5789",
                formatted_price: "57.89",
                up_to_amount: "6"
              },
              {
                id: "",
                price_in_cents: "5730",
                formatted_price: "57.30",
                up_to_amount: "7"
              },
              {
                id: "",
                price_in_cents: "5670",
                formatted_price: "56.70",
                up_to_amount: "8"
              },
              {
                id: "",
                price_in_cents: "5611",
                formatted_price: "56.11",
                up_to_amount: "9"
              },
              {
                id: "",
                price_in_cents: "5552",
                formatted_price: "55.52",
                up_to_amount: "10"
              },
              {
                id: "",
                price_in_cents: "5533",
                formatted_price: "55.33",
                up_to_amount: "11"
              },
              {
                id: "",
                price_in_cents: "5493",
                formatted_price: "54.93",
                up_to_amount: "12"
              },
              {
                id: "",
                price_in_cents: "5474",
                formatted_price: "54.74",
                up_to_amount: "13"
              },
              {
                id: "",
                price_in_cents: "5454",
                formatted_price: "54.54",
                up_to_amount: "14"
              },
              {
                id: "",
                price_in_cents: "5434",
                formatted_price: "54.34",
                up_to_amount: "15"
              },
              {
                id: "",
                price_in_cents: "4588",
                formatted_price: "45.88",
                up_to_amount: "49"
              },
              {
                id: "",
                price_in_cents: "4259",
                formatted_price: "42.59",
                up_to_amount: "149"
              },
              {
                id: "",
                price_in_cents: "3932",
                formatted_price: "39.32",
                up_to_amount: "0"
              }
            ]
          },
          {
            currency: "aud",
            tiers: [
              {
                id: "",
                price_in_cents: "21022",
                formatted_price: "210.22",
                up_to_amount: "1"
              },
              {
                id: "",
                price_in_cents: "17619",
                formatted_price: "176.19",
                up_to_amount: "2"
              },
              {
                id: "",
                price_in_cents: "14594",
                formatted_price: "145.94",
                up_to_amount: "3"
              },
              {
                id: "",
                price_in_cents: "12402",
                formatted_price: "124.02",
                up_to_amount: "4"
              },
              {
                id: "",
                price_in_cents: "11418",
                formatted_price: "114.18",
                up_to_amount: "5"
              },
              {
                id: "",
                price_in_cents: "11116",
                formatted_price: "111.16",
                up_to_amount: "6"
              },
              {
                id: "",
                price_in_cents: "11003",
                formatted_price: "110.03",
                up_to_amount: "7"
              },
              {
                id: "",
                price_in_cents: "10889",
                formatted_price: "108.89",
                up_to_amount: "8"
              },
              {
                id: "",
                price_in_cents: "10776",
                formatted_price: "107.76",
                up_to_amount: "9"
              },
              {
                id: "",
                price_in_cents: "10662",
                formatted_price: "106.62",
                up_to_amount: "10"
              },
              {
                id: "",
                price_in_cents: "10624",
                formatted_price: "106.24",
                up_to_amount: "11"
              },
              {
                id: "",
                price_in_cents: "10549",
                formatted_price: "105.49",
                up_to_amount: "12"
              },
              {
                id: "",
                price_in_cents: "10511",
                formatted_price: "105.11",
                up_to_amount: "13"
              },
              {
                id: "",
                price_in_cents: "10473",
                formatted_price: "104.73",
                up_to_amount: "14"
              },
              {
                id: "",
                price_in_cents: "10435",
                formatted_price: "104.35",
                up_to_amount: "15"
              },
              {
                id: "",
                price_in_cents: "8810",
                formatted_price: "88.10",
                up_to_amount: "49"
              },
              {
                id: "",
                price_in_cents: "8179",
                formatted_price: "81.79",
                up_to_amount: "149"
              },
              {
                id: "",
                price_in_cents: "7550",
                formatted_price: "75.50",
                up_to_amount: "0"
              }
            ]
          },
          {
            currency: "cad",
            tiers: [
              {
                id: "",
                price_in_cents: "19069",
                formatted_price: "190.69",
                up_to_amount: "1"
              },
              {
                id: "",
                price_in_cents: "15982",
                formatted_price: "159.82",
                up_to_amount: "2"
              },
              {
                id: "",
                price_in_cents: "13238",
                formatted_price: "132.38",
                up_to_amount: "3"
              },
              {
                id: "",
                price_in_cents: "11249",
                formatted_price: "112.49",
                up_to_amount: "4"
              },
              {
                id: "",
                price_in_cents: "10358",
                formatted_price: "103.58",
                up_to_amount: "5"
              },
              {
                id: "",
                price_in_cents: "10083",
                formatted_price: "100.83",
                up_to_amount: "6"
              },
              {
                id: "",
                price_in_cents: "9980",
                formatted_price: "99.80",
                up_to_amount: "7"
              },
              {
                id: "",
                price_in_cents: "9877",
                formatted_price: "98.77",
                up_to_amount: "8"
              },
              {
                id: "",
                price_in_cents: "9775",
                formatted_price: "97.75",
                up_to_amount: "9"
              },
              {
                id: "",
                price_in_cents: "9672",
                formatted_price: "96.72",
                up_to_amount: "10"
              },
              {
                id: "",
                price_in_cents: "9637",
                formatted_price: "96.37",
                up_to_amount: "11"
              },
              {
                id: "",
                price_in_cents: "9569",
                formatted_price: "95.69",
                up_to_amount: "12"
              },
              {
                id: "",
                price_in_cents: "9534",
                formatted_price: "95.34",
                up_to_amount: "13"
              },
              {
                id: "",
                price_in_cents: "9500",
                formatted_price: "95.00",
                up_to_amount: "14"
              },
              {
                id: "",
                price_in_cents: "9466",
                formatted_price: "94.66",
                up_to_amount: "15"
              },
              {
                id: "",
                price_in_cents: "7991",
                formatted_price: "79.91",
                up_to_amount: "49"
              },
              {
                id: "",
                price_in_cents: "7419",
                formatted_price: "74.19",
                up_to_amount: "149"
              },
              {
                id: "",
                price_in_cents: "6848",
                formatted_price: "68.48",
                up_to_amount: "0"
              }
            ]
          }
        ]
      },
      {
        id: "price_1PLxHWIGl7aET9vnq6sTqyCD",
        product_id: "prod_QCLzfMY95S4gzV",
        handle: "retail_annual",
        name: "",
        description: "",
        archived: false,
        price_in_cents: "0",
        interval_months: "1",
        formatted_price: "0.00",
        tiers: [
          {
            id: "",
            price_in_cents: "139900",
            formatted_price: "1399.00",
            up_to_amount: "1"
          },
          {
            id: "",
            price_in_cents: "125000",
            formatted_price: "1250.00",
            up_to_amount: "2"
          },
          {
            id: "",
            price_in_cents: "103300",
            formatted_price: "1033.00",
            up_to_amount: "3"
          },
          {
            id: "",
            price_in_cents: "87500",
            formatted_price: "875.00",
            up_to_amount: "4"
          },
          {
            id: "",
            price_in_cents: "82000",
            formatted_price: "820.00",
            up_to_amount: "5"
          },
          {
            id: "",
            price_in_cents: "80000",
            formatted_price: "800.00",
            up_to_amount: "6"
          },
          {
            id: "",
            price_in_cents: "78600",
            formatted_price: "786.00",
            up_to_amount: "7"
          },
          {
            id: "",
            price_in_cents: "77500",
            formatted_price: "775.00",
            up_to_amount: "8"
          },
          {
            id: "",
            price_in_cents: "76700",
            formatted_price: "767.00",
            up_to_amount: "9"
          },
          {
            id: "",
            price_in_cents: "76000",
            formatted_price: "760.00",
            up_to_amount: "10"
          },
          {
            id: "",
            price_in_cents: "75400",
            formatted_price: "754.00",
            up_to_amount: "11"
          },
          {
            id: "",
            price_in_cents: "75000",
            formatted_price: "750.00",
            up_to_amount: "12"
          },
          {
            id: "",
            price_in_cents: "74600",
            formatted_price: "746.00",
            up_to_amount: "13"
          },
          {
            id: "",
            price_in_cents: "74300",
            formatted_price: "743.00",
            up_to_amount: "14"
          },
          {
            id: "",
            price_in_cents: "74000",
            formatted_price: "740.00",
            up_to_amount: "15"
          },
          {
            id: "",
            price_in_cents: "69900",
            formatted_price: "699.00",
            up_to_amount: "49"
          },
          {
            id: "",
            price_in_cents: "64896",
            formatted_price: "648.96",
            up_to_amount: "149"
          },
          {
            id: "",
            price_in_cents: "59904",
            formatted_price: "599.04",
            up_to_amount: "0"
          }
        ],
        currency: "usd",
        additional_currencies: [
          {
            currency: "aud",
            tiers: [
              {
                id: "",
                price_in_cents: "211582",
                formatted_price: "2115.82",
                up_to_amount: "1"
              },
              {
                id: "",
                price_in_cents: "189048",
                formatted_price: "1890.47",
                up_to_amount: "2"
              },
              {
                id: "",
                price_in_cents: "156229",
                formatted_price: "1562.29",
                up_to_amount: "3"
              },
              {
                id: "",
                price_in_cents: "132333",
                formatted_price: "1323.33",
                up_to_amount: "4"
              },
              {
                id: "",
                price_in_cents: "124015",
                formatted_price: "1240.15",
                up_to_amount: "5"
              },
              {
                id: "",
                price_in_cents: "120990",
                formatted_price: "1209.90",
                up_to_amount: "6"
              },
              {
                id: "",
                price_in_cents: "118873",
                formatted_price: "1188.73",
                up_to_amount: "7"
              },
              {
                id: "",
                price_in_cents: "117209",
                formatted_price: "1172.09",
                up_to_amount: "8"
              },
              {
                id: "",
                price_in_cents: "116000",
                formatted_price: "1160.00",
                up_to_amount: "9"
              },
              {
                id: "",
                price_in_cents: "114941",
                formatted_price: "1149.41",
                up_to_amount: "10"
              },
              {
                id: "",
                price_in_cents: "114033",
                formatted_price: "1140.33",
                up_to_amount: "11"
              },
              {
                id: "",
                price_in_cents: "113429",
                formatted_price: "1134.29",
                up_to_amount: "12"
              },
              {
                id: "",
                price_in_cents: "112824",
                formatted_price: "1128.24",
                up_to_amount: "13"
              },
              {
                id: "",
                price_in_cents: "112370",
                formatted_price: "1123.70",
                up_to_amount: "14"
              },
              {
                id: "",
                price_in_cents: "111916",
                formatted_price: "1119.16",
                up_to_amount: "15"
              },
              {
                id: "",
                price_in_cents: "105715",
                formatted_price: "1057.15",
                up_to_amount: "49"
              },
              {
                id: "",
                price_in_cents: "98147",
                formatted_price: "981.47",
                up_to_amount: "149"
              },
              {
                id: "",
                price_in_cents: "90598",
                formatted_price: "905.98",
                up_to_amount: "0"
              }
            ]
          },
          {
            currency: "cad",
            tiers: [
              {
                id: "",
                price_in_cents: "191923",
                formatted_price: "1919.23",
                up_to_amount: "1"
              },
              {
                id: "",
                price_in_cents: "171483",
                formatted_price: "1714.83",
                up_to_amount: "2"
              },
              {
                id: "",
                price_in_cents: "141713",
                formatted_price: "1417.13",
                up_to_amount: "3"
              },
              {
                id: "",
                price_in_cents: "120038",
                formatted_price: "1200.38",
                up_to_amount: "4"
              },
              {
                id: "",
                price_in_cents: "112493",
                formatted_price: "1124.93",
                up_to_amount: "5"
              },
              {
                id: "",
                price_in_cents: "109749",
                formatted_price: "1097.49",
                up_to_amount: "6"
              },
              {
                id: "",
                price_in_cents: "107828",
                formatted_price: "1078.28",
                up_to_amount: "7"
              },
              {
                id: "",
                price_in_cents: "106319",
                formatted_price: "1063.19",
                up_to_amount: "8"
              },
              {
                id: "",
                price_in_cents: "105222",
                formatted_price: "1052.22",
                up_to_amount: "9"
              },
              {
                id: "",
                price_in_cents: "104261",
                formatted_price: "1042.61",
                up_to_amount: "10"
              },
              {
                id: "",
                price_in_cents: "103438",
                formatted_price: "1034.38",
                up_to_amount: "11"
              },
              {
                id: "",
                price_in_cents: "102890",
                formatted_price: "1028.89",
                up_to_amount: "12"
              },
              {
                id: "",
                price_in_cents: "102341",
                formatted_price: "1023.41",
                up_to_amount: "13"
              },
              {
                id: "",
                price_in_cents: "101929",
                formatted_price: "1019.29",
                up_to_amount: "14"
              },
              {
                id: "",
                price_in_cents: "101518",
                formatted_price: "1015.18",
                up_to_amount: "15"
              },
              {
                id: "",
                price_in_cents: "95893",
                formatted_price: "958.93",
                up_to_amount: "49"
              },
              {
                id: "",
                price_in_cents: "89028",
                formatted_price: "890.28",
                up_to_amount: "149"
              },
              {
                id: "",
                price_in_cents: "82180",
                formatted_price: "821.80",
                up_to_amount: "0"
              }
            ]
          },
          {
            currency: "gbp",
            tiers: [
              {
                id: "",
                price_in_cents: "110180",
                formatted_price: "1101.80",
                up_to_amount: "1"
              },
              {
                id: "",
                price_in_cents: "98446",
                formatted_price: "984.46",
                up_to_amount: "2"
              },
              {
                id: "",
                price_in_cents: "81355",
                formatted_price: "813.55",
                up_to_amount: "3"
              },
              {
                id: "",
                price_in_cents: "68912",
                formatted_price: "689.12",
                up_to_amount: "4"
              },
              {
                id: "",
                price_in_cents: "64580",
                formatted_price: "645.80",
                up_to_amount: "5"
              },
              {
                id: "",
                price_in_cents: "63005",
                formatted_price: "630.05",
                up_to_amount: "6"
              },
              {
                id: "",
                price_in_cents: "61903",
                formatted_price: "619.03",
                up_to_amount: "7"
              },
              {
                id: "",
                price_in_cents: "61036",
                formatted_price: "610.36",
                up_to_amount: "8"
              },
              {
                id: "",
                price_in_cents: "60406",
                formatted_price: "604.06",
                up_to_amount: "9"
              },
              {
                id: "",
                price_in_cents: "59855",
                formatted_price: "598.55",
                up_to_amount: "10"
              },
              {
                id: "",
                price_in_cents: "59382",
                formatted_price: "593.82",
                up_to_amount: "11"
              },
              {
                id: "",
                price_in_cents: "59067",
                formatted_price: "590.67",
                up_to_amount: "12"
              },
              {
                id: "",
                price_in_cents: "58752",
                formatted_price: "587.52",
                up_to_amount: "13"
              },
              {
                id: "",
                price_in_cents: "58516",
                formatted_price: "585.16",
                up_to_amount: "14"
              },
              {
                id: "",
                price_in_cents: "58280",
                formatted_price: "582.80",
                up_to_amount: "15"
              },
              {
                id: "",
                price_in_cents: "55051",
                formatted_price: "550.51",
                up_to_amount: "49"
              },
              {
                id: "",
                price_in_cents: "51110",
                formatted_price: "511.10",
                up_to_amount: "149"
              },
              {
                id: "",
                price_in_cents: "47178",
                formatted_price: "471.78",
                up_to_amount: "0"
              }
            ]
          }
        ]
      },
      {
        id: "price_1PLxHWIGl7aET9vn9DBiiCQu",
        product_id: "prod_QCLzfMY95S4gzV",
        handle: "enterprise_quarterly",
        name: "",
        description: "",
        archived: false,
        price_in_cents: "100",
        interval_months: "3",
        formatted_price: "1.00",
        tiers: [],
        currency: "usd",
        additional_currencies: []
      },
      {
        id: "price_1PLxHWIGl7aET9vn0zUL2L6G",
        product_id: "prod_QCLzfMY95S4gzV",
        handle: "enterprise_yearly",
        name: "",
        description: "",
        archived: false,
        price_in_cents: "100",
        interval_months: "1",
        formatted_price: "1.00",
        tiers: [],
        currency: "usd",
        additional_currencies: []
      },
      {
        id: "price_1PLxHWIGl7aET9vnRh3oeLXT",
        product_id: "prod_QCLzfMY95S4gzV",
        handle: "enterprise_monthly",
        name: "",
        description: "",
        archived: false,
        price_in_cents: "100",
        interval_months: "1",
        formatted_price: "1.00",
        tiers: [],
        currency: "usd",
        additional_currencies: []
      }
    ],
    metadata: {
      archived: false,
      display: false
    },
    additional_attributes: null
  }
};


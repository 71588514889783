<template>
  <div id="notes" class="flex bg-white min-h-[60vh] rounded-t-md lg:rounded-t-0">
    <div class="flex flex-col w-full">

      <div class="heading mb-5">
        <div class="flex flex-row flex-wrap sm:flex-nowrap justify-between w-full 3xl:max-w-[2160px] 4xl:max-w-[1726px]">

          <div class="my-auto whitespace-nowrap text-3xl font-bold">
            {{ tableTitle }}
          </div>
          <!-- <RouterLink to="/kanban">
            try the kanban view!
          </RouterLink> -->
          <div class="flex  order-2 sm:order-3">
            <div class="flex items-center flex-grow">
              <div class="block my-auto text-sm mr-2 font-normal">
                View:
              </div>
              <button
                class="note-view-button active grid-cols-4 grid-rows-3 gap-[1px] mr-2">
                <div class="w-full h-full"></div>
                <div class="w-full h-full col-span-3"></div>
                <div class="w-full h-full"></div>
                <div class="w-full h-full col-span-3"></div>
                <div class="w-full h-full"></div>
                <div class="w-full h-full col-span-3"></div>
              </button>

              <button class="note-view-button grid-cols-3"
                      @click="toggleListType">
                <div class="w-full h-full"></div>
                <div class="w-full h-full"></div>
                <div class="w-full h-full"></div>
              </button>
            </div>
          </div>
          <div class="flex items-center justify-center mr-1 order-3 sm:order-2 basis-full sm:basis-auto">
            <transition name="fade">
              <div v-if="noteUsage && noteUsage.start" class="flex fade-duration">
                <span class="text-sm mr-4 font-normal text-right">
                  <div class="tooltip-basic relative">
                    <strong> {{ noteUsage.notesUsedInPeriod }} </strong> Notes this period <span class="text-sm text-gray-500 material-icons align-middle">info</span>
                    <div class="tooltip-text inline-block whitespace-nowrap">
                      {{ noteUsage.start }} - {{ noteUsage.end }}
                    </div>
                  </div>
                </span>
              </div>
            </transition>
          </div>
        </div>

      </div>

      <div class="body-container flex w-full max-w-[2200px] xl:mr-4">


        <div class="flex flex-col w-full">

          <div class="container-gray">
            <!-- :title="tableTitle" -->
            <BaseTable v-model:pagination="pagination"
                       :showLoading="loading"
                       :data="tableData"
                       :colDef="colDef"
                       :callToAction="{
                         text: 'There are no notes to review!',
                         subtext: 'We\'re going to assume you\'re just really great at your job. If no new notes continue to appear, you may need to be added as a scribe on the notes of the person you\'re expecting to review for.'
                       }"
                       :imgLink="require('@/assets/images/transcription_bubble.svg')"
                       showSearch
                       searchPlaceholder="Search Notes"
                       showHeaderFilters
                       :headerFilters="customTableFilters"
                       :presetFilters="userConfig.savedFilters"
                       showSaveFilters
                       selectTable
                       :actionList="actionList"
                       @text-search="handleTextSearch"
                       @save-custom-filters="saveCustomFilters"
                       @clear-header-filters="clearCustomTableFilters"
                       @update-custom-filters="updateCachedFilters"
                       @select-preset-filters="selectSavedFilters"
                       @remove-preset-filters="removeSavedFilters"
                       @page-size-select="handlePageSizeSelect"

                       @page-change="handlePageSelect"
                       @update-column-sort="handleColumnSort"
                       @toggle-row-select="toggleRowSelect"
                       @toggle-all-select="toggleAllSelect"
                       @archive-click="toggleArchiveModal"

            />
          </div>
          <div class="flex pt-3 xl:mt-6 justify-center text-center">
            <a v-if="inPms" id="active-notes-link"
               class="underline text-lg font-semibold text-main"
               @click="swapActiveInPms">
              View Active Notes
            </a>
            <a v-else id="pms-notes-link"
               class="underline text-lg font-semibold text-main"
               @click="swapActiveInPms">
              View Notes In PMS
            </a>
            <div class="border-l-2 mx-2 border-gray-400"></div>
            <a v-if="archived" id="active-notes-link"
               class="underline text-lg font-semibold text-main"
               @click="swapActiveArchived">
              View Active Notes
            </a>
            <a v-else id="archived-notes-link"
               class="underline text-lg font-semibold text-main"
               @click="swapActiveArchived">
              View Archived Notes
            </a>
          </div>
        </div>

        <div class="hidden 4xl:block ">
          <img class="mt-20 min-h-[443px] min-w-[443px]" height="443" width="443" src="@/assets/images/voice_assistant_1.svg" />
        </div>

      </div>
    </div>
  </div>

  <BaseModal v-if="!loading" :title="archived ? 'Restore Notes' : 'Archive Notes'" :toDisplay="showArchiveModal" @toggleEvent="toggleArchiveModal">
    <div class="my-10  max-h-96 max-w-lg sm:max-w-full overflow-y-auto">
      <div class="mx-12 flex flex-col text-center">
        <span class="text-2xl font-bold">
          {{ archiveListNotes.length }} {{ archiveListNotes.length > 1 ? "Notes" : "note" }} will be {{ archived ? "Restored" : "Archived" }} <br />
        </span>

        <p v-if="archived" class="mx-4 pt-4 text-gray-500 lg:text-sm text-lg">
          The following notes will be restored to the main notes list. <br class="hidden sm:block" />

          <br class="hidden sm:block" />
          <strong>This action will only restore the notes<br class="hidden sm:block" />
            that you have permissions to restore.</strong><br class="hidden sm:block" />
        </p>
        <p v-else class="mx-4 pt-4 text-gray-500 lg:text-sm text-lg">
          Archiving notes will move them from the main list to the <br class="hidden sm:block" />
          archived list. They can be restored back to <br class="hidden sm:block" />
          the main list at any time.<br class="hidden sm:block" /><br class="hidden sm:block" />
        </p>
        <div v-if="archiveListNotes.length != selectedData.length">
          <strong>
            Note: you may only archive your own notes.<br class="hidden sm:block" />
            Below is a list of notes that will be archived.</strong><br class="hidden sm:block" />
        </div>
      </div>
    </div>

    <div class="flex flex-col max-h-56 overflow-auto">
      <div class="sticky -top-1 flex px-8 w-full font-bold text-lg justify-between bg-white">
        <span>Title</span>
        <span>Recorded By</span>
      </div>
      <ul class="alternate  pl-0">
        <li v-for="note in archiveListNotes" :key="note.id"
            class="px-8 py-2 text-neutral-alt text-base font-semibold flex justify-between">
          <div class=" w-80 ellipsis-overflow">
            {{ note.title.title != '' ? note.title.title : note.title.text != '' ? note.title.text : 'Untitled Note' }}
          </div> <div class="whitespace-nowrap">
            {{ note.recorded_by_name }}
          </div>
        </li>
      </ul>
    </div>

    <div class="flex justify-end modal-end p-6">
      <a class="cancel-button" @click="toggleArchiveModal"> CANCEL </a>
      <button
        class="btn-slim bg-rare2-darker hover:bg-rare2-dark text-white "
        @click="archiveSelected">
        {{ archived ? "Restore" : "Archive" }} Notes
      </button>
    </div>
  </BaseModal>

</template>

<script src="./Notes.js"></script>
<style scoped >
.fade-duration {
  transition: opacity 0.3s ease-in-out;
}
</style>


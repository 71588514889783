import axios from 'axios';

const SERVICE_URL = __API_URL_V1 + "templates/";
const AUTOSERVICE_URL = __API_URL_V1 + "autotemplate/";

const SendFormatRequest = (transcript, noteType) => {
    switch (noteType) {
        case ("SOAP"):
            return FormatSoap(transcript);
        case ("RECHECK"):
            return FormatRecheck(transcript);
        case ("PROCEDURE"):
            return FormatProcedure(transcript);
        case ("CLEANUP"):
            return FormatCleanup(transcript);
        case ("EUTHANASIA"):
            return FormatEuthanasia(transcript);
        default:
            return Promise.reject("Not a valid note Type");
    }
};


const GetTemplates = () => {
    return axios({
        method: 'GET',
        url: SERVICE_URL + 'list',
    });
};

const SendDynamicTemplates = (transcript, templateUrl, authorId) => {
    const body = {
        transcript,
        user_id: parseInt(authorId),
    };
    return axios({
        method: 'POST',
        //Note that template urls come in as /v1/template
        url: __API_URL + templateUrl,
        data: body
    });
};

const FormatSoap = (transcript) => {
    const body = {
        transcript
    };
    return axios({
        method: 'POST',
        url: SERVICE_URL + 'soap',
        data: body
    });
};

const FormatRecheck = (transcript) => {
    const body = {
        transcript
    };
    return axios({
        method: 'POST',
        url: SERVICE_URL + 'recheck',
        data: body
    });
};

const FormatProcedure = (transcript) => {
    const body = {
        transcript
    };
    return axios({
        method: 'POST',
        url: SERVICE_URL + 'procedure',
        data: body
    });
};

const FormatCleanup = (transcript) => {
    const body = {
        transcript
    };
    return axios({
        method: 'POST',
        url: SERVICE_URL + 'cleanup',
        data: body
    });
};

const FormatEuthanasia = (transcript) => {
    const body = {
        transcript
    };
    return axios({
        method: 'POST',
        url: SERVICE_URL + 'euthanasia',
        data: body
    });
};

const GenerateClientCommunication = (content, greeting, signature) => {

    const body = {
        inText: content,
        greeting: greeting ?? "",
        signature: signature ?? "",
    };
    return axios({
        method: 'POST',
        url: AUTOSERVICE_URL + 'client_comm',
        data: body
    });
};

export {
    GetTemplates,
    SendDynamicTemplates,
    //the old ways
    SendFormatRequest,
    FormatSoap,
    FormatRecheck,
    FormatProcedure,
    FormatCleanup,
    FormatEuthanasia,

    GenerateClientCommunication ,
};
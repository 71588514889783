<template>
  <div id="text-section-header"
       :class="['flex flex-grow px-3 xl:px-9 py-3 text-sm transition-colors min-h-[2.75rem]',
                noteStateVal === -1 ? 'bg-white' :
                note?.needs_attention ? 'bg-error-lighter text-error' :
                note?.archived ? 'bg-gray-100' :
                noteStateVal === draft ? 'bg-pastel-purple-lighter text-rare-darker' :
                noteStateVal === processing ? 'bg-pastel-purple-lighter text-rare-darker' :
                noteStateVal === pending ? 'bg-pastel-yellow-light text-secondary-darker' :
                noteStateVal === review ? 'bg-pastel-blue-lighter text-main-dark' :
                noteStateVal === verified ? 'bg-rare2-lighter text-rare2-darker' :
                noteStateVal === inPms ? 'bg-rare2-lighter text-rare2-darker' :
                'bg-white'
       ]">
    <span v-if="noteStateVal === -1">
      Created on <strong>{{ noteCreatedOn.date }}</strong> at <strong>{{ noteCreatedOn.time }}</strong>
    </span>
    <span v-else-if="note.archived">
      <strong>{{ noteStateText }}. </strong>
      <span v-if="isUserScribe" class="inline-block">Published on <strong>{{ notePublishedOn.date }}</strong> at <strong>{{ notePublishedOn.time }}.&nbsp;</strong></span>
      <span class="inline-block">
        Last changed on
        <strong>{{ noteEditedOn.date }}</strong> at <strong>{{ noteEditedOn.time }}.</strong>
      </span>
    </span>
    <span v-else-if="note.needs_attention && !authorRespondedToIssue">
      <strong>Note Needs Attention.</strong> The reviewer has flagged a problem with this note.
      <span v-if="isUserScribe" class="inline-block">Published on <strong>{{ notePublishedOn.date }}</strong> at <strong>{{ notePublishedOn.time }}.&nbsp;</strong></span>
    </span>
    <span v-else-if="note.needs_attention && authorRespondedToIssue">
      <strong>Note Needs Attention.</strong> The author has responded to the flagged issue on this note.
      <span v-if="isUserScribe" class="inline-block">Published on <strong>{{ notePublishedOn.date }}</strong> at <strong>{{ notePublishedOn.time }}.&nbsp;</strong></span>
    </span>
    <div v-else-if="noteStateVal === draft" class="flex flex-col sm:flex-row items-center w-full">
      <span>
        <strong>{{ noteStateText }} Note.</strong> <br class="xl:hidden" />Created on <strong>{{ noteCreatedOn.date }}</strong> at <strong>{{ noteCreatedOn.time }}</strong>
      </span>
      <div class="block xl:hidden pt-3 pb-1 sm:py-0 mx-auto sm:mr-0 sm:my-auto">
        <button class="btn-primary text-sm px-14 py-2" :disabled="loading"
                @click="stateChange(pending)">
          Publish Note
        </button>
      </div>
    </div>
    <div v-else-if="noteStateVal === processing" class="flex flex-col sm:flex-row items-center w-full">
      <span>
        <strong>{{ noteStateText }}.</strong> <br class="xl:hidden" />Created on <strong>{{ noteCreatedOn.date }}</strong> at <strong>{{ noteCreatedOn.time }}</strong>
      </span>
      <div class="block xl:hidden pt-3 pb-1 sm:py-0 mx-auto sm:mr-0 sm:my-auto">
        <button class="btn-primary text-sm px-14 py-2" :disabled="true">
          Publish Note
        </button>
      </div>
    </div>
    <div v-else-if="noteStateVal === pending" class="sm:inline flex flex-col sm:flex-row items-center w-full">
      <strong>{{ noteStateText }}. &nbsp; </strong>
      <span class="inline-block">
        Published on <strong>{{ notePublishedOn.date }}</strong> at <strong>{{ notePublishedOn.time }}. &nbsp; </strong>
      </span>
      <span class="inline-block">
        Created on <strong>{{ noteCreatedOn.date }}</strong> at <strong>{{ noteCreatedOn.time }}. </strong>
      </span>
      <div class="block xl:hidden pt-3 pb-1 sm:py-0 mx-auto sm:mr-0 sm:my-auto">
        <button class="btn-primary text-sm px-14 py-2" :disabled="loading"
                @click="stateChange(review)">
          Begin Review
        </button>
      </div>
    </div>
    <div v-else-if="noteStateVal === review" class="sm:inline flex flex-col sm:flex-row items-center w-full">
      <strong>{{ noteStateText }}. </strong>
      <span v-if="isUserScribe" class="inline-block">Published on <strong>{{ notePublishedOn.date }}</strong> at <strong>{{ notePublishedOn.time }}. &nbsp;</strong></span>
      <span class="inline-block">
        Last changed on
        <strong>{{ noteEditedOn.date }}</strong> at <strong>{{ noteEditedOn.time }}.  &nbsp;</strong>
      </span>
      <span class="inline-block">
        Created on <strong>{{ noteCreatedOn.date }}</strong> at <strong>{{ noteCreatedOn.time }}. </strong>
      </span>

      <div class="block xl:hidden pt-3 pb-1 sm:py-0 mx-auto sm:mr-0 sm:my-auto">
        <button class="rounded-md text-white bg-rare2-dark text-sm px-14 py-2 whitespace-nowrap"
                :disabled="loading"
                @click="stateChange(isUserVerified || isUserScribe ? verified : inPms)">
          {{ isUserVerified || isUserScribe ? "Verify Note" : "Complete Note" }}
        </button>
      </div>
    </div>
    <div v-else-if="noteStateVal === verified" class="sm:inline flex flex-col sm:flex-row items-center w-full">
      <strong>{{ noteStateText }}.&nbsp; </strong>
      <span v-if="isUserScribe" class="inline-block">Published on <strong>{{ notePublishedOn.date }}</strong> at <strong>{{ notePublishedOn.time }}.&nbsp;</strong></span>
      <span class="inline-block">
        Last changed on
        <strong>{{ noteEditedOn.date }}</strong> at <strong>{{ noteEditedOn.time }}.&nbsp;</strong>
      </span>
      <span class="inline-block">
        Created on <strong>{{ noteCreatedOn.date }}</strong> at <strong>{{ noteCreatedOn.time }}. </strong>
      </span>
      <div class="block xl:hidden pt-3 pb-1 sm:py-0 mx-auto sm:mr-0 sm:my-auto">
        <button class="rounded-md text-white bg-rare2-dark hover:bg-rare-2 text-sm px-14 py-2 whitespace-nowrap" :disabled="loading"
                @click="stateChange(inPms)">
          Added to PMS
        </button>
      </div>

    </div>
    <div v-else-if="noteStateVal === inPms" class="sm:inline flex flex-col sm:flex-row items-center w-full">
      <!-- TODO Verified vs Reviewed for different customers -->
      <span class="inline-block">
        <strong>  {{ isUserVerified || isUserScribe ? 'Verified' : 'Completed' }} and in PMS.</strong>
      </span>
      <span v-if="isUserScribe" class="inline-block">Published on <strong>{{ notePublishedOn.date }}</strong> at <strong>{{ notePublishedOn.time }}.&nbsp;</strong></span>
      <span class="inline-block">
        Last changed on
        <strong>{{ noteEditedOn.date }}</strong> at <strong>{{ noteEditedOn.time }}.&nbsp;</strong>
      </span>
      <span class="inline-block">
        Created on <strong>{{ noteCreatedOn.date }}</strong> at <strong>{{ noteCreatedOn.time }}. </strong>
      </span>
    </div>
  </div>
</template>
<script>
import NotesStateMap from "Modules/NoteStatesMap";

export default {
  name: "NoteHeaderSection",
  emits: ["Note:respondIssue", "NoteTimeline:changeState", "NoteTimeline:resolveIssue", "NoteTimeline:deleteIssue"],
  components: {},
  props: {
    note: Object,
  },
  data () {
    return {
      // values match the noteStateValMap, We just don't need the text values
      draft: 0,
      pending: 1,
      review: 2,
      verified: 3,
      inPms: 4,
      processing: 5,
      needsAttention: 6,
      loading: false,
      // used to index the issue, form the issue list. this will let a user page through the issues if there are many
      issueIndex: 0,
    };
  },
  watch: {
    // watch note, On state change we animate the timeline
    note: {
      handler (newVal) {
        this.loading = false;
      },
      deep: true
    }
  },
  computed: {
    isUserScribe () {
      if (!this.$store.getters.getUserGroups) return false;
      return this.$store.getters.getUserGroups.findIndex(x => x === "Scribe") !== -1;
    },
    isUserVerified () {
      return this.$store.getters.getUserPerms.verified;
    },
    noteStateVal () {
      if (!this.note) return -1;
      return parseInt(this.note.state);
    },
    noteStateText () {
      if (!this.note) return '';
      if (this.note.needs_attention) return 'Needs Attention';
      if (this.note?.archived) return 'Archived';
      let key = this.note.state;
      if ((key === 3 || key === 4) && !(this.isUserVerified || this.isUserScribe)) {
        switch (key) {
          case (3): return "Completed";
          case (4): return "Completed and in PMS";
        }
      }
      return NotesStateMap[key];
    },
    noteCreatedOn () {
      if (!this.note) return '';
      const createdAt = new Date(this.note.created_at * 1000);
      return {
        date: Intl.DateTimeFormat(undefined, { year: "numeric", month: "short", day: "numeric" }).format(createdAt),
        time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", second: "numeric" }).format(createdAt),
      };
    },
    notePublishedOn () {
      if (!this.note) return '';
      let ts = this.note.published_at == 0 ? this.note.last_edited : this.note.published_at;
      const publishedAt = new Date(ts * 1000);
      return {
        date: Intl.DateTimeFormat(undefined, { year: "numeric", month: "short", day: "numeric" }).format(publishedAt),
        time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", second: "numeric" }).format(publishedAt),
      };
    },
    noteEditedOn () {
      if (!this.note || this.note.last_edited == 0) return '';
      const editedAt = new Date(this.note.last_edited * 1000);
      return {
        date: Intl.DateTimeFormat(undefined, { year: "numeric", month: "short", day: "numeric" }).format(editedAt),
        time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", second: "numeric" }).format(editedAt),
      };
    },
    authorRespondedToIssue () {
      if (!this.note) return false;
      if (!this.note.needs_attention) return false;
      if (this.note.issues.length === 0) return false;
      if (this.note.issues[0].responses.length === 0) return false;
      return this.note.issues[0].responses.findIndex(x => x.responded_by == this.note.user_id) !== -1;
    },
  },
  methods: {
    // passed the integer value representing a state.
    stateChange (changeTo) {
      this.loading = true;
      this.$emit("NoteTimeline:changeState", changeTo);
    },
  },
};

</script>
<style></style>
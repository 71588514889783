const colors = require('tailwindcss/colors');
const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  theme: {
    extend: {
      colors: {
        gray: colors.neutral,

        main: {
          DEFAULT: '#4C9DB8',
          lighter: '#97D6ED',
          light: '#52BAE2',
          alt: '#0386B7',
          dark: '#0F7A9E',
          darker: '#124C60',
        },
        neutral: {
          DEFAULT: "#7C7C7C",
          lightest: "#F8F8F8",
          lighter: '#E5E5E5',
          light: '#C4C4C4',
          alt: '#375A65',
          dark: '#444444',
          darker: '#2C2C2C',
        },

        secondary: {
          DEFAULT: '#FFB300',
          lighter: '#FBC665',
          light: '#FBC665',
          dark: '#C48D2B',
          darker: '#765E33',
        },
        rare: {
          DEFAULT: '#7A98CC',
          lightest: "#EDF1F8",
          lighter: '#D7E0FE',
          light: '#97ABED',
          alt: '#5273E2',
          dark: '#1B399F',
          darker: '#032CB7',
        },
        rare2: {
          DEFAULT: '#1B9F81',
          lighter: '#DCF9F3',
          light: '#6BEDD3',
          alt: '#2FDCB5',
          dark: '#03B78E',
          darker: '#1B9F81',
        },
        error: {
          DEFAULT: '#f54c57',
          lighter: '#fdedef',
          light: '#ff8a92',
          dark: '#ad1c26',
        },
        red: {
          DEFAULT: '#FF0000',
          lighter: '#ff939a',
          light: '#ff5863',
          dark: '#ff0010',
          darker: '#b3000b',
        },
        pastel: {
          'blue-light': '#C2E9F5',
          'blue-lighter': '#D1ECF6',
          'blue-lightest': '#F1FCFF',
          'yellow-light': '#FDF2DC',
          'yellow-lighter': '#FEF8EB',
          'green-light': '#E8FCF8',
          'green-lighter': '#E4F7F3',
          'purple-light': '#EEF2F9',
          'purple-lighter': '#E5EBF5',
          'red-light': '#F9EEEE',
          'red-lighter': '#F5E5E5'
        },
        charcoal: '#434343',
        white: '#FFFFFF',
        white2: '#FAFAFA',
      },
      borderWidth: {
        "3": "3px"
      },
      margin: {
        '96': '24rem',
        '128': '32rem',
      },
      padding: {
        '96': '24rem',
        '128': '32rem',
      },
      width: {
        '96': '24rem',
        '120': '30rem',
        '128': '32rem',
        '130': '32.5rem',
        '136': '38rem',
        '140': '42rem',
        '156': '48rem',
      },
      height: {
        '96': '24rem',
        '120': '30rem',
        '128': '32rem',
        '130': '32.5rem',
        '136': '38rem',
        '140': '42rem',
        '156': '48rem',
      },
      minWidth: {
        '96': '24rem',
        '120': '30rem',
        '128': '32rem',
        '130': '32.5rem',
        '136': '38rem',
        '140': '42rem',
        '156': '48rem',
      },
      minHeight: {
        '96': '24rem',
        '120': '30rem',
        '128': '32rem',
        '130': '32.5rem',
        '136': '38rem',
        '140': '42rem',
        '156': '48rem',
      },
    },

    // https://tailwindcss.com/docs/font-family#customizing-your-theme
    fontFamily: {
      'montserrat': ['Montserrat', 'serif'],
      'rokkitt': ['Rokkitt', 'serif'],
      'roboto': ['"Roboto"', 'serif'],
      'roboto-condensed': ['"Roboto Condensed"', 'serif'],
      'roboto-slab': ['"Roboto Slab"', 'serif'],
    },
    // https://tailwindcss.com/docs/screens#adding-smaller-breakpoints
    screens: {
      'xs': '360px',
      'sm': '640px',
      'md': '768px',
      'lg': '1024px',
      'xl': '1200px',
      '2xl': '1400px',
      '3xl': '1800px',
      '4xl': '2400px',
      // ...defaultTheme.screens,
    },
  },
  variants: {
    textColor: ['responsive', 'hover', 'focus', 'group-hover'],
  },
  content: [
    "./public/index.html",
    "./src/**/*.{vue,js,ts,jsx,tsx}",
  ],
};